import React from "react";
import { FileDownload } from "@mui/icons-material";
import {
  IconButton,
  LinearProgress,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
} from "@mui/material";
import { BULLET_POINT } from "~/constants";
import { formatBytes, formatPercent } from "~/format";
import { invariant } from "~/lib/invariant";
import type { Digestion } from "~/lqs";
import { ProcessState, useLogObject } from "~/lqs";

export function DigestionListItem({
  digestion,
  size = "normal",
}: {
  digestion: Digestion;
  size?: "large" | "normal";
}) {
  const downloadQueryEnabled = digestion.state === ProcessState.Completed;

  const downloadQuery = useLogObject(
    digestion.logId,
    `digestions/${digestion.id}/extraction_${digestion.id}.bag`,
    {
      enabled: downloadQueryEnabled,
      select({ data: { presignedUrl, size } }) {
        invariant(presignedUrl != null, "No presigned URL");

        return { href: presignedUrl, size };
      },
    },
  );

  let secondaryText: string = digestion.state;
  let progressBar = null;
  if (
    digestion.state === ProcessState.Processing ||
    digestion.state === ProcessState.Finalizing
  ) {
    secondaryText = `${secondaryText} (${formatPercent(digestion.progress)})`;
    progressBar = (
      <LinearProgress
        sx={{ width: "100%" }}
        variant="determinate"
        value={(digestion.progress ?? 0) * 100}
      />
    );
  } else if (downloadQuery.isSuccess && downloadQuery.data.size != null) {
    secondaryText = `${secondaryText} ${BULLET_POINT} ${formatBytes(downloadQuery.data.size)}`;
  }

  const href = downloadQuery.data?.href;

  return (
    <ListItem
      disableGutters
      sx={{ flexDirection: "column", alignItems: "start" }}
    >
      <Stack
        direction="row"
        sx={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <ListItemText
          sx={{ m: 0 }}
          primary={digestion.name ?? digestion.id}
          primaryTypographyProps={{
            variant: size === "large" ? "h6" : "body1",
          }}
          secondary={secondaryText}
          secondaryTypographyProps={{
            variant: size === "large" ? "body1" : "body2",
            sx: { fontStyle: "italic" },
          }}
        />
        {downloadQueryEnabled && (
          <Tooltip title="Download">
            <span>
              <IconButton
                size={size === "large" ? "medium" : "small"}
                {...(href == null ? { disabled: true } : { href })}
              >
                <FileDownload
                  fontSize={size === "large" ? "medium" : "small"}
                />
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Stack>
      {progressBar}
    </ListItem>
  );
}
