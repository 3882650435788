import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import type { Path } from "react-router-dom";
import type { CountableListResponse } from "~/domain/common";
import type {
  ApiVersion,
  DeprecationPolicy,
  VersionHistories,
} from "~/domain/versioning";
import { DeprecableLabel } from "~/domain/versioning";
import { invariant } from "~/lib/invariant";
import { Card } from "../Card";
import { Center } from "../Center";
import { Loading } from "../Loading";
import { renderQuery } from "../QueryRenderer";
import { ErrorMessage } from "../error-message";
import { TableFooter } from "./TableFooter";
import { TableHeader } from "./TableHeader";
import { getNormalizedAvailableColumns, useColumnVisibility } from "./columns";
import { SortDirection } from "./constants";
import type {
  Column,
  ForeignResourceRenderer,
  NormalizedColumn,
  ResourceTableModel,
} from "./types";
import { useEmbedded } from "./validation";

export interface ResourceTableProps<
  TResource extends object,
  TForeignResource extends string,
> {
  resourceName: string;
  resourceCreateLocation?: Partial<Path>;
  getRowKey: (resource: TResource) => React.Key;
  columns: ReadonlyArray<Column<TResource, TForeignResource>>;
  versionHistories?: VersionHistories<any>;
  apiVersion: ApiVersion;
  deprecationPolicy: DeprecationPolicy;
  renderForeignResource: ForeignResourceRenderer<TForeignResource>;
  searchQuery: UseQueryResult<CountableListResponse<TResource>>;
  tableModel: ResourceTableModel;
  onTableModelChange: (changes: Partial<ResourceTableModel>) => void;
  disableFilters?: boolean;
  activeFilterCount?: number;
}

export function ResourceTable<
  TResource extends object,
  TForeignResource extends string,
>({
  resourceCreateLocation,
  resourceName,
  getRowKey,
  columns,
  versionHistories,
  apiVersion,
  deprecationPolicy,
  renderForeignResource,
  searchQuery,
  tableModel,
  onTableModelChange,
  disableFilters,
  activeFilterCount,
}: ResourceTableProps<TResource, TForeignResource>) {
  const embedded = useEmbedded();

  const normalizedColumns = getNormalizedAvailableColumns(
    columns,
    renderForeignResource,
    apiVersion,
    deprecationPolicy,
    versionHistories,
  );

  const { visibleColumns, toggleColumnVisibility } = useColumnVisibility(
    resourceName,
    normalizedColumns,
  );

  function makeSortChangeHandler(order: string) {
    invariant(!embedded, "Sorting not supported in embedded mode");

    const isActiveSort = order === tableModel.order;
    const newSort =
      !isActiveSort || tableModel.sort === SortDirection.Desc
        ? SortDirection.Asc
        : SortDirection.Desc;

    return function handleSortChange() {
      onTableModelChange({ sort: newSort, order, offset: 0 });
    };
  }

  function renderHeaderCell(column: NormalizedColumn<TResource>) {
    const { sortKey } = column;

    let children: React.ReactNode = (
      <DeprecableLabel deprecated={column.deprecated}>
        {column.header}
      </DeprecableLabel>
    );
    if (!embedded && sortKey !== undefined) {
      const isActiveSort = sortKey === tableModel.order;

      children = (
        <TableSortLabel
          active={isActiveSort}
          direction={isActiveSort ? tableModel.sort : SortDirection.Asc}
          onClick={makeSortChangeHandler(sortKey)}
        >
          {children}
        </TableSortLabel>
      );
    }

    return (
      <TableCell key={column.header} align={column.align}>
        {children}
      </TableCell>
    );
  }

  const body = renderQuery(searchQuery, {
    loading: (
      <Box sx={{ py: 5 }}>
        <Loading type="circular" />
      </Box>
    ),
    error: (
      <Box sx={{ py: 5 }}>
        <ErrorMessage>
          An error occurred searching for {resourceName}s
        </ErrorMessage>
      </Box>
    ),
    success(result) {
      if (result.count === 0) {
        return (
          <Center sx={{ py: 5 }}>
            <Typography variant="h4" component="p">
              The search returned 0 results
            </Typography>
          </Center>
        );
      } else {
        return (
          <TableContainer
            sx={{
              overflowX: "auto",
              whiteSpace: "nowrap",
              ...(embedded && {
                maxHeight: 350,
                overflowY: "auto",
              }),
            }}
          >
            <Table>
              <TableHead>
                <TableRow
                  sx={{
                    [`& .${tableCellClasses.root}`]: {
                      bgcolor: (theme) =>
                        theme.palette.mode === "dark" ? "grey.800" : "grey.300",
                      borderBottom: "unset",
                    },
                  }}
                >
                  {visibleColumns.map(renderHeaderCell)}
                </TableRow>
              </TableHead>
              <TableBody>
                {result.data.map((resource) => (
                  <TableRow
                    key={getRowKey(resource)}
                    sx={{
                      // Remove bottom border for table cells in last row
                      [`&:last-of-type .${tableCellClasses.root}`]: {
                        borderBottom: "unset",
                      },
                    }}
                  >
                    {visibleColumns.map((column) => (
                      <React.Fragment key={column.header}>
                        {column.renderCell(resource, { align: column.align })}
                      </React.Fragment>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    },
  });

  // Don't wrap in a `<Card />` when embedded as it's assumed the embedding
  // component will be rendering some form of container
  const Root = embedded ? React.Fragment : Card;

  return (
    <Root>
      <TableHeader
        resourceCreateLocation={resourceCreateLocation}
        resource={resourceName}
        columns={normalizedColumns}
        visibleColumns={visibleColumns}
        toggleColumnVisibility={toggleColumnVisibility}
        searchQuery={searchQuery}
        disableFilters={disableFilters}
        activeFilterCount={activeFilterCount}
      />
      {body}
      <TableFooter
        count={searchQuery.data?.count}
        paginationModel={tableModel}
        onPaginationModelChange={onTableModelChange}
      />
    </Root>
  );
}
