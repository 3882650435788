import prettyMilliseconds from "pretty-ms";
import { Timestamp } from "~/format";
import { nanosecondsToMilliseconds } from "~/lib/dates";
import type { Log } from "~/lqs";

export function renderRecorded(log: Log) {
  return <Timestamp value={log.startTime} />;
}

export function renderDuration(log: Log) {
  const { startTime, endTime } = log;

  return startTime === null || endTime === null
    ? "-"
    : prettyMilliseconds(nanosecondsToMilliseconds(endTime - startTime), {
        secondsDecimalDigits: 0,
      });
}
