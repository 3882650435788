import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { ResourceInfo } from "~/components/resource-info";
import { assertNever } from "~/utils";
import { useDsmQuery, userKeys } from "./api";
import { useDsmContext } from "./provider";
import type { DsmForeignResourceType } from "./types";

interface DsmResourceLinkProps {
  resourceType: DsmForeignResourceType;
  uuid: string;
}

export function DsmResourceLink({ resourceType, uuid }: DsmResourceLinkProps) {
  const { query } = useResourceQuery({ resourceType, uuid });

  return <ResourceInfo name={query.data} identifier={uuid} />;
}

// TODO: Create links to resources
function useResourceQuery({ resourceType, uuid }: DsmResourceLinkProps): {
  query: UseQueryResult<string | null>;
} {
  const dsmClients = useDsmContext();

  let queryOptions: UseQueryOptions<any, unknown, string | null>;
  switch (resourceType) {
    case "user": {
      queryOptions = createOptions({
        queryKey: userKeys.fetch(uuid),
        queryFn({ signal }) {
          return dsmClients.userApi.fetchUser({ userId: uuid }, { signal });
        },
        select({ data: { username } }) {
          return username;
        },
      });

      break;
    }
    default: {
      assertNever(resourceType);
    }
  }

  return {
    query: useDsmQuery(queryOptions),
  };
}

function createOptions<TQueryFnData>(
  options: UseQueryOptions<TQueryFnData, unknown, string | null>,
) {
  return options;
}
