import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { WorkflowHookEditForm } from "./components/WorkflowHookEditForm";

export function EditWorkflowHookPage() {
  return (
    <LqsPage title="Edit Hook">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <WorkflowHookEditForm />
      </Container>
    </LqsPage>
  );
}
