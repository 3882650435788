import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { CopyableText } from "~/components/CopyableText";
import {
  CheckboxField,
  Form,
  TextField,
  useStudioForm,
} from "~/components/Form";
import { UserSelect } from "~/domain/common";
import { useCreateApiKey } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createApiKeySchema } from "../schemas";

export function ApiKeyCreateForm() {
  const navigate = useNavigate();

  const lqsNavigator = useLqsNavigator({ toApiKeyTable: true });

  const { enqueueSnackbar } = useSnackbar();

  const createApiKey = useCreateApiKey();

  const { control, handleSubmit } = useStudioForm({
    schema: createApiKeySchema,
    defaultValues: {
      name: null,
      userId: null,
      disabled: false,
    },
    onSubmit(values) {
      createApiKey.mutate(values, {
        onError() {
          enqueueSnackbar("Could not create API key", { variant: "error" });
        },
      });
    },
  });

  function handleCloseDialog() {
    navigate(lqsNavigator.toApiKeyTable());
  }

  return (
    <>
      <Card>
        <CardContent>
          <Form
            onSubmit={handleSubmit}
            loading={createApiKey.isLoading}
            submitText="Create"
          >
            <TextField control={control} name="name" required />
            <UserSelect control={control} name="userId" required />
            <CheckboxField control={control} name="disabled" />
          </Form>
        </CardContent>
      </Card>
      {createApiKey.isSuccess && (
        <Dialog open maxWidth="xs" fullWidth>
          <DialogTitle>API Key Created</DialogTitle>
          <DialogContent>
            <Typography paragraph>
              Authenticating with this API key requires both its ID and secret
            </Typography>
            <CopyableText label="ID" text={createApiKey.data.data.id} />
            <CopyableText
              label="Secret"
              text={createApiKey.data.data.secret!}
            />
            <Typography
              paragraph
              variant="body2"
              sx={{ fontWeight: "bold", mt: 1 }}
            >
              This is the only time you will have access to this secret!
            </Typography>
            <Typography variant="body2">
              Closing this dialog will redirect you to the table page
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
