/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CloudObject } from "./CloudObject";
import {
  CloudObjectFromJSON,
  CloudObjectFromJSONTyped,
  CloudObjectToJSON,
} from "./CloudObject";

export interface ObjectDataResponse {
  data: CloudObject;
}

export function ObjectDataResponseFromJSON(json: any): ObjectDataResponse {
  return ObjectDataResponseFromJSONTyped(json);
}

export function ObjectDataResponseFromJSONTyped(json: any): ObjectDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: CloudObjectFromJSON(json["data"]),
  };
}

export function ObjectDataResponseToJSON(
  value: ObjectDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: CloudObjectToJSON(value.data),
  };
}
