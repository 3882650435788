import React, { useState } from "react";
import { Button, styled, Typography } from "@mui/material";
import { QueryClientProvider } from "@tanstack/react-query";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import { GlobalLoadingFallback } from "~/components/GlobalLoadingFallback";
import { createQueryClient } from "~/create-query-client";
import { ApiVersion, v } from "~/domain/versioning";
import type { DataStore } from "~/dsm";
import { useDataStores } from "~/dsm";
import {
  APIKeyApi,
  CurrentDataStoreContext,
  DataStoreClientsContext,
  DigestionApi,
  GroupApi,
  IngestionApi,
  LabelApi,
  LogApi,
  LqsVersionProvider,
  ObjectStoreApi,
  RoleApi,
  TopicApi,
  UserApi,
  WorkflowApi,
} from "~/lqs";
import { LqsCommonResourcesProvider } from "~/lqs/lqs-common-resources-provider";
import * as paths from "~/paths";
import { makeStudioHomepageLocation, useTypedParams } from "~/paths";
import { createDataStoreApiConfiguration } from "./factories";
import {
  dataStoreRoutePaths,
  StudioLqsNavigatorProvider,
} from "./lqs-navigator";

const DEFAULT_DATA_STORE_VERSION = v(1, 0, 0);

export function DataStoreProvider({ children }: { children: React.ReactNode }) {
  const { dataStoreName } = useTypedParams(
    dataStoreRoutePaths.DATASTORE_DASHBOARD,
  );

  const dataStoreQuery = useDataStores(
    // Search the user's DataStores for one whose name exactly matches
    // the URL path param
    { name: dataStoreName },
    {
      select(response) {
        if (response.count !== 1) {
          return null;
        }

        return response.data[0];
      },
    },
  );

  // TODO: Should do something better for errors
  if (!dataStoreQuery.isSuccess) {
    return <GlobalLoadingFallback />;
  }

  const { data: dataStore } = dataStoreQuery;

  if (dataStore === null) {
    const { state, ...to } = paths.makeStudioHomepageLocation({
      unknownDataStore: dataStoreName,
    });

    return <Navigate replace to={to} state={state} />;
  }

  return (
    <InnerProvider key={dataStore.id} dataStore={dataStore}>
      {children}
    </InnerProvider>
  );
}

function InnerProvider({
  dataStore,
  children,
}: {
  dataStore: DataStore;
  children: React.ReactNode;
}) {
  const [clients] = useState(() => {
    const configuration = createDataStoreApiConfiguration(dataStore);

    return {
      apiKeyApi: new APIKeyApi(configuration),
      digestionApi: new DigestionApi(configuration),
      groupApi: new GroupApi(configuration),
      ingestionApi: new IngestionApi(configuration),
      labelApi: new LabelApi(configuration),
      logApi: new LogApi(configuration),
      objectStoreApi: new ObjectStoreApi(configuration),
      roleApi: new RoleApi(configuration),
      topicApi: new TopicApi(configuration),
      userApi: new UserApi(configuration),
      workflowApi: new WorkflowApi(configuration),
    };
  });

  // All DataStore queries will be isolated to their own query client.
  // Since this component is expected to be rendered with a `key` linked to
  // the current DataStore, changing DataStores will ensure the old client
  // is discarded and a new one always created.
  const [queryClient] = useState(createQueryClient);

  let dataStoreVersion: ApiVersion;
  if (dataStore.version == null) {
    dataStoreVersion = DEFAULT_DATA_STORE_VERSION;
  } else {
    try {
      dataStoreVersion = ApiVersion.parse(dataStore.version);
    } catch {
      return <DataStoreVersionError />;
    }
  }

  return (
    <CurrentDataStoreContext.Provider value={dataStore}>
      <DataStoreClientsContext.Provider value={clients}>
        <QueryClientProvider client={queryClient}>
          <LqsVersionProvider version={dataStoreVersion}>
            <StudioLqsNavigatorProvider dataStore={dataStore}>
              <LqsCommonResourcesProvider>
                {children}
              </LqsCommonResourcesProvider>
            </StudioLqsNavigatorProvider>
          </LqsVersionProvider>
        </QueryClientProvider>
      </DataStoreClientsContext.Provider>
    </CurrentDataStoreContext.Provider>
  );
}

const Root = styled(Card)(({ theme }) => ({
  width: 500,
  maxWidth: `calc(100% - ${theme.spacing(2)})`,
}));

function DataStoreVersionError() {
  return (
    <Center>
      <Root title="Unknown DataStore Version" error>
        <Typography gutterBottom>
          Studio cannot determine the DataStore's version.
        </Typography>
        <Typography gutterBottom>
          Without the version, Studio cannot properly interact with the
          DataStore.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          disableElevation
          component={RouterLink}
          to={makeStudioHomepageLocation()}
        >
          Return to DataStores
        </Button>
      </Root>
    </Center>
  );
}
