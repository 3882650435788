import type { AccessorResourceField } from "~/components/DetailsCards/types";
import type { CommonResource } from "./types";

export const commonResourceDetails: ReadonlyArray<
  AccessorResourceField<CommonResource, "user">
> = [
  { dataType: "foreign-key", resourceType: "user", accessor: "createdBy" },
  { dataType: "datetime", accessor: "createdAt" },
  { dataType: "foreign-key", resourceType: "user", accessor: "updatedBy" },
  { dataType: "datetime", accessor: "updatedAt" },
  { dataType: "foreign-key", resourceType: "user", accessor: "deletedBy" },
  { dataType: "datetime", accessor: "deletedAt" },
];
