import type { Topic } from "~/lqs";
import type { Maybe } from "~/types";
import type { InitializedPanelNode } from "../panels";

export type InferenceTopicSearchResult =
  | { status: "found"; topic: Topic }
  | { status: "unset"; topic?: undefined }
  | { status: "missing"; topic?: undefined };

export function searchForInferenceTopic(
  panel: Maybe<InitializedPanelNode>,
  playerTopics: Maybe<ReadonlyArray<Topic>>,
): InferenceTopicSearchResult {
  if (panel == null || playerTopics == null) {
    // This case currently only happens when the on-demand inference provider
    // searches for a possibly-nullish panel's inference topic and/or the
    // player topics query hasn't fulfilled yet. In either situation, it
    // shouldn't do anything with the result so this value should be fine as
    // opposed to adding another type to the union which other callers would
    // then have to handle.
    return { status: "unset" };
  }

  if (panel.inferenceTopicName === null) {
    return { status: "unset" };
  }

  const topic = playerTopics.find(
    (topic) => topic.name === panel.inferenceTopicName,
  );

  if (topic === undefined) {
    return { status: "missing" };
  } else {
    return { status: "found", topic };
  }
}
