export function selectData<TResponse extends { data: unknown }>({
  data,
}: TResponse): TResponse["data"] {
  return data;
}

export function scheduleQueriesRemoval(cb: () => void): void {
  // When a resource is deleted, its related queries are removed from the
  // query cache before navigating back to the table page. The navigation
  // should happen within a few microtasks after the removal. For some reason
  // in Safari, this sequence of events leads to another fetch being made for
  // the deleted resource's details only to immediately be cancelled after
  // leaving the page. This doesn't appear to happen in Chrome.
  //
  // To avoid the unnecessary fetch, the removal can happen after a short
  // timeout. Using a timeout of 0 also didn't solve the problem, but a small
  // timeout like 50ms seems to work.
  setTimeout(cb, 50);
}
