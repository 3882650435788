import type { BufferGeometry } from "three";
import { Color } from "three";
import { DRACOLoader } from "three-stdlib";

const DRACO_DECODERS_URL =
  "https://www.gstatic.com/draco/versioned/decoders/1.5.7/";

const dracoLoader = new DRACOLoader();

dracoLoader.setDecoderPath(DRACO_DECODERS_URL);

export async function loadDracoModel(
  base64EncodedDraco: string,
): Promise<BufferGeometry> {
  const dataUrl = `data:application/octet-stream;base64,${base64EncodedDraco}`;

  // The Draco loader isn't being used for caching here. It's being used for
  // asynchronously decoding the Draco model into three's `BufferGeometry`
  // using the Draco library and a web worker (to decode off the main thread).
  const bufferGeometry = await dracoLoader.loadAsync(dataUrl);

  // The Draco models contain color attributes encoded as unsigned 8-bit ints
  // representing the RGB values for each point, with values being in the range
  // [0, 255]. Three.js internally works with linear RGB colors normalized to
  // the range [0, 1], though, so Studio needs to normalize the non-linear RGB
  // values to the range [0, 1] and then convert to linear RGB.
  const colorAttribute = bufferGeometry.getAttribute("color");
  const color = new Color();

  for (let index = 0; index < colorAttribute.count; index++) {
    color.fromBufferAttribute(colorAttribute, index);
    color.r /= 255;
    color.g /= 255;
    color.b /= 255;
    color.convertSRGBToLinear();
    colorAttribute.setXYZ(index, color.r, color.g, color.b);
  }

  return bufferGeometry;
}
