import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { Path } from "react-router-dom";
import { ResourceInfo } from "~/components/resource-info";
import {
  digestionKeys,
  groupKeys,
  ingestionKeys,
  labelKeys,
  logKeys,
  objectStoreKeys,
  roleKeys,
  topicKeys,
  useDataStoreClients,
  userKeys,
  workflowKeys,
} from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { assertNever } from "~/utils";
import type { LqsForeignResourceType } from "./types";

interface LqsResourceLinkProps {
  resourceType: LqsForeignResourceType;
  uuid: string;
}

export function LqsResourceLink({ resourceType, uuid }: LqsResourceLinkProps) {
  const { query, to } = useResourceQuery({ resourceType, uuid });

  return <ResourceInfo name={query.data} identifier={uuid} to={to} />;
}

function useResourceQuery({ resourceType, uuid }: LqsResourceLinkProps): {
  query: UseQueryResult<string | null>;
  to: Partial<Path> | undefined;
} {
  const dataStoreClients = useDataStoreClients();

  const lqsNavigator = useLqsNavigator();

  let queryOptions: UseQueryOptions<any, unknown, string | null>;
  let to: Partial<Path> | undefined;
  switch (resourceType) {
    case "digestion": {
      queryOptions = createOptions({
        queryKey: digestionKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.digestionApi.fetchDigestion(
            { digestionId: uuid },
            { signal },
          );
        },
        select({ data: { name } }) {
          return name;
        },
      });

      to = lqsNavigator.toDigestionDetails?.({ digestionId: uuid });

      break;
    }
    case "group": {
      queryOptions = createOptions({
        queryKey: groupKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.groupApi.fetchGroup(
            { groupId: uuid },
            { signal },
          );
        },
        select({ data: { name } }) {
          return name;
        },
      });

      to = lqsNavigator.toGroupDetails?.({ groupId: uuid });

      break;
    }
    case "ingestion": {
      queryOptions = createOptions({
        queryKey: ingestionKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.ingestionApi.fetchIngestion(
            { ingestionId: uuid },
            { signal },
          );
        },
        select({ data: { name } }) {
          return name;
        },
      });

      to = lqsNavigator.toIngestionDetails?.({ ingestionId: uuid });

      break;
    }
    case "label": {
      queryOptions = createOptions({
        queryKey: labelKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.labelApi.fetchLabel(
            { labelId: uuid },
            { signal },
          );
        },
        select({ data: { value } }) {
          return value;
        },
      });

      to = lqsNavigator.toLabelDetails?.({ labelId: uuid });

      break;
    }
    case "log": {
      queryOptions = createOptions({
        queryKey: logKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.logApi.fetchLog({ logId: uuid }, { signal });
        },
        select({ data: { name } }) {
          return name;
        },
      });

      to = lqsNavigator.toLogDetails?.({ logId: uuid });

      break;
    }
    case "object-store": {
      queryOptions = createOptions({
        queryKey: objectStoreKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.objectStoreApi.fetchObjectStore(
            { objectStoreId: uuid },
            { signal },
          );
        },
        select({ data: { bucketName } }) {
          return bucketName;
        },
      });

      to = lqsNavigator.toObjectStoreDetails?.({ objectStoreId: uuid });

      break;
    }
    case "role": {
      queryOptions = createOptions({
        queryKey: roleKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.roleApi.fetchRole(
            { roleId: uuid },
            { signal },
          );
        },
        select({ data: { name } }) {
          return name;
        },
      });

      to = lqsNavigator.toRoleDetails?.({ roleId: uuid });

      break;
    }
    case "topic": {
      queryOptions = createOptions({
        queryKey: topicKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.topicApi.fetchTopic(
            { topicId: uuid },
            { signal },
          );
        },
        select({ data: { name } }) {
          return name;
        },
      });

      to = lqsNavigator.toTopicDetails?.({ topicId: uuid });

      break;
    }
    case "user": {
      queryOptions = createOptions({
        queryKey: userKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.userApi.fetchUser(
            { userId: uuid },
            { signal },
          );
        },
        select({ data: { username } }) {
          return username;
        },
      });

      to = lqsNavigator.toUserDetails?.({ userId: uuid });

      break;
    }
    case "workflow": {
      queryOptions = createOptions({
        queryKey: workflowKeys.fetch(uuid),
        queryFn({ signal }) {
          return dataStoreClients.workflowApi.fetchWorkflow(
            { workflowId: uuid },
            { signal },
          );
        },
        select({ data: { name } }) {
          return name;
        },
      });

      to = lqsNavigator.toWorkflowDetails?.({ workflowId: uuid });

      break;
    }
    default: {
      assertNever(resourceType);
    }
  }

  return {
    query: useQuery(queryOptions),
    to,
  };
}

function createOptions<TQueryFnData>(
  options: UseQueryOptions<TQueryFnData, unknown, string | null>,
) {
  return options;
}
