import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { listLogQueriesSchema } from "../schemas";
import { useLogQuerySearchRequest } from "./LogQueryTable";

export function LogQueryFilters() {
  const [request, setRequest] = useLogQuerySearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="query"
      request={request}
      setRequest={setRequest}
      schema={listLogQueriesSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "statement", type: "fuzzy", fuzzyName: "statementLike" },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowIdNull", type: "boolean" },
        { name: "noteLike", type: "text", label: "Note" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
