import {
  EditLqsResourceForm,
  processStateOptions,
  useIngestionPart,
  useUpdateIngestionPart,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editIngestionPartSchema } from "../schemas";

export function IngestionPartEditForm() {
  const { ingestionId, ingestionPartId } = useTypedParams(
    lqsRoutePaths.INGESTION_PART_EDIT,
  );

  const lqsNavigator = useLqsNavigator({ toIngestionPartDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="ingestion part"
      registryKey="ingestionPart"
      query={useIngestionPart(ingestionId, ingestionPartId, {
        select: selectData,
      })}
      schema={editIngestionPartSchema}
      descriptors={[
        { name: "sequence", type: "number", required: true },
        { name: "source", type: "text" },
        {
          name: "state",
          type: "select",
          options: processStateOptions,
          required: true,
        },
        { name: "progress", type: "number" },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowContext", type: "json" },
        { name: "error", type: "json" },
        { name: "errorName", type: "text" },
        { name: "errorMessage", type: "text" },
        { name: "errorPayload", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateIngestionPart(ingestionId, ingestionPartId)}
      detailsLocation={lqsNavigator.toIngestionPartDetails({
        ingestionPartId,
        ingestionId,
      })}
    />
  );
}
