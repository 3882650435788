import React from "react";
import { FormControlLabel, Switch } from "@mui/material";
import type { Topic } from "~/lqs";
import { useAreTopicTagsShowing, useTagActions } from "../tags";

export function TagsControl({ topic }: { topic: Topic }) {
  const areTopicTagsShowing = useAreTopicTagsShowing(topic.id);
  const { toggleTopicTags } = useTagActions();

  function handleToggleTags() {
    toggleTopicTags(topic.id);
  }

  return (
    <FormControlLabel
      sx={{ justifyContent: "space-between", ml: 0 }}
      control={
        <Switch checked={areTopicTagsShowing} onChange={handleToggleTags} />
      }
      label="Topic tags"
      labelPlacement="start"
    />
  );
}
