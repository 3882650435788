import { useSyncExternalStoreWithSelector } from "use-sync-external-store/with-selector";
import { identity, isEqual } from "~/lib/std";
import type { StoreSnapshot } from "./types";

function areSnapshotsEqual<TValue, TRequest>(
  prevSnapshot: StoreSnapshot<TValue, TRequest>,
  nextSnapshot: StoreSnapshot<TValue, TRequest>,
): boolean {
  if (
    nextSnapshot.status === "pending" &&
    prevSnapshot.status === "fulfilled"
  ) {
    // This is stretching the idea of the snapshots being equal but it's useful:
    // if a panel is showing data then switches to a pending state (for example,
    // moving to a timestamp where records aren't yet fetched) we want to
    // continue showing the previous data on screen until the new data is ready.
    // By returning `true` here, we're telling React the snapshots are "equal"
    // in the sense nothing has changed about what should be visualized.
    return true;
  }

  // In all other cases, perform a deep equality check on the entire snapshot.
  return isEqual(prevSnapshot, nextSnapshot);
}

export interface StoreSnapshotParams<
  TSnapshot extends StoreSnapshot<any, any>,
> {
  request: TSnapshot["request"];
  subscribe: (notify: () => void) => () => void;
  getSnapshot: () => TSnapshot;
}

export function useStoreSnapshot<TSnapshot extends StoreSnapshot<any, any>>({
  request,
  subscribe,
  getSnapshot,
}: StoreSnapshotParams<TSnapshot>): {
  snapshot: TSnapshot;
  isPlaceholder: boolean;
} {
  const snapshot = useSyncExternalStoreWithSelector(
    subscribe,
    getSnapshot,
    undefined,
    identity,
    areSnapshotsEqual,
  );

  return {
    snapshot,
    isPlaceholder:
      snapshot.status === "fulfilled" && !isEqual(request, snapshot.request),
  };
}
