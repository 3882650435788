import type React from "react";
import { useMemo } from "react";
import type { Path } from "react-router-dom";
import { createSafeContext } from "~/contexts";
import type { DataStore } from "~/dsm";
import { pipe } from "~/lib/std";
import type { JoinPaths, LqsNavigator } from "~/paths";
import {
  generateDestination,
  joinPaths,
  LqsNavigatorProvider,
  lqsRoutePaths,
} from "~/paths";

const DATASTORE_PREFIX = "/datastores/:dataStoreName" as const;

type DataStoreRoutePaths = {
  [Name in keyof typeof lqsRoutePaths]: JoinPaths<
    [typeof DATASTORE_PREFIX, (typeof lqsRoutePaths)[Name]]
  >;
};

export const dataStoreRoutePaths = generateDataStoreRoutePaths();

type StudioLqsNavigator = Required<LqsNavigator>;

const [useStudioLqsNavigator, StudioLqsNavigatorContext] =
  createSafeContext<StudioLqsNavigator>("StudioLqsNavigator");

export { useStudioLqsNavigator };

// Should only be used in the DSM section of Studio where DataStores objects
// are immediately available rather than needing to be read from context
export function toDataStoreDashboard(dataStore: DataStore): Partial<Path> {
  const { name: dataStoreName } = dataStore;

  return generateDestination({
    pattern: dataStoreRoutePaths.DATASTORE_DASHBOARD,
    params: { dataStoreName },
  });
}

export function StudioLqsNavigatorProvider({
  dataStore,
  children,
}: {
  dataStore: DataStore;
  children: React.ReactNode;
}) {
  const studioLqsNavigator = useMemo(
    () => createStudioLqsNavigator(dataStore),
    [dataStore],
  );

  return (
    <LqsNavigatorProvider navigator={studioLqsNavigator}>
      <StudioLqsNavigatorContext.Provider value={studioLqsNavigator}>
        {children}
      </StudioLqsNavigatorContext.Provider>
    </LqsNavigatorProvider>
  );
}

function createStudioLqsNavigator(dataStore: DataStore): StudioLqsNavigator {
  const { name: dataStoreName } = dataStore;

  return {
    DATASTORE_DASHBOARD: dataStoreRoutePaths.DATASTORE_DASHBOARD,
    toDashboard() {
      return generateDestination({
        pattern: dataStoreRoutePaths.DATASTORE_DASHBOARD,
        params: { dataStoreName },
      });
    },
    PLAYER: dataStoreRoutePaths.PLAYER,
    toPlayer(search) {
      return generateDestination({
        pattern: dataStoreRoutePaths.PLAYER,
        params: { dataStoreName },
        search,
      });
    },
    UPLOAD: dataStoreRoutePaths.UPLOAD,
    toUpload() {
      return generateDestination({
        pattern: dataStoreRoutePaths.UPLOAD,
        params: { dataStoreName },
      });
    },
    TAGGING: dataStoreRoutePaths.TAGGING,
    toTagging() {
      return generateDestination({
        pattern: dataStoreRoutePaths.TAGGING,
        params: { dataStoreName },
      });
    },
    PROFILE: dataStoreRoutePaths.PROFILE,
    toProfile() {
      return generateDestination({
        pattern: dataStoreRoutePaths.PROFILE,
        params: { dataStoreName },
      });
    },
    LOG_TABLE: dataStoreRoutePaths.LOG_TABLE,
    toLogTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_TABLE,
        params: { dataStoreName },
      });
    },
    toLogCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_CREATE,
        params: { dataStoreName },
      });
    },
    toLogDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogTagTable(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_TAG_TABLE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogTagCreate(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_TAG_CREATE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogTagDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_TAG_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogTagEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_TAG_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogQueryTable(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_QUERY_TABLE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogQueryCreate(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_QUERY_CREATE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogQueryDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_QUERY_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogQueryEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_QUERY_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogObjectTable(params, search) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_OBJECT_TABLE,
        params: {
          dataStoreName,
          ...params,
        },
        search,
      });
    },
    toLogObjectUpload(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_OBJECT_UPLOAD,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLogObjectDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LOG_OBJECT_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    INGESTION_TABLE: dataStoreRoutePaths.INGESTION_TABLE,
    toIngestionTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.INGESTION_TABLE,
        params: { dataStoreName },
      });
    },
    toIngestionCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.INGESTION_CREATE,
        params: { dataStoreName },
      });
    },
    toIngestionDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.INGESTION_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toIngestionEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.INGESTION_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toIngestionPartTable(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.INGESTION_PART_TABLE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toIngestionPartCreate(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.INGESTION_PART_CREATE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toIngestionPartDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.INGESTION_PART_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toIngestionPartEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.INGESTION_PART_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    TOPIC_TABLE: dataStoreRoutePaths.TOPIC_TABLE,
    toTopicTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.TOPIC_TABLE,
        params: { dataStoreName },
      });
    },
    toTopicCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.TOPIC_CREATE,
        params: { dataStoreName },
      });
    },
    toTopicDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.TOPIC_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toTopicEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.TOPIC_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toRecordTable(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.RECORD_TABLE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toRecordCreate(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.RECORD_CREATE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toRecordDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.RECORD_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toRecordEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.RECORD_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    DIGESTION_TABLE: dataStoreRoutePaths.DIGESTION_TABLE,
    toDigestionTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_TABLE,
        params: { dataStoreName },
      });
    },
    toDigestionCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_CREATE,
        params: { dataStoreName },
      });
    },
    toDigestionDetails(params, search) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
        search,
      });
    },
    toDigestionEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toDigestionPartTable(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_PART_TABLE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toDigestionPartCreate(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_PART_CREATE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toDigestionPartDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_PART_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toDigestionPartEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_PART_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toDigestionTopicTable(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_TOPIC_TABLE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toDigestionTopicCreate(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_TOPIC_CREATE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toDigestionTopicDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_TOPIC_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toDigestionTopicEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.DIGESTION_TOPIC_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    WORKFLOW_TABLE: dataStoreRoutePaths.WORKFLOW_TABLE,
    toWorkflowTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.WORKFLOW_TABLE,
        params: { dataStoreName },
      });
    },
    toWorkflowCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.WORKFLOW_CREATE,
        params: { dataStoreName },
      });
    },
    toWorkflowDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.WORKFLOW_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toWorkflowEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.WORKFLOW_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toWorkflowHookTable(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.WORKFLOW_HOOK_TABLE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toWorkflowHookCreate(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.WORKFLOW_HOOK_CREATE,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toWorkflowHookDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.WORKFLOW_HOOK_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toWorkflowHookEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.WORKFLOW_HOOK_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    LABEL_TABLE: dataStoreRoutePaths.LABEL_TABLE,
    toLabelTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.LABEL_TABLE,
        params: { dataStoreName },
      });
    },
    toLabelCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.LABEL_CREATE,
        params: { dataStoreName },
      });
    },
    toLabelDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LABEL_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toLabelEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.LABEL_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    OBJECT_STORE_TABLE: dataStoreRoutePaths.OBJECT_STORE_TABLE,
    toObjectStoreTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.OBJECT_STORE_TABLE,
        params: { dataStoreName },
      });
    },
    toObjectStoreCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.OBJECT_STORE_CREATE,
        params: { dataStoreName },
      });
    },
    toObjectStoreDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.OBJECT_STORE_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toObjectStoreEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.OBJECT_STORE_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toObjectStoreObjectTable(params, search) {
      return generateDestination({
        pattern: dataStoreRoutePaths.OBJECT_STORE_OBJECT_TABLE,
        params: {
          dataStoreName,
          ...params,
        },
        search,
      });
    },
    toObjectStoreObjectDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.OBJECT_STORE_OBJECT_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    USER_TABLE: dataStoreRoutePaths.USER_TABLE,
    toUserTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.USER_TABLE,
        params: { dataStoreName },
      });
    },
    toUserCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.USER_CREATE,
        params: { dataStoreName },
      });
    },
    toUserDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.USER_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toUserEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.USER_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    GROUP_TABLE: dataStoreRoutePaths.GROUP_TABLE,
    toGroupTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.GROUP_TABLE,
        params: { dataStoreName },
      });
    },
    toGroupCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.GROUP_CREATE,
        params: { dataStoreName },
      });
    },
    toGroupDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.GROUP_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toGroupEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.GROUP_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    ROLE_TABLE: dataStoreRoutePaths.ROLE_TABLE,
    toRoleTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.ROLE_TABLE,
        params: { dataStoreName },
      });
    },
    toRoleCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.ROLE_CREATE,
        params: { dataStoreName },
      });
    },
    toRoleDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.ROLE_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toRoleEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.ROLE_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    API_KEY_TABLE: dataStoreRoutePaths.API_KEY_TABLE,
    toApiKeyTable() {
      return generateDestination({
        pattern: dataStoreRoutePaths.API_KEY_TABLE,
        params: { dataStoreName },
      });
    },
    toApiKeyCreate() {
      return generateDestination({
        pattern: dataStoreRoutePaths.API_KEY_CREATE,
        params: { dataStoreName },
      });
    },
    toApiKeyDetails(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.API_KEY_DETAILS,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
    toApiKeyEdit(params) {
      return generateDestination({
        pattern: dataStoreRoutePaths.API_KEY_EDIT,
        params: {
          dataStoreName,
          ...params,
        },
      });
    },
  };
}

function generateDataStoreRoutePaths(): DataStoreRoutePaths {
  return pipe(
    Object.entries(lqsRoutePaths),
    (_) =>
      _.map(([name, pattern]) => [
        name,
        joinPaths([DATASTORE_PREFIX, pattern]),
      ]),
    Object.fromEntries,
  ) as DataStoreRoutePaths;
}
