import { useEffect, useState } from "react";
import { useIsMobile } from "~/layout";

export function useMobileFilters(): { show: boolean; toggle: () => void } {
  const isMobile = useIsMobile();

  const [showMobileFilters, setShowMobileFilters] = useState(false);

  useEffect(
    function closeMobileFiltersOnSizeUp() {
      if (!isMobile && showMobileFilters) {
        setShowMobileFilters(false);
      }
    },
    [isMobile, showMobileFilters],
  );

  function handleToggle(): void {
    setShowMobileFilters((prev) => !prev);
  }

  return {
    show: showMobileFilters,
    toggle: handleToggle,
  };
}
