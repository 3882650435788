import { NewLqsResourceForm, useCreateTag } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { createTagSchema } from "../schemas";

export function TagCreateForm() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_TAG_CREATE);

  const lqsNavigator = useLqsNavigator({ toLogTagDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="tag"
      registryKey="tag"
      schema={createTagSchema}
      descriptors={[
        {
          name: "labelId",
          type: "foreign-key",
          resourceType: "label",
          required: true,
        },
        {
          name: "topicId",
          type: "foreign-key",
          resourceType: "topic",
          additionalProps: { logId },
        },
        { name: "startTime", type: "timestamp" },
        { name: "endTime", type: "timestamp" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateTag(logId)}
      createDetailsLocation={(response) =>
        lqsNavigator.toLogTagDetails({ logId, tagId: response.data.id })
      }
    />
  );
}
