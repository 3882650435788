import React from "react";
import { LocalOffer, Settings } from "@mui/icons-material";
import { FileCloud, ScissorsCutting } from "mdi-material-ui";
import {
  DigestionSidebar,
  DigestionStateProvider,
  LabelManagementSection,
  LogSidebar,
  PanelControlsSidebar,
  PlayerPage,
  PlayerSettingsSidebar,
  TaggingSidebar,
} from "~/pages/player";
import { Timestep, VisualizationType } from "~/player";
import { StudioPlayerHeaderTitle } from "./header-title";
import { StudioPlayerInferenceProvider } from "./inference-provider";

export function StudioPlayerPage() {
  return (
    <PlayerPage
      headerTitle={<StudioPlayerHeaderTitle />}
      renderSidebarWrapper={(children) => (
        <DigestionStateProvider naming="digestion">
          {children}
        </DigestionStateProvider>
      )}
      InferenceProvider={StudioPlayerInferenceProvider}
      visualizationStoreParams={{
        [VisualizationType.Timeline]: {
          timestepOverride: Timestep.Decisecond,
          count: 34 * 10,
          limit: 100,
          prefetchBehind: 30 * 10,
          prefetchAhead: 90 * 10,
        },
        [VisualizationType.Chart]: {
          timestepOverride: Timestep.Second,
          count: 34,
          limit: 30,
          prefetchBehind: 30,
          prefetchAhead: 30,
        },
        [VisualizationType.Image]: {
          count: 30,
          limit: 30,
          prefetchBehind: 30,
          prefetchAhead: 30 * 7,
        },
        [VisualizationType.ThreeD]: {
          count: 30,
          limit: 5,
          prefetchBehind: 10,
          prefetchAhead: 90,
        },
      }}
      sidebars={[
        {
          sidebarId: "logs",
          trigger: {
            title: "Logs",
            icon: <FileCloud />,
          },
          element: <LogSidebar />,
        },
        {
          sidebarId: "tagging",
          trigger: {
            title: "Tagging",
            icon: <LocalOffer />,
          },
          element: (
            <TaggingSidebar
              renderLabelManagementSection={(props) => (
                <LabelManagementSection {...props} />
              )}
            />
          ),
        },
        {
          sidebarId: "digestions",
          trigger: {
            title: "Create a digestion",
            icon: <ScissorsCutting />,
          },
          element: <DigestionSidebar />,
        },
        {
          sidebarId: "settings",
          trigger: {
            title: "Settings",
            icon: <Settings />,
          },
          element: <PlayerSettingsSidebar />,
        },
        {
          sidebarId: "panel-controls",
          element: <PanelControlsSidebar />,
        },
      ]}
    />
  );
}
