import {
  NewLqsResourceForm,
  ProcessState,
  processStateOptions,
  useCreateDigestionPart,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { createDigestionPartSchema } from "../schemas";

export function DigestionPartCreateForm() {
  const { digestionId } = useTypedParams(lqsRoutePaths.DIGESTION_PART_CREATE);

  const lqsNavigator = useLqsNavigator({ toDigestionPartDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="digestion part"
      registryKey="digestionPart"
      schema={createDigestionPartSchema}
      descriptors={[
        { name: "sequence", type: "number", required: true },
        {
          name: "state",
          type: "select",
          options: processStateOptions,
          defaultValue: ProcessState.Ready,
          required: true,
        },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowContext", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateDigestionPart(digestionId)}
      createDetailsLocation={(response) =>
        lqsNavigator.toDigestionPartDetails({
          digestionId,
          digestionPartId: response.data.id,
        })
      }
    />
  );
}
