/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DataStoreAssociation } from "./DataStoreAssociation";
import {
  DataStoreAssociationFromJSON,
  DataStoreAssociationFromJSONTyped,
  DataStoreAssociationToJSON,
} from "./DataStoreAssociation";

export interface DataStoreAssociationDataResponse {
  data: DataStoreAssociation;
}

export function DataStoreAssociationDataResponseFromJSON(
  json: any,
): DataStoreAssociationDataResponse {
  return DataStoreAssociationDataResponseFromJSONTyped(json);
}

export function DataStoreAssociationDataResponseFromJSONTyped(
  json: any,
): DataStoreAssociationDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: DataStoreAssociationFromJSON(json["data"]),
  };
}

export function DataStoreAssociationDataResponseToJSON(
  value: DataStoreAssociationDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: DataStoreAssociationToJSON(value.data),
  };
}
