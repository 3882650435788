import { useMemo } from "react";
import { get } from "~/lib/std";
import type { LayoutProfile } from "~/player";
import { useDataStoreLayoutProfiles, usePlayerLog } from "~/player";
import { RuntimeProfileArraySchema } from "~/player/panels/api/models";

export function useDefaultLayoutProfile(): LayoutProfile | undefined {
  const dataStoreLayoutProfiles = useDataStoreLayoutProfiles();

  const logQuery = usePlayerLog();

  const defaultProfileName = get(
    logQuery.data?.context,
    "studio.default_layout_profile",
  );

  const logLayoutProfiles = useMemo(
    () =>
      RuntimeProfileArraySchema.catch([]).parse(
        get(logQuery.data?.context, "studio.layout_profiles"),
      ),
    [logQuery.data],
  );

  if (!logQuery.isSuccess) {
    // Log's layout profiles take precedence over DataStore's so return early
    // if log isn't fetched yet
    return;
  }

  return (
    // Prefer log's layout profiles over DataStore's
    logLayoutProfiles.find((profile) => profile.name === defaultProfileName) ??
    dataStoreLayoutProfiles.find(
      (profile) => profile.name === defaultProfileName,
    )
  );
}
