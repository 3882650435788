import * as z from "zod";
import {
  ClipInsetSide,
  MAX_IMAGE_BRIGHTNESS_PCT,
  MAX_IMAGE_CONTRAST_PCT,
  SplitOrientation,
  VisualizationType,
} from "../constants";
import {
  clipInsetSchema,
  chartFieldsSchema,
  imageFlipDirectionSchema,
  imageRotationSchema,
  topicNameSchema,
  topicTypeNameSchema,
  visualizationTypeSchema,
} from "./common";
import { previousPersistentPanelStateVersionSchema } from "./models.previous";

/**
 * @file models.ts
 *
 * This module encapsulates all the logic for serializing and deserializing
 * layout profiles kept in web storage to ensure they haven't been corrupted in
 * some way, such as a user editing the stored value. It exposes parsers and
 * types for both the runtime and storage format of the layout profiles, though
 * only the runtime types should be exposed outside the parent module.
 *
 * The runtime types refer to the shape of the data Studio works with in the
 * panel layout feature. It's a tree structure of panels - leaf nodes
 * representing panels on screen the user can interact with - and containers -
 * internal nodes transparent to the user which track the position and size
 * of panels and other containers.
 *
 * The storage types refer to the shape of the data when it's to be kept in
 * web storage. It's also a tree structure but with a different shape for the
 * panel nodes.
 *
 * This module additionally provides logic for migrating previous versions of
 * panel nodes. Prior implementations of this feature treated non-layout-related
 * panel fields as "state" and stored it as its own object on the panel node.
 * The runtime types have since moved away from that implementation but the
 * storage types have remained the same, as migrations aren't strictly necessary
 * to translate between the runtime and storage formats. The migration feature
 * uses several zod schemas representing "versions" of the stored data. Schemas
 * for older versions parse the data to ensure it's valid according to that
 * version's requirements, after which transformers will sequentially migrate
 * the data to the newest version of the storage format, applying default field
 * values when needed. The migration is entirely encapsulated within this
 * module: only the most recent storage format is exposed to sibling modules.
 *
 * The panel nodes have a notion, though not explicitly referenced, of
 * "session-only" fields: fields describing state about a given panel that
 * must persist for the duration of the panel's lifetime but should not be
 * stored. For example, the field describing if the image visualization's
 * controls are visible is considered session-only. These fields can be
 * identified by looking at the transforms between the runtime and storage
 * formats: session-only fields are dropped when transforming to the storage
 * format and given hard-coded default values when transforming to the runtime
 * format.
 */

// Persistent panel state

export const persistentPanelStateV5Schema = z.object({
  version: z.literal("5"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: chartFieldsSchema,
  tab: visualizationTypeSchema,
  colorizeImage: z.boolean(),
  imageRotationDeg: imageRotationSchema,
  imageFlipDirection: imageFlipDirectionSchema.nullable(),
  pointCloudPointSize: z.number().min(0).max(0.1),
  imageBrightnessPct: z.number().min(0).max(MAX_IMAGE_BRIGHTNESS_PCT),
  imageContrastPct: z.number().min(0).max(MAX_IMAGE_CONTRAST_PCT),
  supplementaryMapTopics: z.array(topicNameSchema), // Added
});

export type PersistentPanelState = z.infer<typeof persistentPanelStateV5Schema>;

const persistentPanelStateSchema = z.union([
  persistentPanelStateV5Schema,
  previousPersistentPanelStateVersionSchema,
]);

// Base node schemas

const nodeIdSchema = z.number();
const parentNodeIdSchema = nodeIdSchema.nullable();
const flexSchema = z.number();

const basePanelNodeSchema = z.strictObject({
  type: z.literal("panel"),
  id: nodeIdSchema,
  parentId: parentNodeIdSchema,
  flex: flexSchema,
});

const baseContainerNodeSchema = z.strictObject({
  type: z.literal("container"),
  id: nodeIdSchema,
  parentId: parentNodeIdSchema,
  flex: flexSchema,
  orientation: z.nativeEnum(SplitOrientation),
});

// Panel nodes schemas

const storedPanelNodeSchema = basePanelNodeSchema.extend({
  state: persistentPanelStateSchema.nullable(),
});

type StoredPanelNode = z.infer<typeof storedPanelNodeSchema>;

const topicSelectionConfigSchema = z.object({
  search: z.string().nullable(),
  [VisualizationType.Image]: z.boolean(),
  [VisualizationType.ThreeD]: z.boolean(),
  [VisualizationType.Map]: z.boolean(),
  lastSelected: topicNameSchema.nullable(),
});

export type TopicSelectionConfig = z.infer<typeof topicSelectionConfigSchema>;

const baseRuntimePanelNodeSchema = basePanelNodeSchema.extend({
  topicSelection: topicSelectionConfigSchema,
});

const uninitializedRuntimePanelNodeSchema = baseRuntimePanelNodeSchema.extend({
  isInitialized: z.literal(false),
});

const initializedRuntimePanelNodeSchema = baseRuntimePanelNodeSchema.extend({
  isInitialized: z.literal(true),
  topicName: topicNameSchema,
  topicTypeName: topicTypeNameSchema,
  visualization: visualizationTypeSchema,
  hasAutoSkipped: z.boolean(),
  fields: chartFieldsSchema,
  selectedTag: z.string().nullable(),
  imageBrightnessPct: z.number().min(0).max(MAX_IMAGE_BRIGHTNESS_PCT),
  imageContrastPct: z.number().min(0).max(MAX_IMAGE_CONTRAST_PCT),
  colorizeImage: z.boolean(),
  imageRotationDeg: imageRotationSchema,
  imageFlipDirection: imageFlipDirectionSchema.nullable(),
  inferenceTopicName: z.string().nullable(),
  lockInferenceTransform: z.boolean(),
  inferenceRotationDeg: imageRotationSchema,
  inferenceFlipDirection: imageFlipDirectionSchema.nullable(),
  showDetectionBoundingBoxes: z.boolean(),
  showDetectionClassNames: z.boolean(),
  hiddenObjectClassNames: z.array(z.string()),
  inferenceImageOpacity: z.number().min(0).max(1),
  inferenceImageClipInset: clipInsetSchema,
  colorizeInferenceImage: z.boolean(),
  pointCloudPointSize: z.number().min(0).max(0.1),
  supplementaryMapTopics: z.array(topicNameSchema),
});

const runtimePanelNodeSchema = z.discriminatedUnion("isInitialized", [
  uninitializedRuntimePanelNodeSchema,
  initializedRuntimePanelNodeSchema,
]);

type RuntimePanelNode = z.infer<typeof runtimePanelNodeSchema>;

// Panel node transformations

// Given a parsed panel node in storage format, transform it into an equivalent
// panel node in runtime format.
function storedPanelToRuntimePanel(
  storedPanel: StoredPanelNode,
): RuntimePanelNode {
  const { state, ...baseFields } = storedPanel;

  const topicSelection: TopicSelectionConfig = {
    search: null,
    [VisualizationType.Image]: false,
    [VisualizationType.ThreeD]: false,
    [VisualizationType.Map]: false,
    lastSelected: null,
  };

  if (state === null) {
    return {
      ...baseFields,
      topicSelection,
      isInitialized: false,
    };
  } else {
    return {
      ...baseFields,
      topicSelection,
      isInitialized: true,
      topicName: state.name,
      topicTypeName: state.messageTypeName,
      visualization: state.tab,
      hasAutoSkipped: false,
      fields: state.fields,
      selectedTag: null,
      imageBrightnessPct: state.imageBrightnessPct,
      imageContrastPct: state.imageContrastPct,
      colorizeImage: state.colorizeImage,
      imageRotationDeg: state.imageRotationDeg,
      imageFlipDirection: state.imageFlipDirection,
      inferenceTopicName: null,
      lockInferenceTransform: true,
      inferenceRotationDeg: 0,
      inferenceFlipDirection: null,
      showDetectionBoundingBoxes: true,
      showDetectionClassNames: true,
      hiddenObjectClassNames: [],
      inferenceImageOpacity: 1,
      inferenceImageClipInset: {
        side: ClipInsetSide.Left,
        percent: 0.5,
      },
      colorizeInferenceImage: false,
      pointCloudPointSize: state.pointCloudPointSize,
      supplementaryMapTopics: state.supplementaryMapTopics,
    };
  }
}

// Given a parsed panel node in runtime format, transform it into an equivalent
// panel node in storage format.
function runtimePanelToStoredPanel(
  runtimePanel: RuntimePanelNode,
): StoredPanelNode {
  const basePanel = {
    type: runtimePanel.type,
    id: runtimePanel.id,
    parentId: runtimePanel.parentId,
    flex: runtimePanel.flex,
  };

  if (runtimePanel.isInitialized) {
    return {
      ...basePanel,
      state: {
        version: "5",
        name: runtimePanel.topicName,
        messageTypeName: runtimePanel.topicTypeName,
        fields: runtimePanel.fields,
        tab: runtimePanel.visualization,
        imageBrightnessPct: runtimePanel.imageBrightnessPct,
        imageContrastPct: runtimePanel.imageContrastPct,
        colorizeImage: runtimePanel.colorizeImage,
        imageRotationDeg: runtimePanel.imageRotationDeg,
        imageFlipDirection: runtimePanel.imageFlipDirection,
        pointCloudPointSize: runtimePanel.pointCloudPointSize,
        supplementaryMapTopics: runtimePanel.supplementaryMapTopics,
      },
    };
  } else {
    return {
      ...basePanel,
      state: null,
    };
  }
}

// Container and layout node schemas

// zod has a concept of schema input and output types, with input essentially
// meaning "the shape of data that would be successfully parsed by the base
// schema" and output meaning "the shape of the parsed data after any
// transformations." If no transformations or refinements are used then there's
// no difference. Honestly, I don't think the input type has any meaningful
// usage but it's a required generic for all schemas. This pattern of creating
// separate input and output generics is necessary for defining lazy schemas
// when transformations are involved.
type StoredContainerNodeInput = z.input<typeof baseContainerNodeSchema> & {
  firstChild: StoredLayoutNodeInput;
  secondChild: StoredLayoutNodeInput;
};

type StoredLayoutNodeInput =
  | z.input<typeof storedPanelNodeSchema>
  | StoredContainerNodeInput;

type StoredContainerNodeOutput = z.output<typeof baseContainerNodeSchema> & {
  firstChild: StoredLayoutNodeOutput;
  secondChild: StoredLayoutNodeOutput;
};

type StoredLayoutNodeOutput =
  | z.output<typeof storedPanelNodeSchema>
  | StoredContainerNodeOutput;

type RuntimeContainerNode = z.infer<typeof baseContainerNodeSchema> & {
  firstChild: RuntimeLayoutNode;
  secondChild: RuntimeLayoutNode;
};

type RuntimeLayoutNode =
  | z.infer<typeof runtimePanelNodeSchema>
  | RuntimeContainerNode;

// The following unions with lazy schemas will parse a tree of panel or
// container nodes in one format and transform it to the other. Adding the
// transformations to the panel nodes in each union does mean there aren't
// schemas which can parse data in their base format (i.e. no parsing runtime
// format and outputting runtime format) but there's currently no need for that.
// When parsing the storage format, the goal is to make sure it's valid and
// immediately convert to runtime format, and vice versa. By applying the
// transformations to the panel node schemas we avoid needing to process the
// tree a second time to transform between formats which is difficult for TS.

const storedToRuntimeLayoutNodeSchema: z.ZodType<
  RuntimeLayoutNode,
  z.ZodTypeDef,
  StoredLayoutNodeInput
> = z.union([
  storedPanelNodeSchema.transform(storedPanelToRuntimePanel),
  baseContainerNodeSchema.extend({
    firstChild: z.lazy(() => storedToRuntimeLayoutNodeSchema),
    secondChild: z.lazy(() => storedToRuntimeLayoutNodeSchema),
  }),
]);

const runtimeToStoredLayoutNodeSchema: z.ZodType<
  StoredLayoutNodeOutput,
  z.ZodTypeDef,
  RuntimeLayoutNode
> = z.union([
  runtimePanelNodeSchema.transform(runtimePanelToStoredPanel),
  baseContainerNodeSchema.extend({
    firstChild: z.lazy(() => runtimeToStoredLayoutNodeSchema),
    secondChild: z.lazy(() => runtimeToStoredLayoutNodeSchema),
  }),
]);

// Layout profile schemas

const baseLayoutProfileSchema = z.strictObject({
  name: z.string(),
});

export const StoredToRuntimeLayoutProfileSchema =
  baseLayoutProfileSchema.extend({
    layout: storedToRuntimeLayoutNodeSchema,
  });

export const RuntimeProfileArraySchema = z.array(
  StoredToRuntimeLayoutProfileSchema,
);

export const RuntimeToStoredLayoutProfileSchema =
  baseLayoutProfileSchema.extend({
    layout: runtimeToStoredLayoutNodeSchema,
  });

export const StoredProfileArraySchema = z.array(
  RuntimeToStoredLayoutProfileSchema,
);

// Curated exports

export type UninitializedRuntimePanelNode = z.infer<
  typeof uninitializedRuntimePanelNodeSchema
>;

export type InitializedRuntimePanelNode = z.infer<
  typeof initializedRuntimePanelNodeSchema
>;

export type { RuntimePanelNode, RuntimeContainerNode, RuntimeLayoutNode };

export type RuntimeLayoutProfile = z.infer<
  typeof StoredToRuntimeLayoutProfileSchema
>;

export type RuntimeLayoutProfilesList = z.infer<
  typeof RuntimeProfileArraySchema
>;
