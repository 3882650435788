import type { ValueOf } from "type-fest";
import * as z from "zod";

// Utilities

export function deserializeBooleanParam(arg: unknown): unknown {
  if (arg === "1") {
    return true;
  } else if (arg === "0") {
    return false;
  } else {
    return arg;
  }
}

export function isValueMissing(issue: z.ZodInvalidTypeIssue): boolean {
  return [z.ZodParsedType.null, z.ZodParsedType.undefined].includes(
    issue.received as any,
  );
}

type ErrorMapHandlers = Partial<{
  [Issue in z.ZodIssueOptionalMessage as Issue["code"]]:
    | string
    | ((issue: Issue) => string | undefined);
}>;

export function createErrorMap(handlers: ErrorMapHandlers): z.ZodErrorMap {
  return function errorMap(issue, ctx) {
    const handler = handlers[issue.code];

    let message: string | undefined = undefined;
    if (typeof handler === "string") {
      message = handler;
    } else if (typeof handler === "function") {
      message = handler(issue as any);
    }

    return { message: message ?? ctx.defaultError };
  };
}

// Boolean schemas

export const boolean = z.boolean({
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Unexpected value";
      }
    },
  }),
});

export const filterBoolean = z.preprocess(
  deserializeBooleanParam,
  boolean.nullable().default(null),
);

export function filterCheckbox(
  defaultValue: boolean,
): z.ZodType<boolean, z.ZodTypeDef, unknown> {
  return z.preprocess(
    // Nullish values are replaced with default value
    (arg) => deserializeBooleanParam(arg) ?? defaultValue,
    boolean,
  );
}

// Text schemas

export const requiredText = z.string({
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (issue.received === z.ZodParsedType.null) {
        return "Field is required";
      } else {
        return "Expected text";
      }
    },
  }),
});

export const optionalText = requiredText.nullable();

export const filterText = optionalText.default(null);

// UUID schemas

export const requiredUuid = z
  .string({
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (issue.received === z.ZodParsedType.null) {
          return "Field is required";
        } else {
          return "Unexpected input";
        }
      },
      invalid_string(issue) {
        if (issue.validation === "uuid") {
          return "Must be a UUID";
        }
      },
    }),
  })
  .uuid();

export const optionalUuid = requiredUuid.nullable();

export const filterUuid = optionalUuid.default(null);

// Email schemas

export const requiredEmail = z
  .string({
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (isValueMissing(issue)) {
          return "Field is required";
        } else {
          return "Unexpected input";
        }
      },
      invalid_string(issue) {
        if (issue.validation === "email") {
          return "Enter an email address";
        }
      },
    }),
  })
  .email();

// Password schemas

interface PasswordSchemaOptions {
  minLength?: number;
  includesNumber?: boolean;
  includesSpecial?: boolean;
  includesUppercase?: boolean;
  includesLowercase?: boolean;
}

const NUMBER_REGEX = /\d/;
const SPECIAL_REGEX = /[^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/;
const UPPERCASE_REGEX = /[A-Z]/;
const LOWERCASE_REGEX = /[a-z]/;

export function requiredPassword(
  options: PasswordSchemaOptions,
): z.ZodType<string> {
  let schema = z.string({
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (isValueMissing(issue)) {
          return "Field is required";
        } else {
          return "Unexpected value";
        }
      },
    }),
  });

  if (options.minLength != null) {
    schema = schema.min(
      options.minLength,
      `Password must be at least ${options.minLength} character(s) long`,
    );
  }

  if (options.includesNumber) {
    schema = schema.regex(
      NUMBER_REGEX,
      "Password must include at least 1 number",
    );
  }

  if (options.includesSpecial) {
    schema = schema.regex(
      SPECIAL_REGEX,
      "Password must include at least 1 special character",
    );
  }

  if (options.includesUppercase) {
    schema = schema.regex(
      UPPERCASE_REGEX,
      "Password must include at least 1 uppercase character",
    );
  }

  if (options.includesLowercase) {
    schema = schema.regex(
      LOWERCASE_REGEX,
      "Password must include at least 1 lowercase character",
    );
  }

  return schema;
}

// URL schemas

interface UrlSchemaOptions {
  httpOrHttpsOnly?: boolean;
  rejectSearch?: boolean;
  rejectHash?: boolean;
}

export function requiredUrl(options?: UrlSchemaOptions): z.ZodType<string> {
  return z
    .string({
      errorMap: createErrorMap({
        invalid_type(issue) {
          if (isValueMissing(issue)) {
            return "Field is required";
          } else {
            return "Expected a URL";
          }
        },
      }),
    })
    .superRefine((value, ctx) => {
      let url;
      try {
        url = new URL(value);
      } catch {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_string,
          validation: "url",
          message: "Expected a URL",
          fatal: true,
        });

        return;
      }

      if (
        options?.httpOrHttpsOnly &&
        !["http:", "https:"].includes(url.protocol)
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Must be HTTP(S)",
          fatal: true,
        });
      }

      // This doesn't guarantee the URL was free of a lone "?" as the URL spec
      // considers that the same as no query at all
      if (options?.rejectSearch && url.search !== "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Cannot contain query string (?)",
          fatal: true,
        });
      }

      // This doesn't guarantee the URL was free of a lone "#" as the URL spec
      // considers that the same as no hash at all
      if (options?.rejectHash && url.hash !== "") {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Cannot contain a fragment (#)",
          fatal: true,
        });
      }
    });
}

export function optionalUrl(
  options?: UrlSchemaOptions,
): z.ZodType<string | null> {
  return requiredUrl(options).nullable();
}

// Number schemas

export const requiredNumber = z
  .number({
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (isValueMissing(issue)) {
          return "Field is required";
        } else {
          return "Expected a number";
        }
      },
      not_finite: "Expected a number",
    }),
  })
  .finite();

export const optionalNumber = requiredNumber.nullable();

export const filterNumber = z.preprocess((arg) => {
  if (arg == null) {
    return arg;
  } else {
    return Number(arg);
  }
}, optionalNumber.default(null));

// Date schemas

export const filterDate = z.coerce
  .date({
    errorMap: createErrorMap({
      invalid_type: "Expected a date",
      invalid_date: "Invalid date",
    }),
  })
  .nullable()
  .default(null);

// BigInt schemas

export const requiredBigInt: z.ZodType<bigint, z.ZodTypeDef, unknown> =
  z.preprocess(
    (arg) => {
      if (arg == null || typeof arg !== "string") {
        return arg;
      } else {
        try {
          // Unlike numbers which convert invalid strings to `NaN`, the bigint
          // constructor will throw if passed **anything** that's not an integer
          // or a string representing an integer.
          return BigInt(arg);
        } catch {
          return arg;
        }
      }
    },
    z.bigint({
      errorMap: createErrorMap({
        invalid_type(issue) {
          if (
            [z.ZodParsedType.undefined, z.ZodParsedType.null].includes(
              issue.received as any,
            )
          ) {
            return "Field is required";
          } else if (issue.received === z.ZodParsedType.number) {
            return "Unexpected value";
          } else {
            return "Expected an integer";
          }
        },
      }),
    }),
  );

export const optionalBigInt = requiredBigInt.nullable();

export const filterBigInt = optionalBigInt.default(null);

// Enum schemas

type EnumObject = Record<string, string>;
type EnumOptions = readonly [string, ...ReadonlyArray<string>];

export function requiredEnum<const TEnumObject extends EnumObject>(
  input: TEnumObject,
): z.ZodType<ValueOf<TEnumObject>>;
export function requiredEnum<const TOptions extends EnumOptions>(
  input: TOptions,
): z.ZodType<TOptions[number]>;
export function requiredEnum(input: EnumObject | EnumOptions) {
  if (Array.isArray(input)) {
    return z.enum(input as EnumOptions, {
      errorMap: createErrorMap({
        invalid_type(issue) {
          if (isValueMissing(issue)) {
            return "Field is required";
          } else {
            return "Unexpected value";
          }
        },
        invalid_enum_value: "Unexpected value",
      }),
    });
  } else {
    return z.nativeEnum(input as EnumObject, {
      errorMap: createErrorMap({
        invalid_type(issue) {
          if (isValueMissing(issue)) {
            return "Field is required";
          } else {
            return "Unexpected value";
          }
        },
        invalid_enum_value: "Unexpected value",
      }),
    });
  }
}

export function optionalEnum<const TEnumObject extends EnumObject>(
  input: TEnumObject,
): z.ZodType<ValueOf<TEnumObject> | null>;
export function optionalEnum<const TOptions extends EnumOptions>(
  options: TOptions,
): z.ZodType<TOptions[number] | null>;
export function optionalEnum(input: EnumObject | EnumOptions) {
  return requiredEnum(input as any).nullable();
}

export function filterEnum<const TEnumObject extends EnumObject>(
  input: TEnumObject,
): z.ZodType<ValueOf<TEnumObject> | null>;
export function filterEnum<const TOptions extends EnumOptions>(
  input: TOptions,
): z.ZodType<TOptions[number] | null>;
export function filterEnum(input: EnumObject | EnumOptions) {
  return optionalEnum(input as any).default(null);
}

// Object schemas

export const requiredObject: z.ZodType<object> = z.record(z.unknown(), {
  errorMap: createErrorMap({
    invalid_type(issue) {
      if (isValueMissing(issue)) {
        return "Field is required";
      } else {
        return "Expected an object";
      }
    },
  }),
});

export const optionalObject = requiredObject.nullable();

// Array schemas

export function requiredArray<TElement>(
  elementSchema: z.ZodType<TElement, z.ZodTypeDef, any>,
): z.ZodType<Array<TElement>> {
  return z.array(elementSchema, {
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (isValueMissing(issue)) {
          return "Field is required";
        } else {
          return "Expected an array";
        }
      },
    }),
  });
}

export function filterArray<TElement>(
  elementSchema: z.ZodType<TElement>,
): z.ZodType<Array<TElement>, z.ZodTypeDef, unknown> {
  return z
    .preprocess((arg) => {
      if (Array.isArray(arg) || arg == null) {
        return arg;
      } else {
        return [arg];
      }
    }, requiredArray(elementSchema))
    .default([]);
}
