/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { User } from "./User";
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

export interface UserDataResponse {
  data: User;
}

export function UserDataResponseFromJSON(json: any): UserDataResponse {
  return UserDataResponseFromJSONTyped(json);
}

export function UserDataResponseFromJSONTyped(json: any): UserDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: UserFromJSON(json["data"]),
  };
}

export function UserDataResponseToJSON(
  value: UserDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: UserToJSON(value.data),
  };
}
