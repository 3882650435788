import { get } from "~/lib/std";
import type { Topic } from "~/lqs";

export function getTopicContextDisplayName(
  context: Topic["context"],
): string | undefined {
  const displayName = get(context, "studio.display_name");

  if (displayName == null) {
    return undefined;
  } else {
    return String(displayName);
  }
}
