export function stringifyBigintFields<TObject extends object>(
  object: TObject,
): {
  [Key in keyof TObject]: bigint extends TObject[Key]
    ? Exclude<TObject[Key], bigint> | string
    : TObject[Key];
} {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      let safeValue = value;
      if (typeof safeValue === "bigint") {
        safeValue = String(safeValue);
      }

      return [key, safeValue];
    }),
  ) as any;
}
