import { Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout, RelatedResource } from "~/components/DetailsCards";
import type { Workflow } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteWorkflow,
  useUpdateWorkflow,
  useWorkflow,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editWorkflowSchema } from "../schemas";
import {
  WorkflowHookSearchRequestProvider,
  WorkflowHookTable,
} from "./WorkflowHookTable";

export function WorkflowDetails() {
  const { workflowId } = useTypedParams(lqsRoutePaths.WORKFLOW_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toWorkflowHookTable: true,
    toWorkflowEdit: true,
    toWorkflowTable: true,
  });

  const query = useWorkflow(workflowId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResource
        text="Hooks"
        to={lqsNavigator.toWorkflowHookTable({ workflowId })}
        table={
          <WorkflowHookSearchRequestProvider embedded>
            <WorkflowHookTable />
          </WorkflowHookSearchRequestProvider>
        }
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="workflow"
      query={query}
      editLocation={lqsNavigator.toWorkflowEdit({ workflowId })}
      deleteMutation={useDeleteWorkflow(workflowId)}
      getReadableName={(workflow) => workflow.name}
      listLocation={lqsNavigator.toWorkflowTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Workflow> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="workflow"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "name" },
          { dataType: "text", accessor: "processType" },
          { dataType: "boolean", accessor: "managed" },
          { dataType: "json", accessor: "contextSchema" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Workflow> }) {
  const { workflowId } = useTypedParams(lqsRoutePaths.WORKFLOW_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="workflow"
      registryKey="workflow"
      query={query}
      schema={editWorkflowSchema}
      descriptors={[
        { name: "_default", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateWorkflow(workflowId)}
    />
  );
}
