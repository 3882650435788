import * as z from "zod";
import { convertBase64ImageToBlob } from "~/domain/network";
import type { DataStore } from "~/dsm";
import type { Record as LqsRecord, Topic } from "~/lqs";
import { assertNever } from "~/utils";
import { detectionResultsSchema } from "../detections";
import { imageSegmentationsSchema } from "../segmentations";
import type {
  _OnDemandInferenceDataResponse,
  InferenceDataForType,
  InferencePipelineTask,
  InferenceType,
} from "../types";

interface OnDemandInferenceRequest {
  dataStoreId: DataStore["id"];
  topicId: Topic["id"];
  timestamp: LqsRecord["timestamp"];
  pipelineTask: InferencePipelineTask;
  pipelineModel: string;
  pipelineRevision: string | null;
}

export async function runOnDemandInference({
  dataStoreId,
  topicId,
  timestamp,
  pipelineTask,
  pipelineModel,
  pipelineRevision,
}: OnDemandInferenceRequest): Promise<_OnDemandInferenceDataResponse> {
  const config = createPjmApiConfiguration(dataStoreId);

  const response = await fetch(`${config.basePath}/api/inferences`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await config.accessToken()}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      datastore_id: dataStoreId,
      topic_id: topicId,
      timestamp: String(timestamp),
      pipeline_task: pipelineTask,
      pipeline_model: pipelineModel,
      pipeline_revision: pipelineRevision,
    }),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  const { data: responseData } = z
    .object({
      data: z.object({
        output: z.union([z.record(z.unknown()), z.array(z.unknown())]),
        meta: z.record(z.unknown()),
      }),
    })
    .parse(await response.json());

  let output: InferenceDataForType<InferenceType>;
  switch (pipelineTask) {
    case "object-detection": {
      if (Array.isArray(responseData.output)) {
        output = detectionResultsSchema.parse(responseData);
      } else {
        output = detectionResultsSchema.parse(responseData.output);
      }

      break;
    }
    case "image-segmentation": {
      output = await imageSegmentationsSchema.parseAsync(responseData);

      break;
    }
    case "depth-estimation": {
      output = await z
        .object({
          depth: z.string(),
        })
        .transform(({ depth }) => convertBase64ImageToBlob(depth))
        .refine((value) => value != null)
        .parseAsync(responseData.output);

      break;
    }
    default: {
      assertNever(pipelineTask);
    }
  }

  return {
    data: {
      output,
      meta: responseData.meta,
    },
  };
}

function createPjmApiConfiguration(dataStoreId: DataStore["id"]): any {
  throw new Error("TODO: Not implemented");
}
