import type { ResourceVersionHistories } from "~/domain/versioning";
import { v } from "~/domain/versioning";
import type {
  ProcessCreateRequest,
  ProcessListRequest,
  ProcessResource,
  ProcessUpdateRequest,
} from "./types";

const V_1_1_0 = v(1, 1, 0);

export const processResourceVersionHistories = {
  model: {
    error: {
      deprecated: V_1_1_0,
    },
    errorName: {
      added: V_1_1_0,
    },
    errorMessage: {
      added: V_1_1_0,
    },
    errorPayload: {
      added: V_1_1_0,
    },
    previousState: {
      added: V_1_1_0,
    },
  },
  operations: {
    list: {
      errorPayloadFilter: {
        added: V_1_1_0,
      },
    },
    update: {
      error: {
        deprecated: V_1_1_0,
      },
      errorName: {
        added: V_1_1_0,
      },
      errorMessage: {
        added: V_1_1_0,
      },
      errorPayload: {
        added: V_1_1_0,
      },
    },
  },
} satisfies ResourceVersionHistories<
  ProcessResource,
  ProcessListRequest,
  ProcessCreateRequest,
  ProcessUpdateRequest
>;
