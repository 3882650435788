import type { StrictOmit } from "ts-essentials";
import * as z from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterBoolean,
  filterText,
  filterUuid,
  optionalObject,
  optionalText,
  optionalUuid,
  requiredText,
} from "~/domain/common";
import type {
  GroupCreateRequest,
  GroupUpdateRequest,
  ListGroupsRequest,
} from "~/lqs";

export const listGroupsSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  defaultWorkflowId: filterUuid,
  defaultWorkflowIdNull: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<StrictOmit<ListGroupsRequest, "includeCount">>);

export const createGroupSchema = z.object({
  name: requiredText,
  defaultWorkflowId: optionalUuid,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<GroupCreateRequest>);

export const editGroupSchema = z.object({
  name: requiredText,
  defaultWorkflowId: optionalUuid,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<GroupUpdateRequest>);
