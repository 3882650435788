/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProcessState } from "./ProcessState";
import {
  ProcessStateFromJSON,
  ProcessStateFromJSONTyped,
  ProcessStateToJSON,
} from "./ProcessState";
import type { ProcessType } from "./ProcessType";
import {
  ProcessTypeFromJSON,
  ProcessTypeFromJSONTyped,
  ProcessTypeToJSON,
} from "./ProcessType";

export interface HookUpdateRequest {
  triggerProcess?: ProcessType;
  triggerState?: ProcessState;
  name?: string | null;
  note?: string | null;
  context?: object | null;
  disabled?: boolean;
  uri?: string | null;
  secret?: string | null;
}

export function HookUpdateRequestFromJSON(json: any): HookUpdateRequest {
  return HookUpdateRequestFromJSONTyped(json);
}

export function HookUpdateRequestFromJSONTyped(json: any): HookUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    triggerProcess:
      json["trigger_process"] === undefined
        ? undefined
        : ProcessTypeFromJSON(json["trigger_process"]),
    triggerState:
      json["trigger_state"] === undefined
        ? undefined
        : ProcessStateFromJSON(json["trigger_state"]),
    name: json["name"],
    note: json["note"],
    context: json["context"],
    disabled: json["disabled"],
    uri: json["uri"],
    secret: json["secret"],
  };
}

export function HookUpdateRequestToJSON(
  value: HookUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    trigger_process: ProcessTypeToJSON(value.triggerProcess),
    trigger_state: ProcessStateToJSON(value.triggerState),
    name: value.name,
    note: value.note,
    context: value.context,
    disabled: value.disabled,
    uri: value.uri,
    secret: value.secret,
  };
}
