import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useImmer } from "use-immer";
import {
  getNormalizedFilePath,
  InvalidSelection,
  sortSelections,
} from "~/components/Form";
import { MultipartUploader } from "~/domain/network";
import { invariant } from "~/lib/invariant";
import type { Group, Log, LogDataResponse } from "~/lqs";
import {
  createObjectPartPresignedUrlFactory,
  ProcessState,
  ResponseError,
  useDataStoreClients,
} from "~/lqs";
import { assertNever } from "~/utils/assertNever";

type UploadErrorType = "name:duplicate";

export class UploadResponseError extends Error {
  readonly type: UploadErrorType;

  constructor(type: UploadErrorType, responseError: ResponseError) {
    super(type, { cause: responseError });

    this.type = type;
    this.name = this.constructor.name;
  }
}

interface MultipartUploadStatus {
  file: File;
  filePath: string;
  status: "idle" | "preparing" | "uploading" | "complete";
  progress: number;
}

interface BaseLogUploadState {
  getFileIngestProps: (file: File) => {
    checked: boolean;
    onChange: () => void;
  };
}

interface MutableFormMixin extends BaseLogUploadState {
  disabled: false;
  onFilesChange: (
    fieldValue: unknown,
    source: { reason: "select" } | { reason: "remove"; file: File },
  ) => void;
}

interface ImmutableFormMixin extends BaseLogUploadState {
  disabled: true;
  onFilesChange?: never;
}

interface PendingStateMixin extends ImmutableFormMixin {
  status: "pending";
  uploadStatuses: ReadonlyArray<MultipartUploadStatus>;
}

interface IdleState extends MutableFormMixin {
  status: "idle";
  upload: (
    params: {
      name: Log["name"];
      groupId: Group["id"];
      files: ReadonlyArray<File>;
    },
    handlers: {
      onError: (error: unknown) => void;
    },
  ) => void;
}

interface PendingPreUploadState extends PendingStateMixin {
  stage: "pre-upload";
}

interface PendingUploadingState extends PendingStateMixin {
  stage: "uploading";
}

interface PendingPostUploadState extends PendingStateMixin {
  stage: "post-upload";
}

interface CompleteState extends ImmutableFormMixin {
  status: "complete";
  log: Log;
}

interface FailureState extends ImmutableFormMixin {
  status: "failure";
}

export type LogUploadState =
  | IdleState
  | PendingPreUploadState
  | PendingUploadingState
  | PendingPostUploadState
  | CompleteState
  | FailureState;

export function useUploadLog(): LogUploadState {
  const [filesToIngest, setFilesToIngest] = useState(new Set<File>());

  const [uploadStatuses, setUploadStatuses] = useImmer<
    ReadonlyArray<MultipartUploadStatus>
  >([]);

  const { ingestionApi, logApi } = useDataStoreClients();

  const mutation = useMutation({
    async mutationFn({
      name,
      groupId,
      files: inFiles,
    }: {
      name: Log["name"];
      groupId: Group["id"];
      files: ReadonlyArray<File>;
    }) {
      const files = sortSelections(inFiles);

      const fileToPathMap = new Map(
        files.map((file) => [file, getNormalizedFilePath(file)]),
      );

      invariant(
        new Set(fileToPathMap.values()).size === fileToPathMap.size,
        "Some files have duplicate paths. Cannot upload",
      );

      let logDataResponse: LogDataResponse;
      try {
        logDataResponse = await logApi.createLog({
          logCreateRequest: { name, groupId },
        });
      } catch (e) {
        if (e instanceof ResponseError && e.response.status === 409) {
          throw new UploadResponseError("name:duplicate", e);
        }

        throw e;
      }

      const {
        data: { id: logId },
      } = logDataResponse;

      setUploadStatuses(
        files.map((file) => ({
          file,
          filePath: fileToPathMap.get(file)!,
          status: "idle",
          progress: 0,
        })),
      );

      // This loop is intentionally performing the uploads sequentially by
      // awaiting API requests inside the sync for-loop. This doesn't have to
      // be the case, but it certainly simplifies things since nothing besides
      // the actual multipart upload will happen concurrently (and the multipart
      // uploader is designed for that sort of concurrency). If multiple uploads
      // happened concurrently, there'd need to be a way to limit concurrent
      // part uploads _across_ files, not just _within_ a file (which is how it
      // currently works). Uploaders have an internal limit on the number of
      // concurrent parts they'll upload, _p_; however, if there are now _u_
      // concurrent uploads, that could be _p_ * _u_ concurrent parts being
      // uploaded rather than just _p_ parts. If the browser attempted to read
      // those parts into memory to actually send across the network, that could
      // use _a lot_ of memory, though to be clear I'm not sure if browsers
      // would actually do that.
      for (const file of files) {
        setUploadStatuses((draft) => {
          getUploadStatus(draft, file).status = "preparing";
        });

        const filePath = fileToPathMap.get(file)!;

        // This operation can fail if an object with this key already exists
        // for this log. Since Studio just created the log it's not worth
        // handling uniqueness errors that almost certainly won't happen.
        // If Studio ever supports uploading to an existing log then proper
        // error handling would be necessary.
        const {
          data: { key: objectKey },
        } = await logApi.createLogObject({
          logId,
          objectCreateRequest: {
            key: filePath,
          },
        });

        const multipartUploader = new MultipartUploader({
          blob: file,
          createPartPresignedUrl: createObjectPartPresignedUrlFactory(
            logApi,
            logId,
            objectKey,
          ),
          onUpdate(progress) {
            setUploadStatuses((draft) => {
              getUploadStatus(draft, file).progress = progress;
            });
          },
        });

        setUploadStatuses((draft) => {
          getUploadStatus(draft, file).status = "uploading";
        });

        await multipartUploader.start();

        setUploadStatuses((draft) => {
          getUploadStatus(draft, file).progress = 1;
        });

        await logApi.updateLogObject({
          logId,
          objectKey,
          objectUpdateRequest: {
            uploadState: "complete",
          },
        });

        if (filesToIngest.has(file)) {
          await ingestionApi.createIngestion({
            ingestionCreateRequest: {
              logId,
              objectKey,
              name: filePath,
              state: ProcessState.Queued,
            },
          });
        }

        setUploadStatuses((draft) => {
          getUploadStatus(draft, file).status = "complete";
        });
      }

      return logDataResponse;
    },
    // TODO: Abort upload if an error is thrown
    onError() {},
  });

  const baseState: BaseLogUploadState = {
    getFileIngestProps(file: File) {
      const ingest = filesToIngest.has(file);

      return {
        checked: ingest,
        onChange(): void {
          if (file.size === 0) {
            return;
          }

          const clone = new Set(filesToIngest);

          if (ingest) {
            clone.delete(file);
          } else {
            clone.add(file);
          }

          setFilesToIngest(clone);
        },
      };
    },
  };

  if (mutation.isIdle) {
    return {
      ...baseState,
      status: "idle",
      disabled: false,
      onFilesChange(fieldValue, source): void {
        switch (source.reason) {
          case "select": {
            setFilesToIngest(getInitialFilesToIngest(fieldValue));

            return;
          }
          case "remove": {
            const clone = new Set(filesToIngest);
            clone.delete(source.file);

            setFilesToIngest(clone);

            return;
          }
          default: {
            assertNever(source);
          }
        }
      },
      upload: mutation.mutate,
    };
  } else if (mutation.isError) {
    return {
      ...baseState,
      status: "failure",
      disabled: true,
    };
  } else if (mutation.isSuccess) {
    return {
      ...baseState,
      status: "complete",
      disabled: true,
      log: mutation.data.data,
    };
  } else {
    return {
      ...baseState,
      status: "pending",
      disabled: true,
      stage:
        // Upload statuses aren't populated until the log is created and we're
        // actually creating and uploading objects
        uploadStatuses.length === 0
          ? "pre-upload"
          : uploadStatuses.every(({ status }) => status === "complete")
            ? "post-upload"
            : "uploading",
      uploadStatuses,
    };
  }
}

function getUploadStatus(
  statuses: ReadonlyArray<MultipartUploadStatus>,
  file: File,
): MultipartUploadStatus {
  const status = statuses.find((status) => status.file === file);

  invariant(status != null, "No upload status found");

  return status;
}

/**
 * Determine if a newly-selected file should be marked for ingestion by default.
 * The user can still change whether a file should be ingested, but for some
 * files we can determine at selection time if it's likely the user will or
 * won't want them ingested.
 */
function checkShouldIngestByDefault(file: File): boolean {
  if (file.size === 0) {
    return false;
  }

  if (file.name.endsWith(".bag")) {
    return true;
  }

  if (file.name.endsWith(".mcap")) {
    return true;
  }

  if (file.name.endsWith(".jsonl")) {
    return true;
  }

  if (file.name === "log0") {
    return true;
  }

  return false;
}

function getInitialFilesToIngest(fieldValue: unknown): Set<File> {
  let files: ReadonlyArray<File>;
  if (
    Array.isArray(fieldValue) &&
    fieldValue.every((element) => element instanceof File)
  ) {
    files = fieldValue;
  } else if (fieldValue instanceof InvalidSelection) {
    files = fieldValue.accepted;
  } else {
    files = [];
  }

  return new Set(files.filter(checkShouldIngestByDefault));
}
