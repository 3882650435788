import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { ApiKeyDetails } from "./components/ApiKeyDetails";

export function ApiKeyPage() {
  return (
    <LqsPage title="API Key Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <ApiKeyDetails />
      </Container>
    </LqsPage>
  );
}
