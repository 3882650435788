import { useEffect, useState } from "react";

// Adapted from https://usehooks.com/useLocalStorage/

export type Transformer<TValue> = (value: unknown) => TValue;

export default function makeStorage(storage: Storage) {
  return function useStorage<TValue>(
    key: string,
    defaultValue: TValue,
    transformer?: Transformer<TValue>,
  ) {
    const [value, setValue] = useState<TValue>(() => {
      try {
        const storedValue = storage.getItem(key);

        if (storedValue === null) {
          return defaultValue;
        }

        const value = JSON.parse(storedValue);

        return transformer?.(value) ?? value;
      } catch {
        return defaultValue;
      }
    });

    useEffect(
      function syncValueWithStorage() {
        storage.setItem(key, JSON.stringify(value));
      },
      [key, value],
    );

    return [value, setValue] as const;
  };
}
