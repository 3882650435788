import { invariant } from "~/lib/invariant";
import type { Topic } from "~/lqs";
import { useLoadedPlayerTopics } from "../hooks";
import type { InitializedPanelNode } from "./api";
import { usePanelContext } from "./nodes";

export function useInitializedPanel(): InitializedPanelNode {
  const panel = usePanelContext();

  invariant(panel.isInitialized, "Panel is not initialized");

  return panel;
}

export function usePanelTopic(): Topic {
  const panel = useInitializedPanel();

  const playerTopics = useLoadedPlayerTopics();

  const topic = playerTopics.find((topic) => topic.name === panel.topicName);

  invariant(topic != null, "No topic found for panel");

  return topic;
}
