import type { QueryKey } from "@tanstack/react-query";
import { hashQueryKey, QueryClient } from "@tanstack/react-query";
import { isPlainObject } from "~/lib/std";
import { stringifyBigintFields } from "~/utils/stringifyBigintFields";

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        queryKeyHashFn: safelyHashQueryKey,
        // Preserve v3 behavior, opting out of smart query tracking (for now).
        // Smart tracking should be done on a case-by-case basis to avoid
        // unnoticed bugs
        notifyOnChangeProps: "all",
        // API clients use `fetch-retry` library to handle refetching
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        // Default staleTime of Infinity for all queries. We deal with too
        // much data to be refetching willy-nilly and I'd prefer to control
        // when refetching happens anyways
        staleTime: Infinity,
      },
    },
  });
}

// For all query keys, stringify any BigInts encountered to avoid crashes when
// passed to TanStack Query's `hashQueryKey` function which uses `JSON.stringify`
// under the hood. For objects, the stringification is shallow.
function safelyHashQueryKey(queryKey: QueryKey): string {
  const safeQueryKey = queryKey.map((element) => {
    if (isPlainObject(element)) {
      return stringifyBigintFields(element as object);
    } else if (typeof element === "bigint") {
      return String(element);
    } else {
      return element;
    }
  });

  // This is the function TanStack Query would use by default to hash query
  // keys deterministically.
  return hashQueryKey(safeQueryKey);
}
