import { ErrorOutline } from "@mui/icons-material";
import { Box, styled, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { Details } from "~/components/Details";
import { DetailsLayout } from "~/components/DetailsCards";
import { JsonField } from "~/components/DetailsCards/JsonField";
import type { Query } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteLogQuery,
  useLogQuery,
  useUpdateLogQuery,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editLogQuerySchema } from "../schemas";

export function LogQueryDetails() {
  const { logId, queryId } = useTypedParams(lqsRoutePaths.LOG_QUERY_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toLogQueryEdit: true,
    toLogQueryTable: true,
  });

  const queryResult = useLogQuery(logId, queryId, { select: selectData });

  const generalSection = <GeneralSection queryResult={queryResult} />;
  const queryInputSection = <QueryInputSection queryResult={queryResult} />;
  const queryOutputSection = <QueryOutputSection queryResult={queryResult} />;
  const infoSection = <InfoSection queryResult={queryResult} />;
  const historySection = <LqsHistoryCard query={queryResult} />;
  const manageSection = (
    <LqsManageCard
      resourceName="query"
      query={queryResult}
      editLocation={lqsNavigator.toLogQueryEdit({ logId, queryId })}
      deleteMutation={useDeleteLogQuery(logId, queryId)}
      getReadableName={(logQuery) => logQuery.name ?? logQuery.id}
      listLocation={lqsNavigator.toLogQueryTable({ logId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: 3,
              alignItems: "start",
            }}
          >
            {queryInputSection}
            {queryOutputSection}
          </Box>
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {queryInputSection}
          {queryOutputSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({
  queryResult,
}: {
  queryResult: UseQueryResult<Query>;
}) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="query"
        query={queryResult}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "foreign-key", resourceType: "log", accessor: "logId" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function QueryInputSection({
  queryResult,
}: {
  queryResult: UseQueryResult<Query>;
}) {
  return (
    <Card title="Query Input">
      <LqsResourceFields
        registryKey="query"
        query={queryResult}
        fields={[
          { dataType: "pre", accessor: "statement" },
          { dataType: "json", accessor: "parameters" },
        ]}
      />
    </Card>
  );
}

const ErrorContent = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: `
    "icon message"
    "icon error"
  `,
  columnGap: theme.spacing(2),
  gridTemplateColumns: "auto minmax(0, 1fr)",
}));

function QueryOutputSection({
  queryResult,
}: {
  queryResult: UseQueryResult<Query>;
}) {
  const hasError = queryResult.data?.error != null;

  return (
    <Card title="Query Output" error={hasError}>
      {hasError ? (
        <ErrorContent>
          <ErrorOutline
            sx={{
              gridArea: "icon",
              fontSize: "3rem",
            }}
            color="error"
          />
          <Typography paragraph sx={{ gridArea: "message" }}>
            The query failed
          </Typography>
          <Details sx={{ gridArea: "error" }}>
            <Details.Summary>Expand to see error</Details.Summary>
            <JsonField value={queryResult.data!.error} />
          </Details>
        </ErrorContent>
      ) : (
        <LqsResourceFields
          registryKey="query"
          query={queryResult}
          fields={[
            { dataType: "json", accessor: "columns" },
            { dataType: "json", accessor: "rows" },
          ]}
        />
      )}
    </Card>
  );
}

function InfoSection({ queryResult }: { queryResult: UseQueryResult<Query> }) {
  const { logId, queryId } = useTypedParams(lqsRoutePaths.LOG_QUERY_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="query"
      registryKey="query"
      query={queryResult}
      schema={editLogQuerySchema}
      descriptors={[
        { name: "name", type: "text" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateLogQuery(logId, queryId)}
    />
  );
}
