import React from "react";
import type { StrictOmit } from "ts-essentials";
import type { Column, ResourceTableProps } from "~/components/Table";
import { ResourceTable } from "~/components/Table";
import { renderForeignResource } from "./renderForeignResource";
import type {
  GetLqsResourceModel,
  LqsForeignResourceType,
  LqsResource,
} from "./types";
import { lqsResourceVersionRegistry, useLqsVersion } from "./versioning";

export type LqsColumn<TResourceKey extends LqsResource> = Column<
  GetLqsResourceModel<TResourceKey>,
  LqsForeignResourceType
>;

interface LqsResourceTableProps<TResourceKey extends LqsResource>
  extends StrictOmit<
    ResourceTableProps<
      GetLqsResourceModel<TResourceKey>,
      LqsForeignResourceType
    >,
    | "apiVersion"
    | "versionHistories"
    | "deprecationPolicy"
    | "renderForeignResource"
  > {
  registryKey: TResourceKey;
}

export function LqsResourceTable<TResourceKey extends LqsResource>({
  registryKey,
  ...rest
}: LqsResourceTableProps<TResourceKey>) {
  const lqsVersion = useLqsVersion();

  return (
    <ResourceTable
      {...rest}
      apiVersion={lqsVersion}
      versionHistories={lqsResourceVersionRegistry[registryKey]?.model}
      deprecationPolicy="hide"
      renderForeignResource={renderForeignResource}
    />
  );
}
