import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { IngestionDetails } from "./components/IngestionDetails";

export function IngestionPage() {
  return (
    <LqsPage title="Ingestion Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <IngestionDetails />
      </Container>
    </LqsPage>
  );
}
