/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Label {
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  value: string;
  note: string | null;
  category: string | null;
}

export function LabelFromJSON(json: any): Label {
  return LabelFromJSONTyped(json);
}

export function LabelFromJSONTyped(json: any): Label {
  if (json == null) {
    return json;
  }

  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null
        ? json["updated_at"]
        : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] == null
        ? json["deleted_at"]
        : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    value: json["value"],
    note: json["note"],
    category: json["category"],
  };
}

export function LabelToJSON(value: Label | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    value: value.value,
    note: value.note,
    category: value.category,
  };
}
