import React from "react";
import type { Root } from "react-dom/client";
import { App } from "./app";
import { configureApp, initAppConfig } from "./config";

export function start(root: Root): void {
  initAppConfig().then(() => {
    configureApp();

    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  });
}
