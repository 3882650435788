import { useSettings } from "~/settings";
import type { Maybe } from "~/types";
import { formatDateOrTimestamp } from "./utils";

export function Datetime({ date }: { date: Maybe<Date> }) {
  const { dateDisplay } = useSettings();

  return date == null ? (
    "-"
  ) : (
    <span>{formatDateOrTimestamp(date, dateDisplay)}</span>
  );
}
