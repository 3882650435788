import type {
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";
import type { Path } from "react-router-dom";
import { createSafeContext } from "~/contexts";
import type { ApiVersion } from "~/domain/versioning";
import type {
  ListRolesRequest,
  Role,
  RoleDataResponse,
  RoleListResponse,
} from "~/dsm";
import type {
  CommonListUsersRequest,
  CommonMeDataResponse,
  CommonUser,
  CommonUserCreateRequest,
  CommonUserDataResponse,
  CommonUserListResponse,
  CommonUserUpdateRequest,
} from "./types";

interface CommonResourcesContextValue {
  apiVersion: ApiVersion;
  createCurrentUserQueryOptions: () => Required<
    Pick<UseQueryOptions<CommonMeDataResponse>, "queryKey" | "queryFn">
  >;
  createRoleListQueryOptions: (
    request: ListRolesRequest,
  ) => Required<
    Pick<UseQueryOptions<RoleListResponse>, "queryKey" | "queryFn">
  >;
  createRoleFetchQueryOptions: (
    roleId: Role["id"] | null,
  ) => Required<
    Pick<UseQueryOptions<RoleDataResponse>, "queryKey" | "queryFn">
  >;
  createRoleDetailsLocation?: (params: { roleId: Role["id"] }) => Partial<Path>;
  createUserListQueryOptions: (
    request: CommonListUsersRequest,
  ) => Required<
    Pick<UseQueryOptions<CommonUserListResponse>, "queryKey" | "queryFn">
  >;
  createUserFetchQueryOptions: (
    userId: CommonUser["id"] | null,
  ) => Required<
    Pick<UseQueryOptions<CommonUserDataResponse>, "queryKey" | "queryFn">
  >;
  createUserCreateMutationOptions: () => Required<
    Pick<
      UseMutationOptions<
        CommonUserDataResponse,
        unknown,
        CommonUserCreateRequest
      >,
      "mutationFn"
    >
  >;
  createUserUpdateMutationOptions: (
    userId: CommonUser["id"],
  ) => Required<
    Pick<
      UseMutationOptions<
        CommonUserDataResponse,
        unknown,
        CommonUserUpdateRequest
      >,
      "mutationFn"
    >
  >;
  createUserDeleteMutationOptions: (
    userId: CommonUser["id"],
  ) => Required<Pick<UseMutationOptions<void>, "mutationFn">>;
  userTableLocation: Partial<Path>;
  userCreateLocation: Partial<Path>;
  createUserDetailsLocation: (params: {
    userId: CommonUser["id"];
  }) => Partial<Path>;
  createUserEditLocation: (params: {
    userId: CommonUser["id"];
  }) => Partial<Path>;
}

export const [useCommonResourcesContextValue, CommonResourcesContext] =
  createSafeContext<CommonResourcesContextValue>("CommonResources");

export const CommonResourcesContextProvider = CommonResourcesContext.Provider;
