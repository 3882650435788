import type { StrictOmit } from "ts-essentials";
import type { IsStringLiteral, LiteralToPrimitive } from "type-fest";
import type { FiltersSidebarProps } from "~/components/Table";
import { FiltersSidebar } from "~/components/Table";
import type {
  GetLqsResourceRequest,
  LqsResource,
  SubresourceListRequest,
} from "../types";
import { lqsResourceVersionRegistry, useLqsVersion } from "../versioning";
import { renderLqsForeignKeyFormField } from "./render-lqs-foreign-key-form-field";
import type {
  LqsForeignKeyFormFieldType,
  LqsFormFieldDescriptors,
} from "./types";

type NormalizeRegistryRequest<TRegistryRequest extends object> =
  TRegistryRequest extends SubresourceListRequest<
    infer TListRequest,
    infer TParentKeyId
  >
    ? Omit<TListRequest, TParentKeyId>
    : TRegistryRequest;

type PatchRequestFields<TRequest extends object> = {
  [Field in keyof TRequest]: TRequest[Field] extends boolean
    ? TRequest[Field] | null
    : IsStringLiteral<NonNullable<TRequest[Field]>> extends true
      ? LiteralToPrimitive<TRequest[Field]>
      : TRequest[Field];
};

type AllowableLqsResource<TRequest extends object> = {
  [Resource in LqsResource]: Required<
    NormalizeRegistryRequest<GetLqsResourceRequest<Resource, "list">>
  > extends PatchRequestFields<TRequest>
    ? Resource
    : never;
}[LqsResource];

interface LqsFiltersSidebarProps<TRequest extends object>
  extends StrictOmit<
    FiltersSidebarProps<TRequest, LqsForeignKeyFormFieldType>,
    | "descriptors"
    | "apiVersion"
    | "versionHistories"
    | "deprecationPolicy"
    | "renderForeignKeyFormField"
  > {
  registryKey: AllowableLqsResource<TRequest>;
  descriptors: LqsFormFieldDescriptors<TRequest>;
}

export function LqsFiltersSidebar<TRequest extends object>({
  registryKey,
  ...rest
}: LqsFiltersSidebarProps<TRequest>) {
  const lqsVersion = useLqsVersion();

  return (
    <FiltersSidebar
      {...rest}
      apiVersion={lqsVersion}
      versionHistories={
        lqsResourceVersionRegistry[registryKey]?.operations?.list
      }
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderLqsForeignKeyFormField}
    />
  );
}
