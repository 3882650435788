export interface StudioErrorOptions extends ErrorOptions {
  message?: string;
}

/**
 * Base error class for LogQS Studio
 */
export class StudioError extends Error {
  constructor({ message, cause }: StudioErrorOptions = {}) {
    super(message, { cause });

    // Set the name based on the name of the class being instantiated.
    // Using `new.target` lets this work for any subclass of `StudioError`
    this.name = new.target.name;
  }
}
