import { EditLqsResourceForm, useGroup, useUpdateGroup } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editGroupSchema } from "../schemas";

export function GroupEditForm() {
  const { groupId } = useTypedParams(lqsRoutePaths.GROUP_EDIT);

  const lqsNavigator = useLqsNavigator({ toGroupDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="group"
      registryKey="group"
      query={useGroup(groupId, { select: selectData })}
      schema={editGroupSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        {
          name: "defaultWorkflowId",
          type: "foreign-key",
          resourceType: "workflow",
        },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateGroup(groupId)}
      detailsLocation={lqsNavigator.toGroupDetails({ groupId })}
    />
  );
}
