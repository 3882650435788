import type React from "react";
import { createSafeContext } from "~/contexts";

const [useInferenceFrameControls, InferenceFrameControlsContext] =
  createSafeContext<React.JSX.Element>("InferenceFrameControls");

export { useInferenceFrameControls };

export function InferenceFrameControlsProvider({
  element,
  children,
}: {
  element: React.JSX.Element;
  children: React.ReactNode;
}) {
  return (
    <InferenceFrameControlsContext.Provider value={element}>
      {children}
    </InferenceFrameControlsContext.Provider>
  );
}

const [useOnDemandInferenceControls, OnDemandInferenceControlsContext] =
  createSafeContext<React.JSX.Element>("OnDemandInferenceControls");

export { useOnDemandInferenceControls };

export function OnDemandInferenceControlsProvider({
  element,
  children,
}: {
  element: React.JSX.Element;
  children: React.ReactNode;
}) {
  return (
    <OnDemandInferenceControlsContext.Provider value={element}>
      {children}
    </OnDemandInferenceControlsContext.Provider>
  );
}
