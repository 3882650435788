/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ObjectPart } from "./ObjectPart";
import {
  ObjectPartFromJSON,
  ObjectPartFromJSONTyped,
  ObjectPartToJSON,
} from "./ObjectPart";

export interface ObjectPartDataResponse {
  data: ObjectPart;
}

export function ObjectPartDataResponseFromJSON(
  json: any,
): ObjectPartDataResponse {
  return ObjectPartDataResponseFromJSONTyped(json);
}

export function ObjectPartDataResponseFromJSONTyped(
  json: any,
): ObjectPartDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: ObjectPartFromJSON(json["data"]),
  };
}

export function ObjectPartDataResponseToJSON(
  value: ObjectPartDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: ObjectPartToJSON(value.data),
  };
}
