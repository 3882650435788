import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { IngestionPartDetails } from "./components/IngestionPartDetails";

export function IngestionPartPage() {
  return (
    <LqsPage title="Ingestion Part Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <IngestionPartDetails />
      </Container>
    </LqsPage>
  );
}
