export async function hashInsecurely(
  input: string | null,
): Promise<Uint8Array> {
  // The Web Crypto API's `subtle` object is typically a danger zone but the
  // `digest()` method is alright in this situation as it's not being used for
  // anything cryptographic, just to generate "random" data for a given input
  // that's deterministic. This is primarily used for generating random-looking
  // colors which are guaranteed to always be the same for a given input, such
  // as a label value.
  const buff = await window.crypto.subtle.digest(
    // Not cryptographically secure but doesn't need to be
    "SHA-1",
    // String has to be encoded to UTF-8
    new TextEncoder().encode(input ?? ""),
  );

  // SHA-1 returns a 160 bit hash so the view will have exactly 20 elements
  return new Uint8Array(buff);
}
