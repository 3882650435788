import { EditLqsResourceForm, useUpdateWorkflow, useWorkflow } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editWorkflowSchema } from "../schemas";

export function WorkflowEditForm() {
  const { workflowId } = useTypedParams(lqsRoutePaths.WORKFLOW_EDIT);

  const lqsNavigator = useLqsNavigator({ toWorkflowDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="workflow"
      registryKey="workflow"
      query={useWorkflow(workflowId, { select: selectData })}
      schema={editWorkflowSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "_default", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "contextSchema", type: "json" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateWorkflow(workflowId)}
      detailsLocation={lqsNavigator.toWorkflowDetails({ workflowId })}
    />
  );
}
