import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar, processStateOptions } from "~/lqs";
import { listIngestionPartsSchema } from "../schemas";
import { useIngestionPartSearchRequest } from "./IngestionPartTable";

export function IngestionPartFilters() {
  const [request, setRequest] = useIngestionPartSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="ingestionPart"
      request={request}
      setRequest={setRequest}
      schema={listIngestionPartsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "sequence", type: "number" },
        { name: "source", type: "text" },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowIdNull", type: "boolean" },
        { name: "state", type: "select", options: processStateOptions },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
