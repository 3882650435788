import { EditLqsResourceForm, useRecord, useUpdateRecord } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editRecordSchema } from "../schemas";

export function RecordEditForm() {
  const { topicId, timestamp } = useTypedParams(
    lqsRoutePaths.RECORD_EDIT,
    (params) => ({
      ...params,
      timestamp: BigInt(params.timestamp),
    }),
  );

  const lqsNavigator = useLqsNavigator({ toRecordDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="record"
      registryKey="record"
      query={useRecord(topicId, timestamp, { select: selectData })}
      schema={editRecordSchema}
      descriptors={[
        { name: "queryData", type: "json" },
        { name: "error", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateRecord(topicId, timestamp)}
      detailsLocation={lqsNavigator.toRecordDetails({ topicId, timestamp })}
    />
  );
}
