import { useMutation } from "@tanstack/react-query";
import { CommonQueryClientContext } from "~/providers/CommonQueryClientProvider";

export function useCopyToClipboard() {
  return useMutation({
    mutationFn(text: string) {
      return navigator.clipboard.writeText(text);
    },
    context: CommonQueryClientContext,
  });
}
