import type { StrictOmit } from "ts-essentials";
import * as z from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterBoolean,
  filterText,
  filterUuid,
  optionalObject,
  optionalText,
  requiredText,
} from "~/domain/common";
import type {
  HookCreateRequest,
  HookUpdateRequest,
  ListHooksRequest,
  ListWorkflowsRequest,
  WorkflowCreateRequest,
  WorkflowUpdateRequest,
} from "~/lqs";
import {
  filterProcessState,
  filterProcessType,
  requiredProcessState,
  requiredProcessType,
} from "~/lqs";

export const listWorkflowsSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  processType: filterProcessType,
  _default: filterBoolean,
  managed: filterBoolean,
  disabled: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<ListWorkflowsRequest, "contextSchemaFilter" | "includeCount">
>);

export const createWorkflowSchema = z.object({
  name: requiredText,
  processType: requiredProcessType,
  _default: boolean,
  managed: boolean,
  disabled: boolean,
  contextSchema: optionalObject,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<WorkflowCreateRequest>);

export const editWorkflowSchema = z.object({
  name: requiredText,
  _default: boolean,
  disabled: boolean,
  contextSchema: optionalObject,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<WorkflowUpdateRequest>);

export const listWorkflowHooksSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  uri: filterText,
  uriLike: filterText,
  triggerProcess: filterProcessType,
  triggerState: filterProcessState,
  noteLike: filterText,
  managed: filterBoolean,
  disabled: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<ListHooksRequest, "workflowId" | "includeCount">
>);

export const createWorkflowHookSchema = z.object({
  name: optionalText,
  triggerProcess: requiredProcessType,
  triggerState: requiredProcessState,
  uri: optionalText,
  secret: optionalText,
  managed: boolean,
  disabled: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<HookCreateRequest>);

export const editWorkflowHookSchema = z.object({
  name: optionalText,
  triggerProcess: requiredProcessType,
  triggerState: requiredProcessState,
  uri: optionalText,
  disabled: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<StrictOmit<HookUpdateRequest, "secret">>);
