import type { FieldValues, Path } from "react-hook-form";
import type { ResourceSelectFieldProps } from "~/components/Form";
import { ResourceSelectField } from "~/components/Form";
import {
  useObjectStoreQueryOptionsFactory,
  useObjectStoresQueryOptionsFactory,
} from "../api";

export function ObjectStoreSelect<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(props: ResourceSelectFieldProps<TFieldValues, TName>) {
  const createObjectStoresQueryOptions = useObjectStoresQueryOptionsFactory();
  const createObjectStoreQueryOptions = useObjectStoreQueryOptionsFactory();

  return (
    <ResourceSelectField
      {...props}
      createListQueryOptions={(text) =>
        createObjectStoresQueryOptions({
          bucketName: text,
          limit: 5,
          sort: "asc",
          order: "bucket_name",
        })
      }
      createFetchQueryOptions={createObjectStoreQueryOptions}
      selectOption={(objectStore) => ({
        value: objectStore.id,
        label: objectStore.bucketName,
      })}
    />
  );
}
