import {
  ObjectExplorer,
  ObjectExplorerFilters as ObjectStoreObjectFilters,
  ObjectSearchRequestProvider as ObjectStoreObjectSearchRequestProvider,
} from "~/components/ObjectExplorer";
import {
  useObjectStoreObjectQueryOptionsFactory,
  useObjectStoreObjectsQueryOptionsFactory,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";

export { ObjectStoreObjectSearchRequestProvider, ObjectStoreObjectFilters };

export function ObjectStoreObjectTable() {
  const { objectStoreId } = useTypedParams(
    lqsRoutePaths.OBJECT_STORE_OBJECT_TABLE,
  );

  const lqsNavigator = useLqsNavigator({ toObjectStoreObjectDetails: true });

  const createObjectStoreObjectQueryOptions =
    useObjectStoreObjectQueryOptionsFactory();
  const createObjectStoreObjectsQueryOptions =
    useObjectStoreObjectsQueryOptionsFactory();

  return (
    <ObjectExplorer
      homeName={objectStoreId}
      createObjectQueryOptions={createObjectStoreObjectQueryOptions.bind(
        null,
        objectStoreId,
      )}
      createSearchQueryOptions={createObjectStoreObjectsQueryOptions.bind(
        null,
        objectStoreId,
      )}
      createObjectLocation={(objectKey) =>
        lqsNavigator.toObjectStoreObjectDetails({
          objectStoreId,
          objectKey,
        })
      }
    />
  );
}
