import { invariant } from "~/lib/invariant";
import { DsmQueryClientContext, useDsmContext } from "../provider";
import type {
  ListRolesRequest,
  Role,
  RoleDataResponse,
  RoleListResponse,
} from "../sdk";
import type { DsmQueryOptions } from "./utils";

export const roleKeys = {
  all: ["roles"] as const,
  lists: () => [...roleKeys.all, "list"] as const,
  list: (request: ListRolesRequest) => [...roleKeys.lists(), request] as const,
  fetches: () => [...roleKeys.all, "fetch"] as const,
  fetch: (roleId: Role["id"] | null) =>
    [...roleKeys.fetches(), roleId] as const,
};

export function useRoleListQueryOptionsFactory(): (
  request: ListRolesRequest,
) => DsmQueryOptions<RoleListResponse> {
  const { roleApi } = useDsmContext();

  return (request) => ({
    queryKey: roleKeys.list(request),
    queryFn({ signal }) {
      return roleApi.listRoles(request, { signal });
    },
    context: DsmQueryClientContext,
  });
}

export function useRoleFetchQueryOptionsFactory(): (
  roleId: Role["id"] | null,
) => DsmQueryOptions<RoleDataResponse, "enabled"> {
  const { roleApi } = useDsmContext();

  return (roleId) => {
    const enabled = roleId !== null;

    return {
      queryKey: roleKeys.fetch(roleId),
      queryFn({ signal }) {
        invariant(enabled, "Role ID not provided");

        return roleApi.fetchRole({ roleId }, { signal });
      },
      enabled,
      context: DsmQueryClientContext,
    };
  };
}
