import { Link, styled } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import { ResetPasswordForm } from "~/domain/auth";
import { toSignIn } from "~/paths";

const Root = styled(Card)(({ theme }) => ({
  width: 500,
  maxWidth: `calc(100% - ${theme.spacing(2)})`,
}));

export function ForgotPasswordPage() {
  const navigate = useNavigate();

  return (
    <Center>
      <Root title="Forgot Password">
        <ResetPasswordForm
          onResetComplete={() => {
            navigate(toSignIn(), { state: { from: "forgot-password" } });
          }}
          footer={
            <Link component={RouterLink} to={toSignIn()} underline="always">
              Return to Sign In
            </Link>
          }
        />
      </Root>
    </Center>
  );
}
