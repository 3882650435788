import type { CSSProperties } from "react";
import { alpha, getOverlayAlpha } from "@mui/material";

export function getPaperDarkBackgroundImage(
  elevation = 1,
): CSSProperties["backgroundImage"] {
  // MUI applies a transparent background image over <Paper />'s background color
  // in dark mode which needs to be manually recreated when a component wants to
  // match its background without being transparent:
  // https://github.com/mui/material-ui/blob/ae2fc45a686ad0ac71ac3ab47a96d74175ce6525/packages/mui-material/src/Paper/Paper.js#L56-L60
  const colorStop = alpha("#FFF", Number(getOverlayAlpha(elevation)));

  return `linear-gradient(${colorStop}, ${colorStop})`;
}
