import { modulo } from "~/lib/std";
import { traverseTree } from "~/utils";
import type { LayoutNode, PanelNode } from "./api";

/**
 * Performs a breadth-first traversal of the tree starting from the root,
 * calling the visitor function for each node as it is reached. To cancel
 * the traversal before every node is visited, the visitor can return
 * false; otherwise, the visitor should not return anything
 *
 * @param root the root of the tree to traverse
 * @param visitor a function to be called on each node found during the
 *        traversal. Can return false to stop traversal early
 */
export function walkLayoutTree(
  root: LayoutNode,
  visitor: (node: LayoutNode) => false | undefined | void,
) {
  function getChildren(node: LayoutNode) {
    if (node.type === "container") {
      return [node.firstChild, node.secondChild];
    }
  }

  traverseTree(root, getChildren, visitor);
}

export function iteratePanels(
  root: LayoutNode,
  visitor: (node: PanelNode) => false | undefined | void,
): void {
  walkLayoutTree(root, (node) => {
    if (node.type !== "panel") {
      return;
    }

    return visitor(node);
  });
}

export function flattenPanels(root: LayoutNode): Array<PanelNode> {
  const panels = new Array<PanelNode>();
  iteratePanels(root, (panel) => {
    panels.push(panel);
  });

  return panels;
}

export function calculateRotationQuadrant(rotationDeg: number): number {
  // Integer in range [0, 3] representing the effective number of 90 degree
  // clockwise rotations
  return modulo(rotationDeg, 360) / 90;
}
