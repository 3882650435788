import type { UseMutationResult } from "@tanstack/react-query";
import { useDsmContext } from "../provider";
import type { TicketCreateRequest, TicketDataResponse } from "../sdk";
import { useDsmMutation } from "./utils";

export function useCreateTicket(): UseMutationResult<
  TicketDataResponse,
  unknown,
  TicketCreateRequest
> {
  const { ticketApi } = useDsmContext();

  return useDsmMutation({
    mutationFn(request) {
      return ticketApi.createTicket({ ticketCreateRequest: request });
    },
  });
}
