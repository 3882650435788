/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DataStoreAssociation {
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  userId: string;
  datastoreId: string;
  manager: boolean;
  disabled: boolean;
  datastoreUserId: string;
  datastoreUsername: string | null;
  datastoreRoleId: string | null;
  datastoreAdmin: boolean;
  datastoreDisabled: boolean;
}

export function DataStoreAssociationFromJSON(json: any): DataStoreAssociation {
  return DataStoreAssociationFromJSONTyped(json);
}

export function DataStoreAssociationFromJSONTyped(
  json: any,
): DataStoreAssociation {
  if (json == null) {
    return json;
  }

  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    userId: json["user_id"],
    datastoreId: json["datastore_id"],
    manager: json["manager"],
    disabled: json["disabled"],
    datastoreUserId: json["datastore_user_id"],
    datastoreUsername: json["datastore_username"],
    datastoreRoleId: json["datastore_role_id"],
    datastoreAdmin: json["datastore_admin"],
    datastoreDisabled: json["datastore_disabled"],
  };
}

export function DataStoreAssociationToJSON(
  value: DataStoreAssociation | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    user_id: value.userId,
    datastore_id: value.datastoreId,
    manager: value.manager,
    disabled: value.disabled,
    datastore_user_id: value.datastoreUserId,
    datastore_username: value.datastoreUsername,
    datastore_role_id: value.datastoreRoleId,
    datastore_admin: value.datastoreAdmin,
    datastore_disabled: value.datastoreDisabled,
  };
}
