import { NewLqsResourceForm, useCreateRole } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createRoleSchema } from "../schemas";

export function RoleCreateForm() {
  const lqsNavigator = useLqsNavigator({ toRoleDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="role"
      registryKey="role"
      schema={createRoleSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "policy", type: "json", required: true },
        { name: "_default", type: "boolean" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useCreateRole()}
      createDetailsLocation={(response) =>
        lqsNavigator.toRoleDetails({ roleId: response.data.id })
      }
    />
  );
}
