import type { Path } from "react-router-dom";
import type { OverrideProperties } from "type-fest";
import type { ManageCardProps } from "~/components/DetailsCards";
import { ManageCard } from "~/components/DetailsCards";

// TODO: Is this component necessary now that LQS locations no longer need
//  separate handling from DSM locations?
type LqsManageCardProps<TResource extends object> = OverrideProperties<
  ManageCardProps<TResource>,
  {
    editLocation?: Partial<Path>;
    listLocation: Partial<Path>;
  }
>;

export function LqsManageCard<TResource extends object>({
  editLocation,
  listLocation,
  ...rest
}: LqsManageCardProps<TResource>) {
  return (
    <ManageCard
      {...rest}
      editLocation={editLocation}
      listLocation={listLocation}
    />
  );
}
