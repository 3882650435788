import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { secondsToMilliseconds } from "~/lib/dates";
import { invariant } from "~/lib/invariant";
import type { ListRecordsRequest, Record, Topic } from "~/lqs";
import { circumventPagination, useDataStoreClients } from "~/lqs";
import type { DataFilter } from "../types";
import { SampleFrequency } from "../types";

export function useAllRecords<TData = Array<Record>>({
  topic,
  queryDataFilter,
  enabled: enabledProp = true,
  select,
}: {
  topic: Topic | null;
  queryDataFilter?: DataFilter | ReadonlyArray<DataFilter>;
  enabled?: boolean;
  select?: (records: Array<Record>) => TData;
}): UseQueryResult<TData> {
  const { topicApi } = useDataStoreClients();

  const enabled = enabledProp && topic != null;

  return useQuery({
    queryKey: ["all-records", topic?.id, queryDataFilter],
    async queryFn({ signal }) {
      invariant(enabled, "Query not enabled");

      const baseRequest: ListRecordsRequest = {
        topicId: topic.id,
        sort: "asc",
        order: "timestamp",
        frequency: SampleFrequency.Second,
        queryDataFilter:
          queryDataFilter == null
            ? queryDataFilter
            : JSON.stringify(queryDataFilter),
      };

      const { data } = await circumventPagination(
        topicApi.listRecords.bind(topicApi),
        100,
        baseRequest,
        { signal },
      );

      return data;
    },
    select,
    enabled,
    cacheTime: secondsToMilliseconds(20),
  });
}
