import React from "react";
import { Container } from "@mui/material";
import { Page } from "~/layout";
import { UserCreateForm } from "./user-create-form";

export function UserCreatePage({
  breadcrumbs,
}: {
  breadcrumbs?: React.ReactNode;
}) {
  return (
    <Page title="New User">
      <Container fixed>
        {breadcrumbs}
        <UserCreateForm />
      </Container>
    </Page>
  );
}
