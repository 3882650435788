import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { GroupEditForm } from "./components/GroupEditForm";

export function EditGroupPage() {
  return (
    <LqsPage title="Edit Group">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <GroupEditForm />
      </Container>
    </LqsPage>
  );
}
