import React from "react";
import { Error as ErrorIcon } from "@mui/icons-material";
import {
  Box,
  cardContentClasses,
  Checkbox,
  CircularProgress,
  Divider,
  LinearProgress,
  Skeleton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { Card } from "~/components/Card";
import { useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { renderQuery } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { selectCountableListResponse } from "~/domain/common";
import {
  LogTagChip,
  LogThumbnailCard,
  useAppliedLogLevelTags,
  useLogLevelTags,
} from "~/domain/logs";
import type { Label, Log, Tag } from "~/lqs";
import { useLogsQueryOptionsFactory } from "~/lqs";
import type { Maybe } from "~/types";
import { getEventHandlerProps, pluralize } from "~/utils";
import {
  filterSchema,
  getSortParams,
  GroupsField,
  LabelsField,
  NameField,
  PaginationControls,
  SortField,
  useLogsSearchRequest,
} from "./form";
import { useBulkManageTags } from "./use-bulk-manage-tags";

const Root = styled(Card)(({ theme }) => ({
  gridArea: "logs",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  [`& .${cardContentClasses.root}`]: {
    minHeight: 0,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
}));

export function LogsSection({
  selectedLabelIds,
}: {
  selectedLabelIds: ReadonlyArray<Label["id"]>;
}) {
  const [request, setRequest] = useLogsSearchRequest();

  const createLogsQueryOptions = useLogsQueryOptionsFactory();
  const logsQuery = useQuery({
    ...createLogsQueryOptions({
      nameLike: request.name,
      limit: request.limit,
      offset: request.offset,
      groupId: request.groupIds,
      tagLabelIdsIncludes: request.labelIds,
      ...getSortParams(request),
      includeCount: true,
    }),
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const { control, handleSubmit } = useStudioForm({
    schema: filterSchema,
    values: { name: request.name },
    onSubmit: setRequest,
  });

  return (
    <Root title="Logs">
      <Stack spacing={1.5}>
        <Stack direction="row" spacing={1.5} sx={{ flex: "none" }}>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ flex: "auto" }}
          >
            <NameField control={control} />
          </Box>
          <SortField request={request} setRequest={setRequest} />
        </Stack>
        <Stack direction="row" spacing={1.5} sx={{ flex: "none" }}>
          <Box sx={{ minWidth: 0, flex: "1 0" }}>
            <GroupsField request={request} setRequest={setRequest} />
          </Box>
          <Box sx={{ minWidth: 0, flex: "1 0" }}>
            <LabelsField request={request} setRequest={setRequest} />
          </Box>
        </Stack>
      </Stack>
      <Box sx={{ position: "relative" }}>
        <Divider />
        {logsQuery.isRefetching && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </Box>
      {renderQuery(logsQuery, {
        loading: <Loading type="circular" />,
        error: <ErrorMessage>Unable to load logs</ErrorMessage>,
        success(response) {
          return (
            <Stack
              spacing={2}
              sx={{
                minHeight: 0,
                flex: 1,
                overflowY: "auto",
                marginBlock: -3,
                padding: 1,
              }}
            >
              <Typography>{pluralize(response.count, "log")}</Typography>
              {response.data.map((log) => (
                <LogListItem
                  key={log.id}
                  log={log}
                  selectedLabelIds={selectedLabelIds}
                />
              ))}
            </Stack>
          );
        },
      })}
      {logsQuery.isSuccess && (
        <>
          <Divider />
          <PaginationControls
            disableJumping
            logCount={logsQuery.data.count}
            request={request}
            setRequest={setRequest}
          />
        </>
      )}
    </Root>
  );
}

function LogListItem({
  log,
  selectedLabelIds,
}: {
  log: Log;
  selectedLabelIds: ReadonlyArray<Label["id"]>;
}) {
  const appliedTagsQuery = useAppliedLogLevelTags(log, selectedLabelIds);

  const { enqueueSnackbar } = useSnackbar();

  const bulkManaging = selectedLabelIds.length > 0;

  const bulkManageMutation = useBulkManageTags(log, selectedLabelIds);

  const toggleTagEnabled =
    bulkManaging && appliedTagsQuery.isSuccess && !bulkManageMutation.isLoading;

  // The item should only be checked when a log-level tag exists for each
  // selected label; otherwise, it should be unchecked (not indeterminate).
  // Until the back-end imposes a uniqueness constraint on log-level tags it's
  // not guaranteed there'll be a 1-to-1 correspondence between applied tags
  // and selected labels even though every applied tags corresponds to one of
  // the selected labels. For now, create a set of unique label IDs and check
  // if its size matches the number of selected labels.
  const checked =
    appliedTagsQuery.isSuccess &&
    new Set(appliedTagsQuery.data.map((tag) => tag.labelId)).size ===
      selectedLabelIds.length;

  const tagToggleHandlerProps = getEventHandlerProps(
    "onChange",
    toggleTagEnabled &&
      function handleToggleTag() {
        bulkManageMutation.mutate(
          {
            appliedTags: appliedTagsQuery.data,
            operation: checked ? "delete" : "create",
          },
          {
            onError() {
              enqueueSnackbar(
                checked ? "Unable to remove tag(s)" : "Unable to add tag(s)",
                { variant: "error" },
              );
            },
          },
        );
      },
  );

  return (
    <Stack direction="row" spacing={1}>
      {bulkManaging && (
        <Box sx={{ alignSelf: "center", position: "relative" }}>
          <Checkbox checked={checked} {...tagToggleHandlerProps} />
          {bulkManageMutation.isLoading && (
            <CircularProgress
              size="2rem"
              sx={{ position: "absolute", inset: 0, margin: "auto" }}
            />
          )}
        </Box>
      )}
      <LogThumbnailCard
        log={log}
        dense
        tags={
          <Tags
            log={log}
            activeTags={appliedTagsQuery.data}
            bulkManaging={bulkManaging}
          />
        }
      />
    </Stack>
  );
}

function Tags({
  log,
  activeTags,
  bulkManaging,
}: {
  log: Log;
  activeTags: Maybe<ReadonlyArray<Tag>>;
  bulkManaging: boolean;
}) {
  const tagsQuery = useLogLevelTags(log);

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{ flexWrap: tagsQuery.status === "error" ? "nowrap" : "wrap" }}
    >
      {renderQuery(tagsQuery, {
        loading: [1, 2, 3].map((key) => (
          <Skeleton
            key={key}
            variant="rectangular"
            width="8ch"
            height={32}
            sx={{ borderRadius: 4 }}
          />
        )),
        error: (
          <>
            <ErrorIcon color="error" />
            <Typography>
              Couldn't load this log's tags. Adding and removing tags is
              disabled.
            </Typography>
          </>
        ),
        success(tags) {
          return tags.map((tag) => (
            <LogTagChip
              key={tag.id}
              log={log}
              tag={tag}
              active={
                activeTags?.some((activeTag) => activeTag.id === tag.id) ??
                false
              }
              readonly={bulkManaging}
            />
          ));
        },
      })}
    </Stack>
  );
}
