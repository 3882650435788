import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  paginationClasses,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import * as z from "zod";
import { TextField, useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { Pagination } from "~/components/Pagination";
import { renderQuery } from "~/components/QueryRenderer";
import { useSearchRequest } from "~/components/Table";
import { ErrorMessage } from "~/components/error-message";
import {
  filterText,
  requiredText,
  selectCountableListResponse,
} from "~/domain/common";
import type { LogInstantTag, LogLevelTag, LogRangeTag } from "~/domain/logs";
import type { Log } from "~/lqs";
import { useCreateLabel, useLabels } from "~/lqs";
import { getEventHandlerProps } from "~/utils/get-event-handler-props";
import { createPrefixedAliases } from "~/utils/search-params";
import { LabelListItem } from "./label-list-item";
import { TaggingModeControls } from "./tagging-mode-controls";

const LABELS_LIMIT = 15;

const labelsSearchSchema = z.object({
  value: filterText,
});

const labelsRequestSchema = labelsSearchSchema.extend({
  offset: z.coerce
    .number()
    .nonnegative()
    .refine((arg) => arg % LABELS_LIMIT === 0)
    .catch(0),
});

const newLabelSchema = z.object({
  value: requiredText,
});

export interface LabelManagementSectionProps {
  log: Log;
  logLevelTagsQuery: UseQueryResult<ReadonlyArray<LogLevelTag>>;
  logInstantTagsQuery: UseQueryResult<ReadonlyArray<LogInstantTag>>;
  logRangeTagsQuery: UseQueryResult<ReadonlyArray<LogRangeTag>>;
}

export function LabelManagementSection({
  log,
  logLevelTagsQuery,
  logInstantTagsQuery,
  logRangeTagsQuery,
}: LabelManagementSectionProps) {
  const [labelsSearchRequest, setLabelsSearchRequest] = useSearchRequest(
    labelsRequestSchema,
    createPrefixedAliases("label", labelsRequestSchema.keyof().options),
  );

  const labelsQuery = useLabels(
    {
      valueLike: labelsSearchRequest.value,
      limit: LABELS_LIMIT,
      offset: labelsSearchRequest.offset,
      sort: "asc",
      order: "value",
      includeCount: true,
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const labelsSearchForm = useStudioForm({
    schema: labelsSearchSchema,
    values: { value: labelsSearchRequest.value },
    onSubmit: setLabelsSearchRequest,
  });

  const { enqueueSnackbar } = useSnackbar();

  const [creating, setCreating] = useState(false);

  const createLabelMutation = useCreateLabel();
  const newLabelForm = useStudioForm({
    schema: newLabelSchema,
    defaultValues: { value: null },
    onSubmit(values) {
      createLabelMutation.mutate(values, {
        onSuccess() {
          enqueueSnackbar("Label created", { variant: "success" });
          setCreating(false);
          newLabelForm.reset();
        },
        onError() {
          enqueueSnackbar("Unable to create label", { variant: "error" });
        },
      });
    },
  });

  const startCreatingHandlerProps = getEventHandlerProps(
    "onClick",
    labelsQuery.isSuccess &&
      !creating &&
      function handleStartCreating() {
        setCreating(true);
      },
  );

  const cancelCreatingHandlerProps = getEventHandlerProps(
    "onClick",
    creating &&
      !createLabelMutation.isLoading &&
      function handleCancelCreating() {
        setCreating(false);
        newLabelForm.reset();
      },
  );

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" component="p">
          Labels
        </Typography>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          {...startCreatingHandlerProps}
        >
          New Label
        </Button>
      </Stack>
      {creating && (
        <>
          <Stack
            spacing={1}
            component="form"
            noValidate
            onSubmit={newLabelForm.handleSubmit}
          >
            <Typography sx={{ fontWeight: "bold" }}>New Label</Typography>
            <TextField
              name="value"
              control={newLabelForm.control}
              required
              size="small"
            />
            <Stack direction="row" spacing={2}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                loading={createLabelMutation.isLoading}
              >
                Create label
              </LoadingButton>
              <Button
                variant="text"
                color="secondary"
                size="small"
                {...cancelCreatingHandlerProps}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
          <Divider sx={{ my: 2 }} />
        </>
      )}
      <TaggingModeControls />
      <Box component="form" noValidate onSubmit={labelsSearchForm.handleSubmit}>
        <TextField
          name="value"
          control={labelsSearchForm.control}
          label="Search labels"
          size="small"
          noHelperText
          endAdornment={
            <InputAdornment position="end">
              <Tooltip title="Search">
                <IconButton type="submit" edge="end" size="small">
                  <Search fontSize="small" />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
        />
      </Box>
      <LinearProgress
        // Remount when made visible so animation restarts
        key={String(labelsQuery.isRefetching)}
        sx={{
          mt: 2,
          // Always present to prevent layout shift when made visible
          visibility: labelsQuery.isRefetching ? "visible" : "hidden",
        }}
      />
      {renderQuery(labelsQuery, {
        loading: <Loading type="circular" />,
        error: <ErrorMessage>Unable to load labels</ErrorMessage>,
        success(response) {
          return (
            <>
              {response.data.length === 0 ? (
                <Typography
                  sx={{
                    my: 2,
                    fontStyle: "italic",
                    textAlign: "center",
                  }}
                >
                  No labels
                </Typography>
              ) : (
                <List disablePadding>
                  {response.data.map((label) => (
                    <LabelListItem
                      key={label.id}
                      log={log}
                      label={label}
                      logLevelTagsQuery={logLevelTagsQuery}
                      logInstantTagsQuery={logInstantTagsQuery}
                      logRangeTagsQuery={logRangeTagsQuery}
                    />
                  ))}
                </List>
              )}
              <Box
                sx={{
                  mt: 2,
                  [`& .${paginationClasses.ul}`]: {
                    justifyContent: "space-between",
                  },
                }}
              >
                <Pagination
                  size="large"
                  disableJumping
                  count={response.count}
                  limit={LABELS_LIMIT}
                  offset={labelsSearchRequest.offset}
                  onChange={(offset) => setLabelsSearchRequest({ offset })}
                />
              </Box>
            </>
          );
        },
      })}
    </>
  );
}
