import type React from "react";
import { useId, useState } from "react";
import { clamp } from "~/lib/std";
import type { PointerContainerProps, PointerLocation } from "../hooks";
import { usePointerLocation } from "../hooks";
import { SplitOrientation } from "./constants";

interface SplitContainerProps extends PointerContainerProps {
  "data-orientation": SplitOrientation;
}

interface SplitNodeProps {
  style: Pick<
    React.CSSProperties,
    "flexGrow" | "flexShrink" | "flexBasis" | "minWidth" | "minHeight"
  >;
}

interface SplitterProps {
  id: string;
  style: Pick<React.CSSProperties, "touchAction">;
}

export function useSplitNodes({
  orientation,
  firstNodeFlex,
  secondNodeFlex,
  onResizeStop,
}: {
  orientation: SplitOrientation;
  firstNodeFlex: number;
  secondNodeFlex: number;
  onResizeStop: (firstNodeFlex: number, secondNodeFlex: number) => void;
}): {
  containerProps: SplitContainerProps;
  firstNodeProps: SplitNodeProps;
  secondNodeProps: SplitNodeProps;
  splitterProps: SplitterProps;
} {
  const splitterId = useId();

  const [flexOverrides, setFlexOverrides] = useState<[number, number] | null>(
    null,
  );

  const pointerContainerProps = usePointerLocation({
    onInteractionStart(e) {
      // Only allow interactions initiated through the splitter
      return (e.target as HTMLDivElement).id === splitterId;
    },
    onChange(pointerLocation) {
      setFlexOverrides(calculateFlexValues(pointerLocation, orientation));
    },
    onInteractionEnd(pointerLocation) {
      setFlexOverrides(null);

      onResizeStop(...calculateFlexValues(pointerLocation, orientation));
    },
  });

  const minSizeProperty =
    orientation === SplitOrientation.Horizontal ? "minHeight" : "minWidth";

  const baseNodeStyles = {
    flexShrink: 0,
    flexBasis: 0,
    [minSizeProperty]: 0,
  } as const;

  return {
    containerProps: {
      "data-orientation": orientation,
      ...pointerContainerProps,
    },
    firstNodeProps: {
      style: {
        ...baseNodeStyles,
        flexGrow: flexOverrides?.[0] ?? firstNodeFlex,
      },
    },
    secondNodeProps: {
      style: {
        ...baseNodeStyles,
        flexGrow: flexOverrides?.[1] ?? secondNodeFlex,
      },
    },
    splitterProps: {
      id: splitterId,
      style: {
        touchAction: "none",
      },
    },
  };
}

function calculateFlexValues(
  pointerLocation: PointerLocation,
  orientation: SplitOrientation,
): [firstNodeFlex: number, secondNodeFlex: number] {
  let firstNodeFlex: number;
  if (orientation === SplitOrientation.Horizontal) {
    // Splitter is horizontal so the nodes are stacked vertically
    firstNodeFlex = clamp(pointerLocation.y / pointerLocation.height, 0, 1);
  } else {
    // Splitter is vertical so the nodes are stacked horizontally
    firstNodeFlex = clamp(pointerLocation.x / pointerLocation.width, 0, 1);
  }

  return [firstNodeFlex, 1 - firstNodeFlex];
}
