import React from "react";
import {
  Cancel,
  CheckCircle,
  CloudUpload,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import * as z from "zod";
import { getAppConfig } from "~/apps/studio/config";
import { Card } from "~/components/Card";
import { Details } from "~/components/Details";
import type { Option } from "~/components/Form";
import {
  ComboBox,
  FileField,
  files,
  TextField,
  useField,
  useStudioForm,
} from "~/components/Form";
import { requiredText, requiredUuid } from "~/domain/common";
import { useAllGroups } from "~/domain/logs";
import { MultipartUploader } from "~/domain/network";
import { formatBytes, formatPercent } from "~/format";
import { Page } from "~/layout";
import { invariant } from "~/lib/invariant";
import { isEmpty } from "~/lib/std";
import { NOTEBOOK_HELP_LINK } from "~/links";
import type { GroupListResponse, Log } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { assertNever } from "~/utils/assertNever";
import { buildLqsRestApiDocsEndpoint } from "~/utils/build-api-endpoints";
import { getEventHandlerProps } from "~/utils/get-event-handler-props";
import type { LogUploadState } from "./use-upload-log";
import { UploadResponseError, useUploadLog } from "./use-upload-log";

const schema = z.object({
  name: requiredText,
  groupId: requiredUuid,
  files: files({ required: true }),
});

export function UploadPage() {
  const logUploadState = useUploadLog();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
    trigger,
  } = useStudioForm({
    schema,
    defaultValues: {
      name: null,
      groupId: null,
      files: [],
    },
    onSubmit(values) {
      invariant(
        logUploadState.status === "idle",
        "Cannot being upload in this state",
      );

      logUploadState.upload(values, {
        onError(error) {
          if (error instanceof UploadResponseError) {
            if (error.type === "name:duplicate") {
              setError("name", {
                message: "A log with that name already exists",
              });
            }
          }
        },
      });
    },
  });

  const { name, files } = watch();

  let nameCompletionStatus: SectionCompletionStatus = "incomplete";
  if (errors.name != null) {
    nameCompletionStatus = "error";
  } else if (name != null) {
    nameCompletionStatus = "complete";
  }

  const groupOptionsQuery = useAllGroups({ select: selectGroupOptions });
  const groupField = useField({ control, name: "groupId" });

  let groupCompletionStatus: SectionCompletionStatus = "incomplete";
  if (errors.groupId != null) {
    groupCompletionStatus = "error";
  } else if (groupField.value != null) {
    groupCompletionStatus = "complete";
  }

  let filesCompletionStatus: SectionCompletionStatus = "incomplete";
  if (errors.files != null) {
    filesCompletionStatus = "error";
  } else if (Array.isArray(files) && files.length > 0) {
    filesCompletionStatus = "complete";
  }

  const completionStatuses: Record<
    "name" | "group" | "files",
    SectionCompletionStatus
  > = {
    name: nameCompletionStatus,
    group: groupCompletionStatus,
    files: filesCompletionStatus,
  };

  const beginUploadHandleProps = getEventHandlerProps(
    "onClick",
    completionStatuses.name === "complete" &&
      completionStatuses.group === "complete" &&
      completionStatuses.files === "complete" &&
      logUploadState.status === "idle" &&
      function handleBeginUpload(): void {
        handleSubmit();
      },
  );

  return (
    <Page title="Upload">
      <Container fixed>
        <Stack spacing={4}>
          <Card
            title={renderSectionHeader(completionStatuses.name, "Log Name")}
          >
            <Box sx={{ width: "40ch" }}>
              <TextField
                control={control}
                name="name"
                required
                disabled={logUploadState.disabled}
              />
            </Box>
          </Card>
          <Card title={renderSectionHeader(completionStatuses.group, "Group")}>
            <Box sx={{ width: "30ch" }}>
              <ComboBox
                name="groupId"
                value={groupField.value}
                onChange={groupField.onChange}
                optionsQuery={groupOptionsQuery}
                errorMessage={groupField.errorMessage}
                required
                disabled={logUploadState.disabled}
              />
            </Box>
          </Card>
          <Card title={renderSectionHeader(completionStatuses.files, "Files")}>
            <FileField
              control={control}
              name="files"
              multiple
              maxSize={MultipartUploader.MAX_FILE_SIZE_BYTES}
              trigger={trigger}
              onChange={logUploadState.onFilesChange}
              disabled={logUploadState.disabled}
              renderFileContent={(file) => (
                <FormControlLabel
                  sx={{
                    marginBlockStart: -1.5,
                    marginInlineStart: -0.5,
                    marginInlineEnd: 0,
                  }}
                  label={
                    <>
                      Ingest
                      {file.size === 0 && " (Can't ingest empty file)"}
                    </>
                  }
                  control={
                    <Checkbox
                      size="small"
                      sx={{ padding: 0.5 }}
                      {...logUploadState.getFileIngestProps(file)}
                      disabled={logUploadState.disabled || file.size === 0}
                    />
                  }
                />
              )}
            />
            <Details sx={{ mt: 2 }}>
              <Details.Summary>
                How can I upload logs larger than{" "}
                {formatBytes(MultipartUploader.MAX_FILE_SIZE_BYTES)}?
              </Details.Summary>
              <Details.Content paragraph>
                To upload a log larger than{" "}
                {formatBytes(MultipartUploader.MAX_FILE_SIZE_BYTES)} you'll need
                to use the{" "}
                <Link color="inherit" href={buildDocsEndpoint()}>
                  DataStore REST API
                </Link>{" "}
                directly. Using the API, you can upload logs up to 5 TiB.
              </Details.Content>
              <Details.Content>
                Follow this detailed guide on{" "}
                <Link color="inherit" href={NOTEBOOK_HELP_LINK}>
                  using the API to upload large files
                </Link>
                .
              </Details.Content>
            </Details>
          </Card>
          <Card title="Upload">
            <Stack spacing={2} sx={{ alignItems: "baseline" }}>
              <List disablePadding>
                <ListItem>
                  <ListItemIcon>
                    {renderStatusIcon(completionStatuses.name)}
                  </ListItemIcon>
                  <ListItemText>Log Name</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {renderStatusIcon(completionStatuses.group)}
                  </ListItemIcon>
                  <ListItemText>Group</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {renderStatusIcon(completionStatuses.files)}
                  </ListItemIcon>
                  <ListItemText>Files</ListItemText>
                </ListItem>
              </List>
              <Typography id="upload-warning">
                <Typography component="span" sx={{ fontWeight: "bold" }}>
                  Important:
                </Typography>{" "}
                Once you start uploading your log, leave this page open until
                the upload is complete
              </Typography>
              <LoadingButton
                aria-describedby="upload-warning"
                type="button"
                color="primary"
                variant="contained"
                startIcon={<CloudUpload />}
                loading={logUploadState.status === "pending"}
                {...beginUploadHandleProps}
              >
                Upload Log
              </LoadingButton>
              {renderPendingState(logUploadState)}
              {logUploadState.status === "complete" && (
                <SuccessAlert logId={logUploadState.log.id} />
              )}
              {/* If `errors` is empty but the upload failed, it means
                some otherwise-unknown error occurred that isn't directly
                related to any of the form inputs. */}
              {logUploadState.status === "failure" && isEmpty(errors) && (
                <Alert severity="error" variant="filled" sx={{ width: 1 }}>
                  <AlertTitle>Error</AlertTitle>
                  An unknown error occurred trying to upload the log
                </Alert>
              )}
            </Stack>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}

type SectionCompletionStatus = "incomplete" | "complete" | "error";

function renderStatusIcon(status: SectionCompletionStatus): React.JSX.Element {
  switch (status) {
    case "incomplete": {
      return <RadioButtonUnchecked />;
    }
    case "complete": {
      return <CheckCircle color="success" />;
    }
    case "error": {
      return <Cancel color="error" />;
    }
    default: {
      assertNever(status);
    }
  }
}

function renderSectionHeader(
  status: SectionCompletionStatus,
  title: string,
): React.JSX.Element {
  return (
    <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
      {renderStatusIcon(status)}
      <Typography variant="h5" component="h2">
        {title}
      </Typography>
    </Stack>
  );
}

function renderPendingState(logUploadState: LogUploadState): React.ReactNode {
  if (logUploadState.status !== "pending") {
    return null;
  }

  return (
    <Stack sx={{ alignSelf: "stretch" }}>
      {logUploadState.uploadStatuses.map((uploadStatus) => {
        const progressLabel = {
          idle: "Waiting",
          preparing: "Preparing to upload...",
          uploading: `Upload progress: ${formatPercent(uploadStatus.progress)}`,
          complete: "Uploaded",
        }[uploadStatus.status];

        const progressLabelId = `${uploadStatus.filePath}-progress-label`;

        return (
          <Stack key={uploadStatus.filePath} sx={{ width: "100%", pt: 2 }}>
            <Typography variant="h6" component="p" gutterBottom>
              {uploadStatus.filePath}
            </Typography>
            <Typography id={progressLabelId} gutterBottom>
              {progressLabel}
            </Typography>
            <LinearProgress
              aria-labelledby={progressLabelId}
              variant="determinate"
              value={uploadStatus.progress * 100}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}

function SuccessAlert({ logId }: { logId: Log["id"] }) {
  const lqsNavigator = useLqsNavigator({ toLogDetails: true });

  return (
    <Alert severity="success" variant="filled" sx={{ width: 1 }}>
      <AlertTitle>Log Uploaded</AlertTitle>
      Your log has been uploaded and is processing.{" "}
      <Link
        component={RouterLink}
        to={lqsNavigator.toPlayer({ logId })}
        color="inherit"
      >
        View it in the player
      </Link>
      {" or "}
      <Link
        component={RouterLink}
        to={lqsNavigator.toLogDetails({ logId })}
        color="inherit"
      >
        view its details page.
      </Link>
      .
    </Alert>
  );
}

function selectGroupOptions(
  response: GroupListResponse,
): ReadonlyArray<Option> {
  return response.data.map((group) => ({
    value: group.id,
    label: group.name,
  }));
}

function buildDocsEndpoint(): string {
  return buildLqsRestApiDocsEndpoint(getAppConfig().apiEndpoint);
}
