import { omit, snakeCase } from "~/lib/std";
import type { ResourceTableModel } from "./types";

const orderKeys = new Map([["_default", "default"]]);

/**
 * Perform common preparation tasks on a list request's parameters.
 *
 * Tasks performed:
 * 1. The `order` field's value is converted to snake_case
 * 2. Pass `includeCount: true`
 */
export function preprocessSearchRequest<TRequest extends { order: string }>(
  request: TRequest,
): TRequest & { includeCount: true } {
  return {
    ...request,
    order: orderKeys.get(request.order) ?? snakeCase(request.order),
    includeCount: true,
  };
}

export function withoutBaseTableModel<TRequest extends ResourceTableModel>(
  request: TRequest,
): Omit<TRequest, keyof ResourceTableModel> {
  return omit(request, ["sort", "order", "limit", "offset"]);
}

export function getActiveFiltersCount(
  filters: Record<string, unknown>,
): number {
  return Object.values(filters).filter((value) =>
    Array.isArray(value) ? value.length > 0 : value != null,
  ).length;
}
