import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { listLabelsSchema } from "../schemas";
import { useLabelSearchRequest } from "./LabelTable";

export function LabelFilters() {
  const [request, setRequest] = useLabelSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="label"
      request={request}
      setRequest={setRequest}
      schema={listLabelsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "value", type: "fuzzy", fuzzyName: "valueLike" },
        { name: "noteLike", type: "text", label: "Note" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
