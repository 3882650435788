import React from "react";
import { Settings } from "@mui/icons-material";
import { Container } from "@mui/material";
import { DocumentTitle } from "~/components/document-title";
import * as Layout from "~/layout";
import {
  LayoutStateProvider,
  ScrollableContainer,
  SidebarSwitch,
  SidebarTrigger,
} from "~/layout";
import { SettingsSidebar } from "~/settings";
import { DataStoreDetails } from "./DataStoreDetails";

export function DataStoreDetailsPage() {
  return (
    <>
      <DocumentTitle>DataStore Details</DocumentTitle>
      <LayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation />
          <Layout.Header
            title="DataStore Details"
            actions={
              <SidebarTrigger
                sidebarId="settings"
                title="Settings"
                icon={<Settings />}
              />
            }
          />
          <Layout.Main>
            <ScrollableContainer>
              <Container fixed>
                <DataStoreDetails />
              </Container>
            </ScrollableContainer>
          </Layout.Main>
          <Layout.Sidebar>
            <SidebarSwitch
              options={[
                {
                  sidebarId: "settings",
                  element: <SettingsSidebar />,
                },
              ]}
            />
          </Layout.Sidebar>
        </Layout.Root>
      </LayoutStateProvider>
    </>
  );
}
