import type { StrictOmit } from "ts-essentials";
import * as z from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  boolean,
  commonResourceFilterSchemas,
  filterBigInt,
  filterBoolean,
  filterNumber,
  filterText,
  filterUuid,
  optionalBigInt,
  optionalNumber,
  optionalObject,
  optionalText,
  optionalUuid,
  requiredNumber,
  requiredUuid,
} from "~/domain/common";
import type {
  DigestionCreateRequest,
  DigestionPartCreateRequest,
  DigestionPartUpdateRequest,
  DigestionTopicCreateRequest,
  DigestionTopicUpdateRequest,
  DigestionUpdateRequest,
  ListDigestionPartsRequest,
  ListDigestionsRequest,
  ListDigestionTopicsRequest,
} from "~/lqs";
import { filterProcessState, requiredProcessState } from "~/lqs";

export const listDigestionsSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  logId: filterUuid,
  workflowId: filterUuid,
  workflowIdNull: filterBoolean,
  noteLike: filterText,
  progressGte: filterNumber,
  progressLte: filterNumber,
  progressNull: filterBoolean,
  state: filterProcessState,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListDigestionsRequest,
    | "workflowContextFilter"
    | "contextFilter"
    | "errorFilter"
    | "errorPayloadFilter"
    | "groupId"
    | "includeCount"
  >
>);

export const createDigestionSchema = z.object({
  name: optionalText,
  logId: requiredUuid,
  state: requiredProcessState,
  workflowId: optionalUuid,
  workflowContext: optionalObject,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<DigestionCreateRequest>);

export const editDigestionSchema = z.object({
  name: optionalText,
  state: requiredProcessState,
  progress: optionalNumber,
  workflowId: optionalUuid,
  workflowContext: optionalObject,
  error: optionalObject,
  errorName: optionalText,
  errorMessage: optionalText,
  errorPayload: optionalObject,
  locked: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<DigestionUpdateRequest>);

export const listDigestionTopicsSchema = z.object({
  id: filterUuid,
  startTimeGte: filterBigInt,
  startTimeLte: filterBigInt,
  startTimeNull: filterBoolean,
  endTimeGte: filterBigInt,
  endTimeLte: filterBigInt,
  endTimeNull: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListDigestionTopicsRequest,
    "digestionId" | "topicId" | "logId" | "groupId" | "includeCount"
  >
>);

export const createDigestionTopicSchema = z.object({
  topicId: requiredUuid,
  startTime: optionalBigInt,
  endTime: optionalBigInt,
  frequency: optionalNumber,
  queryDataFilter: optionalObject,
  contextFilter: optionalObject,
} satisfies SchemaShape<DigestionTopicCreateRequest>);

export const editDigestionTopicSchema = z.object({
  startTime: optionalBigInt,
  endTime: optionalBigInt,
  frequency: optionalNumber,
  queryDataFilter: optionalObject,
  contextFilter: optionalObject,
} satisfies SchemaShape<DigestionTopicUpdateRequest>);

export const listDigestionPartsSchema = z.object({
  id: filterUuid,
  sequence: filterNumber,
  state: filterProcessState,
  workflowId: filterUuid,
  workflowIdNull: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListDigestionPartsRequest,
    "digestionId" | "groupId" | "logId" | "includeCount"
  >
>);

export const createDigestionPartSchema = z.object({
  sequence: requiredNumber,
  state: requiredProcessState,
  workflowId: optionalUuid,
  workflowContext: optionalObject,
  locked: boolean,
  context: optionalObject,
} satisfies SchemaShape<StrictOmit<DigestionPartCreateRequest, "index">>);

export const editDigestionPartSchema = z.object({
  sequence: requiredNumber,
  state: requiredProcessState,
  progress: optionalNumber,
  workflowId: optionalUuid,
  workflowContext: optionalObject,
  error: optionalObject,
  errorName: optionalText,
  errorMessage: optionalText,
  errorPayload: optionalObject,
  locked: boolean,
  context: optionalObject,
} satisfies SchemaShape<StrictOmit<DigestionPartUpdateRequest, "index">>);
