import React from "react";
import { LqsResourceLink } from "./LqsResourceLink";
import type { LqsForeignResourceType } from "./types";

export function renderForeignResource(
  uuid: string,
  resourceType: LqsForeignResourceType,
) {
  return <LqsResourceLink resourceType={resourceType} uuid={uuid} />;
}
