import CR_ICON from "~/images/cr_icon.png";
import CR_LOGO from "~/images/cr_logo.png";
import LOGQS_ICON from "~/images/logqs_icon.png";
import LOGQS_LOGO from "~/images/logqs_logo.png";

export { CR_ICON, CR_LOGO, LOGQS_ICON, LOGQS_LOGO };

export const CR_ORIGIN = "https://carnegierobotics.com";

export const LOGQS_ORIGIN = "https://logqs.com/";

export const DOCS_ORIGIN = "https://docs.logqs.com";

export const NOTEBOOK_HELP_LINK =
  "https://colab.research.google.com/drive/1RevEpY40lj-jGjl8WZRvzxxKXRC4NtSp#scrollTo=e9YbbSlcJ7ua";

export const REQUEST_FULL_ACCESS_LINK = "https://carnegierobotics.com/contact";

export const PRIVACY_POLICY_LINK = "https://carnegierobotics.com/privacypolicy";
