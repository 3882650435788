import React from "react";
import { alpha, Backdrop, styled } from "@mui/material";
import { Card } from "~/components/Card";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.7),
  backdropFilter: "blur(5px)",
}));

export function CardOverlay({ children }: { children: React.ReactNode }) {
  return (
    <Backdrop open sx={{ position: "absolute" }}>
      <StyledCard>{children}</StyledCard>
    </Backdrop>
  );
}
