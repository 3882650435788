import { useMutation } from "@tanstack/react-query";
import { merge } from "~/lib/std";
import type { Record, Topic } from "~/lqs";
import { useDataStoreClients } from "~/lqs";

export function useClassifyDetectionsQuality({ topic }: { topic: Topic }) {
  const { topicApi } = useDataStoreClients();

  return useMutation({
    async mutationFn({
      detectionTopicId,
      timestamp,
      classification,
    }: {
      detectionTopicId: Topic["id"];
      timestamp: Record["timestamp"];
      classification: "accept" | "reject";
    }) {
      const {
        data: { context },
      } = await topicApi.fetchRecord({
        topicId: topic.id,
        timestamp,
      });

      return topicApi.updateRecord({
        topicId: topic.id,
        timestamp,
        recordUpdateRequest: {
          context: mergeDetectionsClassificationIntoContext(
            context,
            detectionTopicId,
            classification,
          ),
        },
      });
    },
  });
}

function mergeDetectionsClassificationIntoContext(
  context: object | null,
  detectionTopicId: Topic["id"],
  classification: "accept" | "reject",
): object {
  return merge({}, context ?? {}, {
    studio: {
      ml: {
        inference: {
          [detectionTopicId]: classification,
        },
      },
    },
  });
}
