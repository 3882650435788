import React from "react";
import {
  Box,
  Chip,
  chipClasses,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useInsecureHash } from "~/hooks/use-insecure-hash";
import type { Log, Tag } from "~/lqs";
import { selectLabelColor, useDeleteTag, useLabel } from "~/lqs";
import { assertNever, combineQueries, selectData } from "~/utils";
import type { LogInstantTag, LogLevelTag, LogRangeTag } from "./types";
import { useLabelColors } from "./use-label-colors";

interface BaseLogTagChipProps {
  log: Log;
  active?: boolean;
  readonly?: boolean;
  onClick?: () => void;
  useHashColor?: boolean;
}

interface AnyLogTagChipProps extends BaseLogTagChipProps {
  type?: never;
  tag: Tag;
  formatTimestamp?: never;
}

interface LogLevelTagChipProps extends BaseLogTagChipProps {
  type: "log-level";
  tag: LogLevelTag;
  formatTimestamp?: never;
}

interface LogInstantTagChipProps extends BaseLogTagChipProps {
  type: "instant";
  tag: LogInstantTag;
  formatTimestamp: (timestamp: bigint) => string;
}

interface LogRangeTagChipProps extends BaseLogTagChipProps {
  type: "range";
  tag: LogRangeTag;
  formatTimestamp: (timestamp: bigint) => string;
}

type LogTagChipProps =
  | AnyLogTagChipProps
  | LogLevelTagChipProps
  | LogInstantTagChipProps
  | LogRangeTagChipProps;

export function LogTagChip({
  log,
  type,
  tag,
  formatTimestamp,
  active = false,
  readonly = false,
  onClick,
  useHashColor = false,
}: LogTagChipProps) {
  const labelQuery = useLabel(tag.labelId, { select: selectData });
  const labelColorQuery = useInsecureHash(labelQuery.data?.value, {
    select: selectLabelColor,
  });
  const combinedQuery = combineQueries({
    queries: [labelQuery, labelColorQuery],
    transform([label, color]) {
      return { label, color };
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const deleteTagMutation = useDeleteTag(log.id, tag.id);

  const labelColors = useLabelColors(
    combinedQuery.data?.label,
    combinedQuery.data?.color,
  );

  function handleDeleteTag(): void {
    deleteTagMutation.mutate(undefined, {
      onError() {
        enqueueSnackbar("Unable to remove tag", { variant: "error" });
      },
    });
  }

  const interactive = onClick != null;

  let chipLabel;
  switch (combinedQuery.status) {
    case "loading": {
      chipLabel = "Loading...";
      break;
    }
    case "error": {
      chipLabel = "Unknown";
      break;
    }
    case "success": {
      const {
        data: { label },
      } = combinedQuery;

      switch (type) {
        case undefined:
        case "log-level": {
          chipLabel = label.value;
          break;
        }
        case "instant": {
          chipLabel = renderStackedLabel(
            label.value,
            formatTimestamp(tag.startTime),
          );
          break;
        }
        case "range": {
          chipLabel = renderStackedLabel(
            label.value,
            `${formatTimestamp(tag.startTime)} - ${formatTimestamp(tag.endTime)}`,
          );
          break;
        }
        default: {
          assertNever(type);
        }
      }
      break;
    }
    default: {
      assertNever(combinedQuery);
    }
  }

  return (
    <Box sx={{ position: "relative" }}>
      <Chip
        sx={{
          ...(combinedQuery.status !== "success" && {
            [`& .${chipClasses.label}`]: {
              fontStyle: "italic",
            },
          }),
          ...(useHashColor &&
            labelColors !== null && {
              color: labelColors.text,
              backgroundColor: labelColors.background,
              ...(interactive && {
                [`&:hover, &.${chipClasses.focusVisible}`]: {
                  backgroundColor: labelColors.backgroundInteraction,
                },
              }),
            }),
          ...(["instant", "range"].includes(type as any) && {
            height: "auto",
          }),
        }}
        variant={combinedQuery.status === "success" ? "filled" : "outlined"}
        color={useHashColor ? "default" : active ? "primary" : "default"}
        label={chipLabel}
        onClick={onClick}
        {...(!readonly && {
          disabled:
            combinedQuery.status !== "success" || deleteTagMutation.isLoading,
          onDelete: handleDeleteTag,
        })}
      />
      {deleteTagMutation.isLoading && (
        <CircularProgress
          size="1rem"
          sx={{
            position: "absolute",
            inset: 0,
            margin: "auto",
          }}
        />
      )}
    </Box>
  );
}

function renderStackedLabel(first: React.ReactNode, second: React.ReactNode) {
  return (
    <Stack component="span" sx={{ alignItems: "center", padding: 0.25 }}>
      <Typography component="span" sx={{ fontSize: "inherit" }}>
        {first}
      </Typography>
      <Typography component="span" sx={{ fontSize: "0.85em" }}>
        {second}
      </Typography>
    </Stack>
  );
}
