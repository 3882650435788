import type { SimpleQueryResult, UniversalQueryResult } from "~/types";

// Extracts the type of each query result's `data` property when it's
// successful. This supports tuples and arrays. When mapping over an array or
// tuple TypeScript will make the final type an array or tuple as well (which
// is different from how mapped types usually work with objects).
type QueriesData<TQueries extends ReadonlyArray<UniversalQueryResult<any>>> = {
  [Index in keyof TQueries]: Extract<
    TQueries[Index],
    { status: "success" }
  >["data"];
};

export function combineQueries<
  const TQueries extends ReadonlyArray<UniversalQueryResult<any>>,
  TTransformedData,
>({
  queries,
  transform,
}: {
  queries: TQueries;
  transform: (data: QueriesData<TQueries>) => TTransformedData;
}): SimpleQueryResult<TTransformedData> {
  if (queries.length === 0) {
    // The check below to see if every query is successful would be vacuously
    // true for an empty array so this check needs to come first. It might be
    // worth adding an option to specify if an empty array should be considered
    // successful or loading.
    return { status: "loading" };
  } else if (queries.every((query) => query.status === "success")) {
    return {
      status: "success",
      data: transform(
        queries.map((query) => query.data) as QueriesData<TQueries>,
      ),
    };
  } else if (queries.some((query) => query.status === "error")) {
    return { status: "error" };
  } else {
    return { status: "loading" };
  }
}
