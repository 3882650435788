import React from "react";
import { TablePage } from "~/components/Table";
import { UserSearchRequestProvider, UserTable } from "./user-table";
import { UserTableFilters } from "./user-table-filters";

export function UserTablePage() {
  return (
    <TablePage
      title="Search Users"
      RequestProvider={UserSearchRequestProvider}
      table={<UserTable />}
      filters={<UserTableFilters />}
    />
  );
}
