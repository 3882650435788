/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Workflow } from "./Workflow";
import {
  WorkflowFromJSON,
  WorkflowFromJSONTyped,
  WorkflowToJSON,
} from "./Workflow";

export interface WorkflowListResponse {
  offset: number;
  limit: number;
  order: string;
  sort: string;
  count: number | null;
  data: Array<Workflow>;
}

export function WorkflowListResponseFromJSON(json: any): WorkflowListResponse {
  return WorkflowListResponseFromJSONTyped(json);
}

export function WorkflowListResponseFromJSONTyped(
  json: any,
): WorkflowListResponse {
  if (json == null) {
    return json;
  }

  return {
    offset: json["offset"],
    limit: json["limit"],
    order: json["order"],
    sort: json["sort"],
    count: json["count"],
    data: (json["data"] as Array<any>).map(WorkflowFromJSON),
  };
}

export function WorkflowListResponseToJSON(
  value: WorkflowListResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    offset: value.offset,
    limit: value.limit,
    order: value.order,
    sort: value.sort,
    count: value.count,
    data: (value.data as Array<any>).map(WorkflowToJSON),
  };
}
