import React from "react";
import { selectData } from "~/utils";
import { useDataStore, useUpdateDataStore } from "../../../api";
import { EditDsmResourceForm } from "../../../forms";
import * as dsmPaths from "../../../paths";
import { editDataStoreSchema } from "../schemas";

export function DataStoreEditForm() {
  const { dataStoreId } = dsmPaths.useDsmPathParams(dsmPaths.DATASTORE_EDIT);

  return (
    <EditDsmResourceForm
      resourceName="DataStore"
      query={useDataStore(dataStoreId, { select: selectData })}
      schema={editDataStoreSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "ownerId", type: "foreign-key", resourceType: "user" },
        { name: "version", type: "text" },
        { name: "region", type: "text" },
        { name: "endpoint", type: "text" },
        { name: "config", type: "json" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateDataStore(dataStoreId)}
      detailsLocation={dsmPaths.createDsmPath(dsmPaths.DATASTORE_DETAILS, {
        dataStoreId,
      })}
    />
  );
}
