import React from "react";
import type { UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type { Control } from "react-hook-form";
import type { Path } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import type { StrictOmit } from "ts-essentials";
import type * as z from "zod";
import type {
  ApiVersion,
  DeprecationPolicy,
  VersionHistories,
} from "~/domain/versioning";
import { pick } from "~/lib/std";
import { Card } from "../Card";
import { QueryRenderer } from "../QueryRenderer";
import { ErrorMessage } from "../error-message";
import { Form } from "./Form";
import { FormSkeleton } from "./FormSkeleton";
import { useStudioForm } from "./hooks";
import { renderFormField } from "./render-form-field";
import type {
  ForeignKeyFormFieldDescriptor,
  ForeignKeyResourceType,
  FormFieldDescriptor,
  SimplifiedMutation,
} from "./types";
import { getAvailableFieldNames, getChangedFields } from "./utils";

export interface EditResourceFormProps<
  TResource extends object,
  TRequest extends object,
  TForeignResource extends ForeignKeyResourceType,
> {
  resourceName: string;
  disabled?: (resource: NoInfer<TResource>) => boolean;
  footer?: (resource: NoInfer<TResource>) => React.ReactNode;
  query: UseQueryResult<TResource>;
  schema: z.ZodObject<
    z.ZodRawShape,
    z.UnknownKeysParam,
    z.ZodTypeAny,
    TRequest
  >;
  /**
   * Descriptors can be a function if the resource is needed to fill out
   * additional props. The `resource` argument will be `undefined` if the
   * descriptors are needed to generate form skeleton shapes before the query
   * succeeds; however, `resource` will never be `undefined` when the function
   * is called to initialize and render the form.
   */
  descriptors:
    | ReadonlyArray<FormFieldDescriptor<NoInfer<TRequest>, TForeignResource>>
    | ((
        resource?: NoInfer<TResource>,
      ) => ReadonlyArray<
        FormFieldDescriptor<NoInfer<TRequest>, TForeignResource>
      >);
  versionHistories?: VersionHistories<any>;
  apiVersion: ApiVersion;
  deprecationPolicy: DeprecationPolicy;
  renderForeignKeyFormField: (
    control: Control<NoInfer<TRequest>>,
    descriptor: ForeignKeyFormFieldDescriptor<
      NoInfer<TRequest>,
      TForeignResource
    >,
    deprecated: boolean,
  ) => React.JSX.Element;
  mutation: SimplifiedMutation<{ data: NoInfer<TResource> }, NoInfer<TRequest>>;
  detailsLocation?: Partial<Path>;
}

export function EditResourceForm<
  TResource extends object,
  TRequest extends object,
  TForeignResource extends ForeignKeyResourceType,
>({
  resourceName,
  query,
  ...rest
}: EditResourceFormProps<TResource, TRequest, TForeignResource>) {
  const resolvedDescriptors =
    typeof rest.descriptors === "function"
      ? rest.descriptors()
      : rest.descriptors;

  return (
    <Card>
      <QueryRenderer
        query={query}
        loading={
          <FormSkeleton shapes={resolvedDescriptors.map(pickSkeletonShape)} />
        }
        error={<ErrorMessage>Error fetching {resourceName}</ErrorMessage>}
        success={(resource) => (
          <EditResourceFormInner
            {...rest}
            resourceName={resourceName}
            resource={resource}
          />
        )}
      />
    </Card>
  );
}

function EditResourceFormInner<
  TResource extends object,
  TRequest extends object,
  TForeignResource extends ForeignKeyResourceType,
>({
  resourceName,
  disabled,
  footer,
  resource,
  schema,
  descriptors: descriptorsProp,
  versionHistories,
  apiVersion,
  deprecationPolicy,
  renderForeignKeyFormField,
  mutation,
  detailsLocation,
}: StrictOmit<
  EditResourceFormProps<TResource, TRequest, TForeignResource>,
  "query"
> & { resource: TResource }) {
  const descriptors =
    typeof descriptorsProp === "function"
      ? descriptorsProp(resource)
      : descriptorsProp;

  const availableFields = descriptors.flatMap((descriptor) => {
    const history = versionHistories?.[descriptor.name];

    if (history == null) {
      return [{ descriptor, deprecated: false }];
    } else {
      const status = apiVersion.checkStatus(history);

      if (status === "unavailable") {
        return [];
      } else if (deprecationPolicy === "hide" && status === "deprecated") {
        return [];
      } else {
        return [{ descriptor, deprecated: status === "deprecated" }];
      }
    }
  });

  const availableFieldNames = getAvailableFieldNames(availableFields);

  const pickedSchema = schema.pick(
    Object.fromEntries(availableFieldNames.map((name) => [name, true])),
  );

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useStudioForm({
    schema: pickedSchema,
    values: pick(resource, availableFieldNames) as any,
    onSubmit: function onSubmit(values: any) {
      const changedFields = getChangedFields(values, dirtyFields as any);

      mutation.mutate(changedFields as TRequest, {
        onSuccess() {
          enqueueSnackbar(`Updated ${resourceName}`, { variant: "success" });

          if (detailsLocation != null) {
            navigate(detailsLocation);
          }
        },
        onError() {
          enqueueSnackbar(`Unable to update ${resourceName}`, {
            variant: "error",
          });
        },
      });
    } as any,
  });

  return (
    <Form
      onSubmit={handleSubmit}
      loading={mutation.isLoading}
      submitText="Update"
      disabled={disabled?.(resource)}
      footer={footer?.(resource)}
    >
      {availableFields.map((field) => (
        <React.Fragment key={field.descriptor.name}>
          {renderFormField(
            control,
            field.descriptor,
            field.deprecated,
            renderForeignKeyFormField,
          )}
        </React.Fragment>
      ))}
    </Form>
  );
}

function pickSkeletonShape(
  descriptor: FormFieldDescriptor<any, any>,
): "text" | "multiline" {
  if (descriptor.type === "text" && descriptor.multiline) {
    return "multiline";
  } else {
    return "text";
  }
}
