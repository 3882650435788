/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface User {
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  username: string;
  roleId: string | null;
  admin: boolean;
  disabled: boolean;
  managed: boolean;
  externalId: string | null;
  context: object | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  company: string | null;
  jobTitle: string | null;
  language: string | null;
  timeZone: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  postalCode: string | null;
  country: string | null;
  profilePicture: string | null;
  freshdeskContactId: number | null;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json);
}

export function UserFromJSONTyped(json: any): User {
  if (json == null) {
    return json;
  }

  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] === null ? null : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] === null ? null : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    username: json["username"],
    roleId: json["role_id"],
    admin: json["admin"],
    disabled: json["disabled"],
    managed: json["managed"],
    externalId: json["external_id"],
    context: json["context"],
    firstName: json["first_name"],
    lastName: json["last_name"],
    phone: json["phone"],
    company: json["company"],
    jobTitle: json["job_title"],
    language: json["language"],
    timeZone: json["time_zone"],
    addressLine1: json["address_line_1"],
    addressLine2: json["address_line_2"],
    city: json["city"],
    state: json["state"],
    postalCode: json["postal_code"],
    country: json["country"],
    profilePicture: json["profile_picture"],
    freshdeskContactId: json["freshdesk_contact_id"],
  };
}

export function UserToJSON(value: User | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    username: value.username,
    role_id: value.roleId,
    admin: value.admin,
    disabled: value.disabled,
    managed: value.managed,
    external_id: value.externalId,
    context: value.context,
    first_name: value.firstName,
    last_name: value.lastName,
    phone: value.phone,
    company: value.company,
    job_title: value.jobTitle,
    language: value.language,
    time_zone: value.timeZone,
    address_line_1: value.addressLine1,
    address_line_2: value.addressLine2,
    city: value.city,
    state: value.state,
    postal_code: value.postalCode,
    country: value.country,
    profile_picture: value.profilePicture,
    freshdesk_contact_id: value.freshdeskContactId,
  };
}
