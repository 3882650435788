import { useEffect } from "react";
import { tinykeys } from "tinykeys";

export function useHotkeys(
  keybinding: string,
  handler: (e: KeyboardEvent) => void,
) {
  useEffect(() => {
    const cleanup = tinykeys(document.body, { [keybinding]: handler });

    return cleanup;
  }, [keybinding, handler]);
}
