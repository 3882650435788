import React from "react";
import type { Control } from "react-hook-form";
import { invariant } from "~/lib/invariant";
import { assertNever } from "~/utils/assertNever";
import { BooleanField } from "./BooleanField";
import { CheckboxField } from "./CheckboxField";
import { DateField } from "./DateField";
import { FuzzyField } from "./FuzzyField";
import { NumberField } from "./NumberField";
import { ObjectField } from "./ObjectField";
import { SelectField } from "./SelectField";
import { TextField } from "./TextField";
import { TimestampField } from "./TimestampField";
import type {
  ForeignKeyFormFieldDescriptor,
  FormFieldDescriptor,
} from "./types";

export function renderFormField(
  control: Control<any>,
  descriptor: FormFieldDescriptor<any, any>,
  deprecated: boolean,
  renderForeignKeyFormField:
    | undefined
    | ((
        control: Control<any>,
        descriptor: ForeignKeyFormFieldDescriptor<any, any>,
        deprecated: boolean,
      ) => React.JSX.Element),
): React.ReactNode {
  const { name, type, label, required } = descriptor;
  switch (type) {
    case "text": {
      const { multiline, monospace } = descriptor;

      return (
        <TextField
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
          multiline={multiline}
          monospace={monospace}
        />
      );
    }
    case "fuzzy": {
      const { fuzzyName } = descriptor;

      return (
        <FuzzyField
          control={control}
          exactName={name}
          fuzzyName={fuzzyName}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "select": {
      const { options } = descriptor;

      return (
        <SelectField
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
          options={options}
        />
      );
    }
    case "number": {
      return (
        <NumberField
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "timestamp": {
      return (
        <TimestampField
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "datetime": {
      return (
        <DateField
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "boolean": {
      const { optional } = descriptor;

      if (optional) {
        return (
          <BooleanField
            control={control}
            name={name}
            deprecated={deprecated}
            label={label}
            required={required}
          />
        );
      } else {
        return (
          <CheckboxField
            control={control}
            name={name}
            deprecated={deprecated}
            label={label}
            required={required}
          />
        );
      }
    }
    case "json": {
      return (
        <ObjectField
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "foreign-key": {
      invariant(
        renderForeignKeyFormField != null,
        "Expected foreign key form field renderer to be defined",
      );

      return renderForeignKeyFormField(control, descriptor, deprecated);
    }
    default: {
      assertNever(type);
    }
  }
}
