import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { ValidationError } from "~/errors";
import type {
  SearchParamAliases,
  SearchParamsParser,
} from "~/utils/search-params";
import { createSearchParamHandlers } from "~/utils/search-params";

export function useTypedSearchParams<TSearchParams extends Record<any, any>>(
  parser: SearchParamsParser<TSearchParams>,
  aliases?: NoInfer<SearchParamAliases<TSearchParams>>,
): [TSearchParams, (updates: Partial<TSearchParams>) => void] {
  const [_rawSearchParams, _setSearchParams] = useSearchParams();

  const handlers = useMemo(
    () => createSearchParamHandlers(parser, aliases),
    [parser, aliases],
  );

  let searchParams: TSearchParams;
  try {
    searchParams = handlers.deserialize(_rawSearchParams);
  } catch (e) {
    throw new ValidationError({
      source: "search",
      cause: e,
    });
  }

  const setSearchParams = useCallback(
    (updates: Partial<TSearchParams>) => {
      _setSearchParams((prevSearchParams) => {
        return handlers.serialize(updates, prevSearchParams);
      });
    },
    [_setSearchParams, handlers],
  );

  return [searchParams, setSearchParams];
}
