import type React from "react";
import type { LqsNavigator } from "~/paths";
import {
  generateDestination,
  LqsNavigatorProvider,
  lqsRoutePaths as originalLqsRoutePaths,
} from "~/paths";

const { DATASTORE_DASHBOARD, PLAYER, PROFILE } = originalLqsRoutePaths;

const DIGESTION_TABLE = "/extractions" as const;

export const lqsRoutePaths = {
  DATASTORE_DASHBOARD,
  PLAYER,
  DIGESTION_TABLE,
  PROFILE,
};

export function PublicLqsNavigatorProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <LqsNavigatorProvider navigator={publicLqsNavigator}>
      {children}
    </LqsNavigatorProvider>
  );
}

const publicLqsNavigator: LqsNavigator = {
  DATASTORE_DASHBOARD,
  toDashboard() {
    return generateDestination({
      pattern: DATASTORE_DASHBOARD,
    });
  },
  PLAYER,
  toPlayer(search) {
    return generateDestination({
      pattern: PLAYER,
      search,
    });
  },
  DIGESTION_TABLE,
  toDigestionTable() {
    return generateDestination({
      pattern: DIGESTION_TABLE,
    });
  },
  PROFILE,
  toProfile() {
    return generateDestination({
      pattern: PROFILE,
    });
  },
};
