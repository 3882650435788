import { Container } from "@mui/material";
import { LqsPage } from "~/lqs/layout";
import MeView from "./components/MeView";

export function ProfilePage() {
  return (
    <LqsPage title="Profile">
      <Container fixed>
        <MeView />
      </Container>
    </LqsPage>
  );
}
