import { Box, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout, LockCard } from "~/components/DetailsCards";
import type { Group } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteGroup,
  useGroup,
  useUpdateGroup,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editGroupSchema } from "../schemas";

export function GroupDetails() {
  const { groupId } = useTypedParams(lqsRoutePaths.GROUP_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toGroupEdit: true,
    toGroupTable: true,
  });

  const query = useGroup(groupId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="group"
      query={query}
      updateMutation={useUpdateGroup(groupId)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="group"
      query={query}
      editLocation={lqsNavigator.toGroupEdit({ groupId })}
      deleteMutation={useDeleteGroup(groupId)}
      getReadableName={(group) => group.name}
      listLocation={lqsNavigator.toGroupTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: 3,
            }}
          >
            {lockSection}
            {manageSection}
          </Box>
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Group> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="group"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          {
            dataType: "foreign-key",
            resourceType: "workflow",
            accessor: "defaultWorkflowId",
          },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Group> }) {
  const { groupId } = useTypedParams(lqsRoutePaths.GROUP_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="group"
      registryKey="group"
      disabled={(group) => group.locked}
      footer={(group) =>
        group.locked && (
          <Typography variant="body2">
            Unlock this group to make updates.
          </Typography>
        )
      }
      query={query}
      schema={editGroupSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateGroup(groupId)}
    />
  );
}
