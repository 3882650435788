import { useState } from "react";
import { Container, styled } from "@mui/material";
import { Page } from "~/layout";
import type { Label } from "~/lqs";
import { LabelsSection } from "./labels-section";
import { LogsSection } from "./logs-section";

const Sections = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    height: "100%",
    display: "grid",
    gridTemplateAreas: `
    "labels logs"
  `,
    gridTemplateColumns: "minmax(0, 2fr) minmax(0, 3fr)",
    columnGap: theme.spacing(4),
    alignItems: "start",
  },
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(4),
  },
}));

export function TaggingPage() {
  const [selectedLabelIds, setSelectedLabelIds] = useState<
    ReadonlyArray<Label["id"]>
  >([]);

  return (
    <Page title="Tagging">
      <Sections fixed>
        <LabelsSection
          selectedLabelIds={selectedLabelIds}
          setSelectedLabelIds={setSelectedLabelIds}
        />
        <LogsSection selectedLabelIds={selectedLabelIds} />
      </Sections>
    </Page>
  );
}
