import { EditLqsResourceForm, useRole, useUpdateRole } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editRoleSchema } from "../schemas";

export function RoleEditForm() {
  const { roleId } = useTypedParams(lqsRoutePaths.ROLE_EDIT);

  const lqsNavigator = useLqsNavigator({ toRoleDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="role"
      registryKey="role"
      query={useRole(roleId, { select: selectData })}
      schema={editRoleSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "policy", type: "json", required: true },
        { name: "_default", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateRole(roleId)}
      detailsLocation={lqsNavigator.toRoleDetails({ roleId })}
    />
  );
}
