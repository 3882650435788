import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import { DigestionTopicFilters } from "./components/DigestionTopicFilters";
import {
  DigestionTopicSearchRequestProvider,
  DigestionTopicTable,
} from "./components/DigestionTopicTable";

export function DigestionTopicsPage() {
  return (
    <TablePage
      title="Search Digestion Topics"
      RequestProvider={DigestionTopicSearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <DigestionTopicTable />
        </>
      }
      filters={<DigestionTopicFilters />}
    />
  );
}
