/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UserUpdateRequest {
  username?: string;
  roleId?: string | null;
  admin?: boolean;
  disabled?: boolean;
  externalId?: string | null;
  password?: string | null;
  context?: object | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  company?: string | null;
  jobTitle?: string | null;
  language?: string | null;
  timeZone?: string | null;
  addressLine1?: string | null;
  addressLine2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

export function UserUpdateRequestFromJSON(json: any): UserUpdateRequest {
  return UserUpdateRequestFromJSONTyped(json);
}

export function UserUpdateRequestFromJSONTyped(json: any): UserUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    username: json["username"],
    roleId: json["role_id"],
    admin: json["admin"],
    disabled: json["disabled"],
    externalId: json["external_id"],
    password: json["password"],
    context: json["context"],
    firstName: json["first_name"],
    lastName: json["last_name"],
    phone: json["phone"],
    company: json["company"],
    jobTitle: json["job_title"],
    language: json["language"],
    timeZone: json["time_zone"],
    addressLine1: json["address_line_1"],
    addressLine2: json["address_line_2"],
    city: json["city"],
    state: json["state"],
    postalCode: json["postal_code"],
    country: json["country"],
  };
}

export function UserUpdateRequestToJSON(
  value: UserUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    username: value.username,
    role_id: value.roleId,
    admin: value.admin,
    disabled: value.disabled,
    external_id: value.externalId,
    password: value.password,
    context: value.context,
    first_name: value.firstName,
    last_name: value.lastName,
    phone: value.phone,
    company: value.company,
    job_title: value.jobTitle,
    language: value.language,
    time_zone: value.timeZone,
    address_line_1: value.addressLine1,
    address_line_2: value.addressLine2,
    city: value.city,
    state: value.state,
    postal_code: value.postalCode,
    country: value.country,
  };
}
