/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Digestion } from "./Digestion";
import {
  DigestionFromJSON,
  DigestionFromJSONTyped,
  DigestionToJSON,
} from "./Digestion";

export interface DigestionDataResponse {
  data: Digestion;
}

export function DigestionDataResponseFromJSON(
  json: any,
): DigestionDataResponse {
  return DigestionDataResponseFromJSONTyped(json);
}

export function DigestionDataResponseFromJSONTyped(
  json: any,
): DigestionDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: DigestionFromJSON(json["data"]),
  };
}

export function DigestionDataResponseToJSON(
  value: DigestionDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: DigestionToJSON(value.data),
  };
}
