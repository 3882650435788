/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { User } from "./User";
import { UserFromJSON, UserFromJSONTyped, UserToJSON } from "./User";

export interface MeDataResponse {
  data: User | null;
}

export function MeDataResponseFromJSON(json: any): MeDataResponse {
  return MeDataResponseFromJSONTyped(json);
}

export function MeDataResponseFromJSONTyped(json: any): MeDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: UserFromJSON(json["data"]),
  };
}

export function MeDataResponseToJSON(
  value: MeDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: UserToJSON(value.data),
  };
}
