import type { FieldValues, Path } from "react-hook-form";
import type {
  ResourceSelectFieldProps,
  ResourceSelectInputProps,
} from "~/components/Form";
import { ResourceSelectField, ResourceSelectInput } from "~/components/Form";
import {
  useGroupQueryOptionsFactory,
  useGroupsQueryOptionsFactory,
} from "../api";

export function GroupSelectInput(props: ResourceSelectInputProps) {
  const createGroupsQueryOptions = useGroupsQueryOptionsFactory();
  const createGroupQueryOptions = useGroupQueryOptionsFactory();

  return (
    <ResourceSelectInput
      {...props}
      createListQueryOptions={(text) =>
        createGroupsQueryOptions({
          nameLike: text,
          limit: 5,
          sort: "asc",
          order: "name",
        })
      }
      createFetchQueryOptions={createGroupQueryOptions}
      selectOption={(group) => ({
        value: group.id,
        label: group.name,
      })}
    />
  );
}

export function GroupSelectField<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(props: ResourceSelectFieldProps<TFieldValues, TName>) {
  const createGroupsQueryOptions = useGroupsQueryOptionsFactory();
  const createGroupQueryOptions = useGroupQueryOptionsFactory();

  return (
    <ResourceSelectField
      {...props}
      createListQueryOptions={(text) =>
        createGroupsQueryOptions({
          nameLike: text,
          limit: 5,
          sort: "asc",
          order: "name",
        })
      }
      createFetchQueryOptions={createGroupQueryOptions}
      selectOption={(group) => ({
        value: group.id,
        label: group.name,
      })}
    />
  );
}
