import React from "react";
import { assertNever } from "~/utils";
import type { InitializedPanelNode } from "../panels";
import type { ImageFrame } from "../visualizations/image-visualization/types";
import type { DetectionInferenceResults } from "./detections";
import { DetectionResultsVisualization } from "./detections";
import type { ImageInferenceResults } from "./images";
import { ImageResultsVisualization } from "./images";
import { usePanelOnDemandInference } from "./on-demand-inference";
import { ImageSegmentationsResultsVisualization } from "./segmentations";

export type InferenceResults =
  | DetectionInferenceResults
  | ImageInferenceResults;

export function InferenceResultsVisualization({
  panel,
  imageFrame,
}: {
  panel: InitializedPanelNode;
  imageFrame: ImageFrame;
}) {
  const onDemandInference = usePanelOnDemandInference(panel);

  if (onDemandInference?.status === "fulfilled") {
    const { value: onDemandResult } = onDemandInference;

    switch (onDemandResult.task) {
      case "object-detection": {
        return (
          <DetectionResultsVisualization
            panel={panel}
            results={onDemandResult.data.output}
          />
        );
      }
      case "image-segmentation": {
        return (
          <ImageSegmentationsResultsVisualization
            panel={panel}
            results={onDemandResult.data.output}
          />
        );
      }
      case "depth-estimation": {
        return (
          <ImageResultsVisualization
            panel={panel}
            results={onDemandResult.data.output}
          />
        );
      }
      default: {
        assertNever(onDemandResult);
      }
    }
  }

  const { inferenceFrames } = imageFrame;

  if (inferenceFrames?.current == null) {
    return null;
  }

  switch (inferenceFrames.type) {
    case "object-detection":
    case "zero-shot-object-detection": {
      return (
        <DetectionResultsVisualization
          panel={panel}
          results={inferenceFrames.current}
        />
      );
    }
    case "image-segmentation": {
      return (
        <ImageSegmentationsResultsVisualization
          panel={panel}
          results={inferenceFrames.current}
        />
      );
    }
    case "depth-estimation": {
      return (
        <ImageResultsVisualization
          panel={panel}
          results={inferenceFrames.current}
        />
      );
    }
    default: {
      assertNever(inferenceFrames);
    }
  }
}
