import React, { useState } from "react";
import { Search } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  cardContentClasses,
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  MenuItem,
  Stack,
  styled,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as z from "zod";
import { Card } from "~/components/Card";
import { TextField, useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { Pagination } from "~/components/Pagination";
import { renderQuery } from "~/components/QueryRenderer";
import { useSearchRequest } from "~/components/Table";
import { ErrorMessage } from "~/components/error-message";
import {
  filterText,
  requiredText,
  selectCountableListResponse,
} from "~/domain/common";
import type { Label } from "~/lqs";
import { useCreateLabel, useLabel, useLabels } from "~/lqs";
import {
  createPrefixedAliases,
  getEventHandlerProps,
  selectData,
} from "~/utils";
import { getPaperDarkBackgroundImage } from "~/utils/get-paper-dark-background-image";

const Root = styled(Card)(({ theme }) => ({
  gridArea: "labels",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  [`& .${cardContentClasses.root}`]: {
    minHeight: 0,
    flex: 1,
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(3),
  },
}));

const LIMIT_OPTIONS = [15, 25, 50];

const valueFilterSchema = z.object({
  value: filterText,
});

const baseLabelsFilterSchema = valueFilterSchema.extend({
  limit: z.coerce
    .number()
    .refine((arg) => LIMIT_OPTIONS.includes(arg))
    .catch(LIMIT_OPTIONS[0]),
  offset: z.coerce.number().int().nonnegative().catch(0),
});

const labelsFilterSchema = baseLabelsFilterSchema.transform((arg) => {
  // Offset should be multiple of limit
  const isValidOffset = arg.offset % arg.limit === 0;

  return {
    ...arg,
    offset: isValidOffset ? arg.offset : 0,
  };
});

const newLabelSchema = z.object({
  value: requiredText,
});

export function LabelsSection({
  selectedLabelIds,
  setSelectedLabelIds,
}: {
  selectedLabelIds: ReadonlyArray<Label["id"]>;
  setSelectedLabelIds: React.Dispatch<
    React.SetStateAction<ReadonlyArray<Label["id"]>>
  >;
}) {
  const [creating, setCreating] = useState(false);

  const [labelsSearchRequest, setLabelsSearchRequest] = useSearchRequest(
    labelsFilterSchema,
    createPrefixedAliases("label", baseLabelsFilterSchema.keyof().options),
  );

  const labelsQuery = useLabels(
    {
      valueLike: labelsSearchRequest.value,
      limit: labelsSearchRequest.limit,
      offset: labelsSearchRequest.offset,
      sort: "asc",
      order: "value",
      includeCount: true,
    },
    {
      keepPreviousData: true,
      cacheTime: 0,
      select: selectCountableListResponse,
    },
  );

  const labelsFilterForm = useStudioForm({
    schema: valueFilterSchema,
    values: { value: labelsSearchRequest.value },
    onSubmit: setLabelsSearchRequest,
  });

  const { enqueueSnackbar } = useSnackbar();

  const createLabelMutation = useCreateLabel();
  const newLabelForm = useStudioForm({
    schema: newLabelSchema,
    defaultValues: { value: null },
    onSubmit(values) {
      createLabelMutation.mutate(values, {
        onSuccess() {
          enqueueSnackbar("Label created", { variant: "success" });
          setCreating(false);
          newLabelForm.reset();
        },
        onError() {
          enqueueSnackbar("Unable to create label", { variant: "error" });
        },
      });
    },
  });

  function handleLimitChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setLabelsSearchRequest({ limit: Number(e.target.value) });
  }

  function handleOffsetChange(newOffset: number): void {
    setLabelsSearchRequest({ offset: newOffset });
  }

  const startCreatingHandlerProps = getEventHandlerProps(
    "onClick",
    labelsQuery.isSuccess &&
      !creating &&
      function handleStartCreating() {
        setCreating(true);
      },
  );

  const cancelCreatingHandlerProps = getEventHandlerProps(
    "onClick",
    creating &&
      !createLabelMutation.isLoading &&
      function handleCancelCreating() {
        setCreating(false);
        newLabelForm.reset();
      },
  );

  return (
    <Root
      title="Labels"
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          {...startCreatingHandlerProps}
        >
          New Label
        </Button>
      }
    >
      {creating && (
        <>
          <Stack
            spacing={1}
            component="form"
            noValidate
            onSubmit={newLabelForm.handleSubmit}
          >
            <Typography sx={{ fontWeight: "bold" }}>New Label</Typography>
            <TextField
              name="value"
              control={newLabelForm.control}
              required
              size="small"
            />
            <Stack direction="row" spacing={2}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                disableElevation
                loading={createLabelMutation.isLoading}
              >
                Create label
              </LoadingButton>
              <Button
                variant="text"
                color="secondary"
                size="small"
                {...cancelCreatingHandlerProps}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
          <Divider />
        </>
      )}
      <Stack spacing={1.5}>
        <Box
          component="form"
          noValidate
          onSubmit={labelsFilterForm.handleSubmit}
        >
          <TextField
            name="value"
            control={labelsFilterForm.control}
            size="small"
            noHelperText
            endAdornment={
              <InputAdornment position="end">
                <Tooltip title="Search">
                  <IconButton type="submit" edge="end" size="small">
                    <Search fontSize="small" />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
          />
        </Box>
      </Stack>
      <Box sx={{ position: "relative" }}>
        <Divider />
        {labelsQuery.isRefetching && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </Box>
      {renderQuery(labelsQuery, {
        loading: <Loading type="circular" />,
        error: <ErrorMessage>Unable to load labels</ErrorMessage>,
        success(response) {
          return (
            <>
              <List
                sx={{
                  minHeight: 0,
                  flex: 1,
                  overflowY: "auto",
                  marginBlock: -3,
                }}
                disablePadding
                subheader={
                  <ListSubheader
                    disableGutters
                    sx={(theme) => ({
                      paddingBlock: 1,
                      backgroundColor: "background.paper",
                      ...(theme.palette.mode === "dark" && {
                        backgroundImage: getPaperDarkBackgroundImage(),
                      }),
                    })}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        alignItems: "center",
                        flexWrap: "wrap",
                        paddingBlock: 1,
                      }}
                    >
                      {selectedLabelIds.length === 0 ? (
                        <Typography sx={{ fontStyle: "italic" }}>
                          No labels selected
                        </Typography>
                      ) : (
                        <>
                          <Typography>Selected labels:</Typography>
                          {selectedLabelIds.map((labelId) => (
                            <SelectedLabelChip
                              key={labelId}
                              labelId={labelId}
                            />
                          ))}
                        </>
                      )}
                    </Stack>
                    <Divider />
                  </ListSubheader>
                }
              >
                {response.data.map((label) => (
                  <LabelListItem
                    key={label.id}
                    label={label}
                    selectedLabelIds={selectedLabelIds}
                    setSelectedLabelIds={setSelectedLabelIds}
                  />
                ))}
              </List>
              <Divider />
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", alignItems: "center" }}
              >
                <MuiTextField
                  select
                  size="small"
                  label="Results per page"
                  value={labelsSearchRequest.limit}
                  onChange={handleLimitChange}
                  sx={{ width: "15ch" }}
                >
                  {LIMIT_OPTIONS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </MuiTextField>
                <Pagination
                  disableJumping
                  count={response.count}
                  limit={labelsSearchRequest.limit}
                  offset={labelsSearchRequest.offset}
                  onChange={handleOffsetChange}
                />
              </Stack>
            </>
          );
        },
      })}
    </Root>
  );
}

function SelectedLabelChip({ labelId }: { labelId: Label["id"] }) {
  const query = useLabel(labelId, { select: selectData });

  return (
    <Chip
      label={
        // A label can only be selected from the list of rendered labels. So
        // long as the query grabs its initial data from the cached list
        // response, this query should only ever be in a success state. Just to
        // be safe, though, default to a loading message.
        query.data?.value ?? "Loading..."
      }
    />
  );
}

function LabelListItem({
  label,
  selectedLabelIds,
  setSelectedLabelIds,
}: {
  label: Label;
  selectedLabelIds: ReadonlyArray<Label["id"]>;
  setSelectedLabelIds: React.Dispatch<
    React.SetStateAction<ReadonlyArray<Label["id"]>>
  >;
}) {
  const selected = selectedLabelIds.includes(label.id);

  function handleClick(): void {
    setSelectedLabelIds(
      selected
        ? selectedLabelIds.filter((id) => id !== label.id)
        : [...selectedLabelIds, label.id],
    );
  }

  return (
    <ListItem disablePadding>
      <ListItemButton selected={selected} onClick={handleClick}>
        <Chip label={label.value} />
      </ListItemButton>
    </ListItem>
  );
}
