import { TablePage } from "~/components/Table";
import { LabelFilters } from "./components/LabelFilters";
import {
  LabelSearchRequestProvider,
  LabelTable,
} from "./components/LabelTable";

export function LabelsPage() {
  return (
    <TablePage
      title="Search Labels"
      RequestProvider={LabelSearchRequestProvider}
      table={<LabelTable />}
      filters={<LabelFilters />}
    />
  );
}
