import {
  EditLqsResourceForm,
  useObjectStore,
  useUpdateObjectStore,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editObjectStoreSchema } from "../schemas";

export function ObjectStoreEditForm() {
  const { objectStoreId } = useTypedParams(lqsRoutePaths.OBJECT_STORE_EDIT);

  const lqsNavigator = useLqsNavigator({ toObjectStoreDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="object store"
      registryKey="objectStore"
      query={useObjectStore(objectStoreId, { select: selectData })}
      schema={editObjectStoreSchema}
      descriptors={[
        { name: "bucketName", type: "text", required: true },
        { name: "keyPrefix", type: "text" },
        { name: "accessKeyId", type: "text" },
        { name: "regionName", type: "text" },
        { name: "endpointUrl", type: "text" },
        { name: "_default", type: "boolean" },
        { name: "readOnly", type: "boolean" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateObjectStore(objectStoreId)}
      detailsLocation={lqsNavigator.toObjectStoreDetails({ objectStoreId })}
    />
  );
}
