import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Stack, Typography } from "@mui/material";
import * as z from "zod";
import { TextField, useStudioForm } from "~/components/Form";
import { checkIsSignInDone } from "~/lib/auth";
import { invariant } from "~/lib/invariant";
import { requiredEmail, requiredPassword, requiredText } from "../common";
import { useConfirmSignUp, useRequestNewSignUpCode, useSignUp } from "./api";
import { AuthErrorAlert } from "./auth-error-alert";
import { PasswordRequirements } from "./password-requirements";
import { RequestNewCodeButton } from "./request-new-code-button";
import { SocialAuth } from "./social-auth";

const signUpSchema = z.object({
  email: requiredEmail,
  password: requiredPassword({
    minLength: 8,
    includesNumber: true,
    includesSpecial: true,
    includesUppercase: true,
    includesLowercase: true,
  }),
});

const confirmSignUpSchema = z.object({
  code: requiredText,
});

export function SignUpForm({
  onLoadingStart,
  onLoadingEnd,
  onSignUpComplete,
  message,
  footer,
}: {
  onLoadingStart?: () => void;
  onLoadingEnd?: () => void;
  onSignUpComplete: () => void;
  message?: React.ReactNode;
  footer?: React.ReactNode;
}) {
  const signUpMutation = useSignUp();

  const signUpForm = useStudioForm({
    schema: signUpSchema,
    onSubmit(values) {
      onLoadingStart?.();
      signUpMutation.mutate(values, {
        onSettled() {
          onLoadingEnd?.();
        },
      });
    },
    defaultValues: {
      email: null,
      password: null,
    },
  });

  const [email, password] = signUpForm.watch(["email", "password"]);
  const canConfirmSignUp =
    email != null &&
    password != null &&
    signUpMutation.isSuccess &&
    signUpMutation.data.nextStep.signUpStep === "CONFIRM_SIGN_UP";

  const confirmSignUpMutation = useConfirmSignUp();

  const confirmSignUpForm = useStudioForm({
    schema: confirmSignUpSchema,
    onSubmit(values) {
      invariant(canConfirmSignUp, "Not in state to confirm sign up");

      onLoadingStart?.();
      confirmSignUpMutation.mutate(
        { email, password, ...values },
        {
          onSuccess(response) {
            if (checkIsSignInDone(response)) {
              onSignUpComplete();
            }
          },
          onSettled() {
            onLoadingEnd?.();
          },
        },
      );
    },
    defaultValues: {
      code: null,
    },
  });

  const requestNewCodeMutation = useRequestNewSignUpCode();

  let content;
  if (!signUpMutation.isSuccess) {
    content = (
      <React.Fragment key="sign-up-step">
        {message}
        <Stack
          spacing={2}
          component="form"
          noValidate
          onSubmit={signUpForm.handleSubmit}
        >
          <TextField
            control={signUpForm.control}
            name="email"
            required
            type="username"
          />
          <TextField
            control={signUpForm.control}
            name="password"
            required
            type="new-password"
            helperText={<PasswordRequirements />}
          />
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            disableElevation
            loading={signUpMutation.isLoading}
          >
            Sign Up
          </LoadingButton>
          <Typography>
            We'll send a verification code to your email to complete sign up.
          </Typography>
          <AuthErrorAlert mutation={signUpMutation} />
        </Stack>
        <Divider>or</Divider>
        <SocialAuth type="sign-up" />
      </React.Fragment>
    );
  } else if (canConfirmSignUp) {
    content = (
      <Stack
        key="confirm-step"
        spacing={2}
        component="form"
        noValidate
        onSubmit={confirmSignUpForm.handleSubmit}
      >
        <Typography>
          A verification code was sent to your email. Enter the code below to
          verify your email.
        </Typography>
        <TextField
          control={confirmSignUpForm.control}
          name="code"
          required
          autoComplete="one-time-code"
        />
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disableElevation
          loading={confirmSignUpMutation.isLoading}
        >
          Verify Email
        </LoadingButton>
        <RequestNewCodeButton mutation={requestNewCodeMutation} email={email} />
        <AuthErrorAlert mutation={confirmSignUpMutation} />
      </Stack>
    );
  } else {
    content = null;
  }

  return (
    <Stack spacing={2}>
      {content}
      <Box sx={{ mt: 4 }}>{footer}</Box>
    </Stack>
  );
}
