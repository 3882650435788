import { invariant } from "~/lib/invariant";
import { useDataStoreClients } from "../context";
import type {
  ListRolesRequest,
  Role,
  RoleCreateRequest,
  RoleDataResponse,
  RoleListResponse,
  RoleUpdateRequest,
} from "../sdk";
import type { LqsQueryOptions } from "./utils";
import { createResourceCrudHooks } from "./utils";

export const {
  queryKeyFactory: roleKeys,
  useList: useRoles,
  useFetch: useRole,
  useCreate: useCreateRole,
  useUpdate: useUpdateRole,
  useDelete: useDeleteRole,
} = createResourceCrudHooks({
  baseQueryKey: "roles",
  getIdentifier(role: Role) {
    return role.id;
  },
  listResource({ signal }, { roleApi }, request: ListRolesRequest) {
    return roleApi.listRoles(request, { signal });
  },
  fetchResource({ signal }, { roleApi }, roleId: Role["id"]) {
    return roleApi.fetchRole({ roleId }, { signal });
  },
  createResource({ roleApi }, request: RoleCreateRequest) {
    return roleApi.createRole({ roleCreateRequest: request });
  },
  updateResource({ roleApi }, roleId: Role["id"], updates: RoleUpdateRequest) {
    return roleApi.updateRole({ roleId, roleUpdateRequest: updates });
  },
  deleteResource({ roleApi }, roleId: Role["id"]) {
    return roleApi.deleteRole({ roleId });
  },
});

export function useRolesQueryOptionsFactory(): (
  request: ListRolesRequest,
) => LqsQueryOptions<RoleListResponse> {
  const { roleApi } = useDataStoreClients();

  return (request) => ({
    queryKey: roleKeys.list(request),
    queryFn({ signal }) {
      return roleApi.listRoles(request, { signal });
    },
  });
}

export function useRoleQueryOptionsFactory(): (
  roleId: Role["id"] | null,
) => LqsQueryOptions<RoleDataResponse, "enabled"> {
  const { roleApi } = useDataStoreClients();

  return (roleId) => {
    const enabled = roleId !== null;

    return {
      queryKey: roleKeys.fetch(roleId),
      queryFn({ signal }) {
        invariant(enabled, "Role ID not provided");

        return roleApi.fetchRole({ roleId }, { signal });
      },
      enabled,
    };
  };
}
