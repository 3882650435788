import type { FieldValues, Path } from "react-hook-form";
import type { ResourceSelectFieldProps } from "~/components/Form";
import { ResourceSelectField } from "~/components/Form";
import {
  useIngestionQueryOptionsFactory,
  useIngestionsQueryOptionsFactory,
} from "../api";

export function IngestionSelect<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(props: ResourceSelectFieldProps<TFieldValues, TName>) {
  const createIngestionsQueryOptions = useIngestionsQueryOptionsFactory();
  const createIngestionQueryOptions = useIngestionQueryOptionsFactory();

  return (
    <ResourceSelectField
      {...props}
      createListQueryOptions={(text) =>
        createIngestionsQueryOptions({
          nameLike: text,
          limit: 5,
          sort: "asc",
          order: "name",
        })
      }
      createFetchQueryOptions={createIngestionQueryOptions}
      selectOption={(ingestion) => ({
        value: ingestion.id,
        label: ingestion.name ?? ingestion.id,
      })}
    />
  );
}
