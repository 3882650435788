import React from "react";
import type { SxProps } from "@mui/material";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  cardHeaderClasses,
} from "@mui/material";

export function Card({
  title,
  action,
  error = false,
  children,
  className,
  sx,
}: {
  title?: React.ReactNode;
  action?: React.ReactNode;
  error?: boolean;
  children: React.ReactNode;
  className?: string;
  sx?: SxProps;
}) {
  return (
    <MuiCard
      className={className}
      sx={{
        ...sx,
        ...(error && {
          border: "1px solid",
          borderColor: (theme) => theme.palette.error.main,
        }),
      }}
    >
      {(title !== undefined || action !== undefined) && (
        <CardHeader
          title={title}
          {...(typeof title === "string"
            ? { titleTypographyProps: { component: "h2" } }
            : { disableTypography: true })}
          action={action}
          sx={{
            [`& .${cardHeaderClasses.action}`]: {
              m: 0,
            },
          }}
        />
      )}
      <CardContent>{children}</CardContent>
    </MuiCard>
  );
}
