import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout, ManageCard } from "~/components/DetailsCards";
import { selectData } from "~/utils";
import { DsmHistoryCard } from "../../../DsmHistoryCard";
import { DsmResourceFields } from "../../../DsmResourceFields";
import {
  useDataStore,
  useDeleteDataStore,
  useUpdateDataStore,
} from "../../../api";
import { EditDsmResourceForm } from "../../../forms";
import * as dsmPaths from "../../../paths";
import type { DataStore } from "../../../sdk";
import { editDataStoreSchema } from "../schemas";
import { UserManagementSection } from "./UserManagementSection";

export function DataStoreDetails() {
  const { dataStoreId } = dsmPaths.useDsmPathParams(dsmPaths.DATASTORE_DETAILS);

  const query = useDataStore(dataStoreId, { select: selectData });
  const deleteMutation = useDeleteDataStore(dataStoreId);

  const generalSection = <GeneralSection query={query} />;
  const userManagementSection = <UserManagementSection />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <DsmHistoryCard query={query} />;
  const manageSection = (
    <ManageCard
      resourceName="DataStore"
      query={query}
      editLocation={dsmPaths.createDsmPath(dsmPaths.DATASTORE_EDIT, {
        dataStoreId,
      })}
      deleteMutation={deleteMutation}
      getReadableName={(dataStore) => dataStore.name}
      listLocation={dsmPaths.createDsmPath(dsmPaths.DATASTORE_TABLE)}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {userManagementSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {userManagementSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<DataStore> }) {
  return (
    <Card title="General">
      <DsmResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          {
            dataType: "foreign-key",
            resourceType: "user",
            accessor: "ownerId",
          },
          { dataType: "text", accessor: "version" },
          { dataType: "text", accessor: "region" },
          { dataType: "text", accessor: "endpoint" },
          { dataType: "json", accessor: "config" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<DataStore> }) {
  const { dataStoreId } = dsmPaths.useDsmPathParams(dsmPaths.DATASTORE_DETAILS);

  return (
    <EditDsmResourceForm
      resourceName="DataStore"
      query={query}
      schema={editDataStoreSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "note", type: "text", multiline: true },
        { name: "disabled", type: "boolean" },
      ]}
      mutation={useUpdateDataStore(dataStoreId)}
    />
  );
}
