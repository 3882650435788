import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { WorkflowHookCreateForm } from "./components/WorkflowHookCreateForm";

export function NewWorkflowHookPage() {
  return (
    <LqsPage title="New Hook">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <WorkflowHookCreateForm />
      </Container>
    </LqsPage>
  );
}
