import React from "react";
import type { AutocompleteProps } from "@mui/material";
import {
  Autocomplete,
  Box,
  Chip,
  createFilterOptions,
  TextField,
  Typography,
} from "@mui/material";
import { BreakableText } from "~/components/BreakableText";
import type { Topic } from "~/lqs";

const filterOptions = createFilterOptions<Topic>({
  stringify: (topic) => `${topic.name} ${topic.typeName ?? ""}`,
});

interface BaseTopicSelectProps {
  id?: string;
  inputLabel: string;
  topics: ReadonlyArray<Topic>;
  disabled?: boolean;
  sx?: AutocompleteProps<any, any, any, any>["sx"];
}

interface SingleTopicSelectProps extends BaseTopicSelectProps {
  multiple?: false;
  value: Topic | null;
  onChange: (selection: Topic | null) => void;
}

interface MultiTopicSelectProps extends BaseTopicSelectProps {
  multiple: true;
  value: Array<Topic>;
  onChange: (selection: Array<Topic>) => void;
}

export type TopicSelectProps = SingleTopicSelectProps | MultiTopicSelectProps;

export function TopicSelect({
  id,
  inputLabel,
  topics,
  disabled,
  multiple,
  value,
  onChange,
  sx,
}: TopicSelectProps) {
  return (
    <Autocomplete
      sx={sx}
      fullWidth
      autoComplete
      autoHighlight
      disabled={disabled}
      id={id}
      options={topics}
      multiple={multiple}
      limitTags={1}
      value={value}
      onChange={(_, newSelection) => onChange(newSelection as any)}
      filterOptions={filterOptions}
      renderInput={(params) => <TextField {...params} label={inputLabel} />}
      renderTags={(value, getTagProps) =>
        value.map((topic, index) => (
          <Chip
            {...getTagProps({ index })}
            label={topic.name}
            title={topic.name}
          />
        ))
      }
      getOptionLabel={({ name }) => name}
      renderOption={(props, topic) => (
        <li {...props}>
          <Box maxWidth={1}>
            <Typography title={topic.name}>
              <BreakableText separator={/(\/)/} insertBreak="after">
                {topic.name}
              </BreakableText>
            </Typography>
            <Typography noWrap variant="body2" color="text.secondary">
              {topic.typeName}
            </Typography>
          </Box>
        </li>
      )}
    />
  );
}
