import { Alert, AlertTitle } from "@mui/material";
import type { UseMutationResult } from "@tanstack/react-query";
import { checkIsAuthError } from "~/lib/auth";

export function AuthErrorAlert({
  mutation,
}: {
  mutation: UseMutationResult<any, unknown, any>;
}) {
  if (!mutation.isError) {
    return null;
  }

  const { error } = mutation;

  let content;
  if (checkIsAuthError(error)) {
    content = (
      <>
        <AlertTitle>An error occurred</AlertTitle>
        {error.message}
      </>
    );
  } else {
    content = "An unexpected error occurred";
  }

  return (
    <Alert variant="outlined" severity="error">
      {content}
    </Alert>
  );
}
