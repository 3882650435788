import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { listApiKeysSchema } from "../schemas";
import { useApiKeySearchRequest } from "./ApiKeyTable";

export function ApiKeyFilters() {
  const [request, setRequest] = useApiKeySearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="apiKey"
      request={request}
      setRequest={setRequest}
      schema={listApiKeysSchema}
      descriptors={[
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "userId", type: "foreign-key", resourceType: "user" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
