import React from "react";
import { Link, styled, Typography } from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import { SignUpForm } from "~/domain/auth";
import { makeStudioHomepageLocation, toSignIn } from "~/paths";

const Root = styled(Card)(({ theme }) => ({
  width: 500,
  maxWidth: `calc(100% - ${theme.spacing(2)})`,
}));

export function SignUpPage() {
  const navigate = useNavigate();

  return (
    <Center>
      <Root title="Sign Up">
        <SignUpForm
          onSignUpComplete={() => {
            navigate(makeStudioHomepageLocation());
          }}
          message={
            <Typography>
              To use LogQS Studio, you'll first need to sign up for an account.
            </Typography>
          }
          footer={
            <Typography>
              Already have an account?{" "}
              <Link component={RouterLink} to={toSignIn()} underline="always">
                Sign in
              </Link>
            </Typography>
          }
        />
      </Root>
    </Center>
  );
}
