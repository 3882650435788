import { styled } from "@mui/material";
import { CR_ICON, CR_LOGO } from "~/links";

const Root = styled("img")({
  display: "block",
  height: 40,
  width: "auto",
});

export function CrLogo({
  icon = false,
  alt = "Carnegie Robotics logo",
  className,
}: {
  icon?: boolean;
  alt?: string;
  className?: string;
}) {
  return (
    <Root className={className} src={icon ? CR_ICON : CR_LOGO} alt={alt} />
  );
}
