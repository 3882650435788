import { useTheme } from "@mui/material";
import type { Label } from "~/lqs";
import type { Maybe } from "~/types";

interface LabelColors {
  text: string;
  background: string;
  backgroundInteraction: string;
}

export function useLabelColors(
  label: Maybe<Label>,
  color: Maybe<string>,
): LabelColors | null {
  const theme = useTheme();

  if (label == null || color == null) {
    return null;
  } else {
    const augmentedColor = theme.palette.augmentColor({
      color: {
        main: color,
      },
      name: label.value,
    });

    return {
      text: augmentedColor.contrastText,
      background: augmentedColor.main,
      backgroundInteraction: augmentedColor.dark,
    };
  }
}
