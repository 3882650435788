import {
  EditLqsResourceForm,
  useDigestionTopic,
  useUpdateDigestionTopic,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editDigestionTopicSchema } from "../schemas";

export function DigestionTopicEditForm() {
  const { digestionId, topicId } = useTypedParams(
    lqsRoutePaths.DIGESTION_TOPIC_EDIT,
  );

  const lqsNavigator = useLqsNavigator({ toDigestionTopicDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="digestion topic"
      registryKey="digestionTopic"
      query={useDigestionTopic(digestionId, topicId, { select: selectData })}
      schema={editDigestionTopicSchema}
      descriptors={[
        { name: "startTime", type: "timestamp" },
        { name: "endTime", type: "timestamp" },
        { name: "frequency", type: "number" },
        { name: "queryDataFilter", type: "json" },
        { name: "contextFilter", type: "json" },
      ]}
      mutation={useUpdateDigestionTopic(digestionId, topicId)}
      detailsLocation={lqsNavigator.toDigestionTopicDetails({
        digestionId,
        topicId,
      })}
    />
  );
}
