import { EditLqsResourceForm, useLogQuery, useUpdateLogQuery } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editLogQuerySchema } from "../schemas";

export function LogQueryEditForm() {
  const { logId, queryId } = useTypedParams(lqsRoutePaths.LOG_QUERY_EDIT);

  const lqsNavigator = useLqsNavigator({ toLogQueryDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="query"
      registryKey="query"
      query={useLogQuery(logId, queryId, { select: selectData })}
      schema={editLogQuerySchema}
      descriptors={[
        { name: "name", type: "text" },
        { name: "statement", type: "text", multiline: true, monospace: true },
        { name: "parameters", type: "json" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateLogQuery(logId, queryId)}
      detailsLocation={lqsNavigator.toLogQueryDetails({ logId, queryId })}
    />
  );
}
