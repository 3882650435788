import { TablePage } from "~/components/Table";
import { ObjectStoreFilters } from "./components/ObjectStoreFilters";
import {
  ObjectStoreSearchRequestProvider,
  ObjectStoreTable,
} from "./components/ObjectStoreTable";

export function ObjectStoresPage() {
  return (
    <TablePage
      title="Search Object Stores"
      RequestProvider={ObjectStoreSearchRequestProvider}
      table={<ObjectStoreTable />}
      filters={<ObjectStoreFilters />}
    />
  );
}
