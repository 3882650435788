import React, { useState } from "react";
import {
  CloudDownload,
  Dashboard,
  Person,
  PlayCircleFilled,
} from "@mui/icons-material";
import {
  Button,
  ButtonBase,
  Link,
  Modal,
  Stack,
  styled,
  svgIconClasses,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Center } from "~/components/Center";
import {
  DocsLink,
  GlobalNavigation,
  NavigationLink,
  SignOutButton,
  useShouldUseMiniDrawerStyles,
} from "~/components/GlobalNavigation";
import { CrLogo } from "~/components/cr-logo";
import { LqsLogo } from "~/components/lqs-logo";
import { AuthenticationGuard } from "~/domain/auth";
import { SupportTicketButton } from "~/dsm/support-ticket";
import { CR_ORIGIN, REQUEST_FULL_ACCESS_LINK } from "~/links";
import * as paths from "~/paths";
import { useLqsNavigator } from "~/paths";
import { useAuthDialogControls } from "./auth-dialog";
import { INTRO_VIDEO_PATH, INTRO_VIDEO_THUMBNAIL_PATH } from "./config";

export function PublicLqsNavigation() {
  const shouldUseMiniDrawerStyles = useShouldUseMiniDrawerStyles();

  const lqsNavigator = useLqsNavigator({
    DIGESTION_TABLE: true,
    toDigestionTable: true,
    PROFILE: true,
    toProfile: true,
  });

  const authDialogControls = useAuthDialogControls();

  return (
    <GlobalNavigation
      homepageLink={
        <RouterLink
          className="homepage-link"
          to={paths.makeStudioHomepageLocation()}
        >
          <LqsLogo alt="Homepage" icon={shouldUseMiniDrawerStyles} />
        </RouterLink>
      }
      footerActions={
        <>
          <AuthenticationGuard
            unauthenticatedFallback={
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={authDialogControls.open}
              >
                Sign In / Sign Up
              </Button>
            }
          >
            <SupportTicketButton />
            <SignOutButton />
          </AuthenticationGuard>
          <Link
            href={CR_ORIGIN}
            target="_blank"
            rel="noopener"
            color="text.primary"
            underline="none"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CrLogo icon={shouldUseMiniDrawerStyles} />
            <Typography variant="caption">
              A Carnegie Robotics Product
            </Typography>
          </Link>
        </>
      }
    >
      <NavigationLink
        to={lqsNavigator.toDashboard()}
        Icon={Dashboard}
        text="Dashboard"
        path={lqsNavigator.DATASTORE_DASHBOARD}
        end
      />
      <NavigationLink
        to={lqsNavigator.toDigestionTable()}
        Icon={CloudDownload}
        text="Extractions"
        path={lqsNavigator.DIGESTION_TABLE}
      />
      <AuthenticationGuard>
        <NavigationLink
          to={lqsNavigator.toProfile()}
          Icon={Person}
          text="Profile"
          path={lqsNavigator.PROFILE}
        />
      </AuthenticationGuard>
      {!shouldUseMiniDrawerStyles && (
        <Stack spacing={4} sx={{ mt: 2 }}>
          <Button
            href={REQUEST_FULL_ACCESS_LINK}
            target="_blank"
            rel="noopener"
            color="primary"
            variant="contained"
            fullWidth
          >
            Request Full Access
          </Button>
          <DocsLink />
          <IntroVideo />
        </Stack>
      )}
    </GlobalNavigation>
  );
}

const IntroButton = styled(ButtonBase)(({ theme }) => ({
  flexDirection: "column",
  backgroundColor:
    theme.palette.mode === "dark"
      ? theme.palette.grey["600"]
      : theme.palette.grey["200"],
  padding: theme.spacing(0.75),
  borderRadius: theme.shape.borderRadius * 2,
  "& .img-container": {
    position: "relative",
    "& img": {
      display: "block",
      width: "100%",
      height: "auto",
    },
    [`& .${svgIconClasses.root}`]: {
      position: "absolute",
      left: "50%",
      top: "50%",
      translate: "-50% -50%",
    },
  },
}));

const VideoContainer = styled(Center)({
  pointerEvents: "none",
  "& video": {
    pointerEvents: "auto",
    objectFit: "contain",
    maxWidth: "90%",
    maxHeight: "90%",
  },
});

function IntroVideo() {
  const [showVideo, setShowVideo] = useState(false);

  function handleShowVideo(): void {
    setShowVideo(true);
  }

  function handleCloseModal(): void {
    setShowVideo(false);
  }

  return (
    <>
      <IntroButton onClick={handleShowVideo}>
        <div className="img-container">
          <img src={INTRO_VIDEO_THUMBNAIL_PATH} aria-hidden />
          <PlayCircleFilled fontSize="large" />
        </div>
        <Typography variant="body1" component="span">
          Intro to LogQS
        </Typography>
      </IntroButton>
      <Modal open={showVideo} onClose={handleCloseModal}>
        <VideoContainer>
          <video
            src={INTRO_VIDEO_PATH}
            poster={INTRO_VIDEO_THUMBNAIL_PATH}
            controls
          />
        </VideoContainer>
      </Modal>
    </>
  );
}
