import React from "react";
import { invariant } from "~/lib/invariant";
import type { NotUndefined } from "~/types";

// Taken from https://react-typescript-cheatsheet.netlify.app/docs/basic/getting-started/context/#extended-example
export function createSafeContext<TContextValue extends NotUndefined>(
  contextName: string,
) {
  // `undefined` should *never* be passed as a legitimate value by a provider
  // as it's used as a sentinel by the `useSafeContext` hook to detect if
  // the hook is being called without a parent context provider. This default
  // value is the *only* place `undefined` can actually be passed.
  const context = React.createContext<TContextValue>(undefined as any);

  function useSafeContext(): TContextValue;
  function useSafeContext({
    strict,
  }: {
    strict: false;
  }): TContextValue | undefined;
  function useSafeContext(
    { strict }: { strict: boolean } = { strict: true },
  ): TContextValue | undefined {
    const contextVal = React.useContext(context);

    invariant(
      !strict || contextVal !== undefined,
      `${contextName} context used without parent provider`,
    );

    return contextVal as any;
  }

  return [useSafeContext, context] as const;
}
