import { useEffect } from "react";
import { usePlaybackTimer } from "./PlaybackTimerProvider";

export function usePlaybackTimerPause(shouldPause: boolean) {
  const playbackTimer = usePlaybackTimer();

  useEffect(
    function pauseTimer() {
      if (!shouldPause) {
        return;
      }

      playbackTimer.pause();

      return () => {
        playbackTimer.unpause();
      };
    },
    [shouldPause, playbackTimer],
  );
}
