import type { Option } from "~/components/Form";
import { filterEnum, optionalEnum, requiredEnum } from "~/domain/common";
import { TypeEncoding } from "../sdk";

export const typeEncodingOptions = Object.values(TypeEncoding).map(
  (value): Option => ({
    label: value,
    value,
  }),
);

export const requiredTypeEncoding = requiredEnum(TypeEncoding);

export const optionalTypeEncoding = optionalEnum(TypeEncoding);

export const filterTypeEncoding = filterEnum(TypeEncoding);
