/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { IngestionPart } from "./IngestionPart";
import {
  IngestionPartFromJSON,
  IngestionPartFromJSONTyped,
  IngestionPartToJSON,
} from "./IngestionPart";

export interface IngestionPartDataResponse {
  data: IngestionPart;
}

export function IngestionPartDataResponseFromJSON(
  json: any,
): IngestionPartDataResponse {
  return IngestionPartDataResponseFromJSONTyped(json);
}

export function IngestionPartDataResponseFromJSONTyped(
  json: any,
): IngestionPartDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: IngestionPartFromJSON(json["data"]),
  };
}

export function IngestionPartDataResponseToJSON(
  value: IngestionPartDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: IngestionPartToJSON(value.data),
  };
}
