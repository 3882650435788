import {
  NewLqsResourceForm,
  ProcessState,
  processStateOptions,
  useCreateDigestion,
} from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createDigestionSchema } from "../schemas";

export function DigestionCreateForm() {
  const lqsNavigator = useLqsNavigator({ toDigestionDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="digestion"
      registryKey="digestion"
      schema={createDigestionSchema}
      descriptors={[
        { name: "name", type: "text" },
        {
          name: "logId",
          type: "foreign-key",
          resourceType: "log",
          required: true,
        },
        {
          name: "state",
          type: "select",
          options: processStateOptions,
          defaultValue: ProcessState.Ready,
          required: true,
        },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowContext", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateDigestion()}
      createDetailsLocation={(response) =>
        lqsNavigator.toDigestionDetails({ digestionId: response.data.id })
      }
    />
  );
}
