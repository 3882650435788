import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { TopicEditForm } from "./components/TopicEditForm";

export function EditTopicPage() {
  return (
    <LqsPage title="Edit Topic">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <TopicEditForm />
      </Container>
    </LqsPage>
  );
}
