export async function convertBase64ImageToBlob(
  base64EncodedImage: string,
): Promise<Blob | null> {
  const dataUrl = `data:image/webp;base64,${base64EncodedImage}`;

  const response = await fetch(dataUrl);

  if (!response.ok) {
    return null;
  }

  return response.blob();
}
