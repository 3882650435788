import React, { useMemo } from "react";
import type { PaletteMode as ColorScheme } from "@mui/material";
import * as z from "zod";
import { createSafeContext } from "~/contexts";
import { useLocalStorage } from "~/hooks";
import { StorageItem, useStorageValue } from "~/utils/storage";

export type { ColorScheme };

const COLOR_SCHEME_STORAGE_KEY = "settings.color-scheme";

export type BytesFormat = "plain" | "compact";

const BYTES_FORMAT_STORAGE_KEY = "settings.bytes-format";

const DEFAULT_BYTES_FORMAT: BytesFormat = "compact";

export type DateDisplay = "local" | "utc";

const dateDisplayStorageItem = new StorageItem<DateDisplay>({
  storage: window.localStorage,
  version: 0,
  key: "settings.date-display",
  parser: z.enum(["local", "utc"]),
  defaultValue: "local",
});

interface SettingsContextValue {
  colorScheme: ColorScheme;
  setColorScheme: (colorScheme: ColorScheme) => void;
  bytesFormat: BytesFormat;
  setBytesFormat: (bytesFormat: BytesFormat) => void;
  dateDisplay: DateDisplay;
  setDateDisplay: (dateDisplay: DateDisplay) => void;
}

export const [useSettings, SettingsContext] =
  createSafeContext<SettingsContextValue>("Settings");

export function SettingsProvider({
  defaultColorScheme,
  children,
}: {
  defaultColorScheme: ColorScheme;
  children: React.ReactNode;
}) {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>(
    COLOR_SCHEME_STORAGE_KEY,
    defaultColorScheme,
  );

  const [bytesFormat, setBytesFormat] = useLocalStorage<BytesFormat>(
    BYTES_FORMAT_STORAGE_KEY,
    DEFAULT_BYTES_FORMAT,
  );

  const dateDisplay = useStorageValue(dateDisplayStorageItem);

  const value: SettingsContextValue = useMemo(
    () => ({
      colorScheme,
      setColorScheme,
      bytesFormat,
      setBytesFormat,
      dateDisplay,
      setDateDisplay: dateDisplayStorageItem.set,
    }),
    [colorScheme, setColorScheme, bytesFormat, setBytesFormat, dateDisplay],
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}
