import {
  NewLqsResourceForm,
  ProcessType,
  processTypeOptions,
  useCreateWorkflow,
} from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createWorkflowSchema } from "../schemas";

export function WorkflowCreateForm() {
  const lqsNavigator = useLqsNavigator({ toWorkflowDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="workflow"
      registryKey="workflow"
      schema={createWorkflowSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        {
          name: "processType",
          type: "select",
          options: processTypeOptions,
          defaultValue: ProcessType.Digestion,
          required: true,
        },
        { name: "_default", type: "boolean" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "contextSchema", type: "json" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateWorkflow()}
      createDetailsLocation={(response) =>
        lqsNavigator.toWorkflowDetails({ workflowId: response.data.id })
      }
    />
  );
}
