import { TextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { useController } from "react-hook-form";
import { getFieldLabel } from "~/domain/common";
import { DeprecableLabel } from "~/domain/versioning";
import type { BaseFieldProps } from "./types";

export function TimestampField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, bigint | null>,
>({
  control,
  name,
  label = getFieldLabel(name),
  deprecated,
  required,
}: BaseFieldProps<TFieldValues, TName>) {
  const {
    field: { value, onChange, ...field },
    fieldState,
  } = useController({ control, name });

  return (
    <TextField
      fullWidth
      required={required}
      label={<DeprecableLabel deprecated={deprecated}>{label}</DeprecableLabel>}
      InputProps={{
        inputMode: "numeric",
      }}
      error={fieldState.error !== undefined}
      helperText={fieldState.error?.message ?? " "}
      {...field}
      value={value === null ? "" : String(value)}
      onChange={(e) => {
        const rawValue = e.target.value.trim();

        if (rawValue === "") {
          onChange(null);
        } else {
          try {
            onChange(BigInt(rawValue));
          } catch {
            onChange(rawValue);
          }
        }
      }}
    />
  );
}
