import type React from "react";
import { createSafeContext } from "~/contexts";

const [useNavigationElementContext, NavigationElementContext] =
  createSafeContext<React.JSX.Element>("NavigationElement");

export { useNavigationElementContext };

export function NavigationElementProvider({
  element,
  children,
}: {
  element: React.JSX.Element;
  children: React.ReactNode;
}) {
  return (
    <NavigationElementContext.Provider value={element}>
      {children}
    </NavigationElementContext.Provider>
  );
}
