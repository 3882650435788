import { useLayoutEffect } from "react";
import type { Topic } from "~/lqs";
import { usePlayerActions } from "../actions";
import type { InitializedPanelNode } from "../panels";

export function useSkipToFirstTimestamp(
  panel: InitializedPanelNode,
  topic: Topic,
  isSuccessful: boolean,
): void {
  const { autoSkipToFirstTimestamp } = usePlayerActions();

  const { startTime: firstTimestamp } = topic;

  useLayoutEffect(() => {
    if (!isSuccessful) {
      return;
    }

    if (firstTimestamp === null) {
      return;
    }

    autoSkipToFirstTimestamp(panel, firstTimestamp);
  }, [isSuccessful, firstTimestamp, autoSkipToFirstTimestamp, panel]);
}
