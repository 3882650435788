import { styled } from "@mui/material";

export const Pre = styled("pre")(({ theme }) => ({
  border: "1px solid",
  borderColor: theme.palette.divider,
  borderRadius: 4,
  margin: 0,
  padding: theme.spacing(0.25),
  overflow: "auto",
  maxHeight: 350,
}));
