import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { DigestionCreateForm } from "./components/DigestionCreateForm";

export function NewDigestionPage() {
  return (
    <LqsPage title="New Digestion">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <DigestionCreateForm />
      </Container>
    </LqsPage>
  );
}
