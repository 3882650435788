import { useMutation, useQuery } from "@tanstack/react-query";
import { EditResourceForm } from "~/components/Form";
import { selectData } from "~/utils";
import { useCommonResourcesContextValue } from "../../../context";
import { renderCommonForeignKeyFormField } from "../../../forms";
import { useUserParams } from "../hooks";
import { editUserSchema } from "../schemas";

export function UserEditForm() {
  const { userId } = useUserParams();

  const {
    apiVersion,
    createUserDetailsLocation,
    createUserFetchQueryOptions,
    createUserUpdateMutationOptions,
  } = useCommonResourcesContextValue();

  return (
    <EditResourceForm
      resourceName="user"
      query={useQuery({
        ...createUserFetchQueryOptions(userId),
        select: selectData,
      })}
      schema={editUserSchema}
      descriptors={[
        { name: "username", type: "text", required: true },
        { name: "roleId", type: "foreign-key", resourceType: "role" },
        { name: "admin", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "externalId", type: "text" },
      ]}
      apiVersion={apiVersion}
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderCommonForeignKeyFormField}
      mutation={useMutation(createUserUpdateMutationOptions(userId))}
      detailsLocation={createUserDetailsLocation({ userId })}
    />
  );
}
