import { NewLqsResourceForm, useCreateRecord } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { createRecordSchema } from "../schemas";

export function RecordCreateForm() {
  const { topicId } = useTypedParams(lqsRoutePaths.RECORD_CREATE);

  const lqsNavigator = useLqsNavigator({ toRecordDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="record"
      registryKey="record"
      schema={createRecordSchema}
      descriptors={[
        { name: "timestamp", type: "timestamp", required: true },
        { name: "dataOffset", type: "number" },
        { name: "dataLength", type: "number" },
        { name: "chunkCompression", type: "text" },
        { name: "chunkOffset", type: "number" },
        { name: "chunkLength", type: "number" },
        { name: "queryData", type: "json" },
        { name: "source", type: "text" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateRecord(topicId)}
      createDetailsLocation={(response) =>
        lqsNavigator.toRecordDetails({
          topicId,
          timestamp: response.data.timestamp,
        })
      }
    />
  );
}
