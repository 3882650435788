import { useCallback } from "react";
import * as z from "zod";
import { filterBigInt, filterText } from "~/domain/common";
import { useTypedSearchParams } from "~/hooks/useTypedSearchParams";

const playerParamsSchema = z.object({
  logId: filterText,
  initialTime: filterBigInt.catch(null),
});

const aliases = {
  initialTime: "t",
} as const;

interface PlayerParams {
  logId: string | null;
  initialTime: bigint | null;
  setLogId: (logId: string) => void;
}

export function usePlayerParams(): PlayerParams {
  const [params, setParams] = useTypedSearchParams(playerParamsSchema, aliases);

  const setLogId = useCallback(
    (logId: string) => {
      // The initial timestamp param is specific to a given log ID, so changing
      // the log ID requires the initial timestamp param to be stripped from
      // the URL if present
      setParams({ logId, initialTime: null });
    },
    [setParams],
  );

  return {
    ...params,
    setLogId,
  };
}
