import React from "react";
import { Card, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Center } from "~/components/Center";
import { makeStudioHomepageLocation } from "~/paths";

export function NotFoundPage() {
  return (
    <Center>
      <Card sx={{ width: 500 }}>
        <CardHeader
          title="Page Not Found"
          titleTypographyProps={{ component: "h1" }}
        />
        <CardContent>
          <Typography paragraph>
            A page doesn't exist at this URL. You may have mistyped the URL or
            the page no longer exists.
          </Typography>
          <Link component={RouterLink} to={makeStudioHomepageLocation()}>
            Return to Home
          </Link>
        </CardContent>
      </Card>
    </Center>
  );
}
