/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ObjectPart } from "./ObjectPart";
import {
  ObjectPartFromJSON,
  ObjectPartFromJSONTyped,
  ObjectPartToJSON,
} from "./ObjectPart";

export interface ObjectPartListResponse {
  data: Array<ObjectPart>;
  partNumberMarker: number | null;
  nextPartNumberMarker: number | null;
  maxParts: number | null;
  isTruncated: boolean | null;
}

export function ObjectPartListResponseFromJSON(
  json: any,
): ObjectPartListResponse {
  return ObjectPartListResponseFromJSONTyped(json);
}

export function ObjectPartListResponseFromJSONTyped(
  json: any,
): ObjectPartListResponse {
  if (json == null) {
    return json;
  }

  return {
    data: (json["data"] as Array<any>).map(ObjectPartFromJSON),
    partNumberMarker: json["part_number_marker"],
    nextPartNumberMarker: json["next_part_number_marker"],
    maxParts: json["max_parts"],
    isTruncated: json["is_truncated"],
  };
}

export function ObjectPartListResponseToJSON(
  value: ObjectPartListResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: (value.data as Array<any>).map(ObjectPartToJSON),
    part_number_marker: value.partNumberMarker,
    next_part_number_marker: value.nextPartNumberMarker,
    max_parts: value.maxParts,
    is_truncated: value.isTruncated,
  };
}
