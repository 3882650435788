import { useQuery } from "@tanstack/react-query";
import { invariant } from "~/lib/invariant";
import { CommonQueryClientContext } from "~/providers/CommonQueryClientProvider";
import type { Maybe } from "~/types";
import { hashInsecurely } from "~/utils/hash-insecurely";

export function useInsecureHash<TData = Uint8Array>(
  input: Maybe<string>,
  { select }: { select?: (result: Uint8Array) => TData } = {},
) {
  const enabled = input != null;

  return useQuery({
    queryKey: ["insecure-hash", input],
    queryFn() {
      invariant(enabled, "No input to hash");

      return hashInsecurely(input);
    },
    enabled,
    select,
    context: CommonQueryClientContext,
  });
}
