import React from "react";
import { Divider } from "@mui/material";
import type { Topic } from "~/lqs";
import { TagsControl } from "../../../components";
import {
  useInferenceFrameControls,
  useOnDemandInferenceControls,
} from "../../../inference";
import type { InitializedPanelNode } from "../../../panels";
import { ImageTransformControls } from "./image-transform-controls";

export function ImageControls({
  panel,
  topic,
}: {
  panel: InitializedPanelNode;
  topic: Topic;
}) {
  const inferenceFrameControls = useInferenceFrameControls({ strict: false });

  const onDemandInferenceControls = useOnDemandInferenceControls({
    strict: false,
  });

  return (
    <>
      <TagsControl topic={topic} />
      <Divider />
      <ImageTransformControls
        panel={panel}
        flipDirection={panel.imageFlipDirection}
      />
      {inferenceFrameControls != null && (
        <>
          <Divider />
          {inferenceFrameControls}
        </>
      )}
      {onDemandInferenceControls != null && (
        <>
          <Divider />
          {onDemandInferenceControls}
        </>
      )}
    </>
  );
}
