import * as z from "zod";
import {
  ClipInsetSide,
  FlipDirection,
  MAX_CHART_FIELDS,
  VisualizationType,
} from "../constants";

export const topicNameSchema = z.string();

export const topicTypeNameSchema = z.string().nullable();

export const chartFieldsSchema = z.array(z.string()).max(MAX_CHART_FIELDS);

export const visualizationTypeSchema = z.nativeEnum(VisualizationType);

export const imageRotationSchema = z.number();

export const imageFlipDirectionSchema = z.nativeEnum(FlipDirection);

export const clipInsetSchema = z.object({
  side: z.nativeEnum(ClipInsetSide),
  percent: z.number().min(0).max(1),
});

export type ClipInset = z.infer<typeof clipInsetSchema>;
