import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { lqsRoutePaths, useTypedParams } from "~/paths";
import { listTagsSchema } from "../schemas";
import { useTagSearchRequest } from "./TagTable";

export function TagFilters() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_TAG_TABLE);

  const [request, setRequest] = useTagSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="tag"
      request={request}
      setRequest={setRequest}
      schema={listTagsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "labelId", type: "foreign-key", resourceType: "label" },
        {
          name: "topicId",
          type: "foreign-key",
          resourceType: "topic",
          additionalProps: { logId },
        },
        { name: "startTimeGte", type: "timestamp" },
        { name: "startTimeLte", type: "timestamp" },
        { name: "startTimeNull", type: "boolean" },
        { name: "endTimeGte", type: "timestamp" },
        { name: "endTimeLte", type: "timestamp" },
        { name: "endTimeNull", type: "boolean" },
        { name: "note", type: "fuzzy", fuzzyName: "noteLike" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
