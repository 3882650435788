import { Link, Skeleton, Stack, Typography } from "@mui/material";
import type { Path } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import type { Maybe } from "~/types";

export function ResourceInfo({
  name,
  identifier,
  to,
}: {
  name: Maybe<string>;
  identifier: string;
  to?: Partial<Path>;
}) {
  const identifierText = <Typography variant="body2">{identifier}</Typography>;

  return (
    <Stack>
      {name === null ? "-" : name ?? <Skeleton sx={{ maxWidth: 350 }} />}
      {to === undefined ? (
        identifierText
      ) : (
        <Link component={RouterLink} to={to}>
          {identifierText}
        </Link>
      )}
    </Stack>
  );
}
