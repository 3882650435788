import React from "react";
import { Schedule as Clock, Watch } from "@mui/icons-material";
import { FormControlLabel, Switch } from "@mui/material";
import * as z from "zod";
import { usePlaybackSettings } from "~/player";
import { SettingControl, SettingsSidebar } from "~/settings";
import { StorageItem, useStorageValue } from "~/utils/storage";

export function PlayerSettingsSidebar() {
  const playbackSettings = usePlaybackSettings();

  const autoSkipSetting = useAutoSkipSetting();

  function handleAutoSkipSettingChange(
    e: React.ChangeEvent<HTMLInputElement>,
  ): void {
    autoSkipSettingStorageItem.set(e.target.checked);
  }

  return (
    <SettingsSidebar>
      <SettingControl
        title="Timestamp Format"
        value={playbackSettings.displayFormat}
        onChange={playbackSettings.setDisplayFormat}
        options={[
          { value: "elapsed", label: "Elapsed", icon: <Watch /> },
          { value: "original", label: "Original", icon: <Clock /> },
        ]}
      />
      <FormControlLabel
        sx={{ justifyContent: "space-between", ml: 0 }}
        control={
          <Switch
            checked={autoSkipSetting}
            onChange={handleAutoSkipSettingChange}
          />
        }
        label="Automatically skip to first record"
        labelPlacement="start"
      />
    </SettingsSidebar>
  );
}

const autoSkipSettingStorageItem = StorageItem.usingLocalStorage({
  version: 0,
  key: "settings.auto-skip",
  parser: z.boolean(),
  defaultValue: true,
});

export function useAutoSkipSetting(): boolean {
  return useStorageValue(autoSkipSettingStorageItem);
}
