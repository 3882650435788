import { Card, CardContent } from "@mui/material";
import { Loading } from "~/components/Loading";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import {
  NewLqsResourceForm,
  useCreateDigestionTopic,
  useDigestion,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { createDigestionTopicSchema } from "../schemas";

export function DigestionTopicCreateForm() {
  const { digestionId } = useTypedParams(lqsRoutePaths.DIGESTION_TOPIC_CREATE);

  const lqsNavigator = useLqsNavigator({ toDigestionTopicDetails: true });

  const digestionQuery = useDigestion(digestionId, { select: selectData });

  const createDigestionTopic = useCreateDigestionTopic(digestionId);

  return (
    <QueryRenderer
      query={digestionQuery}
      loading={
        <Card>
          <CardContent>
            <Loading type="circular" />
          </CardContent>
        </Card>
      }
      error={
        <Card>
          <CardContent>
            <ErrorMessage>Error fetching digestion</ErrorMessage>
          </CardContent>
        </Card>
      }
      success={(digestion) => {
        return (
          <NewLqsResourceForm
            resourceName="digestion topic"
            registryKey="digestionTopic"
            schema={createDigestionTopicSchema}
            descriptors={[
              {
                name: "topicId",
                type: "foreign-key",
                resourceType: "topic",
                additionalProps: { logId: digestion.logId },
                required: true,
              },
              { name: "startTime", type: "timestamp" },
              { name: "endTime", type: "timestamp" },
              { name: "frequency", type: "number" },
              { name: "queryDataFilter", type: "json" },
              { name: "contextFilter", type: "json" },
            ]}
            mutation={createDigestionTopic}
            createDetailsLocation={(response) =>
              lqsNavigator.toDigestionTopicDetails({
                digestionId,
                topicId: response.data.id,
              })
            }
          />
        );
      }}
    />
  );
}
