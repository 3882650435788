import type React from "react";
import type { Control } from "react-hook-form";
import type { ForeignKeyFormFieldDescriptor } from "~/components/Form";
import { assertNever } from "~/utils/assertNever";
import { RoleSelect } from "./role-select";
import { UserSelect } from "./user-select";

export type CommonForeignKeyFormFieldType = "role" | "user";

export function renderCommonForeignKeyFormField(
  control: Control<any>,
  descriptor: ForeignKeyFormFieldDescriptor<any, CommonForeignKeyFormFieldType>,
  deprecated: boolean,
): React.JSX.Element {
  const { name, label, required } = descriptor;
  switch (descriptor.resourceType) {
    case "role": {
      return (
        <RoleSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "user": {
      return (
        <UserSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    default: {
      assertNever(descriptor);
    }
  }
}
