import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type {
  ConfirmSignUpOutput,
  ResetPasswordOutput,
  SignInOutput,
  SignUpOutput,
} from "~/lib/auth";
import {
  checkIsSignInDone,
  checkUserIsAuthenticated,
  confirmResetPassword,
  confirmSignUp,
  requestNewSignUpCode,
  resetPassword,
  signIn,
  signOut,
  signUp,
} from "~/lib/auth";
import { AuthQueryClientContext } from "./context";
import type { AuthenticationStatus } from "./types";

interface Credentials {
  email: string;
  password: string;
}

const AUTH_STATUS_QUERY_KEY = ["authentication-status"] as const;

/**
 * @internal
 */
export function useAuthenticationStatusQuery(): UseQueryResult<AuthenticationStatus> {
  return useQuery({
    queryKey: AUTH_STATUS_QUERY_KEY,
    async queryFn() {
      if (await checkUserIsAuthenticated()) {
        return "authenticated";
      } else {
        return "unauthenticated";
      }
    },
    context: AuthQueryClientContext,
  });
}

/**
 * @internal
 */
export function useSignUp(): UseMutationResult<
  SignUpOutput,
  unknown,
  Credentials
> {
  return useMutation({
    mutationFn: signUp,
    context: AuthQueryClientContext,
    cacheTime: 0,
  });
}

/**
 * @internal
 */
export function useConfirmSignUp(): UseMutationResult<
  ConfirmSignUpOutput | SignInOutput,
  unknown,
  Credentials & { code: string }
> {
  const queryClient = useQueryClient({ context: AuthQueryClientContext });

  return useMutation({
    mutationFn: confirmSignUp,
    context: AuthQueryClientContext,
    cacheTime: 0,
    onSuccess(response) {
      if (checkIsSignInDone(response)) {
        return queryClient.invalidateQueries({
          queryKey: AUTH_STATUS_QUERY_KEY,
          exact: true,
        });
      }
    },
  });
}

/**
 * @internal
 */
export function useRequestNewSignUpCode(): UseMutationResult<
  void,
  unknown,
  { email: string }
> {
  return useMutation({
    mutationFn: requestNewSignUpCode,
    context: AuthQueryClientContext,
    cacheTime: 0,
  });
}

/**
 * @internal
 */
export function useSignIn(): UseMutationResult<
  SignInOutput,
  unknown,
  Credentials
> {
  const queryClient = useQueryClient({ context: AuthQueryClientContext });

  return useMutation({
    mutationFn: signIn,
    context: AuthQueryClientContext,
    cacheTime: 0,
    onSuccess(response) {
      if (checkIsSignInDone(response)) {
        return queryClient.invalidateQueries({
          queryKey: AUTH_STATUS_QUERY_KEY,
          exact: true,
        });
      }
    },
  });
}

/**
 * @internal
 */
export function useResetPassword(): UseMutationResult<
  ResetPasswordOutput,
  unknown,
  { email: string }
> {
  return useMutation({
    mutationFn: resetPassword,
    context: AuthQueryClientContext,
    cacheTime: 0,
  });
}

/**
 * @internal
 */
export function useConfirmResetPassword(): UseMutationResult<
  void,
  unknown,
  { email: string; code: string; newPassword: string }
> {
  return useMutation({
    mutationFn: confirmResetPassword,
    context: AuthQueryClientContext,
    cacheTime: 0,
  });
}

export function useSignOut(): UseMutationResult<void, unknown, void> {
  return useMutation({
    mutationFn: signOut,
    context: AuthQueryClientContext,
    cacheTime: 0,
  });
}
