import type { Location, ParamParseKey, Params, Path } from "react-router-dom";
import { generatePath, useParams } from "react-router-dom";
import type {
  IsEmptyObject,
  OverrideProperties,
  RequireExactlyOne,
} from "type-fest";
import type { Log } from "~/lqs";
import { serializeSearchParams } from "~/utils";
import type { JoinPaths } from "./join-paths";
import { joinPaths } from "./join-paths";

type NonNullableFields<TType extends object> = {
  [Key in keyof TType]: NonNullable<TType[Key]>;
};

type ParamsShape<TPath extends string> = NonNullableFields<
  Params<ParamParseKey<TPath>>
>;

type CrudPathPatterns<
  TBase extends string,
  TResource extends string,
  TIdentifier extends string,
> = {
  TABLE: JoinPaths<[TBase, TResource]>;
  CREATE: JoinPaths<[TBase, TResource, "new"]>;
  DETAILS: JoinPaths<[TBase, TResource, `:${TIdentifier}`]>;
  EDIT: JoinPaths<[TBase, TResource, `:${TIdentifier}`, "edit"]>;
};

function createCrudPathPatterns<
  const TBase extends string,
  const TResource extends string,
  const TIdentifier extends string,
>(
  base: TBase,
  resource: TResource,
  identifier: TIdentifier,
): CrudPathPatterns<TBase, TResource, TIdentifier> {
  const tablePattern = joinPaths([base, resource]);
  const createPattern = joinPaths([tablePattern, "new"]);
  const detailsPattern = joinPaths([tablePattern, `:${identifier}`]);
  const editPattern = joinPaths([detailsPattern, "edit"]);

  return {
    TABLE: tablePattern,
    CREATE: createPattern,
    DETAILS: detailsPattern,
    EDIT: editPattern,
  } as any;
}

export const STUDIO_HOMEPAGE = "/" as const;

export interface StudioHomepageState {
  unknownDataStore?: string;
}

export function makeStudioHomepageLocation(
  state?: RequireExactlyOne<StudioHomepageState>,
): Partial<Location> {
  return {
    pathname: STUDIO_HOMEPAGE,
    state,
  };
}

export const SIGN_UP = joinPaths([STUDIO_HOMEPAGE, "sign-up"]);
export const toSignUp: DestinationGenerator<typeof SIGN_UP> = () =>
  generateDestination({ pattern: SIGN_UP });

export const SIGN_IN = joinPaths([STUDIO_HOMEPAGE, "sign-in"]);
export const toSignIn: DestinationGenerator<typeof SIGN_IN> = () =>
  generateDestination({ pattern: SIGN_IN });

export const FORGOT_PASSWORD = joinPaths([STUDIO_HOMEPAGE, "forgot-password"]);
export const toForgotPassword: DestinationGenerator<
  typeof FORGOT_PASSWORD
> = () => generateDestination({ pattern: FORGOT_PASSWORD });

const DATASTORE_DASHBOARD = "/" as const;

const PLAYER = joinPaths([DATASTORE_DASHBOARD, "player"]);

const UPLOAD = joinPaths([DATASTORE_DASHBOARD, "upload"]);

const TAGGING = joinPaths([DATASTORE_DASHBOARD, "tagging"]);

const PROFILE = joinPaths([DATASTORE_DASHBOARD, "profile"]);

const {
  TABLE: LOG_TABLE,
  CREATE: LOG_CREATE,
  DETAILS: LOG_DETAILS,
  EDIT: LOG_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "logs", "logId");

const {
  TABLE: LOG_TAG_TABLE,
  CREATE: LOG_TAG_CREATE,
  DETAILS: LOG_TAG_DETAILS,
  EDIT: LOG_TAG_EDIT,
} = createCrudPathPatterns(LOG_DETAILS, "tags", "tagId");

const { TABLE: LOG_OBJECT_TABLE, DETAILS: LOG_OBJECT_DETAILS } =
  createCrudPathPatterns(LOG_DETAILS, "objects", "key");
const LOG_OBJECT_UPLOAD = joinPaths([LOG_OBJECT_TABLE, "upload"]);

const {
  TABLE: LOG_QUERY_TABLE,
  CREATE: LOG_QUERY_CREATE,
  DETAILS: LOG_QUERY_DETAILS,
  EDIT: LOG_QUERY_EDIT,
} = createCrudPathPatterns(LOG_DETAILS, "queries", "queryId");

const {
  TABLE: INGESTION_TABLE,
  CREATE: INGESTION_CREATE,
  DETAILS: INGESTION_DETAILS,
  EDIT: INGESTION_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "ingestions", "ingestionId");

const {
  TABLE: INGESTION_PART_TABLE,
  CREATE: INGESTION_PART_CREATE,
  DETAILS: INGESTION_PART_DETAILS,
  EDIT: INGESTION_PART_EDIT,
} = createCrudPathPatterns(INGESTION_DETAILS, "parts", "ingestionPartId");

const {
  TABLE: TOPIC_TABLE,
  CREATE: TOPIC_CREATE,
  DETAILS: TOPIC_DETAILS,
  EDIT: TOPIC_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "topics", "topicId");

const {
  TABLE: RECORD_TABLE,
  CREATE: RECORD_CREATE,
  DETAILS: RECORD_DETAILS,
  EDIT: RECORD_EDIT,
} = createCrudPathPatterns(TOPIC_DETAILS, "records", "timestamp");

const {
  TABLE: DIGESTION_TABLE,
  CREATE: DIGESTION_CREATE,
  DETAILS: DIGESTION_DETAILS,
  EDIT: DIGESTION_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "digestions", "digestionId");

const {
  TABLE: DIGESTION_TOPIC_TABLE,
  CREATE: DIGESTION_TOPIC_CREATE,
  DETAILS: DIGESTION_TOPIC_DETAILS,
  EDIT: DIGESTION_TOPIC_EDIT,
} = createCrudPathPatterns(DIGESTION_DETAILS, "topics", "topicId");

const {
  TABLE: DIGESTION_PART_TABLE,
  CREATE: DIGESTION_PART_CREATE,
  DETAILS: DIGESTION_PART_DETAILS,
  EDIT: DIGESTION_PART_EDIT,
} = createCrudPathPatterns(DIGESTION_DETAILS, "parts", "digestionPartId");

const {
  TABLE: WORKFLOW_TABLE,
  CREATE: WORKFLOW_CREATE,
  DETAILS: WORKFLOW_DETAILS,
  EDIT: WORKFLOW_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "workflows", "workflowId");

const {
  TABLE: WORKFLOW_HOOK_TABLE,
  CREATE: WORKFLOW_HOOK_CREATE,
  DETAILS: WORKFLOW_HOOK_DETAILS,
  EDIT: WORKFLOW_HOOK_EDIT,
} = createCrudPathPatterns(WORKFLOW_DETAILS, "hooks", "hookId");

const {
  TABLE: LABEL_TABLE,
  CREATE: LABEL_CREATE,
  DETAILS: LABEL_DETAILS,
  EDIT: LABEL_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "labels", "labelId");

const {
  TABLE: OBJECT_STORE_TABLE,
  CREATE: OBJECT_STORE_CREATE,
  DETAILS: OBJECT_STORE_DETAILS,
  EDIT: OBJECT_STORE_EDIT,
} = createCrudPathPatterns(
  DATASTORE_DASHBOARD,
  "object-stores",
  "objectStoreId",
);

const {
  TABLE: OBJECT_STORE_OBJECT_TABLE,
  DETAILS: OBJECT_STORE_OBJECT_DETAILS,
} = createCrudPathPatterns(OBJECT_STORE_DETAILS, "objects", "objectKey");

const {
  TABLE: USER_TABLE,
  CREATE: USER_CREATE,
  DETAILS: USER_DETAILS,
  EDIT: USER_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "users", "userId");

const {
  TABLE: GROUP_TABLE,
  CREATE: GROUP_CREATE,
  DETAILS: GROUP_DETAILS,
  EDIT: GROUP_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "groups", "groupId");

const {
  TABLE: ROLE_TABLE,
  CREATE: ROLE_CREATE,
  DETAILS: ROLE_DETAILS,
  EDIT: ROLE_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "roles", "roleId");

const {
  TABLE: API_KEY_TABLE,
  CREATE: API_KEY_CREATE,
  DETAILS: API_KEY_DETAILS,
  EDIT: API_KEY_EDIT,
} = createCrudPathPatterns(DATASTORE_DASHBOARD, "api-keys", "apiKeyId");

export const lqsRoutePaths = {
  DATASTORE_DASHBOARD,
  PLAYER,
  UPLOAD,
  TAGGING,
  PROFILE,
  LOG_TABLE,
  LOG_CREATE,
  LOG_DETAILS,
  LOG_EDIT,
  LOG_TAG_TABLE,
  LOG_TAG_CREATE,
  LOG_TAG_DETAILS,
  LOG_TAG_EDIT,
  LOG_QUERY_TABLE,
  LOG_QUERY_CREATE,
  LOG_QUERY_DETAILS,
  LOG_QUERY_EDIT,
  LOG_OBJECT_TABLE,
  LOG_OBJECT_UPLOAD,
  LOG_OBJECT_DETAILS,
  INGESTION_TABLE,
  INGESTION_CREATE,
  INGESTION_DETAILS,
  INGESTION_EDIT,
  INGESTION_PART_TABLE,
  INGESTION_PART_CREATE,
  INGESTION_PART_DETAILS,
  INGESTION_PART_EDIT,
  TOPIC_TABLE,
  TOPIC_CREATE,
  TOPIC_DETAILS,
  TOPIC_EDIT,
  RECORD_TABLE,
  RECORD_CREATE,
  RECORD_DETAILS,
  RECORD_EDIT,
  DIGESTION_TABLE,
  DIGESTION_CREATE,
  DIGESTION_DETAILS,
  DIGESTION_EDIT,
  DIGESTION_PART_TABLE,
  DIGESTION_PART_CREATE,
  DIGESTION_PART_DETAILS,
  DIGESTION_PART_EDIT,
  DIGESTION_TOPIC_TABLE,
  DIGESTION_TOPIC_CREATE,
  DIGESTION_TOPIC_DETAILS,
  DIGESTION_TOPIC_EDIT,
  WORKFLOW_TABLE,
  WORKFLOW_CREATE,
  WORKFLOW_DETAILS,
  WORKFLOW_EDIT,
  WORKFLOW_HOOK_TABLE,
  WORKFLOW_HOOK_CREATE,
  WORKFLOW_HOOK_DETAILS,
  WORKFLOW_HOOK_EDIT,
  LABEL_TABLE,
  LABEL_CREATE,
  LABEL_DETAILS,
  LABEL_EDIT,
  OBJECT_STORE_TABLE,
  OBJECT_STORE_CREATE,
  OBJECT_STORE_DETAILS,
  OBJECT_STORE_EDIT,
  OBJECT_STORE_OBJECT_TABLE,
  OBJECT_STORE_OBJECT_DETAILS,
  USER_TABLE,
  USER_CREATE,
  USER_DETAILS,
  USER_EDIT,
  GROUP_TABLE,
  GROUP_CREATE,
  GROUP_DETAILS,
  GROUP_EDIT,
  ROLE_TABLE,
  ROLE_CREATE,
  ROLE_DETAILS,
  ROLE_EDIT,
  API_KEY_TABLE,
  API_KEY_CREATE,
  API_KEY_DETAILS,
  API_KEY_EDIT,
} as const;

type GetPathParams<
  TPathPattern extends string,
  TParamsOverrides extends Partial<
    Record<keyof ParamsShape<TPathPattern>, any>
  > = {},
> =
  IsEmptyObject<ParamsShape<TPathPattern>> extends true
    ? []
    : [
        pathParams: OverrideProperties<
          ParamsShape<TPathPattern>,
          Readonly<TParamsOverrides>
        >,
      ];

type AddSearchParams<
  TPathParams extends ReadonlyArray<unknown>,
  TSearch extends Record<string, any> = never,
> =
  // Disable distributive behavior: https://github.com/microsoft/TypeScript/issues/31751#issuecomment-498526919
  [TSearch] extends [never]
    ? TPathParams
    : [...TPathParams, searchParams?: TSearch];

type DestinationGenerator<
  TPathPattern extends string,
  TSearch extends Record<string, any> = never,
  TParamsOverrides extends Partial<
    Record<keyof ParamsShape<TPathPattern>, any>
  > = {},
> = (
  ...params: AddSearchParams<
    GetPathParams<TPathPattern, TParamsOverrides>,
    TSearch
  >
) => Partial<Path>;

export interface LqsNavigator {
  DATASTORE_DASHBOARD: string;
  toDashboard: DestinationGenerator<typeof DATASTORE_DASHBOARD>;
  PLAYER: string;
  toPlayer: DestinationGenerator<typeof PLAYER, { logId?: Log["id"] }>;
  UPLOAD?: string;
  toUpload?: DestinationGenerator<typeof UPLOAD>;
  TAGGING?: string;
  toTagging?: DestinationGenerator<typeof TAGGING>;
  PROFILE?: string;
  toProfile?: DestinationGenerator<typeof PROFILE>;
  LOG_TABLE?: string;
  toLogTable?: DestinationGenerator<typeof LOG_TABLE>;
  toLogCreate?: DestinationGenerator<typeof LOG_CREATE>;
  toLogDetails?: DestinationGenerator<typeof LOG_DETAILS>;
  toLogEdit?: DestinationGenerator<typeof LOG_EDIT>;
  toLogTagTable?: DestinationGenerator<typeof LOG_TAG_TABLE>;
  toLogTagCreate?: DestinationGenerator<typeof LOG_TAG_CREATE>;
  toLogTagDetails?: DestinationGenerator<typeof LOG_TAG_DETAILS>;
  toLogTagEdit?: DestinationGenerator<typeof LOG_TAG_EDIT>;
  toLogQueryTable?: DestinationGenerator<typeof LOG_QUERY_TABLE>;
  toLogQueryCreate?: DestinationGenerator<typeof LOG_QUERY_CREATE>;
  toLogQueryDetails?: DestinationGenerator<typeof LOG_QUERY_DETAILS>;
  toLogQueryEdit?: DestinationGenerator<typeof LOG_QUERY_EDIT>;
  toLogObjectTable?: DestinationGenerator<
    typeof LOG_OBJECT_TABLE,
    { directory?: string; processing?: boolean }
  >;
  toLogObjectUpload?: DestinationGenerator<typeof LOG_OBJECT_UPLOAD>;
  toLogObjectDetails?: DestinationGenerator<typeof LOG_OBJECT_DETAILS>;
  INGESTION_TABLE?: string;
  toIngestionTable?: DestinationGenerator<typeof INGESTION_TABLE>;
  toIngestionCreate?: DestinationGenerator<typeof INGESTION_CREATE>;
  toIngestionDetails?: DestinationGenerator<typeof INGESTION_DETAILS>;
  toIngestionEdit?: DestinationGenerator<typeof INGESTION_EDIT>;
  toIngestionPartTable?: DestinationGenerator<typeof INGESTION_PART_TABLE>;
  toIngestionPartCreate?: DestinationGenerator<typeof INGESTION_PART_CREATE>;
  toIngestionPartDetails?: DestinationGenerator<typeof INGESTION_PART_DETAILS>;
  toIngestionPartEdit?: DestinationGenerator<typeof INGESTION_PART_EDIT>;
  TOPIC_TABLE?: string;
  toTopicTable?: DestinationGenerator<typeof TOPIC_TABLE>;
  toTopicCreate?: DestinationGenerator<typeof TOPIC_CREATE>;
  toTopicDetails?: DestinationGenerator<typeof TOPIC_DETAILS>;
  toTopicEdit?: DestinationGenerator<typeof TOPIC_EDIT>;
  toRecordTable?: DestinationGenerator<typeof RECORD_TABLE>;
  toRecordCreate?: DestinationGenerator<typeof RECORD_CREATE>;
  toRecordDetails?: DestinationGenerator<
    typeof RECORD_DETAILS,
    never,
    { timestamp: bigint }
  >;
  toRecordEdit?: DestinationGenerator<
    typeof RECORD_EDIT,
    never,
    { timestamp: bigint }
  >;
  DIGESTION_TABLE?: string;
  toDigestionTable?: DestinationGenerator<typeof DIGESTION_TABLE>;
  toDigestionCreate?: DestinationGenerator<typeof DIGESTION_CREATE>;
  toDigestionDetails?: DestinationGenerator<
    typeof DIGESTION_DETAILS,
    { directory?: string }
  >;
  toDigestionEdit?: DestinationGenerator<typeof DIGESTION_EDIT>;
  toDigestionPartTable?: DestinationGenerator<typeof DIGESTION_PART_TABLE>;
  toDigestionPartCreate?: DestinationGenerator<typeof DIGESTION_PART_CREATE>;
  toDigestionPartDetails?: DestinationGenerator<typeof DIGESTION_PART_DETAILS>;
  toDigestionPartEdit?: DestinationGenerator<typeof DIGESTION_PART_EDIT>;
  toDigestionTopicTable?: DestinationGenerator<typeof DIGESTION_TOPIC_TABLE>;
  toDigestionTopicCreate?: DestinationGenerator<typeof DIGESTION_TOPIC_CREATE>;
  toDigestionTopicDetails?: DestinationGenerator<
    typeof DIGESTION_TOPIC_DETAILS
  >;
  toDigestionTopicEdit?: DestinationGenerator<typeof DIGESTION_TOPIC_EDIT>;
  WORKFLOW_TABLE?: string;
  toWorkflowTable?: DestinationGenerator<typeof WORKFLOW_TABLE>;
  toWorkflowCreate?: DestinationGenerator<typeof WORKFLOW_CREATE>;
  toWorkflowDetails?: DestinationGenerator<typeof WORKFLOW_DETAILS>;
  toWorkflowEdit?: DestinationGenerator<typeof WORKFLOW_EDIT>;
  toWorkflowHookTable?: DestinationGenerator<typeof WORKFLOW_HOOK_TABLE>;
  toWorkflowHookCreate?: DestinationGenerator<typeof WORKFLOW_HOOK_CREATE>;
  toWorkflowHookDetails?: DestinationGenerator<typeof WORKFLOW_HOOK_DETAILS>;
  toWorkflowHookEdit?: DestinationGenerator<typeof WORKFLOW_HOOK_EDIT>;
  LABEL_TABLE?: string;
  toLabelTable?: DestinationGenerator<typeof LABEL_TABLE>;
  toLabelCreate?: DestinationGenerator<typeof LABEL_CREATE>;
  toLabelDetails?: DestinationGenerator<typeof LABEL_DETAILS>;
  toLabelEdit?: DestinationGenerator<typeof LABEL_EDIT>;
  OBJECT_STORE_TABLE?: string;
  toObjectStoreTable?: DestinationGenerator<typeof OBJECT_STORE_TABLE>;
  toObjectStoreCreate?: DestinationGenerator<typeof OBJECT_STORE_CREATE>;
  toObjectStoreDetails?: DestinationGenerator<typeof OBJECT_STORE_DETAILS>;
  toObjectStoreEdit?: DestinationGenerator<typeof OBJECT_STORE_EDIT>;
  toObjectStoreObjectTable?: DestinationGenerator<
    typeof OBJECT_STORE_OBJECT_TABLE,
    { directory?: string; processing?: boolean }
  >;
  toObjectStoreObjectDetails?: DestinationGenerator<
    typeof OBJECT_STORE_OBJECT_DETAILS
  >;
  USER_TABLE?: string;
  toUserTable?: DestinationGenerator<typeof USER_TABLE>;
  toUserCreate?: DestinationGenerator<typeof USER_CREATE>;
  toUserDetails?: DestinationGenerator<typeof USER_DETAILS>;
  toUserEdit?: DestinationGenerator<typeof USER_EDIT>;
  GROUP_TABLE?: string;
  toGroupTable?: DestinationGenerator<typeof GROUP_TABLE>;
  toGroupCreate?: DestinationGenerator<typeof GROUP_CREATE>;
  toGroupDetails?: DestinationGenerator<typeof GROUP_DETAILS>;
  toGroupEdit?: DestinationGenerator<typeof GROUP_EDIT>;
  ROLE_TABLE?: string;
  toRoleTable?: DestinationGenerator<typeof ROLE_TABLE>;
  toRoleCreate?: DestinationGenerator<typeof ROLE_CREATE>;
  toRoleDetails?: DestinationGenerator<typeof ROLE_DETAILS>;
  toRoleEdit?: DestinationGenerator<typeof ROLE_EDIT>;
  API_KEY_TABLE?: string;
  toApiKeyTable?: DestinationGenerator<typeof API_KEY_TABLE>;
  toApiKeyCreate?: DestinationGenerator<typeof API_KEY_CREATE>;
  toApiKeyDetails?: DestinationGenerator<typeof API_KEY_DETAILS>;
  toApiKeyEdit?: DestinationGenerator<typeof API_KEY_EDIT>;
}

export function generateDestination<const TPathPattern extends string>({
  pattern,
  params,
  search,
}: {
  pattern: TPathPattern;
  search?: Record<string, any>;
} & (IsEmptyObject<ParamsShape<TPathPattern>> extends true
  ? { params?: never }
  : { params: Record<keyof ParamsShape<TPathPattern>, any> })): Partial<Path> {
  const serializedParams: any = {};
  if (params != null) {
    for (const [name, value] of Object.entries(params)) {
      serializedParams[name] = encodeURIComponent(String(value));
    }
  }

  return {
    pathname: generatePath(pattern, serializedParams),
    ...(search != null && {
      search: serializeSearchParams({ params: search }).toString(),
    }),
  };
}

export function useTypedParams<
  const TPathPattern extends string,
  TShape extends Record<
    keyof ParamsShape<TPathPattern>,
    any
  > = ParamsShape<TPathPattern>,
>(
  pattern: TPathPattern,
  deserialize?: (params: ParamsShape<TPathPattern>) => TShape,
): TShape {
  const params = useParams() as ParamsShape<TPathPattern>;

  return (deserialize?.(params) ?? params) as TShape;
}
