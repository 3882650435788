import { useState } from "react";
import { PersonRemove } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as z from "zod";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import { CheckboxField, useStudioForm } from "~/components/Form";
import { Loading } from "~/components/Loading";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { UserSelect, boolean, requiredUuid } from "~/domain/common";
import { selectData } from "~/utils";
import { DsmResourceLink } from "../../../DsmResourceLink";
import {
  useCreateDataStoreAssociation,
  useDataStoreAssociations,
  useDeleteDataStoreAssociation,
  useUser,
} from "../../../api";
import * as dsmPaths from "../../../paths";
import type { DataStoreAssociation } from "../../../sdk";

interface AddUserDialogState {
  type: "add";
}

interface RemoveUserDialogState {
  type: "remove";
  dataStoreAssociationId: DataStoreAssociation["id"];
}

type DialogState = AddUserDialogState | RemoveUserDialogState;

export function UserManagementSection() {
  const [dialogState, setDialogState] = useState<DialogState | null>(null);

  const { dataStoreId } = dsmPaths.useDsmPathParams(dsmPaths.DATASTORE_DETAILS);

  const listQuery = useDataStoreAssociations({
    datastoreId: dataStoreId,
    limit: 500,
    sort: "asc",
    order: "datastore_username",
  });

  function handleBeginAdding() {
    setDialogState({ type: "add" });
  }

  function createRemoveClickHandler(
    dataStoreAssociation: DataStoreAssociation,
  ) {
    return function handleRemoveClick() {
      setDialogState({
        type: "remove",
        dataStoreAssociationId: dataStoreAssociation.id,
      });
    };
  }

  function handleCloseDialog() {
    setDialogState(null);
  }

  return (
    <>
      <Card
        title="Manage Users"
        action={
          <Button
            variant="contained"
            disableElevation
            onClick={handleBeginAdding}
          >
            Add User
          </Button>
        }
      >
        <QueryRenderer
          query={listQuery}
          loading={<Loading type="circular" />}
          error={<ErrorMessage>Error fetching DataStore's users</ErrorMessage>}
          success={(response) =>
            response.count === 0 ? (
              <Center>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ fontStyle: "italic", my: 4 }}
                >
                  No users belong to this DataStore
                </Typography>
              </Center>
            ) : (
              <>
                <List>
                  {response.data.map((association) => (
                    <AssociationListItem
                      key={association.id}
                      association={association}
                      onRemoveClick={createRemoveClickHandler(association)}
                    />
                  ))}
                </List>
                <RemoveDialog
                  association={response.data.find(
                    (association) =>
                      dialogState?.type === "remove" &&
                      dialogState.dataStoreAssociationId === association.id,
                  )}
                  onClose={handleCloseDialog}
                />
              </>
            )
          }
        />
      </Card>
      <AddDialog
        open={dialogState?.type === "add"}
        onClose={handleCloseDialog}
      />
    </>
  );
}

function AssociationListItem({
  association,
  onRemoveClick,
}: {
  association: DataStoreAssociation;
  onRemoveClick: () => void;
}) {
  return (
    <ListItem
      secondaryAction={
        <Tooltip title="Remove from DataStore...">
          <IconButton color="error" onClick={onRemoveClick}>
            <PersonRemove />
          </IconButton>
        </Tooltip>
      }
    >
      <ListItemText disableTypography>
        <DsmResourceLink resourceType="user" uuid={association.userId} />
      </ListItemText>
    </ListItem>
  );
}

const schema = z.object({
  userId: requiredUuid,
  manager: boolean,
  disabled: boolean,
  datastoreAdmin: boolean,
  datastoreDisabled: boolean,
});

const DEFAULT_VALUES = {
  userId: null,
  manager: false,
  disabled: false,
  datastoreAdmin: false,
  datastoreDisabled: false,
};

function AddDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { dataStoreId } = dsmPaths.useDsmPathParams(dsmPaths.DATASTORE_DETAILS);

  const createAssociation = useCreateDataStoreAssociation();

  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit, reset } = useStudioForm({
    schema,
    defaultValues: DEFAULT_VALUES,
    onSubmit(values) {
      createAssociation.mutate(
        {
          datastoreId: dataStoreId,
          ...values,
        },
        {
          onSuccess() {
            enqueueSnackbar("User added to DataStore", { variant: "success" });

            onClose();
          },
          onError() {
            enqueueSnackbar("Unable to add user", { variant: "error" });
          },
        },
      );
    },
  });

  function handleClose() {
    if (createAssociation.isLoading) {
      return;
    }

    onClose();
  }

  function handleCloseTransitionFinished() {
    reset(DEFAULT_VALUES);
    createAssociation.reset();
  }

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      fullWidth
      onClose={handleClose}
      TransitionProps={{
        onExited: handleCloseTransitionFinished,
      }}
    >
      <DialogTitle>Add User to DataStore</DialogTitle>
      <form onSubmit={handleSubmit} noValidate>
        <DialogContent>
          <Stack spacing={2}>
            <UserSelect control={control} name="userId" required />
            <CheckboxField control={control} name="manager" />
            <CheckboxField control={control} name="disabled" />
            <CheckboxField control={control} name="datastoreAdmin" />
            <CheckboxField control={control} name="datastoreDisabled" />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            disabled={createAssociation.isLoading}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disableElevation
            loading={createAssociation.isLoading}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
}

function RemoveDialog({
  association,
  onClose,
}: {
  association: DataStoreAssociation | undefined;
  onClose: () => void;
}) {
  const deleteAssociation = useDeleteDataStoreAssociation(association?.id);
  const userQuery = useUser(association?.userId ?? null, {
    select: selectData,
  });

  const { enqueueSnackbar } = useSnackbar();

  const open = association != null;

  function handleClose() {
    if (deleteAssociation.isLoading) {
      return;
    }

    onClose();
  }

  function handleRemove() {
    deleteAssociation.mutate(undefined, {
      onSuccess() {
        enqueueSnackbar("User removed from DataStore", { variant: "success" });

        onClose();
      },
      onError() {
        enqueueSnackbar("Unable to remove user", { variant: "error" });
      },
    });
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
      <DialogTitle>Remove User from DataStore</DialogTitle>
      <DialogContent>
        <DialogContentText paragraph>
          This user will be removed from the DataStore:
        </DialogContentText>
        {/* Since the dialog will have been opened from the association's list
        item, it's reasonable to assume the associated user has already been
        fetched and their username will be available. */}
        <DialogContentText sx={{ fontWeight: "bold" }}>
          {userQuery.data?.username}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="secondary"
          disabled={deleteAssociation.isLoading}
          onClick={handleClose}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          disableElevation
          loading={deleteAssociation.isLoading}
          onClick={handleRemove}
        >
          Remove
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
