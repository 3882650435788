import {
  EditLqsResourceForm,
  processStateOptions,
  useIngestion,
  useUpdateIngestion,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editIngestionSchema } from "../schemas";

export function IngestionEditForm() {
  const { ingestionId } = useTypedParams(lqsRoutePaths.INGESTION_EDIT);

  const lqsNavigator = useLqsNavigator({ toIngestionDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="ingestion"
      registryKey="ingestion"
      query={useIngestion(ingestionId, { select: selectData })}
      schema={editIngestionSchema}
      descriptors={[
        { name: "name", type: "text" },
        {
          name: "objectStoreId",
          type: "foreign-key",
          resourceType: "object-store",
        },
        { name: "objectKey", type: "text" },
        {
          name: "state",
          type: "select",
          options: processStateOptions,
          required: true,
        },
        { name: "progress", type: "number" },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowContext", type: "json" },
        { name: "error", type: "json" },
        { name: "errorName", type: "text" },
        { name: "errorMessage", type: "text" },
        { name: "errorPayload", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateIngestion(ingestionId)}
      detailsLocation={lqsNavigator.toIngestionDetails({ ingestionId })}
    />
  );
}
