import React from "react";
import type { BoxProps, TypographyProps } from "@mui/material";
import { Box, Typography } from "@mui/material";
import type { StrictOmit } from "ts-essentials";

export type SummaryProps = StrictOmit<
  TypographyProps<"summary", { component: "summary" }>,
  "component"
>;

export function Summary(props: SummaryProps) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      {...props}
      component="summary"
    />
  );
}

export type ContentProps<TComponent extends React.ElementType> =
  TypographyProps<TComponent, { component?: TComponent }>;

export function Content<TComponent extends React.ElementType = "p">(
  props: ContentProps<TComponent>,
) {
  return <Typography variant="body2" {...props} />;
}

export type DetailsProps = StrictOmit<
  BoxProps<"details", { component: "details" }>,
  "component"
>;

export function Details(props: DetailsProps) {
  return <Box {...props} component="details" />;
}

Details.Summary = Summary;
Details.Content = Content;
