/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DataStoreAssociationCreateRequest {
  userId: string;
  datastoreId: string;
  manager?: boolean;
  disabled?: boolean;
  datastoreUserId?: string | null;
  datastoreUsername?: string | null;
  datastoreRoleId?: string | null;
  datastoreAdmin?: boolean;
  datastoreDisabled?: boolean;
}

export function DataStoreAssociationCreateRequestFromJSON(
  json: any,
): DataStoreAssociationCreateRequest {
  return DataStoreAssociationCreateRequestFromJSONTyped(json);
}

export function DataStoreAssociationCreateRequestFromJSONTyped(
  json: any,
): DataStoreAssociationCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    userId: json["user_id"],
    datastoreId: json["datastore_id"],
    manager: json["manager"],
    disabled: json["disabled"],
    datastoreUserId: json["datastore_user_id"],
    datastoreUsername: json["datastore_username"],
    datastoreRoleId: json["datastore_role_id"],
    datastoreAdmin: json["datastore_admin"],
    datastoreDisabled: json["datastore_disabled"],
  };
}

export function DataStoreAssociationCreateRequestToJSON(
  value: DataStoreAssociationCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    user_id: value.userId,
    datastore_id: value.datastoreId,
    manager: value.manager,
    disabled: value.disabled,
    datastore_user_id: value.datastoreUserId,
    datastore_username: value.datastoreUsername,
    datastore_role_id: value.datastoreRoleId,
    datastore_admin: value.datastoreAdmin,
    datastore_disabled: value.datastoreDisabled,
  };
}
