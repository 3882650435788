import React from "react";
import { Container } from "@mui/material";
import { Page } from "~/layout";
import { UserEditForm } from "./user-edit-form";

export function UserEditPage({
  breadcrumbs,
}: {
  breadcrumbs?: React.ReactNode;
}) {
  return (
    <Page title="Edit User">
      <Container fixed>
        {breadcrumbs}
        <UserEditForm />
      </Container>
    </Page>
  );
}
