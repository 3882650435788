/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { PolicyInput } from "./PolicyInput";
import {
  PolicyInputFromJSON,
  PolicyInputFromJSONTyped,
  PolicyInputToJSON,
} from "./PolicyInput";

export interface RoleCreateRequest {
  name: string;
  policy: PolicyInput;
  note?: string | null;
  disabled?: boolean;
  managed?: boolean;
  _default?: boolean;
}

export function RoleCreateRequestFromJSON(json: any): RoleCreateRequest {
  return RoleCreateRequestFromJSONTyped(json);
}

export function RoleCreateRequestFromJSONTyped(json: any): RoleCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    name: json["name"],
    policy: PolicyInputFromJSON(json["policy"]),
    note: json["note"],
    disabled: json["disabled"],
    managed: json["managed"],
    _default: json["default"],
  };
}

export function RoleCreateRequestToJSON(
  value: RoleCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value.name,
    policy: PolicyInputToJSON(value.policy),
    note: value.note,
    disabled: value.disabled,
    managed: value.managed,
    default: value._default,
  };
}
