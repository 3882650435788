import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar, typeEncodingOptions } from "~/lqs";
import { listTopicsSchema } from "../schemas";
import { useTopicSearchRequest } from "./TopicTable";

export function TopicFilters() {
  const [request, setRequest] = useTopicSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="topic"
      request={request}
      setRequest={setRequest}
      schema={listTopicsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "typeName", type: "fuzzy", fuzzyName: "typeNameLike" },
        { name: "typeEncoding", type: "select", options: typeEncodingOptions },
        { name: "logId", type: "foreign-key", resourceType: "log" },
        {
          name: "associatedTopicId",
          type: "foreign-key",
          resourceType: "topic",
        },
        { name: "locked", type: "boolean" },
        { name: "strict", type: "boolean" },
        { name: "latched", type: "boolean" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
