/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface Record {
  locked: boolean;
  lockedBy: string | null;
  lockedAt: Date | null;
  lockToken: string | null;
  timestamp: bigint;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  logId: string;
  topicId: string;
  ingestionId: string | null;
  dataOffset: number | null;
  dataLength: number | null;
  chunkCompression: string | null;
  chunkOffset: number | null;
  chunkLength: number | null;
  source: string | null;
  error: object | null;
  queryData: object | null;
  auxiliaryData: object | null;
  rawData: string | null;
  context: object | null;
  note: string | null;
}

export function RecordFromJSON(json: any): Record {
  return RecordFromJSONTyped(json);
}

export function RecordFromJSONTyped(json: any): Record {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    lockedBy: json["locked_by"],
    lockedAt:
      json["locked_at"] == null
        ? json["locked_at"]
        : new Date(json["locked_at"]),
    lockToken: json["lock_token"],
    timestamp: BigInt(json["timestamp"]),
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null
        ? json["updated_at"]
        : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] == null
        ? json["deleted_at"]
        : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    logId: json["log_id"],
    topicId: json["topic_id"],
    ingestionId: json["ingestion_id"],
    dataOffset: json["data_offset"],
    dataLength: json["data_length"],
    chunkCompression: json["chunk_compression"],
    chunkOffset: json["chunk_offset"],
    chunkLength: json["chunk_length"],
    source: json["source"],
    error: json["error"],
    queryData: json["query_data"],
    auxiliaryData: json["auxiliary_data"],
    rawData: json["raw_data"],
    context: json["context"],
    note: json["note"],
  };
}

export function RecordToJSON(value: Record | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    locked_by: value.lockedBy,
    locked_at: value.lockedAt === null ? null : value.lockedAt.toISOString(),
    lock_token: value.lockToken,
    timestamp: value.timestamp.toString(),
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    log_id: value.logId,
    topic_id: value.topicId,
    ingestion_id: value.ingestionId,
    data_offset: value.dataOffset,
    data_length: value.dataLength,
    chunk_compression: value.chunkCompression,
    chunk_offset: value.chunkOffset,
    chunk_length: value.chunkLength,
    source: value.source,
    error: value.error,
    query_data: value.queryData,
    auxiliary_data: value.auxiliaryData,
    raw_data: value.rawData,
    context: value.context,
    note: value.note,
  };
}
