import React from "react";
import { TableCell } from "@mui/material";
import * as z from "zod";
import {
  Boolean,
  Bytes,
  Datetime,
  Duration,
  formatNumber,
  formatPercent,
  Timestamp,
} from "~/format";
import { invariant } from "~/lib/invariant";
import { assertNever } from "~/utils";
import type {
  AccessorColumn,
  CellRenderer,
  ForeignResourceRenderer,
} from "./types";

export function makeCellRenderer<
  TResource extends object,
  TForeignResource extends string,
>(
  column: AccessorColumn<TResource, TForeignResource>,
  renderForeignResource: ForeignResourceRenderer<TForeignResource> | undefined,
): CellRenderer<TResource> {
  return function renderCell(resource, props) {
    const cellData: unknown = resource[column.accessor];

    return (
      <TableCell {...props}>
        {cellData === null
          ? "-"
          : getCellContent(cellData, column, renderForeignResource)}
      </TableCell>
    );
  };
}

const uuidSchema = z.string().uuid();

const numberSchema = z.number();

const bigintSchema = z.bigint();

const datetimeSchema = z.date();

const booleanSchema = z.boolean();

function getCellContent(
  cellData: unknown,
  column: AccessorColumn<any, any>,
  renderForeignResource: ForeignResourceRenderer<any> | undefined,
): React.ReactNode {
  const { dataType } = column;
  switch (dataType) {
    case "text": {
      return String(cellData);
    }
    case "number": {
      return formatNumber(numberSchema.parse(cellData));
    }
    case "percent": {
      return formatPercent(numberSchema.parse(cellData));
    }
    case "timestamp": {
      return <Timestamp value={bigintSchema.parse(cellData)} />;
    }
    case "duration": {
      return <Duration value={bigintSchema.parse(cellData)} />;
    }
    case "datetime": {
      return <Datetime date={datetimeSchema.parse(cellData)} />;
    }
    case "bytes": {
      return <Bytes value={numberSchema.parse(cellData)} />;
    }
    case "boolean": {
      return <Boolean value={booleanSchema.parse(cellData)} />;
    }
    case "foreign-key": {
      invariant(
        renderForeignResource !== undefined,
        "No foreign resource renderer provided",
      );

      return renderForeignResource(
        uuidSchema.parse(cellData),
        column.resourceType,
      );
    }
    default: {
      assertNever(dataType);
    }
  }
}
