import { Box } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout } from "~/components/DetailsCards";
import type { APIKey } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useApiKey,
  useDeleteApiKey,
  useUpdateApiKey,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editApiKeySchema } from "../schemas";

export function ApiKeyDetails() {
  const { apiKeyId } = useTypedParams(lqsRoutePaths.API_KEY_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toApiKeyEdit: true,
    toApiKeyTable: true,
  });

  const query = useApiKey(apiKeyId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="API key"
      query={query}
      editLocation={lqsNavigator.toApiKeyEdit({ apiKeyId })}
      deleteMutation={useDeleteApiKey(apiKeyId)}
      getReadableName={(apiKey) => apiKey.name}
      listLocation={lqsNavigator.toApiKeyTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: 3,
            }}
          >
            {infoSection}
            {manageSection}
          </Box>
        </>
      }
      secondaryGridColumn={historySection}
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<APIKey> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="apiKey"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "foreign-key", resourceType: "user", accessor: "userId" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<APIKey> }) {
  const { apiKeyId } = useTypedParams(lqsRoutePaths.API_KEY_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="API key"
      registryKey="apiKey"
      query={query}
      schema={editApiKeySchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "disabled", type: "boolean" },
      ]}
      mutation={useUpdateApiKey(apiKeyId)}
    />
  );
}
