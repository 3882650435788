import {
  EditLqsResourceForm,
  processStateOptions,
  useDigestion,
  useUpdateDigestion,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editDigestionSchema } from "../schemas";

export function DigestionEditForm() {
  const { digestionId } = useTypedParams(lqsRoutePaths.DIGESTION_EDIT);

  const lqsNavigator = useLqsNavigator({ toDigestionDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="digestion"
      registryKey="digestion"
      query={useDigestion(digestionId, { select: selectData })}
      schema={editDigestionSchema}
      descriptors={[
        { name: "name", type: "text" },
        {
          name: "state",
          type: "select",
          options: processStateOptions,
          required: true,
        },
        { name: "progress", type: "number" },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowContext", type: "json" },
        { name: "error", type: "json" },
        { name: "errorName", type: "text" },
        { name: "errorMessage", type: "text" },
        { name: "errorPayload", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateDigestion(digestionId)}
      detailsLocation={lqsNavigator.toDigestionDetails({ digestionId })}
    />
  );
}
