/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Hook } from "./Hook";
import { HookFromJSON, HookFromJSONTyped, HookToJSON } from "./Hook";

export interface HookListResponse {
  offset: number;
  limit: number;
  order: string;
  sort: string;
  count: number | null;
  data: Array<Hook>;
}

export function HookListResponseFromJSON(json: any): HookListResponse {
  return HookListResponseFromJSONTyped(json);
}

export function HookListResponseFromJSONTyped(json: any): HookListResponse {
  if (json == null) {
    return json;
  }

  return {
    offset: json["offset"],
    limit: json["limit"],
    order: json["order"],
    sort: json["sort"],
    count: json["count"],
    data: (json["data"] as Array<any>).map(HookFromJSON),
  };
}

export function HookListResponseToJSON(
  value: HookListResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    offset: value.offset,
    limit: value.limit,
    order: value.order,
    sort: value.sort,
    count: value.count,
    data: (value.data as Array<any>).map(HookToJSON),
  };
}
