import React, {
  memo,
  useDeferredValue,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CameraAlt,
  ChevronRight,
  ExpandMore,
  Public,
} from "@mui/icons-material";
import type { SxProps } from "@mui/material";
import {
  alpha,
  Button,
  ButtonGroup,
  Stack,
  styled,
  TextField,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { TreeItem, treeItemClasses, TreeView } from "@mui/x-tree-view";
import { CubeOutline } from "mdi-material-ui";
import type { StrictOmit } from "ts-essentials";
import { BreakableText } from "~/components/BreakableText";
import { invariant } from "~/lib/invariant";
import { compact, get } from "~/lib/std";
import type { Topic } from "~/lqs";
import { getEventHandlerProps, pluralize, traverseTree } from "~/utils";
import type { VisualizationFilter } from "../panels";
import { supportsVisualization, VisualizationType } from "../panels";
import { getTopicContextDisplayName } from "./utils";

type NodeId = string;

interface TreeActions {
  expandAll: () => void;
  collapseAll: () => void;
}

interface TopicNode {
  name: Topic["name"];
  nodeId: NodeId;
  typeName?: Topic["typeName"];
  children: Array<TopicNode>;
}

interface BaseTopicTreeProps {
  topics: ReadonlyArray<Topic>;
  disabled?: boolean;
  defaultSearch?: string | null;
  onSearchChange?: (newSearch: string | null) => void;
  filterSectionStyles?: SxProps;
}

interface ReadonlyTopicTreeProps extends BaseTopicTreeProps {
  multiple?: false;
  defaultHighlighted?: Topic["name"] | null;
  selected?: never;
  onSelect: (newValue: Topic) => void;
  showVisualizationFilters?: boolean;
  defaultVisualizationFilter?: VisualizationFilter | null;
  onVisualizationFilterChange?: (
    newVisualizationFilter: VisualizationFilter | null,
  ) => void;
}

interface MultiTopicTreeProps extends BaseTopicTreeProps {
  multiple: true;
  defaultHighlighted?: never;
  selected: Array<Topic>;
  onSelect: (newValue: ReadonlyArray<Topic>) => void;
  showVisualizationFilters?: never;
  defaultVisualizationFilter?: never;
  onVisualizationFilterChange?: never;
}

type TopicTreeProps = ReadonlyTopicTreeProps | MultiTopicTreeProps;

export function TopicTree({
  topics,
  defaultSearch,
  onSearchChange,
  showVisualizationFilters,
  defaultVisualizationFilter,
  onVisualizationFilterChange,
  filterSectionStyles,
  ...rest
}: TopicTreeProps) {
  // TS doesn't like destructuring these above then trying to individually
  // pass them to <TreeContent /> below
  const { disabled, multiple, defaultHighlighted, selected, onSelect } = rest;

  const [filter, setFilter] = useState(defaultSearch ?? "");
  const [visualizationFilter, setVisualizationFilter] =
    useState<VisualizationFilter | null>(defaultVisualizationFilter ?? null);
  const deferredFilter = useDeferredValue(filter);
  const deferredVisualizationFilter = useDeferredValue(visualizationFilter);

  const rootRef = useRef<HTMLElement>(null);
  useLayoutEffect(() => {
    if (defaultHighlighted == null) {
      return;
    }

    const filtersSection =
      rootRef.current?.querySelector<HTMLElement>("[data-filters]");
    if (filtersSection == null) {
      return;
    }

    const treeRoot =
      rootRef.current?.querySelector<HTMLElement>('[role="tree"]');
    if (treeRoot == null) {
      return;
    }

    // MUI's tree items' IDs end with the node ID. Since a topic name was given
    // to highlight by default, the leaf tree item whose ID has the given name
    // represents the node to be scrolled into view.
    const target = treeRoot.querySelector<HTMLElement>(
      `[id$="leaf:${defaultHighlighted}"]`,
    );
    if (target == null) {
      return;
    }

    // TODO: Checking if the position is actually sticky is letting the
    //  <TopicSelector />'s logic/use-case leak into this component
    let scrollMarginBlockStart = 0;
    if (window.getComputedStyle(filtersSection).position === "sticky") {
      // Just scrolling the highlighted node into view isn't sufficient when
      // the filters are stickily positioned at the top of the scrollable
      // container: just scrolling into view would likely put the highlighted
      // node behind the filters. Instead, use the tree root's offset from the
      // top of the panel as the scroll margin since the offset accounts for the
      // space taken up by the filters which will put the highlighted node just
      // below the filters.
      scrollMarginBlockStart = treeRoot.offsetTop;
    }

    target.style.scrollMarginBlockStart = `${scrollMarginBlockStart}px`;
    target.scrollIntoView();
    target.style.scrollMarginBlockStart = "0px";
  }, [defaultHighlighted]);

  const treeActionsRef = useRef<TreeActions>(null);

  const filteredTopics = useMemo(() => {
    let filteredTopics = topics;

    filteredTopics = filteredTopics.filter(
      (topic) => get(topic.context, "studio.hide_in_player") !== true,
    );

    if (deferredVisualizationFilter !== null) {
      filteredTopics = filteredTopics.filter((topic) =>
        supportsVisualization(topic.typeName, deferredVisualizationFilter),
      );
    }

    if (deferredFilter === "") {
      return filteredTopics;
    }

    return filteredTopics.filter((topic) => {
      const { name, typeName, context } = topic;

      return (
        name.includes(deferredFilter) ||
        typeName?.includes(deferredFilter) ||
        getTopicContextDisplayName(context)?.includes(deferredFilter)
      );
    });
  }, [topics, deferredFilter, deferredVisualizationFilter]);

  let helperText;
  if (deferredFilter === "" && deferredVisualizationFilter === null) {
    helperText = `Showing ${pluralize(filteredTopics.length, "topic")}`;
  } else {
    helperText = `${pluralize(filteredTopics.length, "topic")} matched`;
  }

  if (multiple) {
    const filteredTopicIds = new Set(filteredTopics.map(({ id }) => id));
    const selectedFilteredTopicsCount = selected.filter(({ id }) =>
      filteredTopicIds.has(id),
    ).length;

    helperText = `${helperText}. ${selectedFilteredTopicsCount} selected`;

    const hiddenSelectedTopicsCount =
      selected.length - selectedFilteredTopicsCount;
    if (hiddenSelectedTopicsCount > 0) {
      helperText = `${helperText} (${hiddenSelectedTopicsCount} hidden)`;
    }
  }

  function handleFilterChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setFilter(e.target.value);
    onSearchChange?.(e.target.value || null);
  }

  function handleVisualizationFilterChange(
    _: unknown,
    newVisualizationFilter: VisualizationFilter | null,
  ): void {
    setVisualizationFilter(newVisualizationFilter);
    onVisualizationFilterChange?.(newVisualizationFilter);
  }

  const expandAllHandlerProps = getEventHandlerProps(
    "onClick",
    !disabled &&
      function handleExpandAll() {
        treeActionsRef.current?.expandAll();
      },
  );

  const collapseAllHandlerProps = getEventHandlerProps(
    "onClick",
    !disabled &&
      function handleCollapseAll() {
        treeActionsRef.current?.collapseAll();
      },
  );

  const selectAllHandlerProps = getEventHandlerProps(
    "onClick",
    !disabled &&
      multiple &&
      function handleSelectAll() {
        const selectedTopicIds = new Set(selected.map(({ id }) => id));

        // Only add filtered topics that aren't already selected
        const additions = filteredTopics.filter(
          ({ id }) => !selectedTopicIds.has(id),
        );

        if (additions.length > 0) {
          onSelect([...selected, ...additions]);
        }
      },
  );

  const deselectAllHandlerProps = getEventHandlerProps(
    "onClick",
    !disabled &&
      multiple &&
      function handleDeselectAll() {
        const filteredTopicIds = new Set(filteredTopics.map(({ id }) => id));

        const remaining = selected.filter(
          ({ id }) => !filteredTopicIds.has(id),
        );

        onSelect(remaining);
      },
  );

  return (
    <Stack spacing={1} ref={rootRef}>
      <Stack
        data-filters=""
        spacing={1}
        sx={{
          width: 1,
          position: "sticky",
          top: 0,
          zIndex: 1,
          backdropFilter: "blur(15px)",
          py: 1,
          ...filterSectionStyles,
        }}
      >
        {showVisualizationFilters && (
          <ToggleButtonGroup
            exclusive
            fullWidth
            color="primary"
            size="small"
            value={visualizationFilter}
            onChange={handleVisualizationFilterChange}
            sx={{
              mb: 1,
              [`& .${toggleButtonClasses.root}`]: {
                gap: 1,
              },
            }}
          >
            <ToggleButton value={VisualizationType.Image}>
              <CameraAlt fontSize="small" />
              Image
            </ToggleButton>
            <ToggleButton value={VisualizationType.ThreeD}>
              <CubeOutline fontSize="small" />
              3D
            </ToggleButton>
            <ToggleButton value={VisualizationType.Map}>
              <Public fontSize="small" />
              Map
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <TextField
          label="Filter topics"
          fullWidth
          disabled={disabled}
          value={filter}
          onChange={handleFilterChange}
          helperText={helperText}
        />
        <Stack spacing={1}>
          <ButtonGroup variant="outlined" size="small">
            <Button {...expandAllHandlerProps}>Expand All</Button>
            <Button {...collapseAllHandlerProps}>Collapse All</Button>
          </ButtonGroup>
          {multiple && (
            <ButtonGroup variant="outlined" size="small">
              <Button {...selectAllHandlerProps}>Select All</Button>
              <Button {...deselectAllHandlerProps}>Deselect All</Button>
            </ButtonGroup>
          )}
        </Stack>
      </Stack>
      <TreeContent
        key={`${deferredFilter}:${deferredVisualizationFilter}`}
        filteredTopics={filteredTopics}
        actionsRef={treeActionsRef}
        {...rest}
      />
    </Stack>
  );
}

type TreeContentProps = StrictOmit<
  TopicTreeProps,
  | "topics"
  | "defaultSearch"
  | "onSearchChange"
  | "showVisualizationFilters"
  | "defaultVisualizationFilter"
  | "onVisualizationFilterChange"
> & {
  filteredTopics: ReadonlyArray<Topic>;
  actionsRef: React.RefObject<TreeActions>;
};

const TreeContent = memo(function TreeContent({
  filteredTopics,
  actionsRef,
  multiple,
  defaultHighlighted,
  selected,
  onSelect,
}: TreeContentProps) {
  const { rootNodes, nodeIdsToTopics } = useMemo(() => {
    return treeifyTopics(filteredTopics);
  }, [filteredTopics]);

  // When the tree first mounts or when the filter changes, all nodes in the
  // tree should be expanded. The parent component will set the filter as a
  // `key` on this component so this state will be reset when the filter changes.
  const [expanded, setExpanded] = useState(() => getInternalNodeIds(rootNodes));

  useImperativeHandle(
    actionsRef,
    () => ({
      expandAll() {
        setExpanded(getInternalNodeIds(rootNodes));
      },
      collapseAll() {
        setExpanded([]);
      },
    }),
    [rootNodes],
  );

  function handleExpansionChange(_: unknown, newExpanded: Array<NodeId>): void {
    if (newExpanded.length > expanded.length) {
      // A node was expanded
      setExpanded(newExpanded);
    } else {
      // A node was collapsed. Collapse all its expanded children too
      const newExpandedIds = new Set(newExpanded);

      // ID(s) of the node(s) which triggered this event. Should only be a
      // single node ID but being defensive here since this is sort of
      // circumventing the tree's default behavior. Would be better if MUI gave
      // the ID of the node which triggered the event instead of the final array
      // of currently-expanded node IDs
      const collapsedNodesIds = expanded.filter(
        (expandedNodeId) => !newExpandedIds.has(expandedNodeId),
      );
      invariant(
        collapsedNodesIds.length === 1,
        "Expected only a single node to have been collapsed",
      );
      const [collapsedNodeId] = collapsedNodesIds;

      setExpanded(
        expanded.filter(
          (expandedNodeId) => !expandedNodeId.startsWith(collapsedNodeId),
        ),
      );
    }
  }

  function handleNodeSelect(
    e: React.SyntheticEvent,
    nodeIds: NodeId | Array<NodeId>,
  ) {
    if (multiple) {
      if (e.type === "click") {
        // Default node multi-selection behavior on click is overridden.
        return;
      }

      const selectedTopicIds = new Set(selected.map(({ id }) => id));
      const newVisibleSelectedTopics = (nodeIds as Array<NodeId>).flatMap(
        (nodeId) => {
          const topic = nodeIdsToTopics.get(nodeId);

          if (topic === undefined) {
            // Not a leaf node
            return [];
          }

          return topic;
        },
      );

      const additions = newVisibleSelectedTopics.filter(
        ({ id }) => !selectedTopicIds.has(id),
      );

      const filteredTopicIds = new Set(filteredTopics.map(({ id }) => id));
      const newVisibleSelectedTopicIds = new Set(
        newVisibleSelectedTopics.map(({ id }) => id),
      );

      const remaining = selected.filter(({ id }) => {
        // Seems simpler to describe what to remove rather than what to keep.
        //
        // Hidden topics should always be kept so they aren't deselected when
        // the user isn't actively looking at them. For a filtered/visible
        // topic, if the new set of selected visible topics doesn't include its
        // ID then it must have been deselected and should be removed.
        const shouldRemove =
          filteredTopicIds.has(id) && !newVisibleSelectedTopicIds.has(id);

        // Make sure to negate this so the filter operation works as expected.
        return !shouldRemove;
      });

      onSelect([...remaining, ...additions]);
    } else {
      const topic = nodeIdsToTopics.get(nodeIds as NodeId);

      if (topic !== undefined) {
        onSelect(topic);
      }
    }
  }

  // MUI's multi-selection only lets users select more than one topic by
  // clicking if they're holding a modifier key like `ctrl` or `shift`. For
  // Studio's single use case where multi-select is used, it'd be better for
  // users to be able to toggle topics like checkboxes without needing to hold
  // a modifier key.
  function createNodeClickHandler(nodeId: NodeId) {
    return function handleNodeClick() {
      if (!multiple) {
        return;
      }

      const topic = nodeIdsToTopics.get(nodeId);

      if (topic === undefined) {
        // Not a leaf node
        return;
      }

      const selectedIndex = selected.findIndex(({ id }) => id === topic.id);

      if (selectedIndex === -1) {
        onSelect([...selected, topic]);
      } else {
        onSelect(selected.filter((_, index) => index !== selectedIndex));
      }
    };
  }

  if (rootNodes.length === 0) {
    return (
      <Typography>No topics or message types matched your search</Typography>
    );
  } else {
    let selectedNodeIds: Array<NodeId> | null;
    if (multiple) {
      const selectedTopicIds = new Set(selected.map(({ id }) => id));

      selectedNodeIds = [];
      for (const [nodeId, topic] of nodeIdsToTopics) {
        if (selectedTopicIds.has(topic.id)) {
          selectedNodeIds.push(nodeId);
        }
      }
    } else {
      selectedNodeIds = null;
    }

    let props;
    if (multiple) {
      props = {
        multiSelect: true,
        selected: selectedNodeIds,
        onNodeSelect: handleNodeSelect,
      } as const;
    } else {
      props = {
        multiSelect: false,
        defaultSelected:
          defaultHighlighted == null ? null : `leaf:${defaultHighlighted}`,
        onNodeSelect: handleNodeSelect,
      } as const;
    }

    return (
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        expanded={expanded}
        onNodeToggle={handleExpansionChange}
        {...props}
      >
        {rootNodes.map((rootNode) => (
          <TopicTreeItem
            key={rootNode.nodeId}
            node={rootNode}
            createNodeClickHandler={createNodeClickHandler}
          />
        ))}
      </TreeView>
    );
  }
});

/**
 * Generates a list of globally unique node IDs based on the parts of the
 * topic's name which are assumed to have already been split on the "/"
 * character.
 *
 * Each node ID is the absolute path from the base namespace to the given node.
 * Leaf nodes are distinguished from internal nodes by prepending a prefix.
 * For example, if run against a topic named "/namespace/sub_namespace/topic",
 * the node IDs would be:
 * ["internal:/namespace", "internal:/namespace/sub_namespace",
 * "leaf:/namespace/sub_namespace/topic"]
 *
 * @param pathParts Array of strings created by splitting a topic's name on "/"
 */
function generateTopicNodeIds(pathParts: ReadonlyArray<string>): Array<string> {
  const nodeIds = new Array<string>();

  pathParts.forEach((_, index, parts) => {
    // Some IDs can represent both actual topics (i.e. leaf nodes) and
    // internal nodes which have leaves under them. An example would be
    // the topics `/a/b` and `/a/b/c` where the node `b` would represent
    // both a leaf and an internal node. In this case there needs to be two
    // separate nodes in the tree differentiated by leaf status
    const prefix = index === parts.length - 1 ? "leaf" : "internal";
    const nodeId = `${prefix}:/${parts.slice(0, index + 1).join("/")}`;

    nodeIds.push(nodeId);
  });

  return nodeIds;
}

function treeifyTopics(topics: ReadonlyArray<Topic>) {
  const rootNodes = new Array<TopicNode>();
  const nodeIdsToTopics = new Map<NodeId, Topic>();

  // To easily look up existing nodes and append children to them, a cache
  // is kept mapping node IDs to that node's array of children. Root nodes have
  // no parent so `null` is used instead
  const cache = new Map<NodeId | null, Array<TopicNode>>([[null, rootNodes]]);

  topics.forEach((topic) => {
    const { name: originalName, typeName, context } = topic;

    const name = getTopicContextDisplayName(context) ?? originalName;

    const nameParts = compact(name.split("/"));
    const topicNodeIds = generateTopicNodeIds(nameParts);

    nameParts.forEach((pathPart, index, pathParts) => {
      const currentId = topicNodeIds[index];
      // Root nodes will be at index 0 and have no parent so the "path"
      // should default to `null`
      const parentPath = topicNodeIds[index - 1] ?? null;

      if (!cache.has(currentId)) {
        const node: TopicNode = {
          name: pathPart,
          nodeId: currentId,
          children: [],
        };

        if (index === pathParts.length - 1) {
          node.typeName = typeName;

          nodeIdsToTopics.set(currentId, topic);
        }

        cache.get(parentPath)!.push(node);
        cache.set(currentId, node.children);
      }
    });
  });

  return { rootNodes, nodeIdsToTopics };
}

function getInternalNodeIds(
  rootNodes: ReadonlyArray<TopicNode>,
): Array<NodeId> {
  const internalNodeIds = new Array<NodeId>();

  traverseTree(
    rootNodes,
    (node) => node.children,
    (node) => {
      if (node.nodeId.startsWith("internal:")) {
        internalNodeIds.push(node.nodeId);
      }
    },
  );

  return internalNodeIds;
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    // Make it line up vertically with dashed border on group
    marginLeft: "1px",
  },
  [`& .${treeItemClasses.group}`]: {
    borderLeft: "1px dashed",
    borderColor: alpha(theme.palette.divider, 0.35),
  },
  [`&:has(.Mui-focused) > .${treeItemClasses.group}`]: {
    borderColor: alpha(theme.palette.divider, 0.75),
  },
}));

function TopicTreeItem({
  node,
  createNodeClickHandler,
}: {
  node: TopicNode;
  createNodeClickHandler: (nodeId: NodeId) => () => void;
}) {
  const isLeaf = node.children.length === 0;

  function formatTopicName(name: TopicNode["name"]) {
    return (
      <>
        /
        <BreakableText separator={/(_)/} insertBreak="after">
          {name}
        </BreakableText>
      </>
    );
  }

  function formatTypeName(typeName: TopicNode["typeName"]) {
    return (
      <BreakableText separator={/([_/.])/} insertBreak="after">
        {typeName}
      </BreakableText>
    );
  }

  let label;
  if (isLeaf) {
    label = (
      <>
        <Typography component="span">{formatTopicName(node.name)}</Typography>{" "}
        <Typography component="span" variant="subtitle2" color="text.secondary">
          {formatTypeName(node.typeName)}
        </Typography>
      </>
    );
  } else {
    label = (
      <Typography component="span" sx={{ fontWeight: "bold" }}>
        {formatTopicName(node.name)}
      </Typography>
    );
  }

  return (
    <StyledTreeItem
      nodeId={node.nodeId}
      label={label}
      onClick={createNodeClickHandler(node.nodeId)}
    >
      {isLeaf
        ? null
        : node.children.map((childNode) => (
            <TopicTreeItem
              key={childNode.nodeId}
              node={childNode}
              createNodeClickHandler={createNodeClickHandler}
            />
          ))}
    </StyledTreeItem>
  );
}
