import React from "react";
import { Close, Folder, InsertDriveFile } from "@mui/icons-material";
import {
  alpha,
  Box,
  Button,
  Divider,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  styled,
  svgIconClasses,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { FileAlert, FolderOpen } from "mdi-material-ui";
import type { FileRejection } from "react-dropzone";
import { ErrorCode, useDropzone } from "react-dropzone";
import type {
  FieldPath,
  FieldPathByValue,
  FieldValues,
  UseFormTrigger,
} from "react-hook-form";
import { useController } from "react-hook-form";
import { formatBytes } from "~/format";
import { invariant } from "~/lib/invariant";
import type { TypedFields } from "~/types";
import type { BaseFieldProps } from "../types";
import type { FileTreeNode } from "./utils";
import { treeifySelectedFiles } from "./utils";
import { InvalidSelection } from "./validation";

const customProperties = {
  dashColor: "--dash-color",
  bgColor: "--bg-color",
} as const;

const classNames = {
  selectionArea: "selection-area",
  selection: "selection",
} as const;

// 8px-long colored dash followed by 8px-long transparent dash
const gradientStops = `var(${customProperties.dashColor}) 0px 8px, transparent 8px 16px`;

const Root = styled(Stack)(({ theme }) => ({
  width: "100%",
  paddingBlockStart: theme.spacing(4),
  justifyContent: "center",
  alignItems: "center",
  // Make space for the background image
  border: "1px transparent",
  background: [
    `repeating-linear-gradient(to top, ${gradientStops}) bottom left / 1px 100%`,
    `repeating-linear-gradient(to right, ${gradientStops}) top left / 100% 1px`,
    `repeating-linear-gradient(to bottom, ${gradientStops}) top right / 1px 100%`,
    `repeating-linear-gradient(to left, ${gradientStops}) bottom right / 100% 1px`,
  ].join(", "),
  backgroundRepeat: "no-repeat",
  // Trailing comma is intentional
  backgroundColor: `var(${customProperties.bgColor},)`,
  [`& .${classNames.selectionArea}`]: {
    width: "100%",
    marginBlockStart: theme.spacing(4),
    padding: theme.spacing(2),
    borderBlockStart: "1px transparent",
    background: `repeating-linear-gradient(to right, ${gradientStops}) top left / 100% 1px no-repeat`,
  },
  [`& .${classNames.selection}`]: {
    paddingBlock: theme.spacing(0.5),
    display: "grid",
    gridTemplateAreas: `
      "icon name    action"
      ".    content content"
    `,
    gridTemplateColumns: "auto auto minmax(0, 1fr)",
    columnGap: theme.spacing(1),
    alignItems: "center",
    [`& .${svgIconClasses.root}`]: {
      display: "block",
    },
  },
}));

interface BaseFileFieldProps<TFieldValues extends FieldValues> {
  trigger: UseFormTrigger<TFieldValues>;
  maxSize: number;
  onChange?: (
    newValue: unknown,
    source: { reason: "select" } | { reason: "remove"; file: File },
  ) => void;
  disabled?: boolean;
  renderFileContent?: (file: File) => React.ReactNode;
}

interface SingleFileFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, File | null>,
> extends Pick<BaseFieldProps<TFieldValues, TName>, "control" | "name">,
    BaseFileFieldProps<TFieldValues> {
  multiple?: false;
}

interface MultiFileFieldProps<
  TFieldValues extends FieldValues,
  TName extends TypedFields<TFieldValues, Array<File>> &
    FieldPath<TFieldValues>,
> extends Pick<BaseFieldProps<TFieldValues, TName>, "control" | "name">,
    BaseFileFieldProps<TFieldValues> {
  multiple: true;
}

export function FileField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, File | null>,
>(props: SingleFileFieldProps<TFieldValues, TName>): React.JSX.Element;
export function FileField<
  TFieldValues extends FieldValues,
  TName extends TypedFields<TFieldValues, Array<File>> &
    FieldPath<TFieldValues>,
>(props: MultiFileFieldProps<TFieldValues, TName>): React.JSX.Element;
export function FileField<TFieldValues extends FieldValues>({
  control,
  name,
  multiple = false,
  maxSize,
  trigger,
  onChange: onChangeProp,
  disabled = false,
  renderFileContent,
}:
  | SingleFileFieldProps<
      TFieldValues,
      FieldPathByValue<TFieldValues, File | null>
    >
  | MultiFileFieldProps<
      TFieldValues,
      TypedFields<TFieldValues, Array<File>> & FieldPath<TFieldValues>
    >) {
  const theme = useTheme();

  const { field, fieldState } = useController({ control, name });

  function handleChange(
    newValue: any,
    source: { reason: "select" } | { reason: "remove"; file: File },
  ): void {
    field.onChange(newValue);
    trigger(name);
    onChangeProp?.(newValue, source);
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple,
    maxSize,
    disabled,
    onDrop: createDropHandler(multiple, handleChange),
  });

  const selections = getSelections(multiple, field.value);

  return (
    <div>
      <Root
        {...getRootProps({
          style: {
            [customProperties.dashColor]:
              fieldState.error !== undefined
                ? theme.palette.error.main
                : theme.palette.text.primary,
            ...(isDragActive && {
              [customProperties.bgColor]: alpha(
                theme.palette.text.primary,
                0.05,
              ),
            }),
          },
        })}
      >
        <FolderOpen sx={{ fontSize: "5rem" }} />
        <span>Drag and drop a file</span>
        <Divider role="presentation" sx={{ width: 100, my: 1 }}>
          or
        </Divider>
        <Button
          type="button"
          aria-describedby="file-requirements"
          color="primary"
          variant="outlined"
          disabled={disabled}
          onClick={open}
        >
          Browse files
        </Button>
        <Typography id="file-requirements" sx={{ mt: 2 }}>
          Maximum file size: {formatBytes(maxSize)}
        </Typography>
        <input
          {...getInputProps({
            ...(multiple && { webkitdirectory: "" }),
          })}
        />
        <div className={classNames.selectionArea}>
          {selections.length === 0 ? (
            <Typography sx={{ fontStyle: "italic" }}>
              {multiple ? "No files selected" : "No file selected"}
            </Typography>
          ) : (
            <List disablePadding>
              {treeifySelectedFiles(selections).map((node) =>
                renderFileTreeNode(node, (selection) => {
                  const isFile = selection instanceof File;

                  const file = isFile ? selection : selection.file;

                  let error: boolean | string = false;
                  if (!isFile) {
                    if (
                      selection.errors.some(
                        (e) => e.code === ErrorCode.FileTooLarge,
                      )
                    ) {
                      error = `Cannot be larger than ${formatBytes(maxSize)}`;
                    } else if (
                      selection.errors.some(
                        (e) => e.code === ErrorCode.TooManyFiles,
                      )
                    ) {
                      error = true;
                    }
                  }

                  return (
                    <Selection
                      key={file.name}
                      icon={
                        error !== false ? (
                          <FileAlert fontSize="small" color="error" />
                        ) : (
                          <InsertDriveFile fontSize="small" />
                        )
                      }
                      name={
                        <>
                          {file.name} ({formatBytes(file.size)})
                        </>
                      }
                      action={
                        <Tooltip title="Remove file">
                          <span>
                            <IconButton
                              size="small"
                              color="error"
                              onClick={createRemoveSelectionHandler(
                                multiple,
                                handleChange,
                                selections,
                                selection,
                              )}
                              disabled={disabled}
                            >
                              <Close />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                      content={
                        typeof error === "string" ? (
                          <FormHelperText error>{error}</FormHelperText>
                        ) : error ? null : (
                          renderFileContent?.(file)
                        )
                      }
                    />
                  );
                }),
              )}
            </List>
          )}
        </div>
      </Root>
      <FormHelperText
        error={fieldState.error !== undefined}
        variant="outlined"
        sx={{ ml: 2 }}
      >
        {fieldState.error?.message ?? " "}
      </FormHelperText>
    </div>
  );
}

function createDropHandler(
  multiple: boolean,
  handleChange: (newValue: any, source: { reason: "select" }) => void,
) {
  const source = { reason: "select" as const };

  return function handleDrop(
    acceptedFiles: ReadonlyArray<File>,
    rejectedFiles: ReadonlyArray<FileRejection>,
  ): void {
    if (multiple) {
      if (rejectedFiles.length === 0) {
        handleChange(acceptedFiles, source);
      } else {
        handleChange(
          new InvalidSelection(acceptedFiles, rejectedFiles),
          source,
        );
      }
    } else {
      if (rejectedFiles.length === 0) {
        invariant(
          acceptedFiles.length === 1,
          "Expected a single file to be accepted",
        );

        handleChange(acceptedFiles[0], source);
      } else {
        handleChange(
          new InvalidSelection(
            [],
            [
              ...rejectedFiles,
              ...acceptedFiles.map(
                (file): FileRejection => ({
                  file,
                  errors: [
                    {
                      message: "Too many files",
                      code: ErrorCode.TooManyFiles,
                    },
                  ],
                }),
              ),
            ],
          ),
          source,
        );
      }
    }
  };
}

function getSelections(
  multiple: boolean,
  fieldValue: unknown,
): ReadonlyArray<File | FileRejection> {
  let selections: ReadonlyArray<File | FileRejection>;
  if (multiple) {
    invariant(
      Array.isArray(fieldValue) || fieldValue instanceof InvalidSelection,
      "Unexpected field value in multi-select mode",
    );

    selections = Array.isArray(fieldValue)
      ? fieldValue
      : [...fieldValue.accepted, ...fieldValue.rejected];
  } else {
    if (fieldValue == null) {
      selections = [];
    } else if (fieldValue instanceof File) {
      selections = [fieldValue];
    } else {
      invariant(
        fieldValue instanceof InvalidSelection,
        "Unexpected field value in single-select mode",
      );

      selections = [...fieldValue.accepted, ...fieldValue.rejected];
    }
  }

  return selections;
}

function createRemoveSelectionHandler(
  multiple: boolean,
  handleChange: (
    newValue: any,
    source: { reason: "remove"; file: File },
  ) => void,
  selections: ReadonlyArray<File | FileRejection>,
  selection: File | FileRejection,
) {
  const removalReason = {
    reason: "remove" as const,
    file: selection instanceof File ? selection : selection.file,
  };

  return function handleRemoveSelection(): void {
    const accepted = new Array<File>();
    const rejected = new Array<FileRejection>();
    for (const existingSelection of selections) {
      // `selection` _should_ be referentially equal to one element in
      // `selections` since it _should_ be passed directly to the outer function
      // while rendering `selections` elsewhere.
      if (existingSelection === selection) {
        // This is the removed selection.
        continue;
      }

      if (existingSelection instanceof File) {
        accepted.push(existingSelection);
      } else {
        rejected.push(existingSelection);
      }
    }

    if (multiple) {
      if (rejected.length === 0) {
        handleChange(accepted, removalReason);
      } else {
        handleChange(new InvalidSelection(accepted, rejected), removalReason);
      }
    } else {
      invariant(accepted.length === 0, "Expected no more files to be accepted");

      if (rejected.length === 0) {
        handleChange(null, removalReason);
      } else if (
        rejected.length === 1 &&
        rejected[0].errors.length === 1 &&
        rejected[0].errors[0].code === ErrorCode.TooManyFiles
      ) {
        // There's a single rejected file left and it's sole reason for being
        // rejected was that it was selected alongside several other files. Now
        // that it's the last remaining file with no other errors (such as being
        // too large), treat it as valid.
        handleChange(rejected[0].file, removalReason);
      } else {
        invariant(rejected.length >= 1, "Expected multiple rejected files");

        handleChange(new InvalidSelection([], rejected), removalReason);
      }
    }
  };
}

function renderFileTreeNode(
  node: FileTreeNode,
  renderSelection: (selection: File | FileRejection) => React.ReactNode,
): React.ReactNode {
  if (node.type === "directory") {
    return (
      <Selection
        key={node.name}
        icon={<Folder fontSize="small" />}
        name={node.name}
        content={
          <List disablePadding>
            {node.children.map((node) =>
              renderFileTreeNode(node, renderSelection),
            )}
          </List>
        }
      />
    );
  } else {
    return renderSelection(node.selection);
  }
}

function Selection({
  icon,
  name,
  action,
  content,
}: {
  icon: React.ReactNode;
  name: React.ReactNode;
  action?: React.ReactNode;
  content: React.ReactNode;
}) {
  return (
    <ListItem className={classNames.selection} disablePadding>
      <Box sx={{ gridArea: "icon" }}>{icon}</Box>
      <ListItemText sx={{ gridArea: "name" }}>{name}</ListItemText>
      <Box sx={{ gridArea: "action" }}>{action}</Box>
      <Box sx={{ gridArea: "content" }}>{content}</Box>
    </ListItem>
  );
}
