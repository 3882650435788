import { produce } from "immer";
import * as z from "zod";
import { StorageItem, useStorageValue } from "~/utils/storage";
import type { InferencePipelineTask } from "../types";

const modelsSchema = z.array(z.string());

const recentModelsSchema = z.object({
  "object-detection": modelsSchema,
  "image-segmentation": modelsSchema,
  "depth-estimation": modelsSchema,
}) satisfies z.ZodType<Record<InferencePipelineTask, ReadonlyArray<string>>>;

const recentModelsStorageItem = StorageItem.usingLocalStorage({
  key: "recent-models",
  version: 0,
  parser: recentModelsSchema,
  defaultValue: {
    "object-detection": [],
    "image-segmentation": [],
    "depth-estimation": [],
  },
});

export function useRecentModels(
  task: InferencePipelineTask | null,
): ReadonlyArray<string> | null {
  const allRecentModels = useStorageValue(recentModelsStorageItem);

  if (task === null) {
    return null;
  } else {
    return allRecentModels[task];
  }
}

export function recordRecentModel(
  task: InferencePipelineTask,
  model: string,
): void {
  const newValue = produce(recentModelsStorageItem.get(), (draft) => {
    if (draft[task].includes(model)) {
      // This model is already in the list. Remove it so it can be reinserted
      // at the front of the list below
      draft[task] = draft[task].filter(
        (existingModel) => existingModel !== model,
      );
    }

    // Insert at the front of the list since this is the most recent one
    draft[task].unshift(model);
    // Only save the 5 most recent models for this task
    draft[task] = draft[task].slice(0, 5);
  });

  recentModelsStorageItem.set(newValue);
}
