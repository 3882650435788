import React from "react";
import { Page } from "~/layout";

export function LqsPage({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return <Page title={title}>{children}</Page>;
}
