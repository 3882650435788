import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { listDigestionTopicsSchema } from "../schemas";
import { useDigestionTopicSearchRequest } from "./DigestionTopicTable";

export function DigestionTopicFilters() {
  const [request, setRequest] = useDigestionTopicSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="digestionTopic"
      request={request}
      setRequest={setRequest}
      schema={listDigestionTopicsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "startTimeGte", type: "timestamp" },
        { name: "startTimeLte", type: "timestamp" },
        { name: "startTimeNull", type: "boolean" },
        { name: "endTimeGte", type: "timestamp" },
        { name: "endTimeLte", type: "timestamp" },
        { name: "endTimeNull", type: "boolean" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
