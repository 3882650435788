import {
  NewLqsResourceForm,
  ProcessState,
  processStateOptions,
  useCreateIngestion,
} from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createIngestionSchema } from "../schemas";

export function IngestionCreateForm() {
  const lqsNavigator = useLqsNavigator({ toIngestionDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="ingestion"
      registryKey="ingestion"
      schema={createIngestionSchema}
      descriptors={[
        { name: "name", type: "text" },
        {
          name: "logId",
          type: "foreign-key",
          resourceType: "log",
          required: true,
        },
        {
          name: "objectStoreId",
          type: "foreign-key",
          resourceType: "object-store",
        },
        { name: "objectKey", type: "text" },
        {
          name: "state",
          type: "select",
          options: processStateOptions,
          defaultValue: ProcessState.Ready,
          required: true,
        },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowContext", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateIngestion()}
      createDetailsLocation={(response) =>
        lqsNavigator.toIngestionDetails({ ingestionId: response.data.id })
      }
    />
  );
}
