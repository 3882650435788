import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { Group, LqsColumn } from "~/lqs";
import { LqsResourceTable, useGroups } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { listGroupsSchema } from "../schemas";

function GroupNameCell({ group }: { group: Group }) {
  const lqsNavigator = useLqsNavigator({ toGroupDetails: true });

  return (
    <TableCell>
      {group.name}
      <Link
        component={RouterLink}
        to={lqsNavigator.toGroupDetails({ groupId: group.id })}
      >
        <Typography variant="body2">{group.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<"group">> = [
  {
    header: "Group",
    sortKey: "name",
    renderCell: (group) => <GroupNameCell group={group} />,
  },
  {
    accessor: "defaultWorkflowId",
    dataType: "foreign-key",
    resourceType: "workflow",
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...commonResourceColumns,
];

export const [useGroupSearchRequest, GroupSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listGroupsSchema,
  });

export function GroupTable() {
  const [request, setRequest] = useGroupSearchRequest();

  const lqsNavigator = useLqsNavigator({ toGroupCreate: true });

  const searchQuery = useGroups(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="group"
      registryKey="group"
      resourceCreateLocation={lqsNavigator.toGroupCreate()}
      getRowKey={(group) => group.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
