import React, { useId } from "react";
import { DarkMode, LightMode } from "@mui/icons-material";
import {
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { createSafeContext } from "~/contexts";
import { SidebarHeader } from "~/layout";
import { useSettings } from "./provider";

const [useGlobalSettingsControlsContext, GlobalSettingsControlsContext] =
  createSafeContext<React.ReactNode>("GlobalSettingsControls");

export { useGlobalSettingsControlsContext };

export function GlobalSettingsControlsProvider({
  controls,
  children,
}: {
  controls: React.ReactNode;
  children?: React.ReactNode;
}) {
  return (
    <GlobalSettingsControlsContext.Provider value={controls}>
      {children}
    </GlobalSettingsControlsContext.Provider>
  );
}

export function SettingsSidebar({ children }: { children?: React.ReactNode }) {
  const globalControls = useGlobalSettingsControlsContext();

  return (
    <>
      <SidebarHeader title="Settings" />
      <Stack spacing={2}>
        {globalControls}
        {children !== undefined && (
          <>
            <Divider />
            {children}
          </>
        )}
      </Stack>
    </>
  );
}

export function SettingControl<TValue extends string>({
  title,
  value,
  onChange,
  options,
}: {
  title: string;
  // By only allowing TS to infer `TValue` from the array of options, it
  // ensures options are passed for all possible types `value` would be expected
  // to take on. If `options` doesn't include a type that `value` does, then
  // `value` won't be assignable.
  value: NoInfer<TValue>;
  onChange: (value: NoInfer<TValue>) => void;
  options: ReadonlyArray<{
    value: TValue;
    label: string;
    icon?: React.JSX.Element;
  }>;
}) {
  const id = useId();

  function handleChange(_: unknown, newValue: TValue | null): void {
    if (newValue === null) {
      return;
    }

    onChange(newValue);
  }

  return (
    <div>
      <Typography variant="h6" component="p" id={id} gutterBottom>
        {title}
      </Typography>
      <ToggleButtonGroup
        sx={{
          "& .MuiSvgIcon-root": {
            mr: 1,
          },
        }}
        aria-labelledby={id}
        value={value}
        onChange={handleChange}
        exclusive
        color="primary"
        fullWidth
      >
        {options.map((option) => (
          <ToggleButton key={option.value} value={option.value}>
            {option.icon}
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
}

export function ColorSchemeControl() {
  const settings = useSettings();

  return (
    <SettingControl
      title="Color Scheme"
      value={settings.colorScheme}
      onChange={settings.setColorScheme}
      options={[
        { value: "light", label: "Light", icon: <LightMode /> },
        { value: "dark", label: "Dark", icon: <DarkMode /> },
      ]}
    />
  );
}

export function DateDisplayControl() {
  const settings = useSettings();

  return (
    <SettingControl
      title="Date Display"
      value={settings.dateDisplay}
      onChange={settings.setDateDisplay}
      options={[
        { value: "local", label: "Local" },
        { value: "utc", label: "UTC" },
      ]}
    />
  );
}

export function BytesFormatControl() {
  const settings = useSettings();

  return (
    <SettingControl
      title="Bytes Format"
      value={settings.bytesFormat}
      onChange={settings.setBytesFormat}
      options={[
        { value: "plain", label: "Plain" },
        { value: "compact", label: "Compact" },
      ]}
    />
  );
}
