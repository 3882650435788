import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  DataStore,
  DataStoreCreateRequest,
  DataStoreDataResponse,
  DataStoreListResponse,
  DataStoreUpdateRequest,
  ListDatastoresRequest,
} from "~/dsm";
import { useDsmMutation, useDsmQueryClient, useDsmQuery } from "~/dsm";
import { scheduleQueriesRemoval } from "~/utils";
import { useDsmContext } from "../provider";

export const dataStoreKeys = {
  all: ["datastores"] as const,
  lists: () => [...dataStoreKeys.all, "list"] as const,
  list: (request: ListDatastoresRequest) =>
    [...dataStoreKeys.lists(), request] as const,
  fetches: () => [...dataStoreKeys.all, "fetch"] as const,
  fetch: (dataStoreId: DataStore["id"]) =>
    [...dataStoreKeys.fetches(), dataStoreId] as const,
};

export function useDataStores<TData = DataStoreListResponse>(
  request: ListDatastoresRequest,
  options?: Pick<
    UseQueryOptions<DataStoreListResponse, unknown, TData>,
    "context" | "select" | "keepPreviousData" | "cacheTime"
  >,
): UseQueryResult<TData> {
  const { dataStoreApi } = useDsmContext();

  return useDsmQuery({
    queryKey: dataStoreKeys.list(request),
    queryFn({ signal }) {
      return dataStoreApi.listDatastores(request, { signal });
    },
    ...options,
  });
}

export function useDataStore<TData = DataStoreDataResponse>(
  dataStoreId: DataStore["id"],
  options?: Pick<
    UseQueryOptions<DataStoreDataResponse, unknown, TData>,
    "context" | "select"
  >,
): UseQueryResult<TData> {
  const { dataStoreApi } = useDsmContext();

  return useDsmQuery({
    queryKey: dataStoreKeys.fetch(dataStoreId),
    queryFn({ signal }) {
      return dataStoreApi.fetchDatastore(
        { datastoreId: dataStoreId },
        { signal },
      );
    },
    ...options,
  });
}

export function useCreateDataStore(): UseMutationResult<
  DataStoreDataResponse,
  unknown,
  DataStoreCreateRequest
> {
  const queryClient = useDsmQueryClient();

  const { dataStoreApi } = useDsmContext();

  return useDsmMutation({
    mutationFn(request) {
      return dataStoreApi.createDatastore({ dataStoreCreateRequest: request });
    },
    onSuccess(response) {
      queryClient.removeQueries({ queryKey: dataStoreKeys.lists() });
      queryClient.setQueryData<DataStoreDataResponse>(
        dataStoreKeys.fetch(response.data.id),
        response,
      );
    },
  });
}

export function useUpdateDataStore(
  dataStoreId: DataStore["id"],
): UseMutationResult<DataStoreDataResponse, unknown, DataStoreUpdateRequest> {
  const queryClient = useDsmQueryClient();

  const { dataStoreApi } = useDsmContext();

  return useDsmMutation({
    mutationFn(request) {
      return dataStoreApi.updateDatastore({
        datastoreId: dataStoreId,
        dataStoreUpdateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.removeQueries({ queryKey: dataStoreKeys.lists() });
      queryClient.setQueryData<DataStoreDataResponse>(
        dataStoreKeys.fetch(response.data.id),
        response,
      );
    },
  });
}

export function useDeleteDataStore(
  dataStoreId: DataStore["id"],
): UseMutationResult<void, unknown, void> {
  const queryClient = useDsmQueryClient();

  const { dataStoreApi } = useDsmContext();

  return useDsmMutation({
    mutationFn() {
      return dataStoreApi.deleteDatastore({ datastoreId: dataStoreId });
    },
    onSuccess() {
      queryClient.removeQueries({ queryKey: dataStoreKeys.lists() });
      scheduleQueriesRemoval(() =>
        queryClient.removeQueries({
          queryKey: dataStoreKeys.fetch(dataStoreId),
        }),
      );
    },
  });
}
