import type { PaginationProps } from "@mui/material";
import { Pagination as MuiPagination, PaginationItem } from "@mui/material";

export function Pagination({
  count,
  limit,
  offset,
  onChange,
  disableJumping = false,
  size,
}: {
  /**
   * The total number of items being paginated
   */
  count: number;
  limit: number;
  offset: number;
  onChange: (offset: number) => void;
  disableJumping?: boolean;
  size?: PaginationProps["size"];
}) {
  const disabled = count < limit;

  const pageCount = Math.ceil(count / limit);

  // MUI's pages are 1-indexed
  const currentPage = offset / limit + 1;

  function handlePageChange(_: unknown, newPage: number) {
    const newOffset = (newPage - 1) * limit;

    onChange(newOffset);
  }

  return (
    <MuiPagination
      size={size}
      disabled={disabled}
      count={pageCount}
      page={currentPage}
      onChange={handlePageChange}
      renderItem={(item) => {
        if (disableJumping && !["previous", "next"].includes(item.type)) {
          return null;
        }

        return <PaginationItem {...item} />;
      }}
    />
  );
}
