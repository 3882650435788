import { Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";
import { useLqsNavigator } from "~/paths";

export function DataStoreBreadcrumbs() {
  const { pathname } = useLocation();

  const lqsNavigator = useLqsNavigator();

  const match = matchPath(
    { path: lqsNavigator.DATASTORE_DASHBOARD, end: false },
    pathname,
  );

  if (match === null) {
    return null;
  }

  const { pathname: base } = match;

  return (
    <Breadcrumbs sx={{ mb: 2 }}>
      {pathname
        // Strip off the base pathname segments that only apply to the DSM
        // part of Studio
        .replace(base, "")
        .split("/")
        .filter((segment) => segment !== "")
        .map((segment, index, segments) => {
          if (index === segments.length - 1) {
            return (
              <Typography key={segment}>{formatSegment(segment)}</Typography>
            );
          } else {
            // Build a relative link for navigating upwards through the path.
            // The number of upwards segments corresponds to how many segments
            // above the current page this segment is. For example, the current
            // page's parent would be ".." while the segment above that would
            // be "../..";
            const to = new Array(segments.length - index - 1)
              .fill("..")
              .join("/");

            return (
              <Link
                key={segment}
                component={RouterLink}
                to={to}
                relative="path"
              >
                {formatSegment(segment)}
              </Link>
            );
          }
        })}
    </Breadcrumbs>
  );
}

/**
 * Formats a breadcrumb segment for displaying to the user. It attempts to
 * decode the segment as a URI component but falls back to displaying the
 * raw, encoded value if decoding throws.
 */
function formatSegment(segment: string): string {
  try {
    return decodeURIComponent(segment);
  } catch {
    return segment;
  }
}
