import React from "react";
import { useImmerReducer } from "use-immer";
import { createSafeContext } from "~/contexts";
import type { LayoutNode } from "./api";
import type {
  PanelInitializationStateOverrider,
  PanelLayoutAction,
} from "./reducer";
import { createReducer, emptyLayout } from "./reducer";

export interface PanelLayoutContextValue {
  layout: LayoutNode;
  dispatch: React.Dispatch<PanelLayoutAction>;
}

export const [usePanelLayoutContext, PanelLayoutContext] =
  createSafeContext<PanelLayoutContextValue>("PanelLayout");

export interface PanelLayoutProviderProps {
  /**
   * The initial layout to use. Can be loaded asynchronously but will be
   * ignored if actions have been dispatched by the time it loads.
   */
  initialLayout?: LayoutNode;
  overrideInitializationState?: PanelInitializationStateOverrider;
  children: React.ReactNode;
}

export function PanelLayoutProvider({
  initialLayout = emptyLayout,
  overrideInitializationState,
  children,
}: PanelLayoutProviderProps) {
  // Until the first action is dispatched, the reducer's state will be `null`.
  // This is a way to signify the user hasn't modified the layout in any way
  // yet so the `initialLayout`, if not synchronously available, still has
  // time to load. Once an action is dispatched, the `initialLayout` will be
  // ignored.
  //
  // The reason for this pattern is to support default log layouts: the name
  // of a log's default layout, if any, is stored in the log's `context`, so
  // until the log is fetched we're in a state of limbo where something needs
  // to be passed through context even though it might be replaced with the
  // real layout once the log loads. Consumers shouldn't be reading the layout
  // nodes until the player log is loaded so the intermediate empty layout
  // context value shouldn't be observed and, consequently, no actions should
  // be dispatched.
  //
  // Additionally, this pattern also ensures the user can change logs without
  // the new log's default layout overwriting their current layout.
  //
  // TODO: If the initial layout changes but the user hasn't dispatched any
  //       actions yet, the original initial layout will be replaced with the
  //       new one. Probably not what we want here.
  const [{ state: layoutState }, dispatch] = useImmerReducer(
    createReducer(initialLayout, overrideInitializationState),
    { state: null },
  );

  const value: PanelLayoutContextValue = {
    layout: layoutState?.root ?? initialLayout,
    dispatch,
  };

  return (
    <PanelLayoutContext.Provider value={value}>
      {children}
    </PanelLayoutContext.Provider>
  );
}
