import { PromisePool } from "@supercharge/promise-pool";
import prettyBytes from "pretty-bytes";
import { clamp, range } from "~/lib/std";

export interface MultipartUploadParams {
  blob: Blob;
  createPartPresignedUrl: (
    partInfo: {
      partNumber: number;
      size: number;
    },
    signal: AbortSignal,
  ) => Promise<string>;
  onUpdate?: (progress: number) => void;
  onAbort?: () => Promise<void>;
}

export class MultipartUploader {
  // 150 GB
  static readonly MAX_FILE_SIZE_BYTES = 150 * 1e9;
  // 150 MB
  static readonly #PART_SIZE_BYTES = 150 * 1e6;

  readonly blob: Blob;

  readonly #createPartPresignedUrl: MultipartUploadParams["createPartPresignedUrl"];
  readonly #onUpdate: MultipartUploadParams["onUpdate"];
  readonly #onAbort: MultipartUploadParams["onAbort"];
  readonly #abortController: AbortController = new AbortController();

  constructor({
    blob,
    createPartPresignedUrl,
    onUpdate,
    onAbort,
  }: MultipartUploadParams) {
    if (blob.size > MultipartUploader.MAX_FILE_SIZE_BYTES) {
      const maxSizePretty = prettyBytes(MultipartUploader.MAX_FILE_SIZE_BYTES);
      const givenSizePretty = prettyBytes(blob.size);

      throw new Error(
        `File is too large. Maximum size: ${maxSizePretty}. Given size: ${givenSizePretty}`,
      );
    }

    this.blob = blob;

    this.#createPartPresignedUrl = createPartPresignedUrl;
    this.#onUpdate = onUpdate;
    this.#onAbort = onAbort;
  }

  #dispatchUpdate(progress: number) {
    if (this.#getSignal().aborted) {
      // Possible upload was cancelled after request was made but prior
      // to event being dispatched. In that case, just ignore event
      // since it's moot
      return;
    }

    this.#onUpdate?.(progress);
  }

  #getSignal() {
    return this.#abortController.signal;
  }

  async #abortRequests() {
    this.#abortController.abort();

    try {
      await this.#onAbort?.();
    } catch {
      // noop
    }
  }

  async #uploadPart(partIndex: number) {
    const offset = partIndex * MultipartUploader.#PART_SIZE_BYTES;
    const part = this.blob.slice(
      offset,
      offset + MultipartUploader.#PART_SIZE_BYTES,
      this.blob.type,
    );

    // Part numbers are 1-indexed
    const partNumber = partIndex + 1;

    const presignedUrl = await this.#createPartPresignedUrl(
      { partNumber, size: part.size },
      this.#getSignal(),
    );

    const partUploadResponse = await fetch(presignedUrl, {
      method: "PUT",
      body: part,
      signal: this.#getSignal(),
    });

    if (!partUploadResponse.ok) {
      throw partUploadResponse;
    }
  }

  async #uploadParts() {
    // Ensure there's a minimum of 1 part so 0-byte blobs still get uploaded
    const numParts = Math.max(
      Math.ceil(this.blob.size / MultipartUploader.#PART_SIZE_BYTES),
      1,
    );

    const partIndices = range(numParts);

    await PromisePool.for(partIndices)
      .withConcurrency(10)
      .onTaskFinished((_, pool) => {
        this.#dispatchUpdate(clamp(pool.processedPercentage() / 100, 0, 1));
      })
      .handleError(async (error) => {
        // To save the user's bandwidth, immediately abort any ongoing uploads
        // if any part upload fails
        await this.#abortRequests();

        // Will cause the `await PromisePool` statement above to throw
        throw error;
      })
      .process(this.#uploadPart.bind(this));
  }

  async start() {
    await this.#uploadParts();
  }
}
