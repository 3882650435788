import { Lock, LockOpen } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Skeleton, Typography } from "@mui/material";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type { Ingestion } from "~/lqs";
import { Card } from "../Card";
import { QueryRenderer } from "../QueryRenderer";

// Ingestion type chosen arbitrarily. The picked fields are common to all
// lockable resources
export type Lockable = Pick<
  Ingestion,
  "locked" | "lockedBy" | "lockedAt" | "lockToken"
>;

export interface LockCardProps {
  resourceName: string;
  query: UseQueryResult<Lockable>;
  updateMutation: UseMutationResult<unknown, unknown, { locked?: boolean }>;
}

export function LockCard({
  resourceName,
  query,
  updateMutation,
}: LockCardProps) {
  const { enqueueSnackbar } = useSnackbar();

  function renderIcon() {
    if (!query.isSuccess) {
      return null;
    }

    const Icon = query.data.locked ? Lock : LockOpen;

    return <Icon sx={{ fontSize: "2rem" }} />;
  }

  return (
    <Card title="Lock" action={renderIcon()}>
      <QueryRenderer
        query={query}
        loading={
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" width={100} sx={{ mb: 2 }} />
            <Skeleton variant="rounded" width={68} height={36} />
          </>
        }
        success={(resource) => {
          function handleClick() {
            updateMutation.mutate(
              { locked: !resource.locked },
              {
                onError() {
                  enqueueSnackbar(
                    `Unable to ${
                      resource.locked ? "unlock" : "lock"
                    } ${resourceName}`,
                    { variant: "error" },
                  );
                },
              },
            );
          }

          return (
            <>
              {resource.locked ? (
                <Typography paragraph>
                  This {resourceName} is locked. It cannot be updated through
                  Studio while locked.
                </Typography>
              ) : (
                <Typography paragraph>
                  This {resourceName} is unlocked. Lock it to prevent accidental
                  updates.
                </Typography>
              )}
              <LoadingButton
                variant="contained"
                disableElevation
                onClick={handleClick}
                loading={updateMutation.isLoading}
              >
                {resource.locked ? "Unlock" : "Lock"}
              </LoadingButton>
            </>
          );
        }}
      />
    </Card>
  );
}
