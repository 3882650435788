import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout } from "~/components/DetailsCards";
import type { Role } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteRole,
  useRole,
  useUpdateRole,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editRoleSchema } from "../schemas";

export function RoleDetails() {
  const { roleId } = useTypedParams(lqsRoutePaths.ROLE_DETAILS);

  const lqsNavigator = useLqsNavigator({ toRoleEdit: true, toRoleTable: true });

  const query = useRole(roleId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="role"
      query={query}
      editLocation={lqsNavigator.toRoleEdit({ roleId })}
      deleteMutation={useDeleteRole(roleId)}
      getReadableName={(role) => role.name}
      listLocation={lqsNavigator.toRoleTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Role> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="role"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "name" },
          { dataType: "boolean", accessor: "managed" },
          { dataType: "json", accessor: "policy" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Role> }) {
  const { roleId } = useTypedParams(lqsRoutePaths.ROLE_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="role"
      registryKey="role"
      query={query}
      schema={editRoleSchema}
      descriptors={[
        { name: "_default", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateRole(roleId)}
    />
  );
}
