import React from "react";
import { DoNotDisturb } from "@mui/icons-material";
import { Loading } from "~/components/Loading";
import { ErrorMessage } from "~/components/error-message";
import { find } from "~/lib/std";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils";
import { PanelLayout, TopicName } from "../components";
import { usePanelContext, VisualizationType } from "../panels";
import { usePlaybackSource } from "../playback";
import { ChartVisualization } from "./chart-visualization";
import { ImageVisualization } from "./image-visualization";
import { MapVisualization } from "./map-visualization";
import { ThreeDVisualization } from "./three-d-visualization";
import { TimelineVisualization } from "./timeline-visualization";
import { TopicSelector } from "./topic-selector";

export function VisualizationSwitch({
  topics,
}: {
  topics: ReadonlyArray<Topic>;
}) {
  const playbackSource = usePlaybackSource();

  const panel = usePanelContext();

  if (playbackSource.isLoading) {
    // Visualizations expect playback source to be loaded when they're rendered
    return <Loading type="circular" />;
  } else if (!panel.isInitialized) {
    return <TopicSelector panel={panel} topics={topics} />;
  } else {
    const topic = find(topics, { name: panel.topicName });

    if (topic === undefined) {
      return (
        <PanelLayout>
          <ErrorMessage icon={<DoNotDisturb fontSize="large" color="error" />}>
            <TopicName monospace>{panel.topicName}</TopicName> is not in this
            log
          </ErrorMessage>
        </PanelLayout>
      );
    } else {
      switch (panel.visualization) {
        case VisualizationType.Timeline: {
          return <TimelineVisualization panel={panel} topic={topic} />;
        }
        case VisualizationType.Chart: {
          return <ChartVisualization panel={panel} topic={topic} />;
        }
        case VisualizationType.Image: {
          return (
            <ImageVisualization
              panel={panel}
              topic={topic}
              playerTopics={topics}
            />
          );
        }
        case VisualizationType.Map: {
          return <MapVisualization panel={panel} topic={topic} />;
        }
        case VisualizationType.ThreeD: {
          return <ThreeDVisualization panel={panel} topic={topic} />;
        }
        default: {
          assertNever(panel.visualization);
        }
      }
    }
  }
}
