import type React from "react";

export function DeprecableLabel({
  deprecated = false,
  children,
}: {
  deprecated: boolean | undefined;
  children: React.ReactNode;
}) {
  if (!deprecated) {
    return children;
  }

  return <s title="Deprecated">{children}</s>;
}
