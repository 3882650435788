import type { Option } from "~/components/Form";
import { filterEnum, optionalEnum, requiredEnum } from "~/domain/common";
import { ProcessState } from "../sdk";

export const processStateOptions = Object.values(ProcessState).map(
  (value): Option => ({
    label: value,
    value,
  }),
);

export const requiredProcessState = requiredEnum(ProcessState);

export const optionalProcessState = optionalEnum(ProcessState);

export const filterProcessState = filterEnum(ProcessState);
