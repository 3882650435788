import { ErrorOutline } from "@mui/icons-material";
import {
  CircularProgress,
  LinearProgress,
  Skeleton,
  Step,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from "@mui/material";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { Details } from "~/components/Details";
import { JsonField } from "~/components/DetailsCards/JsonField";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { Datetime, formatPercent } from "~/format";
import { ProcessState } from "../sdk";
import { LqsVersionSwitch } from "../versioning";
import { ProcessActionButton } from "./process-action-button";
import type { ProcessResource } from "./types";
import { processResourceVersionHistories } from "./versioning";

const STATE_TRANSITION_ORDER: ReadonlyArray<ProcessState> = [
  ProcessState.Ready,
  ProcessState.Queued,
  ProcessState.Processing,
  ProcessState.Finalizing,
  ProcessState.Completed,
];

// States from which the resource isn't expected to transition out of without
// user interaction. Resources in these states don't need to be automatically
// refetched on an interval.
const STATIC_STATES = new Set<ProcessState>([
  ProcessState.Ready,
  ProcessState.Completed,
  ProcessState.Failed,
  ProcessState.Cancelled,
  ProcessState.Archived,
]);

function checkIsProcessStatic(resource: ProcessResource): boolean {
  return STATIC_STATES.has(resource.state);
}

export function checkIsProcessDynamic(resource: ProcessResource): boolean {
  return !checkIsProcessStatic(resource);
}

const ErrorContent = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateAreas: `
    "icon message"
    "icon error"
  `,
  columnGap: theme.spacing(2),
  gridTemplateColumns: "auto minmax(0, 1fr)",
}));

export function Process({
  query,
  mutation,
}: {
  query: UseQueryResult<ProcessResource>;
  mutation: UseMutationResult<unknown, unknown, { state?: ProcessState }>;
}) {
  return (
    <Card
      title="Process Status"
      action={<ProcessActionButton query={query} mutation={mutation} />}
      error={query.data?.state === ProcessState.Failed}
    >
      <QueryRenderer
        query={query}
        loading={
          <>
            <Skeleton variant="text" sx={{ maxWidth: 400, mb: 2 }} />
            <Skeleton variant="rounded" height={24} />
            <Skeleton variant="text" sx={{ maxWidth: 500, mt: 4 }} />
          </>
        }
        error={<ErrorMessage>Failed to load the process status</ErrorMessage>}
        success={(resource) => {
          const transitionIndex = STATE_TRANSITION_ORDER.indexOf(
            resource.state,
          );

          return (
            <>
              {resource.transitionedAt !== null && (
                <Typography paragraph>
                  Last state transition:{" "}
                  <Datetime date={resource.transitionedAt} />
                </Typography>
              )}
              <Stepper
                activeStep={transitionIndex}
                sx={{
                  mb: 4,
                  overflowX: "auto",
                  [`& .${stepConnectorClasses.root}`]: {
                    minWidth: 40,
                  },
                }}
              >
                <Step>
                  <StepLabel>{ProcessState.Ready}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{ProcessState.Queued}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{ProcessState.Processing}</StepLabel>
                </Step>
                <Step>
                  <StepLabel>{ProcessState.Finalizing}</StepLabel>
                </Step>
                <Step completed={resource.state === ProcessState.Completed}>
                  <StepLabel>{ProcessState.Completed}</StepLabel>
                </Step>
              </Stepper>
              {resource.state === ProcessState.Ready && (
                <Typography>
                  To begin processing, select{" "}
                  <kbd style={{ fontWeight: "bold" }}>
                    <samp>Queue</samp>
                  </kbd>
                  .
                </Typography>
              )}
              {(resource.state === ProcessState.Processing ||
                resource.state === ProcessState.Finalizing) && (
                <>
                  <Typography paragraph>
                    Progress: {formatPercent(resource.progress)}
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={(resource.progress ?? 0) * 100}
                  />
                </>
              )}
              {resource.state === ProcessState.Failed && (
                <ErrorContent>
                  <ErrorOutline
                    sx={{
                      gridArea: "icon",
                      fontSize: "3rem",
                    }}
                    color="error"
                  />
                  <Typography paragraph sx={{ gridArea: "message" }}>
                    Processing failed. To try again, select{" "}
                    <kbd style={{ fontWeight: "bold" }}>
                      <samp>Retry</samp>
                    </kbd>
                    .
                  </Typography>
                  <Details sx={{ gridArea: "error" }}>
                    <Details.Summary>Expand to see error</Details.Summary>
                    <LqsVersionSwitch>
                      {[
                        [
                          processResourceVersionHistories.model.error,
                          () => <JsonField value={resource.error} />,
                        ],
                        [
                          processResourceVersionHistories.model.errorPayload,
                          () => <JsonField value={resource.errorPayload} />,
                        ],
                      ]}
                    </LqsVersionSwitch>
                  </Details>
                </ErrorContent>
              )}
              {!checkIsProcessStatic(resource) && (
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mt: 4 }}
                >
                  Process will automatically refresh until completed. Last
                  refreshed <Datetime date={new Date(query.dataUpdatedAt)} />
                  {query.isRefetching && (
                    <CircularProgress sx={{ ml: 0.5 }} size="1em" />
                  )}
                </Typography>
              )}
            </>
          );
        }}
      />
    </Card>
  );
}

const REFETCH_INTERVAL_MS = 15_000; // 15 seconds

export function calculateProcessRefetchInterval(
  resource: ProcessResource | undefined,
): number | false {
  if (resource === undefined) {
    return false;
  }

  if (checkIsProcessStatic(resource)) {
    return false;
  }

  return REFETCH_INTERVAL_MS;
}
