import React from "react";
import { useBlobSource } from "~/hooks";
import type { InitializedPanelNode } from "../../panels";
import { ClipInsetSide } from "../../panels";
import { createColorMapFilterId } from "../../visualizations/image-visualization/color-map";
import { getTransformProps } from "../../visualizations/image-visualization/utils";
import type { InferenceDataForType } from "../types";

export type ImageInferenceResults = Blob;

export function ImageResultsVisualization({
  panel,
  results,
}: {
  panel: InitializedPanelNode;
  results: InferenceDataForType<"depth-estimation">;
}) {
  const {
    inferenceRotationDeg: rotationDeg,
    inferenceFlipDirection: flipDirection,
    colorizeInferenceImage: colorize,
    inferenceImageOpacity: opacity,
    inferenceImageClipInset: clipInset,
  } = panel;

  const imgSrcRef = useBlobSource(results);

  const insetValues = [
    // Matches the order of the positional arguments passed to "inset()" below
    ClipInsetSide.Top,
    ClipInsetSide.Right,
    ClipInsetSide.Bottom,
    ClipInsetSide.Left,
  ]
    .map((side) =>
      clipInset.side === side ? `${clipInset.percent * 100}%` : "0px",
    )
    .join(" ");

  return (
    <img
      // Depth-estimation images can never be dragged since the clip inset
      // listeners are always enabled when one is shown
      draggable="false"
      ref={imgSrcRef}
      {...getTransformProps({
        rotationDeg,
        flipDirection,
        filter: colorize && `url(#${createColorMapFilterId(panel.id)})`,
        style: {
          opacity,
          clipPath: `inset(${insetValues})`,
        },
      })}
    />
  );
}
