import type React from "react";
import { useState } from "react";

export interface PointerLocation {
  /**
   * X coordinate of the pointer relative to top left of the container
   */
  x: number;
  /**
   * `clientX` property of the underlying pointer event
   */
  clientX: number;
  /**
   * Y coordinate of the pointer relative to top left of the container
   */
  y: number;
  /**
   * `clientY` property of the underlying pointer event
   */
  clientY: number;
  /**
   * Width of the container
   */
  width: number;
  /**
   * Height of the container
   */
  height: number;
}

export interface PointerContainerProps
  extends Pick<
    React.HTMLAttributes<HTMLElement | SVGElement>,
    | "onPointerOver"
    | "onPointerDown"
    | "onPointerMove"
    | "onPointerOut"
    | "onPointerUp"
    | "onPointerCancel"
  > {}

export function usePointerLocation({
  trigger = "pointerdown",
  enabled = true,
  onInteractionStart,
  onChange,
  onInteractionEnd,
}: {
  trigger?: "pointerdown" | "pointerover";
  enabled?: boolean;
  onInteractionStart?: (e: React.PointerEvent) => boolean | void | undefined;
  onChange: (pointerLocation: PointerLocation) => void;
  onInteractionEnd?: (pointerLocation: PointerLocation) => void;
}): PointerContainerProps {
  const [interactionPointerId, setInteractionPointerId] = useState<
    number | null
  >(null);

  if (!enabled) {
    return {};
  }

  const triggerEvents = {
    pointerdown: {
      start: "onPointerDown" as const,
      end: "onPointerUp" as const,
    },
    pointerover: {
      start: "onPointerOver" as const,
      end: "onPointerOut" as const,
    },
  }[trigger];

  return {
    ...(enabled && {
      [triggerEvents.start](e: React.PointerEvent<HTMLElement | SVGElement>) {
        if (interactionPointerId !== null) {
          // Already have an ongoing interaction
          return;
        }

        const { currentTarget: container, isPrimary, pointerId } = e;

        if (!isPrimary) {
          // Only track interactions with primary pointer
          return;
        }

        if (onInteractionStart?.(e) === false) {
          // Handler indicates this event should not be allowed to trigger the
          // start of a pointer-tracking interaction
          return;
        }

        if (triggerEvents.start === "onPointerDown") {
          // Prevent drag and drops or text selection
          e.preventDefault();
        }

        // User could move the cursor outside the container during the interaction
        // so ensure pointer capture remains on the container
        container.setPointerCapture(pointerId);
        setInteractionPointerId(pointerId);

        onChange(calculatePointerLocation(e));
      },
      // If there's no ongoing interaction, don't attach the following event
      // handlers, especially `onPointerMove` which would fire almost constantly
      ...(interactionPointerId !== null && {
        onPointerMove(e) {
          if (e.pointerId !== interactionPointerId) {
            // Only handle events from the pointer which started this interaction
            return;
          }

          // Prevent text inside nodes being highlighted during resize
          e.preventDefault();

          onChange(calculatePointerLocation(e));
        },
        [triggerEvents.end](e: React.PointerEvent<HTMLElement | SVGElement>) {
          if (e.pointerId !== interactionPointerId) {
            // Only handle events from the pointer which started this interaction
            return;
          }

          // Interaction is finished
          setInteractionPointerId(null);

          onInteractionEnd?.(calculatePointerLocation(e));
        },
        onPointerCancel(e) {
          if (e.pointerId !== interactionPointerId) {
            // Only handle events from the pointer which started this interaction
            return;
          }

          // Interaction is finished
          setInteractionPointerId(null);

          onInteractionEnd?.(calculatePointerLocation(e));
        },
      }),
    }),
  };
}

function calculatePointerLocation(
  e: React.PointerEvent<HTMLElement | SVGElement>,
): PointerLocation {
  const { currentTarget: container, clientX, clientY } = e;

  const boundingRect = container.getBoundingClientRect();

  return {
    x: clientX - boundingRect.x,
    clientX,
    y: clientY - boundingRect.y,
    clientY,
    width: boundingRect.width,
    height: boundingRect.height,
  };
}
