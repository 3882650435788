import React from "react";
import { TablePage } from "~/components/Table";
import { TopicFilters } from "./components/TopicFilters";
import {
  TopicSearchRequestProvider,
  TopicTable,
} from "./components/TopicTable";

export function TopicsPage() {
  return (
    <TablePage
      title="Search Topics"
      RequestProvider={TopicSearchRequestProvider}
      table={<TopicTable />}
      filters={<TopicFilters />}
    />
  );
}
