import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import { lqsRoutePaths, useTypedParams } from "~/paths";
import {
  LogObjectFilters,
  LogObjectSearchRequestProvider,
  LogObjectTable,
} from "./components/LogObjectTable";

export function LogObjectsPage() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_OBJECT_TABLE);

  return (
    <TablePage
      title="Search Objects"
      RequestProvider={LogObjectSearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <LogObjectTable logId={logId} />
        </>
      }
      filters={<LogObjectFilters />}
    />
  );
}
