import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type { Path } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { capitalize, startCase } from "~/lib/std";

export function DeleteDialog({
  open,
  onClose,
  resourceName,
  mutation,
  onDeleted,
  children,
}: {
  open: boolean;
  onClose: VoidFunction;
  resourceName: string;
  mutation: UseMutationResult<void, unknown, void>;
  onDeleted: VoidFunction;
  children: React.ReactNode;
}) {
  const { enqueueSnackbar } = useSnackbar();

  function handleClose() {
    if (mutation.isLoading) {
      return;
    }

    onClose();
  }

  function handleDelete() {
    mutation.mutate(undefined, {
      onSuccess() {
        enqueueSnackbar(`${capitalize(resourceName)} deleted`, {
          variant: "success",
        });

        onDeleted();
        onClose();
      },
      onError() {
        enqueueSnackbar(`Could not delete ${resourceName}`, {
          variant: "error",
        });
      },
    });
  }

  return (
    <Dialog maxWidth="xs" fullWidth onClose={handleClose} open={open}>
      <DialogTitle>Delete {startCase(resourceName)}?</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="secondary"
          disabled={mutation.isLoading}
          onClick={onClose}
        >
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={mutation.isLoading}
          onClick={handleDelete}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export function useDeleteResourceDialog({
  query,
  listLocation,
}: {
  query: UseQueryResult;
  listLocation: Partial<Path>;
}) {
  const navigate = useNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return {
    open: showDeleteDialog && !query.isLoading,
    handleOpen() {
      setShowDeleteDialog(true);
    },
    handleClose() {
      setShowDeleteDialog(false);
    },
    handleDeleted() {
      navigate(listLocation, { replace: true });
    },
  };
}
