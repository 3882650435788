import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import type {
  QueryClient,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
  UseMutationResult,
} from "@tanstack/react-query";
import type { StrictExclude, StrictOmit } from "ts-essentials";
import { DsmQueryClientContext } from "../provider";

export type DsmQueryOptions<
  TQueryFnData,
  TAdditionalKeys extends StrictExclude<
    keyof UseQueryOptions<TQueryFnData>,
    "queryKey" | "queryFn" | "context"
  > = never,
> = Required<
  Pick<
    UseQueryOptions<TQueryFnData>,
    "queryKey" | "queryFn" | "context" | TAdditionalKeys
  >
>;

export type DsmMutationOptions<
  TData,
  TVariables,
  TAdditionalKeys extends StrictExclude<
    keyof UseMutationOptions<TData, unknown, TVariables>,
    "mutationFn" | "context"
  > = never,
> = Required<
  Pick<
    UseMutationOptions<TData, unknown, TVariables>,
    "mutationFn" | "context" | TAdditionalKeys
  >
>;

export function useDsmQueryClient(): QueryClient {
  return useQueryClient({ context: DsmQueryClientContext });
}

export function useDsmQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryOptions: StrictOmit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    "context"
  >,
): UseQueryResult<TData, TError> {
  return useQuery({
    ...queryOptions,
    context: DsmQueryClientContext,
  });
}

export function useDsmMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  mutationOptions: StrictOmit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    "context"
  >,
): UseMutationResult<TData, TError, TVariables, TContext> {
  return useMutation({
    ...mutationOptions,
    context: DsmQueryClientContext,
  });
}
