import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  SortDirection,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, Record } from "~/lqs";
import { LqsResourceTable, useRecords } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { listRecordsSchema } from "../schemas";

function RecordTimestampCell({ record }: { record: Record }) {
  const lqsNavigator = useLqsNavigator({ toRecordDetails: true });

  return (
    <TableCell>
      <Link
        component={RouterLink}
        to={lqsNavigator.toRecordDetails({
          topicId: record.topicId,
          timestamp: record.timestamp,
        })}
      >
        <Typography variant="body2">{String(record.timestamp)}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<"record">> = [
  {
    header: "Timestamp",
    sortKey: "timestamp",
    renderCell: (record) => <RecordTimestampCell record={record} />,
  },
  {
    accessor: "topicId",
    dataType: "foreign-key",
    resourceType: "topic",
    defaultHidden: true,
  },
  {
    accessor: "logId",
    dataType: "foreign-key",
    resourceType: "log",
    defaultHidden: true,
  },
  {
    accessor: "ingestionId",
    dataType: "foreign-key",
    resourceType: "ingestion",
  },
  {
    accessor: "dataOffset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "dataLength",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "chunkCompression",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "chunkOffset",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "chunkLength",
    dataType: "bytes",
    isSortable: true,
  },
  {
    accessor: "locked",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "lockedBy",
    dataType: "foreign-key",
    resourceType: "user",
    defaultHidden: true,
  },
  {
    accessor: "lockedAt",
    dataType: "datetime",
    isSortable: true,
    defaultHidden: true,
  },
  ...commonResourceColumns,
];

export const [useRecordSearchRequest, RecordSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listRecordsSchema,
    defaultOrder: "timestamp",
    defaultSortDirection: SortDirection.Asc,
  });

export function RecordTable() {
  const { topicId } = useTypedParams(lqsRoutePaths.RECORD_TABLE);

  const lqsNavigator = useLqsNavigator({ toRecordCreate: true });

  const [request, setRequest] = useRecordSearchRequest();

  const searchQuery = useRecords(topicId, preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="record"
      registryKey="record"
      resourceCreateLocation={lqsNavigator.toRecordCreate({ topicId })}
      getRowKey={(record) => String(record.timestamp)}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
