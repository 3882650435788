import { CircularProgress, Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import {
  DetailsLayout,
  LockCard,
  RelatedResource,
} from "~/components/DetailsCards";
import {
  OBJECT_KEY_DELIMITER,
  ObjectSearchRequestProvider,
} from "~/components/ObjectExplorer";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import type { Ingestion } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteIngestion,
  useIngestion,
  useUpdateIngestion,
} from "~/lqs";
import { calculateProcessRefetchInterval, Process } from "~/lqs/process";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { LogObjectTable } from "../../logs/components/LogObjectTable";
import { editIngestionSchema } from "../schemas";
import {
  IngestionPartSearchRequestProvider,
  IngestionPartTable,
} from "./IngestionPartTable";

export function IngestionDetails() {
  const { ingestionId } = useTypedParams(lqsRoutePaths.INGESTION_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toIngestionPartTable: true,
    toIngestionEdit: true,
    toIngestionTable: true,
  });

  const query = useIngestion(ingestionId, {
    select: selectData,
    refetchInterval: calculateProcessRefetchInterval,
  });

  const generalSection = <GeneralSection query={query} />;
  const processSection = (
    <Process query={query} mutation={useUpdateIngestion(ingestionId)} />
  );
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedObjects query={query} />
      <RelatedResource
        text="Parts"
        to={lqsNavigator.toIngestionPartTable({ ingestionId })}
        table={
          <IngestionPartSearchRequestProvider embedded>
            <IngestionPartTable />
          </IngestionPartSearchRequestProvider>
        }
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="ingestion"
      query={query}
      updateMutation={useUpdateIngestion(ingestionId)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="ingestion"
      query={query}
      editLocation={lqsNavigator.toIngestionEdit({ ingestionId })}
      deleteMutation={useDeleteIngestion(ingestionId)}
      getReadableName={(ingestion) => ingestion.name ?? ingestion.id}
      listLocation={lqsNavigator.toIngestionTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {processSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {processSection}
          {lockSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Ingestion> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="ingestion"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "foreign-key", resourceType: "log", accessor: "logId" },
          { dataType: "text", accessor: "objectKey" },
          {
            dataType: "foreign-key",
            resourceType: "object-store",
            accessor: "objectStoreId",
          },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function RelatedObjects({ query }: { query: UseQueryResult<Ingestion> }) {
  const lqsNavigator = useLqsNavigator({ toLogObjectTable: true });

  return (
    <QueryRenderer
      query={query}
      loading={
        <Card>
          <Center sx={{ height: 350 }}>
            <CircularProgress />
          </Center>
        </Card>
      }
      error={
        <Card>
          <ErrorMessage>Couldn't load objects</ErrorMessage>
        </Card>
      }
      success={(ingestion) => {
        const ingestionPrefix = ["ingestions", ingestion.id, ""].join(
          OBJECT_KEY_DELIMITER,
        );

        return (
          <RelatedResource
            text="Objects"
            to={lqsNavigator.toLogObjectTable(
              { logId: ingestion.logId },
              { directory: ingestionPrefix },
            )}
            table={
              <ObjectSearchRequestProvider embedded>
                <LogObjectTable
                  logId={ingestion.logId}
                  homeName={ingestion.id}
                  subResourcePrefix={ingestionPrefix}
                />
              </ObjectSearchRequestProvider>
            }
          />
        );
      }}
    />
  );
}

function InfoSection({ query }: { query: UseQueryResult<Ingestion> }) {
  const { ingestionId } = useTypedParams(lqsRoutePaths.INGESTION_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="ingestion"
      registryKey="ingestion"
      disabled={(ingestion) => ingestion.locked}
      footer={(ingestion) =>
        ingestion.locked && (
          <Typography variant="body2">
            Unlock this ingestion to make updates.
          </Typography>
        )
      }
      query={query}
      schema={editIngestionSchema}
      descriptors={[
        { name: "name", type: "text" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateIngestion(ingestionId)}
    />
  );
}
