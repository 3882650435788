import React from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import * as z from "zod";
import { TextField, useStudioForm } from "~/components/Form";
import { invariant } from "~/lib/invariant";
import { requiredEmail, requiredPassword, requiredText } from "../common";
import { useConfirmResetPassword, useResetPassword } from "./api";
import { AuthErrorAlert } from "./auth-error-alert";
import { PasswordRequirements } from "./password-requirements";
import { RequestNewCodeButton } from "./request-new-code-button";

const resetPasswordSchema = z.object({
  email: requiredEmail,
});

const confirmResetPasswordSchema = z.object({
  code: requiredText,
  newPassword: requiredPassword({
    minLength: 8,
    includesNumber: true,
    includesSpecial: true,
    includesUppercase: true,
    includesLowercase: true,
  }),
});

export function ResetPasswordForm({
  onLoadingStart,
  onLoadingEnd,
  onResetComplete,
  footer,
}: {
  onLoadingStart?: () => void;
  onLoadingEnd?: () => void;
  onResetComplete: () => void;
  footer?: React.ReactNode;
}) {
  const resetPasswordMutation = useResetPassword();

  const resetPasswordForm = useStudioForm({
    schema: resetPasswordSchema,
    onSubmit(values) {
      onLoadingStart?.();
      resetPasswordMutation.mutate(values, {
        onSettled() {
          onLoadingEnd?.();
        },
      });
    },
    defaultValues: {
      email: null,
    },
  });

  const email = resetPasswordForm.watch("email");
  const canConfirmPasswordReset =
    email != null && resetPasswordMutation.isSuccess;

  const confirmResetPasswordMutation = useConfirmResetPassword();

  const confirmResetPasswordForm = useStudioForm({
    schema: confirmResetPasswordSchema,
    onSubmit(values) {
      invariant(
        canConfirmPasswordReset,
        "Not in state to confirm password reset",
      );

      onLoadingStart?.();
      confirmResetPasswordMutation.mutate(
        {
          email,
          ...values,
        },
        {
          onSuccess() {
            onResetComplete();
          },
          onSettled() {
            onLoadingEnd?.();
          },
        },
      );
    },
    defaultValues: {
      code: null,
      newPassword: null,
    },
  });

  const requestNewCodeMutation = useResetPassword();

  let content;
  if (!resetPasswordMutation.isSuccess) {
    content = (
      <Stack
        key="reset-step"
        spacing={2}
        component="form"
        noValidate
        onSubmit={resetPasswordForm.handleSubmit}
      >
        <Typography gutterBottom>
          Enter the email address associated with your LogQS account. If an
          account with that email address is found, we'll email a verification
          code you'll need to give us to reset your password.
        </Typography>
        <Typography>
          <Typography component="span" sx={{ fontWeight: "bold" }}>
            NOTE:
          </Typography>{" "}
          We can only send a code to a{" "}
          <Typography component="span" sx={{ fontWeight: "bold" }}>
            verified
          </Typography>{" "}
          email address. If your email address wasn't verified after sign up, we
          can't send you a code to reset your password.
        </Typography>
        <TextField
          control={resetPasswordForm.control}
          name="email"
          required
          type="username"
        />
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disableElevation
          loading={resetPasswordMutation.isLoading}
        >
          Request Reset Code
        </LoadingButton>
        <AuthErrorAlert mutation={resetPasswordMutation} />
      </Stack>
    );
  } else if (canConfirmPasswordReset) {
    content = (
      <Stack
        key="code-step"
        spacing={2}
        component="form"
        noValidate
        onSubmit={confirmResetPasswordForm.handleSubmit}
      >
        <Typography>
          Please enter the verification code we sent to your email address along
          with your new password.
        </Typography>
        <TextField
          control={confirmResetPasswordForm.control}
          name="code"
          required
          autoComplete="one-time-code"
        />
        <TextField
          control={confirmResetPasswordForm.control}
          name="newPassword"
          required
          type="new-password"
          helperText={<PasswordRequirements />}
        />
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          fullWidth
          disableElevation
          loading={confirmResetPasswordMutation.isLoading}
        >
          Confirm New Password
        </LoadingButton>
        <RequestNewCodeButton mutation={requestNewCodeMutation} email={email} />
        <AuthErrorAlert mutation={confirmResetPasswordMutation} />
      </Stack>
    );
  } else {
    content = null;
  }

  return (
    <Stack spacing={2}>
      {content}
      <Box sx={{ mt: 4 }}>{footer}</Box>
    </Stack>
  );
}
