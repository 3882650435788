import type { FieldValues, Path } from "react-hook-form";
import type { ResourceSelectFieldProps } from "~/components/Form";
import { ResourceSelectField } from "~/components/Form";
import {
  useTopicQueryOptionsFactory,
  useTopicsQueryOptionsFactory,
} from "../api";
import type { Log } from "../sdk";

interface TopicSelectProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> extends ResourceSelectFieldProps<TFieldValues, TName> {
  logId?: Log["id"];
}

export function TopicSelect<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({ logId, ...rest }: TopicSelectProps<TFieldValues, TName>) {
  const createTopicsQueryOptions = useTopicsQueryOptionsFactory();
  const createTopicQueryOptions = useTopicQueryOptionsFactory();

  return (
    <ResourceSelectField
      {...rest}
      createListQueryOptions={(text) =>
        createTopicsQueryOptions({
          nameLike: text,
          limit: 5,
          sort: "asc",
          order: "name",
          logId,
        })
      }
      createFetchQueryOptions={createTopicQueryOptions}
      selectOption={(topic) => ({
        value: topic.id,
        label: topic.name,
      })}
    />
  );
}
