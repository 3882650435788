import { styled } from "@mui/material";

const RequirementsListRoot = styled("ul")({
  marginBlock: 0,
});

export function PasswordRequirements() {
  return (
    <>
      Password requirements:
      <RequirementsListRoot>
        <li>At least 8 characters long</li>
        <li>At least 1 number</li>
        <li>At least 1 special character</li>
        <li>At least 1 uppercase letter</li>
        <li>At least 1 lowercase letter</li>
      </RequirementsListRoot>
    </>
  );
}
