import React from "react";
import { Error, Refresh, Tune } from "@mui/icons-material";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  LinearProgress,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from "material-ui-popup-state/hooks";
import { TableCog } from "mdi-material-ui";
import type { Path } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import type { CountableListResponse } from "~/domain/common";
import { getFieldLabel } from "~/domain/common";
import { useSidebarTrigger } from "~/layout";
import { pluralize } from "~/utils";
import { renderQuery } from "../QueryRenderer";
import type { NormalizedColumn } from "./types";
import { useEmbedded } from "./validation";

export interface TableHeaderProps<TResource extends object> {
  /**
   * Name of the resource being searched, i.e. "log" or "digestion." Should
   * be singular, not plural.
   */
  resource: string;
  resourceCreateLocation?: Partial<Path>;
  columns: ReadonlyArray<NormalizedColumn<TResource>>;
  visibleColumns: ReadonlyArray<NormalizedColumn<TResource>>;
  toggleColumnVisibility: (column: NormalizedColumn<TResource>) => void;
  searchQuery: UseQueryResult<CountableListResponse<TResource>>;
  disableFilters?: boolean;
  activeFilterCount?: number;
}

export function TableHeader<TResource extends object>({
  resourceCreateLocation,
  resource,
  columns,
  visibleColumns,
  toggleColumnVisibility,
  searchQuery,
  disableFilters = false,
  activeFilterCount,
}: TableHeaderProps<TResource>) {
  const embedded = useEmbedded();

  const handleFiltersClick = useSidebarTrigger("filters");

  const columnMenuPopupState = usePopupState({
    variant: "popover",
    popupId: "column-menu",
  });

  function makeColumnVisibilityToggleHandler(
    column: NormalizedColumn<TResource>,
  ) {
    return function handleToggleColumnVisibility() {
      toggleColumnVisibility(column);
    };
  }

  function handleRefresh() {
    searchQuery.refetch();
  }

  const message = renderQuery(searchQuery, {
    loading: <Typography>Fetching {resource}s...</Typography>,
    error: (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Error color="error" />
        <Typography>Unable to perform search</Typography>
      </Stack>
    ),
    success(result) {
      if (searchQuery.isRefetching) {
        return <Typography>Searching...</Typography>;
      } else {
        return <Typography>{pluralize(result.count, resource)}</Typography>;
      }
    },
  });

  return (
    <Stack spacing={2}>
      {!embedded && (
        <Stack direction="row" alignItems="center">
          <Tooltip title="Toggle column visibility" sx={{ mr: 1 }}>
            <Badge
              color="primary"
              variant="dot"
              overlap="circular"
              invisible={visibleColumns.length === columns.length}
            >
              <IconButton size="small" {...bindTrigger(columnMenuPopupState)}>
                <TableCog fontSize="small" />
              </IconButton>
            </Badge>
          </Tooltip>
          <Menu
            slotProps={{
              paper: {
                sx: {
                  minWidth: 250,
                  maxHeight: 500,
                },
              },
            }}
            {...bindMenu(columnMenuPopupState)}
          >
            {columns.map((column) => {
              const isVisible = visibleColumns.some(
                ({ header }) => header === column.header,
              );

              return (
                <MenuItem
                  key={column.header}
                  onClick={makeColumnVisibilityToggleHandler(column)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={isVisible}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  {column.header}
                </MenuItem>
              );
            })}
          </Menu>
          {!disableFilters && (
            <Tooltip title="Filters" sx={{ mr: 1 }}>
              <Badge badgeContent={activeFilterCount} color="primary">
                <IconButton size="small" onClick={handleFiltersClick}>
                  <Tune fontSize="small" />
                </IconButton>
              </Badge>
            </Tooltip>
          )}
          {message}
          <Tooltip title="Refresh" sx={{ ml: "auto" }}>
            <IconButton size="small" onClick={handleRefresh}>
              <Refresh fontSize="small" />
            </IconButton>
          </Tooltip>
          {resourceCreateLocation !== undefined && (
            <Button
              sx={{ ml: 2 }}
              color="primary"
              variant="contained"
              component={RouterLink}
              to={resourceCreateLocation}
            >
              Create {getFieldLabel(resource)}
            </Button>
          )}
        </Stack>
      )}
      <Box position="relative">
        <Divider />
        {searchQuery.isRefetching && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </Box>
    </Stack>
  );
}
