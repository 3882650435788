import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { listRolesSchema } from "../schemas";
import { useRoleSearchRequest } from "./RoleTable";

export function RoleFilters() {
  const [request, setRequest] = useRoleSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="role"
      request={request}
      setRequest={setRequest}
      schema={listRolesSchema}
      descriptors={[
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "_default", type: "boolean" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
