import { TablePage } from "~/components/Table";
import { WorkflowFilters } from "./components/WorkflowFilters";
import {
  WorkflowSearchRequestProvider,
  WorkflowTable,
} from "./components/WorkflowTable";

export function WorkflowsPage() {
  return (
    <TablePage
      title="Search Workflows"
      RequestProvider={WorkflowSearchRequestProvider}
      table={<WorkflowTable />}
      filters={<WorkflowFilters />}
    />
  );
}
