/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Group } from "./Group";
import { GroupFromJSON, GroupFromJSONTyped, GroupToJSON } from "./Group";

export interface GroupDataResponse {
  data: Group;
}

export function GroupDataResponseFromJSON(json: any): GroupDataResponse {
  return GroupDataResponseFromJSONTyped(json);
}

export function GroupDataResponseFromJSONTyped(json: any): GroupDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: GroupFromJSON(json["data"]),
  };
}

export function GroupDataResponseToJSON(
  value: GroupDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: GroupToJSON(value.data),
  };
}
