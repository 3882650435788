/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { CloudObject } from "./CloudObject";
import {
  CloudObjectFromJSON,
  CloudObjectFromJSONTyped,
  CloudObjectToJSON,
} from "./CloudObject";

export interface ObjectListResponse {
  data: Array<CloudObject>;
  isTruncated: boolean | null;
  keyCount: number | null;
  maxKeys: number;
  continuationToken: string | null;
  nextContinuationToken: string | null;
  prefix: string | null;
  startAfter: string | null;
  delimiter: string | null;
  commonPrefixes: Array<string> | null;
}

export function ObjectListResponseFromJSON(json: any): ObjectListResponse {
  return ObjectListResponseFromJSONTyped(json);
}

export function ObjectListResponseFromJSONTyped(json: any): ObjectListResponse {
  if (json == null) {
    return json;
  }

  return {
    data: (json["data"] as Array<any>).map(CloudObjectFromJSON),
    isTruncated: json["is_truncated"],
    keyCount: json["key_count"],
    maxKeys: json["max_keys"],
    continuationToken: json["continuation_token"],
    nextContinuationToken: json["next_continuation_token"],
    prefix: json["prefix"],
    startAfter: json["start_after"],
    delimiter: json["delimiter"],
    commonPrefixes: json["common_prefixes"],
  };
}

export function ObjectListResponseToJSON(
  value: ObjectListResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: (value.data as Array<any>).map(CloudObjectToJSON),
    is_truncated: value.isTruncated,
    key_count: value.keyCount,
    max_keys: value.maxKeys,
    continuation_token: value.continuationToken,
    next_continuation_token: value.nextContinuationToken,
    prefix: value.prefix,
    start_after: value.startAfter,
    delimiter: value.delimiter,
    common_prefixes: value.commonPrefixes,
  };
}
