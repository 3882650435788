import { GTE, LTE } from "~/constants";
import { startCase } from "~/lib/std";

const specialFields = new Map([
  ["_default", "Default"],
  ["id", "ID"],
  ["uri", "URI"],
  ["accessKeyId", "Access Key ID"],
]);

export function getFieldLabel(field: string): string {
  if (specialFields.has(field)) {
    return specialFields.get(field)!;
  }

  if (field.endsWith("Id")) {
    return startCase(field.slice(0, -2));
  }

  if (field.endsWith("Gte")) {
    return `${startCase(field.slice(0, -3))} ${GTE}`;
  }

  if (field.endsWith("Lte")) {
    return `${startCase(field.slice(0, -3))} ${LTE}`;
  }

  return startCase(field);
}
