import React from "react";
import { Alert, AlertTitle, Link, styled, Typography } from "@mui/material";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import { SignInForm } from "~/domain/auth";
import {
  makeStudioHomepageLocation,
  toForgotPassword,
  toSignUp,
} from "~/paths";

const Root = styled(Card)(({ theme }) => ({
  width: 500,
  maxWidth: `calc(100% - ${theme.spacing(2)})`,
}));

export function SignInPage() {
  const navigate = useNavigate();

  const location = useLocation();
  const fromForgotPassword = location.state?.from === "forgot-password";

  return (
    <Center>
      <Root title="Sign In">
        <SignInForm
          onSignInComplete={() => {
            navigate(makeStudioHomepageLocation());
          }}
          message={
            <>
              {fromForgotPassword && (
                <Alert variant="outlined" severity="success">
                  <AlertTitle>Password Reset</AlertTitle>
                  You can now sign in with your new password
                </Alert>
              )}
              <Typography>
                To use LogQS Studio, you'll first need to sign in to your
                account.
              </Typography>
            </>
          }
          forgotPassword={
            <Link
              component={RouterLink}
              to={toForgotPassword()}
              underline="always"
            >
              Forgot password?
            </Link>
          }
          footer={
            <Typography>
              Don't have an account?{" "}
              <Link component={RouterLink} to={toSignUp()} underline="always">
                Sign up
              </Link>
            </Typography>
          }
        />
      </Root>
    </Center>
  );
}
