import React from "react";
import { Skeleton } from "@mui/material";
import { usePlayerLog, usePlayerParams } from "~/player";

export function StudioPlayerHeaderTitle() {
  const { logId } = usePlayerParams();

  const logQuery = usePlayerLog();

  if (logId === null) {
    return "Player";
  } else if (logQuery.isSuccess) {
    return `Player - ${logQuery.data.name}`;
  } else {
    return (
      <>
        Player - <Skeleton width="15ch" sx={{ display: "inline-block" }} />
      </>
    );
  }
}
