import { useEffect, useState } from "react";
import { NoPhotography } from "@mui/icons-material";
import { Box, CardMedia, Skeleton } from "@mui/material";
import { useBlobSource } from "~/hooks";
import { secondsToMilliseconds } from "~/lib/dates";
import type { Log } from "~/lqs";
import { usePreviewImages } from "~/lqs";

export function LogThumbnail({
  className,
  log,
  width,
  height,
  shouldLoad = true,
  cycle = false,
}: {
  className?: string;
  log: Log;
  width: number | string;
  height: number | string;
  shouldLoad?: boolean;
  cycle?: boolean;
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const isLogPlayable = log.startTime !== null && log.endTime !== null;

  const previewImagesQuery = usePreviewImages(log.id, {
    enabled: shouldLoad && isLogPlayable,
    select(thumbnailResults) {
      return thumbnailResults.flatMap((result) =>
        result.blob !== null ? result.blob : [],
      );
    },
  });

  const imgRef = useBlobSource(previewImagesQuery.data?.[currentImageIndex]);

  const numThumbnails = previewImagesQuery.data?.length;
  useEffect(
    function cycleThumbnails() {
      if (!cycle) {
        setCurrentImageIndex(0);
        return;
      }

      if (numThumbnails === undefined || numThumbnails === 0) {
        setCurrentImageIndex(0);
        return;
      }

      const intervalId = setInterval(
        () =>
          setCurrentImageIndex((currIndex) => (currIndex + 1) % numThumbnails),
        secondsToMilliseconds(1),
      );

      return () => {
        clearInterval(intervalId);
      };
    },
    [cycle, numThumbnails],
  );

  let content;
  if (
    previewImagesQuery.status === "error" ||
    !isLogPlayable ||
    numThumbnails === 0
  ) {
    content = (
      <Box
        className={className}
        sx={{
          width,
          height,
          bgcolor: "grey.800",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 8,
        }}
      >
        <NoPhotography fontSize="large" />
      </Box>
    );
  } else if (previewImagesQuery.status === "success") {
    content = (
      <CardMedia
        className={className}
        ref={imgRef}
        component="img"
        sx={{ width, height }}
      />
    );
  } else {
    content = (
      <Skeleton
        className={className}
        variant="rectangular"
        sx={{ width, height }}
      />
    );
  }

  return content;
}
