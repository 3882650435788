/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TicketType } from "./TicketType";
import {
  TicketTypeFromJSON,
  TicketTypeFromJSONTyped,
  TicketTypeToJSON,
} from "./TicketType";

export interface TicketCreateRequest {
  type: TicketType;
  description: string;
  email: string;
}

export function TicketCreateRequestFromJSON(json: any): TicketCreateRequest {
  return TicketCreateRequestFromJSONTyped(json);
}

export function TicketCreateRequestFromJSONTyped(
  json: any,
): TicketCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    type: TicketTypeFromJSON(json["type"]),
    description: json["description"],
    email: json["email"],
  };
}

export function TicketCreateRequestToJSON(
  value: TicketCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    type: TicketTypeToJSON(value.type),
    description: value.description,
    email: value.email,
  };
}
