import { Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout, RelatedResource } from "~/components/DetailsCards";
import type { ObjectStore } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteObjectStore,
  useObjectStore,
  useUpdateObjectStore,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editObjectStoreSchema } from "../schemas";
import {
  ObjectStoreObjectSearchRequestProvider,
  ObjectStoreObjectTable,
} from "./ObjectStoreObjectTable";

export function ObjectStoreDetails() {
  const { objectStoreId } = useTypedParams(lqsRoutePaths.OBJECT_STORE_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toObjectStoreObjectTable: true,
    toObjectStoreEdit: true,
    toObjectStoreTable: true,
  });

  const query = useObjectStore(objectStoreId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResource
        text="Objects"
        to={lqsNavigator.toObjectStoreObjectTable({ objectStoreId })}
        table={
          <ObjectStoreObjectSearchRequestProvider embedded>
            <ObjectStoreObjectTable />
          </ObjectStoreObjectSearchRequestProvider>
        }
      />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="object store"
      query={query}
      editLocation={lqsNavigator.toObjectStoreEdit({ objectStoreId })}
      deleteMutation={useDeleteObjectStore(objectStoreId)}
      getReadableName={(objectStore) => objectStore.bucketName}
      listLocation={lqsNavigator.toObjectStoreTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<ObjectStore> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="objectStore"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "bucketName" },
          { dataType: "text", accessor: "keyPrefix" },
          { dataType: "text", accessor: "accessKeyId" },
          { dataType: "text", accessor: "regionName" },
          { dataType: "text", accessor: "endpointUrl" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<ObjectStore> }) {
  const { objectStoreId } = useTypedParams(lqsRoutePaths.OBJECT_STORE_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="object store"
      registryKey="objectStore"
      query={query}
      schema={editObjectStoreSchema}
      descriptors={[
        { name: "_default", type: "boolean" },
        { name: "readOnly", type: "boolean" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateObjectStore(objectStoreId)}
    />
  );
}
