import { FiltersSidebar } from "~/components/Table";
import { useCommonResourcesContextValue } from "../../../context";
import { commonResourceFormFieldDescriptors } from "../../../filters";
import { renderCommonForeignKeyFormField } from "../../../forms";
import { listUsersSchema } from "../schemas";
import { useUserSearchRequest } from "./user-table";

export function UserTableFilters() {
  const [request, setRequest] = useUserSearchRequest();

  const { apiVersion } = useCommonResourcesContextValue();

  return (
    <FiltersSidebar
      request={request}
      setRequest={setRequest}
      schema={listUsersSchema}
      descriptors={[
        { name: "username", type: "fuzzy", fuzzyName: "usernameLike" },
        { name: "roleId", type: "foreign-key", resourceType: "role" },
        { name: "admin", type: "boolean" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        ...commonResourceFormFieldDescriptors,
      ]}
      apiVersion={apiVersion}
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderCommonForeignKeyFormField}
    />
  );
}
