import { invariant } from "~/lib/invariant";

export interface ListResponse<TData> {
  offset: number;
  limit: number;
  order: string;
  sort: string;
  count: number | null;
  data: Array<TData>;
}

export interface CountableListResponse<TData> extends ListResponse<TData> {
  count: number;
}

export function assertCountableListResponse<TData>(
  listResponse: ListResponse<TData>,
): asserts listResponse is CountableListResponse<TData> {
  invariant(listResponse.count !== null, "'count' should not be null");
}

export function selectCountableListResponse<TResource extends object>(
  listResponse: ListResponse<TResource>,
): CountableListResponse<TResource> {
  assertCountableListResponse(listResponse);

  return listResponse;
}
