/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  DigestionCreateRequest,
  DigestionDataResponse,
  DigestionListResponse,
  DigestionPartCreateRequest,
  DigestionPartDataResponse,
  DigestionPartListResponse,
  DigestionPartUpdateRequest,
  DigestionTopicCreateRequest,
  DigestionTopicDataResponse,
  DigestionTopicListResponse,
  DigestionTopicUpdateRequest,
  DigestionUpdateRequest,
  HTTPValidationError,
} from "../models";
import {
  DigestionCreateRequestFromJSON,
  DigestionCreateRequestToJSON,
  DigestionDataResponseFromJSON,
  DigestionDataResponseToJSON,
  DigestionListResponseFromJSON,
  DigestionListResponseToJSON,
  DigestionPartCreateRequestFromJSON,
  DigestionPartCreateRequestToJSON,
  DigestionPartDataResponseFromJSON,
  DigestionPartDataResponseToJSON,
  DigestionPartListResponseFromJSON,
  DigestionPartListResponseToJSON,
  DigestionPartUpdateRequestFromJSON,
  DigestionPartUpdateRequestToJSON,
  DigestionTopicCreateRequestFromJSON,
  DigestionTopicCreateRequestToJSON,
  DigestionTopicDataResponseFromJSON,
  DigestionTopicDataResponseToJSON,
  DigestionTopicListResponseFromJSON,
  DigestionTopicListResponseToJSON,
  DigestionTopicUpdateRequestFromJSON,
  DigestionTopicUpdateRequestToJSON,
  DigestionUpdateRequestFromJSON,
  DigestionUpdateRequestToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models";

export interface CreateDigestionRequest {
  digestionCreateRequest: DigestionCreateRequest;
  lockToken?: string | null;
}

export interface CreateDigestionPartRequest {
  digestionId: string;
  digestionPartCreateRequest: DigestionPartCreateRequest;
  lockToken?: string | null;
}

export interface CreateDigestionTopicRequest {
  digestionId: string;
  digestionTopicCreateRequest: DigestionTopicCreateRequest;
  lockToken?: string | null;
}

export interface DeleteDigestionRequest {
  digestionId: string;
  lockToken?: string | null;
}

export interface DeleteDigestionPartRequest {
  digestionPartId: string;
  digestionId: string | null;
  lockToken?: string | null;
}

export interface DeleteDigestionTopicRequest {
  digestionTopicId: string;
  digestionId: string | null;
  lockToken?: string | null;
}

export interface FetchDigestionRequest {
  digestionId: string;
}

export interface FetchDigestionPartRequest {
  digestionPartId: string;
  digestionId: string | null;
}

export interface FetchDigestionTopicRequest {
  digestionTopicId: string;
  digestionId: string | null;
}

export interface ListDigestionPartsRequest {
  digestionId: string | null;
  id?: string | null;
  groupId?: string | null;
  logId?: string | null;
  sequence?: number | null;
  workflowId?: string | null;
  workflowIdNull?: boolean | null;
  state?: string | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface ListDigestionTopicsRequest {
  digestionId: string | null;
  id?: string | null;
  groupId?: string | null;
  logId?: string | null;
  topicId?: string | null;
  startTimeNull?: boolean | null;
  startTimeGte?: bigint | null;
  startTimeLte?: bigint | null;
  endTimeNull?: boolean | null;
  endTimeGte?: bigint | null;
  endTimeLte?: bigint | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface ListDigestionsRequest {
  id?: string | null;
  groupId?: string | null;
  logId?: string | null;
  workflowId?: string | null;
  workflowIdNull?: boolean | null;
  state?: string | null;
  name?: string | null;
  nameLike?: string | null;
  progressNull?: boolean | null;
  progressGte?: number | null;
  progressLte?: number | null;
  errorFilter?: string | null;
  errorPayloadFilter?: string | null;
  noteLike?: string | null;
  contextFilter?: string | null;
  workflowContextFilter?: string | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateDigestionRequest {
  digestionId: string;
  digestionUpdateRequest: DigestionUpdateRequest;
  lockToken?: string | null;
}

export interface UpdateDigestionPartRequest {
  digestionPartId: string;
  digestionId: string | null;
  digestionPartUpdateRequest: DigestionPartUpdateRequest;
  lockToken?: string | null;
}

export interface UpdateDigestionTopicRequest {
  digestionTopicId: string;
  digestionId: string | null;
  digestionTopicUpdateRequest: DigestionTopicUpdateRequest;
  lockToken?: string | null;
}

export class DigestionApi extends runtime.BaseAPI {
  async createDigestion(
    this: DigestionApi,
    requestParameters: CreateDigestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.digestionCreateRequest,
      "digestionCreateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/digestions`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DigestionCreateRequestToJSON(
          requestParameters.digestionCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionDataResponseFromJSON(jsonValue);
  }

  async createDigestionPart(
    this: DigestionApi,
    requestParameters: CreateDigestionPartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionPartDataResponse> {
    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    runtime.assertParamIsPresent(
      requestParameters.digestionPartCreateRequest,
      "digestionPartCreateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}/parts`.replace(
          `{${"digestion_id"}}`,
          encodeURIComponent(String(requestParameters.digestionId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DigestionPartCreateRequestToJSON(
          requestParameters.digestionPartCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionPartDataResponseFromJSON(jsonValue);
  }

  async createDigestionTopic(
    this: DigestionApi,
    requestParameters: CreateDigestionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionTopicDataResponse> {
    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    runtime.assertParamIsPresent(
      requestParameters.digestionTopicCreateRequest,
      "digestionTopicCreateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}/topics`.replace(
          `{${"digestion_id"}}`,
          encodeURIComponent(String(requestParameters.digestionId)),
        ),
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DigestionTopicCreateRequestToJSON(
          requestParameters.digestionTopicCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionTopicDataResponseFromJSON(jsonValue);
  }

  async deleteDigestion(
    this: DigestionApi,
    requestParameters: DeleteDigestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/digestions/{digestion_id}`.replace(
          `{${"digestion_id"}}`,
          encodeURIComponent(String(requestParameters.digestionId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async deleteDigestionPart(
    this: DigestionApi,
    requestParameters: DeleteDigestionPartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(
      requestParameters.digestionPartId,
      "digestionPartId",
    );

    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/digestions/{digestion_id}/parts/{digestion_part_id}`
          .replace(
            `{${"digestion_part_id"}}`,
            encodeURIComponent(String(requestParameters.digestionPartId)),
          )
          .replace(
            `{${"digestion_id"}}`,
            encodeURIComponent(String(requestParameters.digestionId)),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async deleteDigestionTopic(
    this: DigestionApi,
    requestParameters: DeleteDigestionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(
      requestParameters.digestionTopicId,
      "digestionTopicId",
    );

    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/digestions/{digestion_id}/topics/{digestion_topic_id}`
          .replace(
            `{${"digestion_topic_id"}}`,
            encodeURIComponent(String(requestParameters.digestionTopicId)),
          )
          .replace(
            `{${"digestion_id"}}`,
            encodeURIComponent(String(requestParameters.digestionId)),
          ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchDigestion(
    this: DigestionApi,
    requestParameters: FetchDigestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionDataResponse> {
    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}`.replace(
          `{${"digestion_id"}}`,
          encodeURIComponent(String(requestParameters.digestionId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionDataResponseFromJSON(jsonValue);
  }

  async fetchDigestionPart(
    this: DigestionApi,
    requestParameters: FetchDigestionPartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionPartDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.digestionPartId,
      "digestionPartId",
    );

    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}/parts/{digestion_part_id}`
          .replace(
            `{${"digestion_part_id"}}`,
            encodeURIComponent(String(requestParameters.digestionPartId)),
          )
          .replace(
            `{${"digestion_id"}}`,
            encodeURIComponent(String(requestParameters.digestionId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionPartDataResponseFromJSON(jsonValue);
  }

  async fetchDigestionTopic(
    this: DigestionApi,
    requestParameters: FetchDigestionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionTopicDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.digestionTopicId,
      "digestionTopicId",
    );

    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}/topics/{digestion_topic_id}`
          .replace(
            `{${"digestion_topic_id"}}`,
            encodeURIComponent(String(requestParameters.digestionTopicId)),
          )
          .replace(
            `{${"digestion_id"}}`,
            encodeURIComponent(String(requestParameters.digestionId)),
          ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionTopicDataResponseFromJSON(jsonValue);
  }

  async listDigestionParts(
    this: DigestionApi,
    requestParameters: ListDigestionPartsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionPartListResponse> {
    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.groupId != null) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.logId != null) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.sequence != null) {
      queryParameters["sequence"] = requestParameters.sequence;
    }

    if (requestParameters.workflowId != null) {
      queryParameters["workflow_id"] = requestParameters.workflowId;
    }

    if (requestParameters.workflowIdNull != null) {
      queryParameters["workflow_id_null"] = requestParameters.workflowIdNull;
    }

    if (requestParameters.state != null) {
      queryParameters["state"] = requestParameters.state;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}/parts`.replace(
          `{${"digestion_id"}}`,
          encodeURIComponent(String(requestParameters.digestionId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionPartListResponseFromJSON(jsonValue);
  }

  async listDigestionTopics(
    this: DigestionApi,
    requestParameters: ListDigestionTopicsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionTopicListResponse> {
    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.groupId != null) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.logId != null) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.topicId != null) {
      queryParameters["topic_id"] = requestParameters.topicId;
    }

    if (requestParameters.startTimeNull != null) {
      queryParameters["start_time_null"] = requestParameters.startTimeNull;
    }

    if (requestParameters.startTimeGte != null) {
      queryParameters["start_time_gte"] = requestParameters.startTimeGte;
    }

    if (requestParameters.startTimeLte != null) {
      queryParameters["start_time_lte"] = requestParameters.startTimeLte;
    }

    if (requestParameters.endTimeNull != null) {
      queryParameters["end_time_null"] = requestParameters.endTimeNull;
    }

    if (requestParameters.endTimeGte != null) {
      queryParameters["end_time_gte"] = requestParameters.endTimeGte;
    }

    if (requestParameters.endTimeLte != null) {
      queryParameters["end_time_lte"] = requestParameters.endTimeLte;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}/topics`.replace(
          `{${"digestion_id"}}`,
          encodeURIComponent(String(requestParameters.digestionId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionTopicListResponseFromJSON(jsonValue);
  }

  async listDigestions(
    this: DigestionApi,
    requestParameters: ListDigestionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionListResponse> {
    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.groupId != null) {
      queryParameters["group_id"] = requestParameters.groupId;
    }

    if (requestParameters.logId != null) {
      queryParameters["log_id"] = requestParameters.logId;
    }

    if (requestParameters.workflowId != null) {
      queryParameters["workflow_id"] = requestParameters.workflowId;
    }

    if (requestParameters.workflowIdNull != null) {
      queryParameters["workflow_id_null"] = requestParameters.workflowIdNull;
    }

    if (requestParameters.state != null) {
      queryParameters["state"] = requestParameters.state;
    }

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.progressNull != null) {
      queryParameters["progress_null"] = requestParameters.progressNull;
    }

    if (requestParameters.progressGte != null) {
      queryParameters["progress_gte"] = requestParameters.progressGte;
    }

    if (requestParameters.progressLte != null) {
      queryParameters["progress_lte"] = requestParameters.progressLte;
    }

    if (requestParameters.errorFilter != null) {
      queryParameters["error_filter"] = requestParameters.errorFilter;
    }

    if (requestParameters.errorPayloadFilter != null) {
      queryParameters["error_payload_filter"] =
        requestParameters.errorPayloadFilter;
    }

    if (requestParameters.noteLike != null) {
      queryParameters["note_like"] = requestParameters.noteLike;
    }

    if (requestParameters.contextFilter != null) {
      queryParameters["context_filter"] = requestParameters.contextFilter;
    }

    if (requestParameters.workflowContextFilter != null) {
      queryParameters["workflow_context_filter"] =
        requestParameters.workflowContextFilter;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/digestions`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionListResponseFromJSON(jsonValue);
  }

  async updateDigestion(
    this: DigestionApi,
    requestParameters: UpdateDigestionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionDataResponse> {
    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    runtime.assertParamIsPresent(
      requestParameters.digestionUpdateRequest,
      "digestionUpdateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}`.replace(
          `{${"digestion_id"}}`,
          encodeURIComponent(String(requestParameters.digestionId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: DigestionUpdateRequestToJSON(
          requestParameters.digestionUpdateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionDataResponseFromJSON(jsonValue);
  }

  async updateDigestionPart(
    this: DigestionApi,
    requestParameters: UpdateDigestionPartRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionPartDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.digestionPartId,
      "digestionPartId",
    );

    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    runtime.assertParamIsPresent(
      requestParameters.digestionPartUpdateRequest,
      "digestionPartUpdateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}/parts/{digestion_part_id}`
          .replace(
            `{${"digestion_part_id"}}`,
            encodeURIComponent(String(requestParameters.digestionPartId)),
          )
          .replace(
            `{${"digestion_id"}}`,
            encodeURIComponent(String(requestParameters.digestionId)),
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: DigestionPartUpdateRequestToJSON(
          requestParameters.digestionPartUpdateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionPartDataResponseFromJSON(jsonValue);
  }

  async updateDigestionTopic(
    this: DigestionApi,
    requestParameters: UpdateDigestionTopicRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DigestionTopicDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.digestionTopicId,
      "digestionTopicId",
    );

    runtime.assertParamIsPresent(requestParameters.digestionId, "digestionId");

    runtime.assertParamIsPresent(
      requestParameters.digestionTopicUpdateRequest,
      "digestionTopicUpdateRequest",
    );

    const queryParameters: any = {};

    if (requestParameters.lockToken != null) {
      queryParameters["lock_token"] = requestParameters.lockToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/digestions/{digestion_id}/topics/{digestion_topic_id}`
          .replace(
            `{${"digestion_topic_id"}}`,
            encodeURIComponent(String(requestParameters.digestionTopicId)),
          )
          .replace(
            `{${"digestion_id"}}`,
            encodeURIComponent(String(requestParameters.digestionId)),
          ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: DigestionTopicUpdateRequestToJSON(
          requestParameters.digestionTopicUpdateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DigestionTopicDataResponseFromJSON(jsonValue);
  }
}
