import React, { useEffect } from "react";
import { Button, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { createSafeContext } from "~/contexts";
import { useLqsNavigator } from "~/paths";
import { useProfileCompletionStatus } from "./use-profile-completion-status";
import { setProfileBannerTimeout, useHasBannerTimeoutPassed } from "./utils";

const [useSuppressProfileBannerContext, SuppressProfileBannerContext] =
  createSafeContext<boolean>("SuppressProfileBanner");

export function SuppressProfileBannerProvider({
  suppress = true,
  children,
}: {
  suppress?: boolean;
  children: React.ReactNode;
}) {
  return (
    <SuppressProfileBannerContext.Provider value={suppress}>
      {children}
    </SuppressProfileBannerContext.Provider>
  );
}

export function CompleteProfileBanner() {
  const suppressProfileBanner =
    useSuppressProfileBannerContext({
      strict: false,
    }) ?? false;

  const profileCompletionStatusQuery = useProfileCompletionStatus();

  const hasBannerTimeoutPassed = useHasBannerTimeoutPassed();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const lqsNavigator = useLqsNavigator({ toProfile: true });
  const navigate = useNavigate();

  useEffect(function showBannerIfNeeded() {
    if (suppressProfileBanner) {
      return;
    }

    if (!hasBannerTimeoutPassed) {
      return;
    }

    if (!profileCompletionStatusQuery.isSuccess) {
      return;
    }

    if (profileCompletionStatusQuery.data.complete) {
      return;
    }

    enqueueSnackbar({
      message: "Your profile isn't complete",
      variant: "info",
      preventDuplicate: true,
      autoHideDuration: null,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
      onEnter() {
        setProfileBannerTimeout();
      },
      action(key) {
        function handleClose(): void {
          closeSnackbar(key);
        }

        function handleGoToProfile(): void {
          handleClose();
          navigate(lqsNavigator.toProfile());
        }

        return (
          <Stack direction="row" spacing={1} sx={{ ml: 2 }}>
            <Button
              color="inherit"
              variant="text"
              size="small"
              onClick={handleGoToProfile}
            >
              Go to Profile
            </Button>
            <Button
              color="inherit"
              variant="text"
              size="small"
              onClick={handleClose}
            >
              Maybe Later
            </Button>
          </Stack>
        );
      },
    });
  });

  return null;
}
