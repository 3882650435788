import type { ValueOf } from "type-fest";

export const LIMIT_OPTIONS = [5, 10, 25, 50, 100];

export const SortDirection = {
  Asc: "asc",
  Desc: "desc",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SortDirection = ValueOf<typeof SortDirection>;
