import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import { DigestionPartFilters } from "./components/DigestionPartFilters";
import {
  DigestionPartSearchRequestProvider,
  DigestionPartTable,
} from "./components/DigestionPartTable";

export function DigestionPartsPage() {
  return (
    <TablePage
      title="Search Digestion Parts"
      RequestProvider={DigestionPartSearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <DigestionPartTable />
        </>
      }
      filters={<DigestionPartFilters />}
    />
  );
}
