import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { ObjectStoreEditForm } from "./components/ObjectStoreEditForm";

export function EditObjectStorePage() {
  return (
    <LqsPage title="Edit Object Store">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <ObjectStoreEditForm />
      </Container>
    </LqsPage>
  );
}
