import type React from "react";
import type { RequireOneOrNone } from "type-fest";
import { assertNever } from "~/utils/assertNever";
import type { ApiVersion } from "./api-version";
import type { VersionHistory } from "./types";

export type VersionGuardProps = {
  apiVersion: ApiVersion;
  versionHistory: VersionHistory;
  unavailable?: React.ReactNode | (() => React.ReactNode);
  deprecated?: React.ReactNode | (() => React.ReactNode);
} & RequireOneOrNone<{
  current: React.ReactNode | (() => React.ReactNode);
  children: React.ReactNode | (() => React.ReactNode);
}>;

export function VersionGuard({
  versionHistory,
  unavailable = null,
  current = null,
  children: childrenProp = null,
  deprecated = null,
  apiVersion,
}: VersionGuardProps) {
  const status = apiVersion.checkStatus(versionHistory);

  let children;
  switch (status) {
    case "unavailable": {
      children = unavailable;
      break;
    }
    case "current": {
      children = current ?? childrenProp;
      break;
    }
    case "deprecated": {
      children = deprecated;
      break;
    }
    default: {
      assertNever(status);
    }
  }

  return typeof children === "function" ? children() : children;
}
