/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  HTTPValidationError,
  RoleCreateRequest,
  RoleDataResponse,
  RoleListResponse,
  RoleUpdateRequest,
} from "../models";
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  RoleCreateRequestFromJSON,
  RoleCreateRequestToJSON,
  RoleDataResponseFromJSON,
  RoleDataResponseToJSON,
  RoleListResponseFromJSON,
  RoleListResponseToJSON,
  RoleUpdateRequestFromJSON,
  RoleUpdateRequestToJSON,
} from "../models";

export interface CreateRoleRequest {
  roleCreateRequest: RoleCreateRequest;
}

export interface DeleteRoleRequest {
  roleId: string;
}

export interface FetchRoleRequest {
  roleId: string;
}

export interface ListRolesRequest {
  name?: string | null;
  nameLike?: string | null;
  disabled?: boolean | null;
  _default?: boolean | null;
  managed?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateRoleRequest {
  roleId: string;
  roleUpdateRequest: RoleUpdateRequest;
}

export class RoleApi extends runtime.BaseAPI {
  async createRole(
    this: RoleApi,
    requestParameters: CreateRoleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RoleDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.roleCreateRequest,
      "roleCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/roles`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: RoleCreateRequestToJSON(requestParameters.roleCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return RoleDataResponseFromJSON(jsonValue);
  }

  async deleteRole(
    this: RoleApi,
    requestParameters: DeleteRoleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(requestParameters.roleId, "roleId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/roles/{role_id}`.replace(
          `{${"role_id"}}`,
          encodeURIComponent(String(requestParameters.roleId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchRole(
    this: RoleApi,
    requestParameters: FetchRoleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RoleDataResponse> {
    runtime.assertParamIsPresent(requestParameters.roleId, "roleId");

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/roles/{role_id}`.replace(
          `{${"role_id"}}`,
          encodeURIComponent(String(requestParameters.roleId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return RoleDataResponseFromJSON(jsonValue);
  }

  async listRoles(
    this: RoleApi,
    requestParameters: ListRolesRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RoleListResponse> {
    const queryParameters: any = {};

    if (requestParameters.name != null) {
      queryParameters["name"] = requestParameters.name;
    }

    if (requestParameters.nameLike != null) {
      queryParameters["name_like"] = requestParameters.nameLike;
    }

    if (requestParameters.disabled != null) {
      queryParameters["disabled"] = requestParameters.disabled;
    }

    if (requestParameters._default != null) {
      queryParameters["default"] = requestParameters._default;
    }

    if (requestParameters.managed != null) {
      queryParameters["managed"] = requestParameters.managed;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/roles`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return RoleListResponseFromJSON(jsonValue);
  }

  async updateRole(
    this: RoleApi,
    requestParameters: UpdateRoleRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<RoleDataResponse> {
    runtime.assertParamIsPresent(requestParameters.roleId, "roleId");

    runtime.assertParamIsPresent(
      requestParameters.roleUpdateRequest,
      "roleUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/roles/{role_id}`.replace(
          `{${"role_id"}}`,
          encodeURIComponent(String(requestParameters.roleId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: RoleUpdateRequestToJSON(requestParameters.roleUpdateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return RoleDataResponseFromJSON(jsonValue);
  }
}
