/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { DigestionTopic } from "./DigestionTopic";
import {
  DigestionTopicFromJSON,
  DigestionTopicFromJSONTyped,
  DigestionTopicToJSON,
} from "./DigestionTopic";

export interface DigestionTopicDataResponse {
  data: DigestionTopic;
}

export function DigestionTopicDataResponseFromJSON(
  json: any,
): DigestionTopicDataResponse {
  return DigestionTopicDataResponseFromJSONTyped(json);
}

export function DigestionTopicDataResponseFromJSONTyped(
  json: any,
): DigestionTopicDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: DigestionTopicFromJSON(json["data"]),
  };
}

export function DigestionTopicDataResponseToJSON(
  value: DigestionTopicDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: DigestionTopicToJSON(value.data),
  };
}
