import type { Topic } from "~/lqs";
import type { Maybe } from "~/types";
import type { RecordType } from "../record-store";
import type { InferenceType } from "./types";

type InferenceRecordType = Extract<
  RecordType,
  "detections" | "image" | "segmentations"
>;

interface InferenceTopicConfiguration {
  type: InferenceType;
  recordType: InferenceRecordType;
}

function createConfigEntry(
  config: InferenceTopicConfiguration,
): [string, InferenceTopicConfiguration] {
  return [`logqs.inference.${config.type}`, config];
}

const topicTypeToConfig = new Map<
  NonNullable<Topic["typeName"]>,
  InferenceTopicConfiguration
>([
  createConfigEntry({
    type: "object-detection",
    recordType: "detections",
  }),
  createConfigEntry({
    type: "zero-shot-object-detection",
    recordType: "detections",
  }),
  createConfigEntry({
    type: "image-segmentation",
    recordType: "segmentations",
  }),
  createConfigEntry({
    type: "depth-estimation",
    recordType: "image",
  }),
]);

export function getInferenceType(topic: Maybe<Topic>): InferenceType | null {
  return topicTypeToConfig.get(topic?.typeName as any)?.type ?? null;
}

export function isInferenceTopic(topic: Topic): boolean {
  return getInferenceType(topic) !== null;
}

export function getInferenceRecordType(
  topic: Maybe<Topic>,
): InferenceRecordType | null {
  return topicTypeToConfig.get(topic?.typeName as any)?.recordType ?? null;
}

export function supportsClipInset(type: InferenceType): boolean {
  return ["depth-estimation"].includes(type as any);
}
