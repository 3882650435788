import { useState } from "react";
import { PanToolOutlined } from "@mui/icons-material";
import {
  Card,
  CardContent,
  cardContentClasses,
  keyframes,
  styled,
  svgIconClasses,
  Typography,
} from "@mui/material";
import * as z from "zod";
import { StorageItem, useStorageValue } from "~/utils/storage";

const indicatorShownStorageItem = new StorageItem({
  storage: window.sessionStorage,
  version: 0,
  key: "3d-ind-shown",
  parser: z.boolean().catch(false),
  defaultValue: false,
});

const animation = keyframes({
  "10%": {
    scale: "100%",
  },
  "15%": {
    scale: "90%",
  },
  "20%": {
    // Icon should pause briefly after scaling down (according to the 15%
    // keyframe above) before beginning to move to the right. Without this it
    // will start moving while still scaling down
    left: "3rem",
  },
  "100%": {
    left: "calc(100% - 3rem)",
  },
});

const Root = styled(Card)(({ theme }) => ({
  pointerEvents: "none",
  position: "absolute",
  bottom: "10%",
  left: "50%",
  translate: "-50% 0%",
  maxWidth: `calc(100% - ${theme.spacing(2)})`,
  transition: theme.transitions.create(["opacity"], { duration: 500 }),
  [`& .${cardContentClasses.root}`]: {
    position: "relative",
  },
  [`& .${svgIconClasses.root}`]: {
    width: "3.5rem",
    height: "3.5rem",
    marginBlockStart: theme.spacing(2),
    position: "relative",
    left: "3rem",
    translate: "-50% 0%",
    animationName: animation,
    animationDuration: "2.5s",
    animationIterationCount: 3,
    animationFillMode: "forwards",
  },
}));

type AnimationState = "running" | "leaving" | "finished";

export function InteractivityIndicator() {
  const indicatorShownThisSession = useStorageValue(indicatorShownStorageItem);

  // If another 3D panel finished its animation prior to this component mounting
  // then also consider this component's animation finished.
  const [animationState, setAnimationState] = useState<AnimationState>(
    indicatorShownThisSession ? "finished" : "running",
  );

  function handleAnimationEnd(): void {
    setAnimationState("leaving");
  }

  function handleTransitionEnd(): void {
    indicatorShownStorageItem.set(true);
    setAnimationState("finished");
  }

  if (animationState === "finished") {
    return null;
  }

  return (
    <Root
      style={{ opacity: animationState === "leaving" ? 0 : 1 }}
      onTransitionEnd={handleTransitionEnd}
    >
      <CardContent>
        <Typography variant="h5" component="p" sx={{ textAlign: "center" }}>
          Click and drag to rotate
        </Typography>
        <PanToolOutlined onAnimationEnd={handleAnimationEnd} />
      </CardContent>
    </Root>
  );
}
