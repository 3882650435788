import { CircularProgress, Stack, Typography } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { Center } from "~/components/Center";
import {
  DetailsLayout,
  LockCard,
  RelatedResource,
} from "~/components/DetailsCards";
import {
  OBJECT_KEY_DELIMITER,
  ObjectSearchRequestProvider,
} from "~/components/ObjectExplorer";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import type { Topic } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteTopic,
  useTopic,
  useUpdateTopic,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { LogObjectTable } from "../../logs/components/LogObjectTable";
import { editTopicSchema } from "../schemas";
import { RecordSearchRequestProvider, RecordTable } from "./RecordTable";

export function TopicDetails() {
  const { topicId } = useTypedParams(lqsRoutePaths.TOPIC_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toRecordTable: true,
    toTopicEdit: true,
    toTopicTable: true,
  });

  const query = useTopic(topicId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const typeSection = <TypeSection query={query} />;
  const relatedResourcesSection = (
    <Stack spacing={2}>
      <Typography variant="h4" component="h2">
        Related Resources
      </Typography>
      <RelatedResource
        text="Records"
        to={lqsNavigator.toRecordTable({ topicId })}
        table={
          <RecordSearchRequestProvider embedded>
            <RecordTable />
          </RecordSearchRequestProvider>
        }
      />
      <RelatedObjects query={query} />
    </Stack>
  );
  const infoSection = <InfoSection query={query} />;
  const lockSection = (
    <LockCard
      resourceName="topic"
      query={query}
      updateMutation={useUpdateTopic(topicId)}
    />
  );
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="topic"
      query={query}
      editLocation={lqsNavigator.toTopicEdit({ topicId })}
      deleteMutation={useDeleteTopic(topicId)}
      getReadableName={(topic) => topic.name}
      listLocation={lqsNavigator.toTopicTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          {typeSection}
          {relatedResourcesSection}
        </>
      }
      secondaryGridColumn={
        <>
          {infoSection}
          {lockSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {typeSection}
          {lockSection}
          {historySection}
          {manageSection}
          {relatedResourcesSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Topic> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="topic"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "name" },
          { dataType: "foreign-key", resourceType: "log", accessor: "logId" },
          {
            dataType: "foreign-key",
            resourceType: "topic",
            accessor: "associatedTopicId",
          },
          { dataType: "timestamp", accessor: "startTime" },
          { dataType: "timestamp", accessor: "endTime" },
          { dataType: "duration", accessor: "duration" },
          { dataType: "bytes", accessor: "recordSize" },
          { dataType: "number", accessor: "recordCount" },
          { dataType: "bytes", accessor: "objectSize" },
          { dataType: "number", accessor: "objectCount" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function TypeSection({ query }: { query: UseQueryResult<Topic> }) {
  return (
    <Card title="Type">
      <LqsResourceFields
        registryKey="topic"
        query={query}
        fields={[
          { dataType: "text", accessor: "typeName" },
          { dataType: "text", accessor: "typeEncoding" },
          { dataType: "pre", accessor: "typeData" },
          { dataType: "text", accessor: "typeSchema" },
        ]}
      />
    </Card>
  );
}

function RelatedObjects({ query }: { query: UseQueryResult<Topic> }) {
  const lqsNavigator = useLqsNavigator({ toLogObjectTable: true });

  return (
    <QueryRenderer
      query={query}
      loading={
        <Card>
          <Center sx={{ height: 350 }}>
            <CircularProgress />
          </Center>
        </Card>
      }
      error={
        <Card>
          <ErrorMessage>Couldn't load objects</ErrorMessage>
        </Card>
      }
      success={(topic) => {
        const topicPrefix = ["topics", topic.id, ""].join(OBJECT_KEY_DELIMITER);

        return (
          <RelatedResource
            text="Objects"
            to={lqsNavigator.toLogObjectTable(
              { logId: topic.logId },
              { directory: topicPrefix },
            )}
            table={
              <ObjectSearchRequestProvider embedded>
                <LogObjectTable
                  logId={topic.logId}
                  homeName={topic.id}
                  subResourcePrefix={topicPrefix}
                />
              </ObjectSearchRequestProvider>
            }
          />
        );
      }}
    />
  );
}

function InfoSection({ query }: { query: UseQueryResult<Topic> }) {
  const { topicId } = useTypedParams(lqsRoutePaths.TOPIC_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="topic"
      registryKey="topic"
      disabled={(topic) => topic.locked}
      footer={(topic) =>
        topic.locked && (
          <Typography variant="body2">
            Unlock this topic to make updates.
          </Typography>
        )
      }
      query={query}
      schema={editTopicSchema}
      descriptors={[
        { name: "strict", type: "boolean" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateTopic(topicId)}
    />
  );
}
