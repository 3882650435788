import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar, processStateOptions } from "~/lqs";
import { listDigestionPartsSchema } from "../schemas";
import { useDigestionPartSearchRequest } from "./DigestionPartTable";

export function DigestionPartFilters() {
  const [request, setRequest] = useDigestionPartSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="digestionPart"
      request={request}
      setRequest={setRequest}
      schema={listDigestionPartsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "sequence", type: "number" },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowIdNull", type: "boolean" },
        { name: "state", type: "select", options: processStateOptions },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
