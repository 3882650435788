import { visuallyHidden } from "@mui/utils";

// Jet colormap values pulled from:
// https://github.com/bpostlethwaite/colormap/blob/34061829844afb7cbd509d3c3f36625b283191eb/colorScale.js#L2
const JET = {
  R: normalizeTableValues([0, 0, 5, 255, 250, 128]),
  G: normalizeTableValues([0, 60, 255, 255, 0, 0]),
  B: normalizeTableValues([131, 170, 255, 0, 0, 0]),
};

export function ColorMap({ panelId }: { panelId: number }) {
  return (
    // Firefox doesn't apply a filter if it's a descendant of a `display: none`
    // element or has that style itself so visually hide the <svg> instead
    <svg style={visuallyHidden}>
      <filter id={createColorMapFilterId(panelId)}>
        <feComponentTransfer>
          <feFuncR type="table" tableValues={JET.R} />
          <feFuncG type="table" tableValues={JET.G} />
          <feFuncB type="table" tableValues={JET.B} />
        </feComponentTransfer>
      </filter>
    </svg>
  );
}

export function createColorMapFilterId(panelId: number): string {
  return `cm-${panelId}`;
}

function normalizeTableValues(tableValues: ReadonlyArray<number>): string {
  return tableValues.map((value) => value / 255).join(" ");
}
