import { LoadingButton } from "@mui/lab";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { ProcessState } from "../sdk";

export function ProcessActionButton({
  query,
  mutation,
}: {
  query: UseQueryResult<{ state: ProcessState }>;
  mutation: UseMutationResult<unknown, unknown, { state?: ProcessState }>;
}) {
  const { enqueueSnackbar } = useSnackbar();

  if (!query.isSuccess) {
    return null;
  }

  const stateTransition = stateTransitionsMap.get(query.data.state);
  if (stateTransition === undefined) {
    return null;
  }

  const handleClick = function handleClick() {
    mutation.mutate(
      { state: stateTransition.to },
      {
        onSuccess() {
          enqueueSnackbar(stateTransition.successMessage, {
            variant: "success",
          });
        },
        onError() {
          enqueueSnackbar(stateTransition.errorMessage, {
            variant: "error",
          });
        },
      },
    );
  };

  return (
    <LoadingButton
      variant="contained"
      disableElevation
      loading={mutation.isLoading}
      onClick={handleClick}
    >
      {stateTransition.buttonText}
    </LoadingButton>
  );
}

interface StateTransition {
  to: ProcessState;
  buttonText: string;
  successMessage: string;
  errorMessage: string;
}

const stateTransitionsMap = new Map<ProcessState, StateTransition>([
  [
    ProcessState.Ready,
    {
      to: ProcessState.Queued,
      buttonText: "Queue",
      successMessage: "Queued",
      errorMessage: "Failed to queue",
    },
  ],
  [
    ProcessState.Failed,
    {
      to: ProcessState.Queued,
      buttonText: "Retry",
      successMessage: "Retrying",
      errorMessage: "Failed to begin retry attempt",
    },
  ],
]);
