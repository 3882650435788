import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { DigestionDetails } from "./components/DigestionDetails";

export function DigestionPage() {
  return (
    <LqsPage title="Digestion Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <DigestionDetails />
      </Container>
    </LqsPage>
  );
}
