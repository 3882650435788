import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { TopicDetails } from "./components/TopicDetails";

export function TopicPage() {
  return (
    <LqsPage title="Topic Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <TopicDetails />
      </Container>
    </LqsPage>
  );
}
