import { invariant } from "~/lib/invariant";
import { reject, some } from "~/lib/std";
import type { RuntimeLayoutProfile } from "./models";
import { RuntimeProfileArraySchema, StoredProfileArraySchema } from "./models";

export const STORAGE_KEY = "layout-profiles";

export async function getLayoutProfiles(): Promise<RuntimeLayoutProfile[]> {
  let storedValue: string | null;
  try {
    storedValue = localStorage.getItem(STORAGE_KEY);
  } catch {
    throw new Error("Couldn't load profiles from storage");
  }

  if (storedValue === null) {
    return [];
  }

  let parsedValue: unknown;
  try {
    parsedValue = JSON.parse(storedValue);
  } catch {
    throw new Error("Stored profiles were corrupted");
  }

  try {
    return RuntimeProfileArraySchema.parse(parsedValue);
  } catch {
    throw new Error("Stored profiles were corrupted");
  }
}

export async function createLayoutProfile(
  newProfile: RuntimeLayoutProfile,
): Promise<void> {
  const currentProfiles = await getLayoutProfiles();

  invariant(
    !some(currentProfiles, { name: newProfile.name }),
    `Profile with name "${newProfile.name}" already exists`,
  );

  writeProfilesToStorage([...currentProfiles, newProfile]);
}

export async function deleteLayoutProfile(
  profileName: RuntimeLayoutProfile["name"],
): Promise<void> {
  const currentProfiles = await getLayoutProfiles();

  invariant(
    some(currentProfiles, { name: profileName }),
    `Couldn't find profile with name "${profileName}" to delete`,
  );

  writeProfilesToStorage(reject(currentProfiles, { name: profileName }));
}

function writeProfilesToStorage(profiles: RuntimeLayoutProfile[]): void {
  try {
    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify(StoredProfileArraySchema.parse(profiles)),
    );
  } catch {
    /* noop */
  }
}
