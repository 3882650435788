import { Box } from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout } from "~/components/DetailsCards";
import type { Label } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteLabel,
  useLabel,
  useUpdateLabel,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editLabelSchema } from "../schemas";

export function LabelDetails() {
  const { labelId } = useTypedParams(lqsRoutePaths.LABEL_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toLabelEdit: true,
    toLabelTable: true,
  });

  const query = useLabel(labelId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="label"
      query={query}
      editLocation={lqsNavigator.toLabelEdit({ labelId })}
      deleteMutation={useDeleteLabel(labelId)}
      getReadableName={(label) => label.value}
      listLocation={lqsNavigator.toLabelTable()}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={
        <>
          {generalSection}
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: 3,
            }}
          >
            {infoSection}
            {manageSection}
          </Box>
        </>
      }
      secondaryGridColumn={historySection}
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Label> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="label"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "value" },
          { dataType: "text", accessor: "category" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Label> }) {
  const { labelId } = useTypedParams(lqsRoutePaths.LABEL_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="label"
      registryKey="label"
      query={query}
      schema={editLabelSchema}
      descriptors={[{ name: "note", type: "text", multiline: true }]}
      mutation={useUpdateLabel(labelId)}
    />
  );
}
