/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DataStoreAssociationUpdateRequest {
  manager?: boolean;
  disabled?: boolean;
  datastoreUsername?: string | null;
  datastoreRoleId?: string | null;
  datastoreAdmin?: boolean;
  datastoreDisabled?: boolean;
}

export function DataStoreAssociationUpdateRequestFromJSON(
  json: any,
): DataStoreAssociationUpdateRequest {
  return DataStoreAssociationUpdateRequestFromJSONTyped(json);
}

export function DataStoreAssociationUpdateRequestFromJSONTyped(
  json: any,
): DataStoreAssociationUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    manager: json["manager"],
    disabled: json["disabled"],
    datastoreUsername: json["datastore_username"],
    datastoreRoleId: json["datastore_role_id"],
    datastoreAdmin: json["datastore_admin"],
    datastoreDisabled: json["datastore_disabled"],
  };
}

export function DataStoreAssociationUpdateRequestToJSON(
  value: DataStoreAssociationUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    manager: value.manager,
    disabled: value.disabled,
    datastore_username: value.datastoreUsername,
    datastore_role_id: value.datastoreRoleId,
    datastore_admin: value.datastoreAdmin,
    datastore_disabled: value.datastoreDisabled,
  };
}
