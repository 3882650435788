/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Role } from "./Role";
import { RoleFromJSON, RoleFromJSONTyped, RoleToJSON } from "./Role";

export interface RoleDataResponse {
  data: Role;
}

export function RoleDataResponseFromJSON(json: any): RoleDataResponse {
  return RoleDataResponseFromJSONTyped(json);
}

export function RoleDataResponseFromJSONTyped(json: any): RoleDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: RoleFromJSON(json["data"]),
  };
}

export function RoleDataResponseToJSON(
  value: RoleDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: RoleToJSON(value.data),
  };
}
