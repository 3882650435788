import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { LogObjectUploadForm } from "./components/LogObjectUploadForm";

export function UploadLogObjectPage() {
  return (
    <LqsPage title="Upload Object">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LogObjectUploadForm />
      </Container>
    </LqsPage>
  );
}
