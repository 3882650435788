import type { CheckboxProps as MuiCheckboxProps } from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { getFieldLabel } from "~/domain/common";
import { DeprecableLabel } from "~/domain/versioning";
import { useField } from "./hooks";
import type { BaseInputProps, FieldPropsFromInputProps } from "./types";

interface CheckboxInputProps
  extends BaseInputProps<boolean | null>,
    Pick<MuiCheckboxProps, "size" | "disabled"> {}

export function CheckboxInput({
  name,
  label = getFieldLabel(name),
  deprecated,
  value,
  onChange,
  size = "medium",
  disabled = false,
}: CheckboxInputProps) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value ?? false}
          onChange={(e) => onChange(e.target.checked)}
          size={size}
          disabled={disabled}
        />
      }
      label={<DeprecableLabel deprecated={deprecated}>{label}</DeprecableLabel>}
    />
  );
}

export function CheckboxField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean | null>,
>({
  control,
  ...rest
}: FieldPropsFromInputProps<TFieldValues, TName, CheckboxInputProps>) {
  const { value, onChange } = useField({
    control,
    name: rest.name,
  });

  return <CheckboxInput {...rest} value={value} onChange={onChange} />;
}
