import * as z from "zod";
import type { User } from "~/dsm";
import { subscribeToAuthEvent } from "~/lib/auth";
import { startOfTomorrow } from "~/lib/dates";
import { get } from "~/lib/std";
import { StorageItem, useStorageValue } from "~/utils/storage";

export function checkCompanyNotApplicable(user: User): boolean {
  return get(user.context, "studio.company_not_applicable") === true;
}

const bannerTimeoutAtStorageItem = StorageItem.usingLocalStorage({
  version: 0,
  key: "banner-timeout-at",
  parser: z.coerce.date().nullable(),
  defaultValue: null,
});

subscribeToAuthEvent(({ payload }) => {
  if (payload.event !== "signedOut") {
    return;
  }

  // Reason for not notifying storage item subscribers:
  // The auth library will refresh or redirect the page shortly, so there's
  // nothing useful for this storage item's subscribers to do in response to the
  // value being removed. More importantly, if the subscribers are notified it
  // seems React is able to re-render and run the banner's Effect before the
  // refresh/redirect occurs because the storage value is back afterwards.
  // That's definitely not desired behavior.
  bannerTimeoutAtStorageItem.remove({ notify: false });
});

export function useHasBannerTimeoutPassed(): boolean {
  const bannerTimeoutAt = useStorageValue(bannerTimeoutAtStorageItem);

  if (bannerTimeoutAt == null) {
    return true;
  }

  return Date.now() - Number(bannerTimeoutAt) >= 0;
}

export function setProfileBannerTimeout(): void {
  bannerTimeoutAtStorageItem.set(startOfTomorrow());
}
