/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  HTTPValidationError,
  TicketCreateRequest,
  TicketDataResponse,
} from "../models";
import {
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  TicketCreateRequestFromJSON,
  TicketCreateRequestToJSON,
  TicketDataResponseFromJSON,
  TicketDataResponseToJSON,
} from "../models";

export interface CreateTicketRequest {
  ticketCreateRequest: TicketCreateRequest;
}

export class TicketApi extends runtime.BaseAPI {
  async createTicket(
    this: TicketApi,
    requestParameters: CreateTicketRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<TicketDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.ticketCreateRequest,
      "ticketCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/tickets`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: TicketCreateRequestToJSON(requestParameters.ticketCreateRequest),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return TicketDataResponseFromJSON(jsonValue);
  }
}
