import React from "react";
import { LoadingButton } from "@mui/lab";
import { Stack } from "@mui/material";
import type { SubmitHandler } from "react-hook-form";

export function Form({
  onSubmit,
  loading,
  submitText,
  children,
  disabled = false,
  footer,
}: {
  onSubmit: SubmitHandler<any>;
  loading: boolean;
  submitText: string;
  children: React.ReactNode;
  disabled?: boolean;
  footer?: React.ReactNode;
}) {
  return (
    <Stack
      spacing={2}
      sx={{ maxWidth: 400, mx: "auto" }}
      component="form"
      onSubmit={onSubmit}
      noValidate
    >
      {children}
      <Stack>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={disabled}
          loading={loading}
          disableElevation
          sx={{ alignSelf: "baseline" }}
        >
          {submitText}
        </LoadingButton>
        {footer}
      </Stack>
    </Stack>
  );
}
