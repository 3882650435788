import type { StrictOmit } from "ts-essentials";
import type { HistoryCardProps } from "~/components/DetailsCards";
import { HistoryCard } from "~/components/DetailsCards";
import { renderForeignResource } from "./renderForeignResource";
import { useDsmVersion } from "./versioning";

type DsmHistoryCardProps = StrictOmit<
  HistoryCardProps,
  "apiVersion" | "deprecationPolicy" | "renderForeignResource"
>;

export function DsmHistoryCard(props: DsmHistoryCardProps) {
  const apiVersion = useDsmVersion();

  return (
    <HistoryCard
      {...props}
      apiVersion={apiVersion}
      deprecationPolicy="hide"
      renderForeignResource={renderForeignResource}
    />
  );
}
