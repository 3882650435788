import { invariant } from "~/lib/invariant";
import { useDataStoreClients } from "../context";
import type {
  Group,
  GroupCreateRequest,
  GroupDataResponse,
  GroupListResponse,
  GroupUpdateRequest,
  ListGroupsRequest,
} from "../sdk";
import type { LqsQueryOptions } from "./utils";
import { createResourceCrudHooks } from "./utils";

export const {
  queryKeyFactory: groupKeys,
  useList: useGroups,
  useFetch: useGroup,
  useCreate: useCreateGroup,
  useUpdate: useUpdateGroup,
  useDelete: useDeleteGroup,
} = createResourceCrudHooks({
  baseQueryKey: "groups",
  getIdentifier(group: Group) {
    return group.id;
  },
  listResource({ signal }, { groupApi }, request: ListGroupsRequest) {
    return groupApi.listGroups(request, { signal });
  },
  fetchResource({ signal }, { groupApi }, groupId: Group["id"]) {
    return groupApi.fetchGroup({ groupId }, { signal });
  },
  createResource({ groupApi }, request: GroupCreateRequest) {
    return groupApi.createGroup({ groupCreateRequest: request });
  },
  updateResource(
    { groupApi },
    groupId: Group["id"],
    updates: GroupUpdateRequest,
  ) {
    return groupApi.updateGroup({ groupId, groupUpdateRequest: updates });
  },
  deleteResource({ groupApi }, groupId: Group["id"]) {
    return groupApi.deleteGroup({ groupId });
  },
});

export function useGroupsQueryOptionsFactory(): (
  request: ListGroupsRequest,
) => LqsQueryOptions<GroupListResponse> {
  const { groupApi } = useDataStoreClients();

  return (request) => ({
    queryKey: groupKeys.list(request),
    queryFn({ signal }) {
      return groupApi.listGroups(request, { signal });
    },
  });
}

export function useGroupQueryOptionsFactory(): (
  groupId: Group["id"] | null,
) => LqsQueryOptions<GroupDataResponse, "enabled"> {
  const { groupApi } = useDataStoreClients();

  return (groupId) => {
    const enabled = groupId !== null;

    return {
      queryKey: groupKeys.fetch(groupId),
      queryFn({ signal }) {
        invariant(enabled, "Group ID not provided");

        return groupApi.fetchGroup({ groupId }, { signal });
      },
      enabled,
    };
  };
}
