import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { RecordEditForm } from "./components/RecordEditForm";

export function EditRecordPage() {
  return (
    <LqsPage title="Edit Record">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <RecordEditForm />
      </Container>
    </LqsPage>
  );
}
