import { useMutation } from "@tanstack/react-query";
import { NewResourceForm } from "~/components/Form";
import { useCommonResourcesContextValue } from "../../../context";
import { renderCommonForeignKeyFormField } from "../../../forms";
import { createUserSchema } from "../schemas";

export function UserCreateForm() {
  const {
    apiVersion,
    createUserCreateMutationOptions,
    createUserDetailsLocation,
  } = useCommonResourcesContextValue();

  return (
    <NewResourceForm
      resourceName="user"
      schema={createUserSchema}
      descriptors={[
        { name: "username", type: "text", required: true },
        { name: "roleId", type: "foreign-key", resourceType: "role" },
        { name: "admin", type: "boolean" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "externalId", type: "text" },
      ]}
      apiVersion={apiVersion}
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderCommonForeignKeyFormField}
      mutation={useMutation(createUserCreateMutationOptions())}
      createDetailsLocation={(response) =>
        createUserDetailsLocation({ userId: response.data.id })
      }
    />
  );
}
