/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RoleUpdateRequest {
  name?: string;
  policy?: object;
  note?: string | null;
  disabled?: boolean;
  _default?: boolean;
}

export function RoleUpdateRequestFromJSON(json: any): RoleUpdateRequest {
  return RoleUpdateRequestFromJSONTyped(json);
}

export function RoleUpdateRequestFromJSONTyped(json: any): RoleUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    name: json["name"],
    policy: json["policy"],
    note: json["note"],
    disabled: json["disabled"],
    _default: json["default"],
  };
}

export function RoleUpdateRequestToJSON(
  value: RoleUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value.name,
    policy: value.policy,
    note: value.note,
    disabled: value.disabled,
    default: value._default,
  };
}
