import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { RecordCreateForm } from "./components/RecordCreateForm";

export function NewRecordPage() {
  return (
    <LqsPage title="New Record">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <RecordCreateForm />
      </Container>
    </LqsPage>
  );
}
