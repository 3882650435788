/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface LabelCreateRequest {
  value: string;
  note?: string | null;
  category?: string | null;
}

export function LabelCreateRequestFromJSON(json: any): LabelCreateRequest {
  return LabelCreateRequestFromJSONTyped(json);
}

export function LabelCreateRequestFromJSONTyped(json: any): LabelCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    value: json["value"],
    note: json["note"],
    category: json["category"],
  };
}

export function LabelCreateRequestToJSON(
  value: LabelCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    value: value.value,
    note: value.note,
    category: value.category,
  };
}
