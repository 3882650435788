export function bigintMax(
  value: bigint,
  ...values: ReadonlyArray<bigint>
): bigint;
export function bigintMax(...values: ReadonlyArray<bigint>): bigint | undefined;
export function bigintMax(
  ...values: ReadonlyArray<bigint>
): bigint | undefined {
  if (values.length === 0) {
    return undefined;
  } else {
    return values.reduce((currentMax, value) =>
      value > currentMax ? value : currentMax,
    );
  }
}

export function bigintMin(
  value: bigint,
  ...values: ReadonlyArray<bigint>
): bigint;
export function bigintMin(...values: ReadonlyArray<bigint>): bigint | undefined;
export function bigintMin(
  ...values: ReadonlyArray<bigint>
): bigint | undefined {
  if (values.length === 0) {
    return undefined;
  } else {
    return values.reduce((currentMin, value) =>
      value < currentMin ? value : currentMin,
    );
  }
}

export function bigintClamp(
  value: bigint,
  lowerBound: bigint,
  upperBound: bigint,
): bigint {
  return bigintMin(bigintMax(value, lowerBound), upperBound);
}
