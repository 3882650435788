import type { FileRejection, FileWithPath } from "react-dropzone";

export function getNormalizedFilePath(file: FileWithPath): string {
  const path = file.path!;
  if (path.startsWith("/")) {
    return path.slice(1);
  } else if (path.startsWith("./")) {
    return path.slice(2);
  } else {
    return path;
  }
}

interface DirectoryNode {
  type: "directory";
  name: string;
  children: ReadonlyArray<FileTreeNode>;
}

interface FileNode {
  type: "file";
  selection: FileWithPath | FileRejection;
}

export type FileTreeNode = DirectoryNode | FileNode;

export function treeifySelectedFiles(
  selections: ReadonlyArray<FileWithPath | FileRejection>,
): ReadonlyArray<FileTreeNode> {
  // Selected files not contained in a directory along with any top-level
  // directories containing other files.
  const rootNodes = new Array<FileTreeNode>();

  // Map a directory's relative path to its list of child nodes so sibling
  // files can quickly access the list.
  const cache = new Map<string, Array<FileTreeNode>>();

  for (const selection of sortSelections(selections)) {
    const file = selection instanceof File ? selection : selection.file;

    const normalizedPath = getNormalizedFilePath(file);
    const pathParts = normalizedPath.split("/");
    // This will be empty for top-level files. For all other files, the parent
    // parts will form the path to the file's final directory node.
    const parentParts = pathParts.slice(0, -1);

    let currentChildren = rootNodes;
    parentParts.forEach((parentPart, index) => {
      const relativePath = parentParts.slice(0, index + 1).join("/");

      if (cache.has(relativePath)) {
        // A previous file had the same path up to this point so the
        // corresponding directory node's list of child nodes is already cached.
        currentChildren = cache.get(relativePath)!;
      } else {
        // No previous file had the same path up to this point, so need to
        // create a new directory node, push the new node into its parent's
        // list of child nodes (`currentChildren`), and cache the new list of
        // child nodes.

        const nextChildren = new Array<FileTreeNode>();

        currentChildren.push({
          type: "directory",
          name: parentPart,
          children: nextChildren,
        });
        cache.set(relativePath, nextChildren);

        currentChildren = nextChildren;
      }
    });

    // Whether this file was at the top-level of the selections or part of a
    // directory, `currentChildren` now represents its parent's list of child
    // nodes.
    currentChildren.push({
      type: "file",
      selection,
    });
  }

  return rootNodes;
}

const collator = new Intl.Collator(undefined, {
  usage: "sort",
  numeric: true,
});

export function sortSelections<TSelection extends File | FileRejection>(
  selections: ReadonlyArray<TSelection>,
): ReadonlyArray<TSelection> {
  return selections.slice().sort((a, b) => {
    const aFile = a instanceof File ? a : a.file;
    const bFile = b instanceof File ? b : b.file;

    return collator.compare(
      getNormalizedFilePath(aFile),
      getNormalizedFilePath(bFile),
    );
  });
}
