/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProcessState } from "./ProcessState";
import {
  ProcessStateFromJSON,
  ProcessStateFromJSONTyped,
  ProcessStateToJSON,
} from "./ProcessState";

export interface Ingestion {
  locked: boolean;
  lockedBy: string | null;
  lockedAt: Date | null;
  lockToken: string | null;
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  workflowId: string | null;
  workflowContext: object | null;
  state: ProcessState;
  progress: number | null;
  previousState: ProcessState | null;
  transitionedAt: Date | null;
  error: object | null;
  errorName: string | null;
  errorMessage: string | null;
  errorPayload: object | null;
  logId: string;
  objectStoreId: string | null;
  objectKey: string | null;
  name: string | null;
  note: string | null;
  context: object | null;
  groupId: string | null;
}

export function IngestionFromJSON(json: any): Ingestion {
  return IngestionFromJSONTyped(json);
}

export function IngestionFromJSONTyped(json: any): Ingestion {
  if (json == null) {
    return json;
  }

  return {
    locked: json["locked"],
    lockedBy: json["locked_by"],
    lockedAt:
      json["locked_at"] == null
        ? json["locked_at"]
        : new Date(json["locked_at"]),
    lockToken: json["lock_token"],
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null
        ? json["updated_at"]
        : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] == null
        ? json["deleted_at"]
        : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    workflowId: json["workflow_id"],
    workflowContext: json["workflow_context"],
    state: ProcessStateFromJSON(json["state"]),
    progress: json["progress"],
    previousState: ProcessStateFromJSON(json["previous_state"]),
    transitionedAt:
      json["transitioned_at"] == null
        ? json["transitioned_at"]
        : new Date(json["transitioned_at"]),
    error: json["error"],
    errorName: json["error_name"],
    errorMessage: json["error_message"],
    errorPayload: json["error_payload"],
    logId: json["log_id"],
    objectStoreId: json["object_store_id"],
    objectKey: json["object_key"],
    name: json["name"],
    note: json["note"],
    context: json["context"],
    groupId: json["group_id"],
  };
}

export function IngestionToJSON(value: Ingestion | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    locked: value.locked,
    locked_by: value.lockedBy,
    locked_at: value.lockedAt === null ? null : value.lockedAt.toISOString(),
    lock_token: value.lockToken,
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    workflow_id: value.workflowId,
    workflow_context: value.workflowContext,
    state: ProcessStateToJSON(value.state),
    progress: value.progress,
    previous_state: ProcessStateToJSON(value.previousState),
    transitioned_at:
      value.transitionedAt === null ? null : value.transitionedAt.toISOString(),
    error: value.error,
    error_name: value.errorName,
    error_message: value.errorMessage,
    error_payload: value.errorPayload,
    log_id: value.logId,
    object_store_id: value.objectStoreId,
    object_key: value.objectKey,
    name: value.name,
    note: value.note,
    context: value.context,
    group_id: value.groupId,
  };
}
