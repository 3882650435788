import React from "react";
import { LoadingButton } from "@mui/lab";
import { styled, Typography } from "@mui/material";
import { Center } from "~/components/Center";
import { ErrorMessage } from "~/components/error-message";
import { PlaybackController, TopicSection } from "./components";
import { usePlayerLog, usePlayerParams } from "./hooks";
import { logIsBounded, logIsTooLong } from "./utils";

const Root = styled("div")({
  flex: 1,
  minWidth: 0,
  minHeight: 0,
  display: "flex",
  flexDirection: "column",
});

export function Player({
  noLogMessage = "No log selected for playback",
  disableSettingDefaultLayout,
}: {
  noLogMessage?: string;
  disableSettingDefaultLayout?: boolean;
}) {
  const { logId } = usePlayerParams();

  const logQuery = usePlayerLog();

  let content;
  if (logId == null) {
    content = (
      <Center>
        <Typography variant="h5" component="p" paragraph sx={{ px: 4 }}>
          {noLogMessage}
        </Typography>
      </Center>
    );
  } else if (logQuery.isSuccess) {
    if (!logIsBounded(logQuery.data)) {
      content = (
        <Center>
          <Typography variant="h5" component="p" paragraph>
            This log doesn't have any records to play yet
          </Typography>
          <LoadingButton
            variant="contained"
            loading={logQuery.isRefetching}
            onClick={() => logQuery.refetch()}
          >
            Refresh
          </LoadingButton>
        </Center>
      );
    } else if (logIsTooLong(logQuery.data)) {
      content = (
        <ErrorMessage>
          <ErrorMessage.Paragraph>
            This log is too long to play.
          </ErrorMessage.Paragraph>
          <ErrorMessage.Paragraph>
            Studio only supports logs less than 24 hours long.
          </ErrorMessage.Paragraph>
        </ErrorMessage>
      );
    } else {
      content = <TopicSection />;
    }
  } else {
    content = <TopicSection />;
  }

  return (
    <Root>
      {content}
      <PlaybackController
        disableSettingDefaultLayout={disableSettingDefaultLayout}
      />
    </Root>
  );
}
