import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout } from "~/components/DetailsCards";
import type { Tag } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteTag,
  useTag,
  useUpdateTag,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editTagSchema } from "../schemas";

export function TagDetails() {
  const { logId, tagId } = useTypedParams(lqsRoutePaths.LOG_TAG_DETAILS);

  const lqsNavigator = useLqsNavigator({
    toLogTagEdit: true,
    toLogTagTable: true,
  });

  const query = useTag(logId, tagId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="tag"
      query={query}
      editLocation={lqsNavigator.toLogTagEdit({ logId, tagId })}
      deleteMutation={useDeleteTag(logId, tagId)}
      getReadableName={(tag) => tag.id}
      listLocation={lqsNavigator.toLogTagTable({ logId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Tag> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="tag"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "foreign-key", resourceType: "log", accessor: "logId" },
          {
            dataType: "foreign-key",
            resourceType: "label",
            accessor: "labelId",
          },
          {
            dataType: "foreign-key",
            resourceType: "topic",
            accessor: "topicId",
          },
          { dataType: "timestamp", accessor: "startTime" },
          { dataType: "timestamp", accessor: "endTime" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Tag> }) {
  const { logId, tagId } = useTypedParams(lqsRoutePaths.LOG_TAG_DETAILS);

  return (
    <EditLqsResourceForm
      resourceName="tag"
      registryKey="tag"
      query={query}
      schema={editTagSchema}
      descriptors={[{ name: "note", type: "text", multiline: true }]}
      mutation={useUpdateTag(logId, tagId)}
    />
  );
}
