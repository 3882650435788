import prettyMilliseconds from "pretty-ms";
import { nanosecondsToMilliseconds } from "~/lib/dates";
import type { Maybe } from "~/types";

export function Duration({ value }: { value: Maybe<bigint> }) {
  return value == null
    ? "-"
    : prettyMilliseconds(nanosecondsToMilliseconds(value), {
        secondsDecimalDigits: 0,
      });
}
