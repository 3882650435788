import type { Log } from "~/lqs";

export interface LogMarker {
  id: Log["id"];
  name: Log["name"];
  coordinates: {
    longitude: number;
    latitude: number;
  };
}

export function calculateLogMarkers(logs: Log[]): LogMarker[] {
  return logs
    .map((log): LogMarker | null => {
      if (
        log.context === null ||
        !("sample_coordinates" in log.context) ||
        log.context.sample_coordinates == null
      ) {
        return null;
      }

      const { name, longitude, latitude } = log.context
        .sample_coordinates as any;

      return {
        id: log.id,
        name: name ?? log.name,
        coordinates: {
          longitude,
          latitude,
        },
      };
    })
    .filter((value) => value !== null);
}
