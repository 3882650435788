import React from "react";
import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { Path } from "react-router-dom";
import type { MarkRequired } from "ts-essentials";
import { ResourceInfo } from "~/components/resource-info";
import { assertNever } from "~/utils";
import { useCommonResourcesContextValue } from "./context";
import type { CommonForeignResourceType } from "./types";

function createOptions<TQueryFnData>(
  options: MarkRequired<
    UseQueryOptions<TQueryFnData, unknown, string | null>,
    "select"
  >,
) {
  return options;
}

function CommonForeignResourceLink({
  uuid,
  resourceType,
}: {
  uuid: string;
  resourceType: CommonForeignResourceType;
}) {
  const {
    createRoleDetailsLocation,
    createRoleFetchQueryOptions,
    createUserDetailsLocation,
    createUserFetchQueryOptions,
  } = useCommonResourcesContextValue();

  let queryOptions: UseQueryOptions<any, unknown, string | null>;
  let to: Partial<Path> | undefined = undefined;
  switch (resourceType) {
    case "user": {
      queryOptions = createOptions({
        ...createUserFetchQueryOptions(uuid),
        select(response) {
          return response.data.username;
        },
      });

      to = createUserDetailsLocation({ userId: uuid });

      break;
    }
    case "role": {
      queryOptions = createOptions({
        ...createRoleFetchQueryOptions(uuid),
        select(response) {
          return response.data.name;
        },
      });

      to = createRoleDetailsLocation?.({ roleId: uuid });

      break;
    }
    default: {
      assertNever(resourceType);
    }
  }

  const query = useQuery(queryOptions);

  return <ResourceInfo name={query.data} identifier={uuid} to={to} />;
}

export function renderForeignResource(
  uuid: string,
  resourceType: CommonForeignResourceType,
): React.ReactNode {
  return <CommonForeignResourceLink uuid={uuid} resourceType={resourceType} />;
}
