import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { WorkflowCreateForm } from "./components/WorkflowCreateForm";

export function NewWorkflowPage() {
  return (
    <LqsPage title="New Workflow">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <WorkflowCreateForm />
      </Container>
    </LqsPage>
  );
}
