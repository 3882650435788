import React from "react";
import type { StrictOmit } from "ts-essentials";
import type { EditResourceFormProps } from "~/components/Form";
import { EditResourceForm } from "~/components/Form";
import type {
  GetLqsResourceModel,
  GetLqsResourceRequest,
  LqsResource,
} from "../types";
import { lqsResourceVersionRegistry, useLqsVersion } from "../versioning";
import { renderLqsForeignKeyFormField } from "./render-lqs-foreign-key-form-field";
import type {
  LqsForeignKeyFormFieldType,
  LqsFormFieldDescriptors,
} from "./types";

interface EditLqsResourceFormProps<TResourceKey extends LqsResource>
  extends StrictOmit<
    EditResourceFormProps<
      GetLqsResourceModel<TResourceKey>,
      GetLqsResourceRequest<TResourceKey, "update">,
      LqsForeignKeyFormFieldType
    >,
    | "descriptors"
    | "apiVersion"
    | "versionHistories"
    | "deprecationPolicy"
    | "renderForeignKeyFormField"
  > {
  registryKey: TResourceKey;
  /**
   * See overridden prop's description explaining when `resource` may be
   * `undefined` for the functional form of this prop.
   */
  descriptors:
    | LqsFormFieldDescriptors<GetLqsResourceRequest<TResourceKey, "update">>
    | ((
        resource?: GetLqsResourceModel<TResourceKey>,
      ) => LqsFormFieldDescriptors<
        GetLqsResourceRequest<TResourceKey, "update">
      >);
}

export function EditLqsResourceForm<TResourceKey extends LqsResource>({
  registryKey,
  ...rest
}: EditLqsResourceFormProps<TResourceKey>) {
  const lqsVersion = useLqsVersion();

  return (
    <EditResourceForm
      {...rest}
      apiVersion={lqsVersion}
      versionHistories={
        lqsResourceVersionRegistry[registryKey]?.operations?.update
      }
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderLqsForeignKeyFormField}
    />
  );
}
