/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export const StatementAction = {
  Read: "read",
  Write: "write",
  Star: "*",
  Create: "create",
  List: "list",
  Fetch: "fetch",
  Update: "update",
  Delete: "delete",
} as const;
export type StatementAction =
  (typeof StatementAction)[keyof typeof StatementAction];

export function StatementActionFromJSON(json: any): StatementAction {
  return StatementActionFromJSONTyped(json);
}

export function StatementActionFromJSONTyped(json: any): StatementAction {
  return json as StatementAction;
}

export function StatementActionToJSON(value?: StatementAction | null): any {
  return value as any;
}
