import { get, sortedLastIndexBy } from "~/lib/std";
import type { Record, Topic } from "~/lqs";
import type {
  GeographicPointConfiguration,
  InitializedPanelNode,
} from "../../panels";
import {
  getMapSubVisualization,
  supportsVisualization,
  VisualizationType,
} from "../../panels";

const LON_MAX = 180;
const LON_MIN = -180;
// Min and max latitude need to be clamped between [-85, 85] as opposed to
// [-90, 90]. See link with reference to these values:
// https://math.gl/modules/web-mercator/docs/developer-guide/about-coordinates#lnglat-coordinates
const LAT_MAX = 85;
const LAT_MIN = -85;

export interface StampedCoordinates {
  timestamp: bigint;
  includesTag: boolean;
  coordinates: [number, number];
}

export type StampedCoordinatesResult =
  | { status: "invalid-data" }
  | { status: "success"; value: Array<StampedCoordinates> };

export function selectStampedCoordinates(
  records: ReadonlyArray<Record>,
  selectedTag: string | null,
  pointConfiguration: GeographicPointConfiguration,
): StampedCoordinatesResult {
  const stampedCoordinates = new Array<StampedCoordinates>();

  for (const record of records) {
    if (record.queryData === null) {
      // This record has no data and should be ignored
      continue;
    }

    const longitude = get(record.queryData, pointConfiguration.longitude);
    const latitude = get(record.queryData, pointConfiguration.latitude);

    if (typeof longitude !== "number" || typeof latitude !== "number") {
      // This record doesn't conform to the expected point configuration. Assume
      // this is the case for all records and consider this topic to have
      // invalid data.
      return { status: "invalid-data" };
    }

    if (longitude === 0 || latitude === 0) {
      // Some individual points have bad data that should be ignored
      continue;
    }

    const includesTag =
      selectedTag !== null &&
      record.context != null &&
      "tags" in record.context &&
      Array.isArray(record.context.tags) &&
      record.context.tags.includes(selectedTag);

    stampedCoordinates.push({
      timestamp: record.timestamp,
      includesTag,
      coordinates: [longitude, latitude],
    });
  }

  if (stampedCoordinates.length === 0) {
    return { status: "invalid-data" };
  }

  return { status: "success", value: stampedCoordinates };
}

export function findCoordinateExtremes(result: StampedCoordinatesResult) {
  let minLon = LON_MAX;
  let maxLon = LON_MIN;
  let minLat = LAT_MAX;
  let maxLat = LAT_MIN;

  if (result.status === "success") {
    for (const {
      coordinates: [longitude, latitude],
    } of result.value) {
      if (
        LON_MIN <= longitude &&
        longitude <= LON_MAX &&
        LAT_MIN <= latitude &&
        latitude <= LAT_MAX
      ) {
        minLon = Math.min(minLon, longitude);
        maxLon = Math.max(maxLon, longitude);

        minLat = Math.min(minLat, latitude);
        maxLat = Math.max(maxLat, latitude);
      }
    }
  }

  return { minLon, maxLon, minLat, maxLat };
}

export function partitionCoordinates(
  timestamp: bigint,
  stampedCoordinates: ReadonlyArray<StampedCoordinates>,
): [Array<[lon: number, lat: number]>, Array<[lon: number, lat: number]>] {
  // All coordinates below this index have a timestamp <= the current timestamp
  const partitionIndex = sortedLastIndexBy(
    stampedCoordinates,
    { timestamp } as StampedCoordinates,
    "timestamp",
  );

  const coordinates = stampedCoordinates.map(({ coordinates }) => coordinates);

  const traversedCoordinates = coordinates.slice(0, partitionIndex);
  // The most recent coordinate must be included in both partitions for the
  // lines to appear visually connected on screen
  const untraversedCoordinates = coordinates.slice(
    Math.max(0, partitionIndex - 1),
  );

  return [traversedCoordinates, untraversedCoordinates];
}

export function getTopicTags(topic: Topic): Array<string> | null {
  if (topic.context === null) {
    return null;
  }

  if (!("tags" in topic.context)) {
    return null;
  }

  if (!Array.isArray(topic.context.tags)) {
    return null;
  }

  if (!topic.context.tags.every((tag) => typeof tag === "string")) {
    return null;
  }

  return topic.context.tags;
}

export function filterSupplementaryTopics(
  baseTopic: Topic,
  topics: ReadonlyArray<Topic>,
): ReadonlyArray<Topic> {
  return topics.filter(
    (topic) =>
      supportsVisualization(topic.typeName, VisualizationType.Map) &&
      getMapSubVisualization(topic.typeName) === "gps" &&
      topic.name !== baseTopic.name,
  );
}

export function findSelectedSupplementaryTopic(
  supplementaryTopics: ReadonlyArray<Topic>,
  panel: InitializedPanelNode,
): Topic | null {
  if (panel.supplementaryMapTopics.length === 0) {
    return null;
  } else {
    const {
      supplementaryMapTopics: [selectedName],
    } = panel;

    return (
      supplementaryTopics.find((topic) => topic.name === selectedName) ?? null
    );
  }
}
