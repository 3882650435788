import type { Simplify } from "type-fest";
import { invariant } from "~/lib/invariant";

type Handler<TParams extends ReadonlyArray<any>, TReturn> = (
  ...params: TParams
) => TReturn;

type EventHandlerProps<
  TName extends string,
  TParams extends ReadonlyArray<any>,
  TReturn,
> = Simplify<
  {
    disabled: boolean;
  } & {
    [Name in TName]: Handler<TParams, TReturn>;
  }
>;

export function getEventHandlerProps<
  const TName extends string,
  TParams extends ReadonlyArray<any>,
  TReturn,
>(
  name: TName,
  handler: false | null | undefined | Handler<TParams, TReturn>,
): EventHandlerProps<TName, TParams, TReturn> {
  const disabled = typeof handler !== "function";

  return {
    disabled,
    [name](...params: TParams): TReturn {
      invariant(!disabled, "Event handler is disabled");

      return handler(...params);
    },
  } as EventHandlerProps<TName, TParams, TReturn>;
}
