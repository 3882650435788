import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import type { StrictOmit } from "ts-essentials";
import * as z from "zod";
import type { TableSchemaShape } from "~/components/Form";
import type { Column } from "~/components/Table";
import {
  createSearchRequestProvider,
  preprocessSearchRequest,
  ResourceTable,
} from "~/components/Table";
import {
  commonResourceColumns,
  commonResourceFilterSchemas,
  filterText,
  filterUuid,
  selectCountableListResponse,
} from "~/domain/common";
import { useDataStores } from "../../../api";
import * as dsmPaths from "../../../paths";
import { renderForeignResource } from "../../../renderForeignResource";
import type { DataStore, ListDatastoresRequest } from "../../../sdk";
import type { DsmForeignResourceType } from "../../../types";
import { useDsmVersion } from "../../../versioning";

const columns: ReadonlyArray<Column<DataStore, DsmForeignResourceType>> = [
  {
    header: "DataStore",
    renderCell(dataStore) {
      return (
        <TableCell>
          {dataStore.name}
          <Link
            component={RouterLink}
            to={dsmPaths.createDsmPath(dsmPaths.DATASTORE_DETAILS, {
              dataStoreId: dataStore.id,
            })}
          >
            <Typography variant="body2">{dataStore.id}</Typography>
          </Link>
        </TableCell>
      );
    },
  },
  {
    accessor: "version",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "region",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "endpoint",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  ...commonResourceColumns,
];

export const listDataStoresSchema = z.object({
  id: filterUuid,
  name: filterText,
  nameLike: filterText,
  noteLike: filterText,
  ownerId: filterUuid,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<
  StrictOmit<
    ListDatastoresRequest,
    | "endpoint"
    | "endpointLike"
    | "includeCount"
    | "configFilter"
    | "plan"
    | "planLike"
    | "planNull"
    | "disabled"
    | "recordCount"
    | "recordCountGt"
    | "recordCountGte"
    | "recordCountLt"
    | "recordCountLte"
  >
>);

export const [useDataStoreSearchRequest, DataStoreSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listDataStoresSchema,
  });

export function DataStoreTable() {
  const [request, setRequest] = useDataStoreSearchRequest();

  const searchQuery = useDataStores(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const dsmVersion = useDsmVersion();

  return (
    <ResourceTable
      resourceName="DataStore"
      resourceCreateLocation={dsmPaths.createDsmPath(dsmPaths.DATASTORE_CREATE)}
      getRowKey={(dataStore) => dataStore.id}
      columns={columns}
      apiVersion={dsmVersion}
      deprecationPolicy="hide"
      renderForeignResource={renderForeignResource}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
    />
  );
}
