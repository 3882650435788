import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { LogCreateForm } from "./components/LogCreateForm";

export function NewLogPage() {
  return (
    <LqsPage title="New Log">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LogCreateForm />
      </Container>
    </LqsPage>
  );
}
