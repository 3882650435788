import type React from "react";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { Dl, renderDlGroup } from "~/components/DescriptionList";
import { useCurrentDataStore, useCurrentUser } from "~/lqs";
import {
  GlobalSettingsControlsProvider,
  useGlobalSettingsControlsContext,
} from "~/settings";
import { selectData } from "~/utils/queries";

export function LqsSettingsControlsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const currentControls = useGlobalSettingsControlsContext();

  return (
    <GlobalSettingsControlsProvider
      controls={
        <>
          <CurrentDataStoreInfo />
          {currentControls}
        </>
      }
    >
      {children}
    </GlobalSettingsControlsProvider>
  );
}

function CurrentDataStoreInfo() {
  const dataStore = useCurrentDataStore();
  const currentUserQuery = useCurrentUser({ select: selectData });

  return (
    <div>
      <Typography variant="h6" component="p">
        Current DataStore Connection
      </Typography>
      <Dl spacing={3}>
        {renderDlGroup("Name", dataStore.name, { xs: 12 })}
        {renderDlGroup("ID", dataStore.id, { xs: 12 })}
        {renderDlGroup(
          "Username",
          currentUserQuery.isSuccess ? (
            currentUserQuery.data?.username ?? "Anonymous"
          ) : currentUserQuery.isError ? (
            <Stack direction="row" alignItems="center" spacing={1}>
              <ErrorIcon color="error" />
              <Typography paragraph>
                You're signed in but your account information couldn't be
                retrieved.
              </Typography>
            </Stack>
          ) : (
            "Fetching your account information..."
          ),
          { xs: 12 },
        )}
      </Dl>
    </div>
  );
}
