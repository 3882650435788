import React from "react";
import { CommonResourcesContextProvider } from "~/domain/common";
import {
  useCurrentUserQueryOptionsFactory,
  useRoleFetchQueryOptionsFactory,
  useRoleListQueryOptionsFactory,
  useUserCreateMutationOptionsFactory,
  useUserDeleteMutationOptionsFactory,
  useUserQueryOptionsFactory,
  useUsersQueryOptionsFactory,
  useUserUpdateMutationOptionsFactory,
} from "./api";
import * as dsmPaths from "./paths";
import { useDsmVersion } from "./versioning";

export function DsmCommonResourcesProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const apiVersion = useDsmVersion();

  const createCurrentUserQueryOptions = useCurrentUserQueryOptionsFactory();

  const createRoleListQueryOptions = useRoleListQueryOptionsFactory();
  const createRoleFetchQueryOptions = useRoleFetchQueryOptionsFactory();

  const createUserListQueryOptions = useUsersQueryOptionsFactory();
  const createUserFetchQueryOptions = useUserQueryOptionsFactory();
  const createUserCreateMutationOptions = useUserCreateMutationOptionsFactory();
  const createUserUpdateMutationOptions = useUserUpdateMutationOptionsFactory();
  const createUserDeleteMutationOptions = useUserDeleteMutationOptionsFactory();

  return (
    <CommonResourcesContextProvider
      value={{
        apiVersion,
        createCurrentUserQueryOptions,
        createRoleListQueryOptions,
        createRoleFetchQueryOptions,
        // TODO: Add role details path generator when DSM has role pages
        createUserListQueryOptions,
        createUserFetchQueryOptions,
        createUserCreateMutationOptions,
        createUserUpdateMutationOptions,
        createUserDeleteMutationOptions,
        userTableLocation: dsmPaths.createDsmPath(dsmPaths.USER_TABLE),
        userCreateLocation: dsmPaths.createDsmPath(dsmPaths.USER_CREATE),
        createUserDetailsLocation: (params) =>
          dsmPaths.createDsmPath(dsmPaths.USER_DETAILS, params),
        createUserEditLocation: (params) =>
          dsmPaths.createDsmPath(dsmPaths.USER_EDIT, params),
      }}
    >
      {children}
    </CommonResourcesContextProvider>
  );
}
