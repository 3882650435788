import React from "react";
import { Search } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  MenuItem,
  selectClasses,
  Stack,
  TextField as MuiTextField,
  Tooltip,
} from "@mui/material";
import type { Control } from "react-hook-form";
import type { ValueOf } from "type-fest";
import * as z from "zod";
import { ComboBox, TextField } from "~/components/Form";
import type { Option } from "~/components/Form/types";
import { Pagination } from "~/components/Pagination";
import { useSearchRequest } from "~/components/Table";
import {
  filterArray,
  filterText,
  filterUuid,
  requiredUuid,
} from "~/domain/common";
import { useAllGroups, useAllLabels } from "~/domain/logs";
import type { GroupListResponse, LabelListResponse } from "~/lqs";
import { assertNever, createPrefixedAliases } from "~/utils";

export const LIMIT_OPTIONS = [15, 25, 50];

export const SortOptions = {
  NewestUpload: "newest-upload",
  OldestUpload: "oldest-upload",
  Newest: "newest",
  Oldest: "oldest",
} as const;

export const filterSchema = z.object({
  name: filterText,
});

const baseRequestSchema = filterSchema.extend({
  // We're planning to support multiple groups eventually so this is plural
  // to be forward-compatible even though it currently won't accept multiple
  groupIds: filterUuid.catch(null),
  labelIds: filterArray(requiredUuid).catch([]),
  limit: z.coerce
    .number()
    .refine((arg) => LIMIT_OPTIONS.includes(arg))
    .catch(LIMIT_OPTIONS[1]),
  offset: z.coerce.number().int().nonnegative().catch(0),
  sort: z.nativeEnum(SortOptions).catch(SortOptions.NewestUpload),
});

const schemaKeys = baseRequestSchema.keyof().options;

const requestSchema = baseRequestSchema.transform((arg) => {
  // Offset should be multiple of limit
  const isValidOffset = arg.offset % arg.limit === 0;

  return {
    ...arg,
    offset: isValidOffset ? arg.offset : 0,
  };
});

export function useLogsSearchRequest() {
  return useSearchRequest(
    requestSchema,
    createPrefixedAliases("log", schemaKeys),
  );
}

type LogsSearchRequest = ReturnType<typeof useLogsSearchRequest>[0];
type SetLogsSearchRequest = ReturnType<typeof useLogsSearchRequest>[1];

export function NameField({
  control,
}: {
  control: Control<{ name: string | null }>;
}) {
  return (
    <TextField
      control={control}
      name="name"
      size="small"
      noHelperText
      endAdornment={
        <InputAdornment position="end">
          <Tooltip title="Search">
            <IconButton type="submit" edge="end" size="small">
              <Search fontSize="small" />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      }
    />
  );
}

export function SortField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleSortChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setRequest({ sort: e.target.value as any });
  }

  return (
    <MuiTextField
      select
      size="small"
      label="Sort by"
      value={request.sort}
      onChange={handleSortChange}
      sx={{
        width: "18ch",
        flex: "none",
        [`& .${selectClasses.select}`]: {
          boxSizing: "border-box",
          height: 40,
          fontSize: ".875rem",
        },
        [`& .${selectClasses.icon}`]: {
          // Font size usually applied when an SVG icon has `size="small"` prop.
          // Doesn't appear to be a simple way to pass that prop to the select.
          fontSize: "1.25rem",
        },
      }}
    >
      <MenuItem value={SortOptions.NewestUpload}>Newest upload</MenuItem>
      <MenuItem value={SortOptions.OldestUpload}>Oldest upload</MenuItem>
      <MenuItem value={SortOptions.Newest}>Newest start</MenuItem>
      <MenuItem value={SortOptions.Oldest}>Oldest start</MenuItem>
    </MuiTextField>
  );
}

export function getSortParams(request: { sort: ValueOf<typeof SortOptions> }) {
  switch (request.sort) {
    case SortOptions.NewestUpload: {
      return { sort: "desc", order: "created_at" };
    }
    case SortOptions.OldestUpload: {
      return { sort: "asc", order: "created_at" };
    }
    case SortOptions.Newest: {
      return { sort: "desc", order: "start_time" };
    }
    case SortOptions.Oldest: {
      return { sort: "asc", order: "start_time" };
    }
    default: {
      assertNever(request.sort);
    }
  }
}

export function PaginationControls({
  disableJumping,
  logCount,
  request,
  setRequest,
}: {
  disableJumping?: boolean;
  logCount: number;
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  function handleLimitChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setRequest({ limit: Number(e.target.value) });
  }

  function handleOffsetChange(newOffset: number): void {
    setRequest({ offset: newOffset });
  }

  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "space-between", alignItems: "center" }}
    >
      <MuiTextField
        select
        size="small"
        label="Results per page"
        value={request.limit}
        onChange={handleLimitChange}
        sx={{ width: "15ch" }}
      >
        {LIMIT_OPTIONS.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </MuiTextField>
      <Pagination
        disableJumping={disableJumping}
        count={logCount}
        limit={request.limit}
        offset={request.offset}
        onChange={handleOffsetChange}
      />
    </Stack>
  );
}

export function GroupsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  const optionsQuery = useAllGroups({ select: selectGroupOptions });

  function handleChange(newGroupId: string | null): void {
    setRequest({ groupIds: newGroupId });
  }

  return (
    <ComboBox
      name="group"
      value={request.groupIds}
      onChange={handleChange}
      optionsQuery={optionsQuery}
      size="small"
      noHelperText
    />
  );
}

function selectGroupOptions(
  response: GroupListResponse,
): ReadonlyArray<Option> {
  return response.data.map((group) => ({
    value: group.id,
    label: group.name,
  }));
}

export function LabelsField({
  request,
  setRequest,
}: {
  request: LogsSearchRequest;
  setRequest: SetLogsSearchRequest;
}) {
  const optionsQuery = useAllLabels({ select: selectLabelOptions });

  function handleChange(newLabelIds: Array<string>): void {
    setRequest({ labelIds: newLabelIds });
  }

  return (
    <ComboBox
      name="tags"
      value={request.labelIds}
      onChange={handleChange}
      optionsQuery={optionsQuery}
      size="small"
      noHelperText
    />
  );
}

function selectLabelOptions(
  response: LabelListResponse,
): ReadonlyArray<Option> {
  return response.data.map((label) => ({
    value: label.id,
    label: label.value,
  }));
}
