import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  LinearProgress,
  Link,
  Stack,
  textFieldClasses,
} from "@mui/material";
import { useSnackbar } from "notistack";
import * as z from "zod";
import { Details } from "~/components/Details";
import { CheckboxField, TextField, useStudioForm } from "~/components/Form";
import { boolean, optionalText, requiredText } from "~/domain/common";
import { useCurrentDsmUser, useUpdateCurrentDsmUser } from "~/dsm";
import { invariant } from "~/lib/invariant";
import { pick } from "~/lib/std";
import { PRIVACY_POLICY_LINK } from "~/links";
import { getEventHandlerProps } from "~/utils/get-event-handler-props";
import { checkCompanyNotApplicable, setProfileBannerTimeout } from "./utils";

const nonDialogSchema = z
  .object({
    firstName: optionalText,
    lastName: optionalText,
    phone: optionalText,
    company: optionalText,
    companyNotApplicable: boolean,
    jobTitle: optionalText,
  })
  .superRefine((values, ctx) => {
    if (values.firstName != null && values.lastName == null) {
      ctx.addIssue({
        fatal: true,
        code: z.ZodIssueCode.custom,
        message: "Last name must also be provided",
        path: ["lastName"],
      });
      return;
    }

    if (values.lastName != null && values.firstName == null) {
      ctx.addIssue({
        fatal: true,
        code: z.ZodIssueCode.custom,
        message: "First name must also be provided",
        path: ["firstName"],
      });
      return;
    }
  });

const inDialogSchema = z
  .object({
    firstName: requiredText,
    lastName: requiredText,
    phone: optionalText,
    jobTitle: optionalText,
  })
  .and(
    z.union([
      z.object({
        companyNotApplicable: z.literal(true),
        company: optionalText,
      }),
      z.object({
        companyNotApplicable: z.literal(false),
        company: requiredText,
      }),
    ]),
  );

type FormValues = z.infer<typeof nonDialogSchema>;

interface NonDialogProfileFormProps {
  inDialog?: false;
  closeText?: never;
  onClose?: never;
  onLoadingStart?: never;
  onLoadingEnd?: never;
  onUpdateComplete?: never;
}

interface InDialogProfileFormProps {
  inDialog: true;
  closeText?: string;
  onClose: () => void;
  onLoadingStart: () => void;
  onLoadingEnd: () => void;
  onUpdateComplete?: () => void;
}

export function ProfileForm({
  inDialog = false,
  closeText = "Close",
  onClose,
  onLoadingStart,
  onLoadingEnd,
  onUpdateComplete,
}: NonDialogProfileFormProps | InDialogProfileFormProps) {
  const profileQuery = useCurrentDsmUser({
    select({ data }) {
      invariant(data != null, "Expected user to be present");

      return data;
    },
  });

  const disabled = !profileQuery.isSuccess;
  const progressVisibility = profileQuery.isSuccess ? "hidden" : "visible";

  const { enqueueSnackbar } = useSnackbar();
  const updateUserMutation = useUpdateCurrentDsmUser();

  const { control, handleSubmit, watch } = useStudioForm<z.ZodType<FormValues>>(
    {
      schema: inDialog ? inDialogSchema : nonDialogSchema,
      onSubmit({ companyNotApplicable, ...values }) {
        onLoadingStart?.();
        updateUserMutation.mutate(
          {
            ...values,
            context: {
              studio: {
                company_not_applicable: companyNotApplicable,
              },
            },
          },
          {
            onSuccess() {
              onUpdateComplete?.();
              enqueueSnackbar("Profile updated", { variant: "success" });
            },
            onError() {
              enqueueSnackbar("Unable to update profile", { variant: "error" });
            },
            onSettled() {
              onLoadingEnd?.();
            },
          },
        );
      },
      defaultValues: {
        firstName: null,
        lastName: null,
        phone: null,
        company: null,
        companyNotApplicable: false,
        jobTitle: null,
      },
      values: profileQuery.isSuccess
        ? {
            ...pick(profileQuery.data, [
              "firstName",
              "lastName",
              "phone",
              "company",
              "jobTitle",
            ]),
            companyNotApplicable: checkCompanyNotApplicable(profileQuery.data),
          }
        : undefined,
    },
  );

  const companyNotApplicable = watch("companyNotApplicable") ?? false;

  const closeHandlerProps = getEventHandlerProps(
    "onClick",
    inDialog &&
      !updateUserMutation.isLoading &&
      function handleClose(): void {
        setProfileBannerTimeout();
        onClose?.();
      },
  );

  return (
    <Stack>
      <LinearProgress
        // Remount when made visible so animation restarts
        key={progressVisibility}
        sx={{ visibility: progressVisibility, mb: 2 }}
      />
      <Stack spacing={2} component="form" noValidate onSubmit={handleSubmit}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            flexWrap: "wrap",
            [`& .${textFieldClasses.root}`]: {
              flex: "1 1",
              minWidth: "25ch",
            },
          }}
        >
          <TextField
            control={control}
            name="firstName"
            required={inDialog}
            disabled={disabled}
            autoComplete="given-name"
          />
          <TextField
            control={control}
            name="lastName"
            required={inDialog}
            disabled={disabled}
            autoComplete="family-name"
          />
        </Stack>
        <TextField
          control={control}
          name="phone"
          disabled={disabled}
          autoComplete="tel"
          inputMode="tel"
        />
        <div>
          <TextField
            control={control}
            name="company"
            required={inDialog}
            disabled={disabled || companyNotApplicable}
            autoComplete="organization"
            helperText={(errorMessage) => errorMessage ?? null}
          />
          <CheckboxField
            control={control}
            name="companyNotApplicable"
            label="Not applicable"
            disabled={disabled}
            size="small"
          />
        </div>
        <TextField
          control={control}
          name="jobTitle"
          disabled={disabled}
          autoComplete="organization-title"
        />
        <Stack
          direction="row"
          spacing={2}
          sx={{ justifyContent: inDialog ? "end" : "start" }}
        >
          {inDialog && (
            <Button
              type="button"
              color="secondary"
              variant="text"
              {...closeHandlerProps}
            >
              {closeText}
            </Button>
          )}
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disableElevation
            disabled={disabled}
            loading={updateUserMutation.isLoading}
          >
            Update Information
          </LoadingButton>
        </Stack>
        <Details>
          <Details.Summary>How do we handle your information?</Details.Summary>
          <Details.Content>
            You can find out how we handle your information by reading our{" "}
            <Link href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener">
              privacy policy
            </Link>
            .
          </Details.Content>
        </Details>
      </Stack>
    </Stack>
  );
}
