import React from "react";
import { alpha, Box, Stack } from "@mui/material";
import { useControlledPanelContext } from "../layout";
import { usePanelContext } from "../panels";
import { PanelHeader } from "./panel-header";

export function PanelLayout({
  contentRef,
  children,
}: {
  contentRef?: React.RefCallback<HTMLElement>;
  children: React.ReactNode;
}) {
  const panel = usePanelContext();

  const controlledPanelContext = useControlledPanelContext({ strict: false });
  const isVisible = controlledPanelContext?.panelId === panel.id;

  return (
    <Stack sx={{ height: 1, width: 1 }}>
      <Box
        sx={{
          height: "auto",
          flexShrink: 0,
          overflow: "hidden",
          px: 0.5,
          py: 0.25,
          borderBottom: 1,
          borderColor: "divider",
          ...(isVisible && {
            bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.3),
          }),
        }}
      >
        <PanelHeader />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
          // Overflow cannot be allowed on this element as it could possibly
          // have its size observed via the `contentRef`. This can (and has)
          // led to some nasty bugs if a scrollbar appears, causing the
          // element's content box size to change, causing resizes, and on
          // and on
          overflow: "hidden",
          position: "relative",
        }}
        ref={contentRef}
      >
        {children}
      </Box>
    </Stack>
  );
}
