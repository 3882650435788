import type { UseQueryResult } from "@tanstack/react-query";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import {
  DetailsLayout,
  HistoryCard,
  ManageCard,
  ResourceFields,
} from "~/components/DetailsCards";
import { EditResourceForm } from "~/components/Form";
import { selectData } from "~/utils";
import { useCommonResourcesContextValue } from "../../../context";
import { renderCommonForeignKeyFormField } from "../../../forms";
import { renderForeignResource } from "../../../render-foreign-resource";
import type { CommonUser } from "../../../types";
import { useUserParams } from "../hooks";
import { editUserSchema } from "../schemas";

export function UserDetails() {
  const { userId } = useUserParams();

  const {
    apiVersion,
    createUserFetchQueryOptions,
    createUserDeleteMutationOptions,
    createUserEditLocation,
    userTableLocation,
  } = useCommonResourcesContextValue();

  const query = useQuery({
    ...createUserFetchQueryOptions(userId),
    select: selectData,
  });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = (
    <HistoryCard
      query={query}
      apiVersion={apiVersion}
      deprecationPolicy="hide"
      renderForeignResource={renderForeignResource}
    />
  );
  const manageSection = (
    <ManageCard
      resourceName="user"
      query={query}
      editLocation={createUserEditLocation({ userId })}
      deleteMutation={useMutation(createUserDeleteMutationOptions(userId))}
      getReadableName={(user) => user.username}
      listLocation={userTableLocation}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<CommonUser> }) {
  const { apiVersion } = useCommonResourcesContextValue();

  return (
    <Card title="General">
      <ResourceFields
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          { dataType: "text", accessor: "username" },
          { dataType: "foreign-key", resourceType: "role", accessor: "roleId" },
          { dataType: "boolean", accessor: "managed" },
        ]}
        apiVersion={apiVersion}
        deprecationPolicy="hide"
        renderForeignResource={renderForeignResource}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<CommonUser> }) {
  const { userId } = useUserParams();

  const { apiVersion, createUserUpdateMutationOptions } =
    useCommonResourcesContextValue();

  return (
    <EditResourceForm
      resourceName="user"
      query={query}
      schema={editUserSchema}
      descriptors={[
        { name: "admin", type: "boolean" },
        { name: "disabled", type: "boolean" },
      ]}
      apiVersion={apiVersion}
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderCommonForeignKeyFormField}
      mutation={useMutation(createUserUpdateMutationOptions(userId))}
    />
  );
}
