import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  Label,
  LabelListResponse,
  Log,
  Tag,
  TagListResponse,
  ListLabelsRequest,
  GroupListResponse,
  ListGroupsRequest,
} from "~/lqs";
import {
  groupKeys,
  circumventPagination,
  labelKeys,
  useDataStoreClients,
  useTags,
} from "~/lqs";
import type { Maybe } from "~/types";
import type { LogInstantTag, LogLevelTag, LogRangeTag } from "./types";

function useLogLevelTagsImpl<TData>(
  log: Maybe<Log>,
  options: Required<
    Pick<UseQueryOptions<TagListResponse, unknown, TData>, "select">
  >,
): UseQueryResult<TData> {
  return useTags(
    log?.id ?? null,
    // TODO: Add `topicIdNull` filter when available
    {
      order: "created_at",
      sort: "asc",
      startTimeNull: true,
      endTimeNull: true,
      // TODO: Consider fetching all tags or having some form of pagination
      limit: 500,
    },
    options,
  );
}

/**
 * List all log-level tags for a log. A log-level tag is one with no start time,
 * end time or topic ID, so it's considered to apply to the log as a whole.
 */
export function useLogLevelTags(
  log: Maybe<Log>,
): UseQueryResult<Array<LogLevelTag>> {
  return useLogLevelTagsImpl(log, {
    select(response) {
      // TODO: Until the tag list endpoint provides a `topicIdNull` filter
      //       ignore any tags with associated topics
      return response.data.filter(
        (tag): tag is LogLevelTag =>
          tag.topicId === null &&
          tag.startTime === null &&
          tag.endTime === null,
      );
    },
  });
}

export function useAppliedLogLevelTags(
  log: Maybe<Log>,
  labelIds: ReadonlyArray<Label["id"]>,
): UseQueryResult<ReadonlyArray<Tag>> {
  return useLogLevelTagsImpl(log, {
    select(response) {
      if (labelIds.length === 0) {
        return [];
      }

      return response.data.filter(
        (tag) =>
          // TODO: Until the tag list endpoint provides a `topicIdNull` filter
          //       ignore any tags with associated topics
          tag.topicId === null && labelIds.includes(tag.labelId),
      );
    },
  });
}

export function useLogInstantTags(
  logId: Log["id"] | null,
): UseQueryResult<ReadonlyArray<LogInstantTag>> {
  return useTags(
    logId,
    {
      order: "created_at",
      sort: "asc",
      startTimeNull: false,
      endTimeNull: true,
      limit: 500,
    },
    {
      select: selectLogInstantTags,
    },
  );
}

function selectLogInstantTags(
  response: TagListResponse,
): ReadonlyArray<LogInstantTag> {
  return response.data.filter(
    (tag): tag is LogInstantTag =>
      tag.topicId === null && tag.startTime !== null && tag.endTime === null,
  );
}

export function useLogRangeTags(
  logId: Log["id"] | null,
): UseQueryResult<ReadonlyArray<LogRangeTag>> {
  return useTags(
    logId,
    {
      order: "created_at",
      sort: "asc",
      startTimeNull: false,
      endTimeNull: false,
      limit: 500,
    },
    {
      select: selectLogRangeTags,
    },
  );
}

function selectLogRangeTags(
  response: TagListResponse,
): ReadonlyArray<LogRangeTag> {
  return response.data.filter(
    (tag): tag is LogRangeTag =>
      tag.topicId === null && tag.startTime !== null && tag.endTime !== null,
  );
}

export function useAllLabels<TData>({
  select,
}: {
  select: (response: LabelListResponse) => TData;
}): UseQueryResult<TData> {
  const { labelApi } = useDataStoreClients();

  const baseRequest: ListLabelsRequest = {
    order: "value",
    sort: "asc",
    limit: -1,
  };

  return useQuery({
    queryKey: labelKeys.list(baseRequest),
    queryFn({ signal }) {
      return circumventPagination(
        labelApi.listLabels.bind(labelApi),
        100,
        baseRequest,
        { signal },
      );
    },
    select,
  });
}

export function useAllGroups<TData>({
  select,
}: {
  select: (response: GroupListResponse) => TData;
}): UseQueryResult<TData> {
  const { groupApi } = useDataStoreClients();

  const baseRequest: ListGroupsRequest = {
    order: "name",
    sort: "asc",
    limit: -1,
  };

  return useQuery({
    queryKey: groupKeys.list(baseRequest),
    queryFn({ signal }) {
      return circumventPagination(
        groupApi.listGroups.bind(groupApi),
        100,
        baseRequest,
        { signal },
      );
    },
    select,
  });
}
