/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Ticket } from "./Ticket";
import { TicketFromJSON, TicketFromJSONTyped, TicketToJSON } from "./Ticket";

export interface TicketDataResponse {
  data: Ticket;
}

export function TicketDataResponseFromJSON(json: any): TicketDataResponse {
  return TicketDataResponseFromJSONTyped(json);
}

export function TicketDataResponseFromJSONTyped(json: any): TicketDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: TicketFromJSON(json["data"]),
  };
}

export function TicketDataResponseToJSON(
  value: TicketDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: TicketToJSON(value.data),
  };
}
