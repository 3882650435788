import type { ParamParseKey, Params, Path } from "react-router-dom";
import { generatePath, useParams } from "react-router-dom";
import type { IsEmptyObject } from "type-fest";

const DSM = "/dsm" as const;

export const DATASTORE_TABLE = `${DSM}/datastores` as const;
export const DATASTORE_CREATE = `${DATASTORE_TABLE}/new` as const;
export const DATASTORE_DETAILS = `${DATASTORE_TABLE}/:dataStoreId` as const;
export const DATASTORE_EDIT = `${DATASTORE_DETAILS}/edit` as const;

export const USER_TABLE = `${DSM}/users` as const;
export const USER_CREATE = `${USER_TABLE}/new` as const;
export const USER_DETAILS = `${USER_TABLE}/:userId` as const;
export const USER_EDIT = `${USER_DETAILS}/edit` as const;

export const ROLE_TABLE = `${DSM}/roles` as const;
export const ROLE_CREATE = `${ROLE_TABLE}/new` as const;
export const ROLE_DETAILS = `${ROLE_TABLE}/:roleId` as const;
export const ROLE_EDIT = `${ROLE_DETAILS}/edit` as const;

type NonNullableFields<TType extends object> = {
  [Key in keyof TType]: NonNullable<TType[Key]>;
};

type ParamsShape<TPathPattern extends string> = NonNullableFields<
  Params<ParamParseKey<TPathPattern>>
>;

type CreateDsmPathArgs<TPathPatterns extends DsmPathPatterns> =
  IsEmptyObject<ParamsShape<TPathPatterns>> extends true
    ? [pathPattern: TPathPatterns]
    : [pathPattern: TPathPatterns, params: ParamsShape<TPathPatterns>];

type DsmPathPatterns =
  | typeof DATASTORE_TABLE
  | typeof DATASTORE_CREATE
  | typeof DATASTORE_DETAILS
  | typeof DATASTORE_EDIT
  | typeof USER_TABLE
  | typeof USER_CREATE
  | typeof USER_DETAILS
  | typeof USER_EDIT
  | typeof ROLE_TABLE
  | typeof ROLE_CREATE
  | typeof ROLE_DETAILS
  | typeof ROLE_EDIT;

export function createDsmPath<const TPathPattern extends DsmPathPatterns>(
  ...args: CreateDsmPathArgs<TPathPattern>
): Partial<Path> {
  const [pathPattern, params] = args;

  return {
    pathname: generatePath(pathPattern, params),
  };
}

export function useDsmPathParams<const TPathPattern extends DsmPathPatterns>(
  /**
   * Purposely not used by value, only by type
   */
  pathPattern: TPathPattern,
): ParamsShape<TPathPattern> {
  return useParams() as ParamsShape<TPathPattern>;
}
