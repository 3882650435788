import { useCreateDataStore } from "../../../api";
import { NewDsmResourceForm } from "../../../forms";
import * as dsmPaths from "../../../paths";
import { createDataStoreSchema } from "../schemas";

export function DataStoreCreateForm() {
  return (
    <NewDsmResourceForm
      resourceName="DataStore"
      schema={createDataStoreSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "ownerId", type: "foreign-key", resourceType: "user" },
        { name: "version", type: "text" },
        { name: "region", type: "text" },
        { name: "endpoint", type: "text" },
        { name: "config", type: "json" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateDataStore()}
      createDetailsLocation={(response) =>
        dsmPaths.createDsmPath(dsmPaths.DATASTORE_DETAILS, {
          dataStoreId: response.data.id,
        })
      }
    />
  );
}
