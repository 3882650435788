import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { TagDetails } from "./components/TagDetails";

export function TagPage() {
  return (
    <LqsPage title="Tag Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <TagDetails />
      </Container>
    </LqsPage>
  );
}
