import * as z from "zod";
import type { SchemaShape } from "~/components/Form";
import {
  boolean,
  optionalObject,
  optionalText,
  optionalUrl,
  optionalUuid,
  requiredText,
} from "~/domain/common";
import type { DataStoreCreateRequest, DataStoreUpdateRequest } from "../../sdk";

export const createDataStoreSchema = z.object({
  name: requiredText,
  ownerId: optionalUuid,
  version: optionalText,
  region: optionalText,
  endpoint: optionalUrl({
    httpOrHttpsOnly: true,
    rejectSearch: true,
    rejectHash: true,
  }),
  config: optionalObject,
  disabled: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<DataStoreCreateRequest>);

export const editDataStoreSchema = z.object({
  name: requiredText,
  ownerId: optionalUuid,
  version: optionalText,
  region: optionalText,
  endpoint: optionalUrl({
    httpOrHttpsOnly: true,
    rejectSearch: true,
    rejectHash: true,
  }),
  config: optionalObject,
  disabled: boolean,
  note: optionalText,
  context: optionalObject,
} satisfies SchemaShape<DataStoreUpdateRequest>);
