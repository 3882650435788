import type { FileRejection } from "react-dropzone";
import { ErrorCode } from "react-dropzone";
import * as z from "zod";
import { requiredArray } from "~/domain/common";

export function file(): z.ZodType<File, z.ZodTypeDef, unknown> {
  return z.unknown().superRefine((value, ctx): value is File => {
    if (value == null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "File is required",
        fatal: true,
      });

      return false;
    }

    if (value instanceof InvalidSelection) {
      let message;
      if (
        value.rejected.some(({ errors }) =>
          errors.some((e) => e.code === ErrorCode.TooManyFiles),
        )
      ) {
        message = "Only 1 file can be uploaded";
      } else {
        message = "Some files failed validation";
      }

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message,
        fatal: true,
      });

      return false;
    }

    if (!(value instanceof File)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Expected a file",
        fatal: true,
      });

      return false;
    }

    return true;
  });
}

export function files({ required = false } = {}): z.ZodType<
  Array<File>,
  z.ZodTypeDef,
  unknown
> {
  return z
    .unknown()
    .superRefine((value, ctx) => {
      if (value instanceof InvalidSelection) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Some files failed validation",
          fatal: true,
        });

        return false;
      }

      return true;
    })
    .pipe(
      requiredArray(file()).superRefine((value, ctx) => {
        if (required && value.length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Files are required",
            fatal: true,
          });

          return false;
        }

        return true;
      }),
    );
}

export class InvalidSelection {
  readonly accepted: ReadonlyArray<File>;
  readonly rejected: ReadonlyArray<FileRejection>;

  constructor(
    accepted: ReadonlyArray<File>,
    rejected: ReadonlyArray<FileRejection>,
  ) {
    this.accepted = accepted;
    this.rejected = rejected;
  }
}
