import prettyBytes from "pretty-bytes";
import { useSettings } from "~/settings";
import type { Maybe } from "~/types";
import { assertNever } from "~/utils";

export function formatBytes(bytes: number) {
  return prettyBytes(bytes);
}

interface BytesProps {
  value: Maybe<number>;
}

export function Bytes({ value }: BytesProps) {
  const settings = useSettings();

  if (value == null) {
    return "-";
  } else {
    switch (settings.bytesFormat) {
      case "plain": {
        return value;
      }
      case "compact": {
        return formatBytes(value);
      }
      default: {
        assertNever(settings.bytesFormat);
      }
    }
  }
}
