import React from "react";
import type { TextFieldProps as MuiTextFieldProps } from "@mui/material";
import { inputBaseClasses, TextField as MuiTextField } from "@mui/material";
import type { FieldPathByValue, FieldValues } from "react-hook-form";
import { getFieldLabel } from "~/domain/common";
import { DeprecableLabel } from "~/domain/versioning";
import { assertNever } from "~/utils/assertNever";
import { useField } from "./hooks";
import type { BaseInputProps, FieldPropsFromInputProps } from "./types";

interface TextInputProps
  extends BaseInputProps<string | null>,
    Pick<MuiTextFieldProps, "size" | "autoComplete" | "inputMode"> {
  type?: "text" | "username" | "new-password" | "current-password";
  disabled?: boolean;
  multiline?: boolean;
  monospace?: boolean;
  endAdornment?: React.ReactNode;
  noHelperText?: boolean;
  helperText?:
    | React.ReactNode
    | ((errorMessage: string | undefined) => React.ReactNode);
}

export function TextInput({
  type: typeProp = "text",
  name,
  label = getFieldLabel(name),
  deprecated,
  required,
  autoComplete: autoCompleteProp,
  inputMode,
  size = "medium",
  disabled = false,
  multiline = false,
  monospace = false,
  noHelperText = false,
  helperText: helperTextProp = " ",
  endAdornment,
  value,
  onChange,
  errorMessage,
}: TextInputProps) {
  let type;
  let autoComplete;
  switch (typeProp) {
    case "text": {
      type = "text";
      autoComplete = autoCompleteProp;
      break;
    }
    case "username": {
      type = "email";
      autoComplete = typeProp;
      break;
    }
    case "new-password":
    case "current-password": {
      // TODO: Allow making password visible
      type = "password";
      autoComplete = typeProp;
      break;
    }
    default: {
      assertNever(typeProp);
    }
  }

  let helperText;
  if (noHelperText) {
    helperText = null;
  } else if (typeof helperTextProp === "function") {
    helperText = helperTextProp(errorMessage);
  } else {
    helperText = errorMessage ?? helperTextProp;
  }

  return (
    <MuiTextField
      type={type}
      fullWidth
      size={size}
      required={required}
      autoComplete={autoComplete}
      disabled={disabled}
      {...(multiline && {
        multiline,
        minRows: 3,
        maxRows: 6,
      })}
      sx={{
        ...(size === "small" && {
          [`& .${inputBaseClasses.root}`]: {
            height: 40,
          },
          [`& .${inputBaseClasses.input}`]: {
            fontSize: ".875rem",
          },
        }),
        ...(monospace && {
          "& textarea": {
            fontFamily: "monospace",
          },
        }),
      }}
      label={<DeprecableLabel deprecated={deprecated}>{label}</DeprecableLabel>}
      error={errorMessage !== undefined}
      helperText={helperText}
      FormHelperTextProps={{
        component: typeof helperText === "string" ? "p" : "div",
      }}
      value={value ?? ""}
      onChange={(e) => {
        if (e.target.value === "") {
          onChange(null);
        } else {
          onChange(e.target.value);
        }
      }}
      inputProps={{
        inputMode,
      }}
      InputProps={{
        endAdornment,
      }}
    />
  );
}

export interface TextFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
> extends FieldPropsFromInputProps<TFieldValues, TName, TextInputProps> {}

export function TextField<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string | null>,
>({
  control,
  onChange: onChangeProp,
  ...rest
}: TextFieldProps<TFieldValues, TName>) {
  const { value, onChange, errorMessage } = useField({
    control,
    name: rest.name,
  });

  const handleChange: typeof onChange = (value): void => {
    onChange(value);
    onChangeProp?.(value);
  };

  return (
    <TextInput
      {...rest}
      value={value}
      onChange={handleChange}
      errorMessage={errorMessage}
    />
  );
}
