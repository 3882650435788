import React from "react";
import type { GridProps, TypographyProps } from "@mui/material";
import { Grid, Typography } from "@mui/material";

interface DtProps extends Pick<TypographyProps, "variant"> {
  children: React.ReactNode;
}

export function Dt({ variant, children }: DtProps) {
  return (
    <Typography
      component="dt"
      variant={variant}
      sx={{ fontWeight: "bold", color: "text.secondary" }}
    >
      {children}
    </Typography>
  );
}

interface DdProps extends Pick<TypographyProps, "variant"> {
  children: React.ReactNode;
}

export function Dd({ variant, children }: DdProps) {
  return (
    <Typography component="dd" variant={variant}>
      {children}
    </Typography>
  );
}

interface DlGroupProps
  extends Omit<GridProps, "container" | "item" | "component"> {
  children: React.ReactNode;
}

export function DlGroup({ children, ...props }: DlGroupProps) {
  return (
    <Grid {...props} item>
      {children}
    </Grid>
  );
}

interface DlProps
  extends Omit<
    GridProps<"dl", { component: "dl" }>,
    "container" | "item" | "component"
  > {
  children: React.ReactNode;
}

export function Dl({ children, ...props }: DlProps) {
  return (
    <Grid {...props} container component="dl">
      {children}
    </Grid>
  );
}

export function renderDlGroup(
  dt: React.ReactNode,
  dd: React.ReactNode,
  groupProps: Omit<DlGroupProps, "children"> = {
    xs: 12,
    md: 6,
  },
) {
  return (
    <DlGroup {...groupProps}>
      <Dt>{dt}</Dt>
      <Dd>{dd}</Dd>
    </DlGroup>
  );
}
