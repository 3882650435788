import { commonResourceFormFieldDescriptors } from "~/domain/common";
import {
  LqsFiltersSidebar,
  processStateOptions,
  processTypeOptions,
} from "~/lqs";
import { listWorkflowHooksSchema } from "../schemas";
import { useWorkflowHookSearchRequest } from "./WorkflowHookTable";

export function WorkflowHookFilters() {
  const [request, setRequest] = useWorkflowHookSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="hook"
      request={request}
      setRequest={setRequest}
      schema={listWorkflowHooksSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "uri", type: "fuzzy", fuzzyName: "uriLike" },
        { name: "triggerProcess", type: "select", options: processTypeOptions },
        { name: "triggerState", type: "select", options: processStateOptions },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "noteLike", type: "text", label: "Note" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
