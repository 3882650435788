import React, { useState } from "react";
import type { StoreApi } from "zustand";
import { createStore, useStore } from "zustand";
import { immer } from "zustand/middleware/immer";
import { createSafeContext } from "~/contexts";
import type { TaggingMode } from "~/domain/logs";
import type { ListTagsRequest, Topic } from "~/lqs";
import { assertNever } from "~/utils";

interface TagStore {
  visibleTags: { type: "log" } | { type: "topic"; topicId: Topic["id"] } | null;
  enableMutations: boolean;
  taggingMode: TaggingMode;
  actions: {
    toggleLogTags: (force?: true) => void;
    toggleTopicTags: (topicId: Topic["id"]) => void;
    toggleTagMutations: () => void;
    changeTaggingMode: (newTaggingMode: TaggingMode) => void;
  };
}

function createTagStore() {
  return createStore<TagStore>()(
    immer((set) => {
      return {
        visibleTags: { type: "log" },
        enableMutations: false,
        taggingMode: "log",
        actions: {
          toggleLogTags(force) {
            set((state) => {
              if (force) {
                state.visibleTags = { type: "log" };
              } else if (state.visibleTags?.type === "log") {
                state.visibleTags = null;
              } else {
                state.visibleTags = { type: "log" };
              }
            });
          },
          toggleTopicTags(topicId) {
            set((state) => {
              if (
                state.visibleTags?.type === "topic" &&
                state.visibleTags.topicId === topicId
              ) {
                state.visibleTags = null;
              } else {
                state.visibleTags = { type: "topic", topicId };
              }
            });
          },
          toggleTagMutations() {
            set((state) => {
              state.enableMutations = !state.enableMutations;
            });
          },
          changeTaggingMode(newTaggingMode) {
            set((state) => {
              state.taggingMode = newTaggingMode;
            });
          },
        },
      };
    }),
  );
}

const [useTagStoreContext, TagStoreContext] =
  createSafeContext<StoreApi<TagStore>>("TagStore");

// Tag store must be scoped to a given log, meaning there can't be a single
// store for the entire app. Instead, the `<TagStoreProvider />` will create
// a fresh store whenever it mounts. This component should be rendered under
// the `<LogPlaybackSourceProvider />`
export function TagStoreProvider({ children }: { children: React.ReactNode }) {
  const [tagStore] = useState(createTagStore);

  return (
    <TagStoreContext.Provider value={tagStore}>
      {children}
    </TagStoreContext.Provider>
  );
}

export function usePlaybackTagsFilters(): Pick<
  ListTagsRequest,
  "topicId" | "startTimeNull"
> | null {
  const tagStore = useTagStoreContext();

  return useStore(
    tagStore,
    (store): Pick<ListTagsRequest, "topicId" | "startTimeNull"> | null => {
      if (store.visibleTags === null) {
        return null;
      }

      switch (store.visibleTags.type) {
        case "log": {
          return {
            startTimeNull: false,
          };
        }
        case "topic": {
          return {
            topicId: store.visibleTags.topicId,
            startTimeNull: false,
          };
        }
        default: {
          assertNever(store.visibleTags);
        }
      }
    },
  );
}

export function useTagMutationsEnabled(): boolean {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.enableMutations);
}

export function useTaggingMode(): TaggingMode {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.taggingMode);
}

export function useAreLogTagsShowing(): boolean {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.visibleTags?.type === "log");
}

export function useAreTopicTagsShowing(topicId: Topic["id"]): boolean {
  const tagStore = useTagStoreContext();

  return useStore(
    tagStore,
    (store) =>
      store.visibleTags?.type === "topic" &&
      store.visibleTags.topicId === topicId,
  );
}

export function useShouldShowTags() {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.visibleTags !== null);
}

export function useTagActions() {
  const tagStore = useTagStoreContext();

  return useStore(tagStore, (store) => store.actions);
}
