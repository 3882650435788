import type {
  ConfirmSignUpOutput,
  ResetPasswordOutput,
  SignInOutput,
  SignUpOutput,
} from "aws-amplify/auth";
import * as AmplifyAuth from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { invariant } from "./invariant";

export type {
  SignUpOutput,
  ConfirmSignUpOutput,
  SignInOutput,
  ResetPasswordOutput,
};

// Circumvent the browser's bfcache. Not ideal but don't want a signed out
// user's previous page being accessible through the bfcache.
// https://web.dev/articles/bfcache#update-data-after-restore
window.addEventListener("pageshow", (e) => {
  if (e.persisted) {
    window.location.reload();
  }
});

// Amplify v6 doesn't de-dupe credential requests. Until it does, the workaround
// is to de-dupe the request in user space, similar to what the issue's author
// suggested:
// https://github.com/aws-amplify/amplify-js/issues/13499#issuecomment-2198130360
let authSessionPromise: ReturnType<typeof AmplifyAuth.fetchAuthSession> | null =
  null;
export async function getAuthToken(): Promise<string> {
  if (authSessionPromise == null) {
    authSessionPromise = AmplifyAuth.fetchAuthSession();
  }

  let tokens;
  try {
    tokens = (await authSessionPromise).tokens;
  } finally {
    authSessionPromise = null;
  }

  const idToken = tokens?.idToken?.toString();

  invariant(idToken != null, "No token available");

  return idToken;
}

export async function checkUserIsAuthenticated(): Promise<boolean> {
  try {
    // Will throw if the user isn't signed in
    await AmplifyAuth.getCurrentUser();
  } catch {
    return false;
  }

  return true;
}

export function signUp({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<SignUpOutput> {
  return AmplifyAuth.signUp({
    username: email,
    password,
  });
}

export async function confirmSignUp({
  email,
  password,
  code,
}: {
  email: string;
  password: string;
  code: string;
}): Promise<ConfirmSignUpOutput | SignInOutput> {
  const confirmResponse = await AmplifyAuth.confirmSignUp({
    username: email,
    confirmationCode: code,
  });

  if (confirmResponse.nextStep.signUpStep === "DONE") {
    return AmplifyAuth.signIn({
      username: email,
      password,
      options: {
        authFlowType: "USER_SRP_AUTH",
      },
    });
  }

  return confirmResponse;
}

export async function requestNewSignUpCode({
  email,
}: {
  email: string;
}): Promise<void> {
  await AmplifyAuth.resendSignUpCode({ username: email });
}

export async function signIn({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<SignInOutput> {
  const response = await AmplifyAuth.signIn({
    username: email,
    password,
    options: {
      authFlowType: "USER_SRP_AUTH",
    },
  });

  // Cognito doesn't appear to automatically send a new sign up code when a user
  // whose account isn't verified tries to sign in, so do that here as a
  // convenience. May want to consider not sending the code for in case the user
  // still has a valid one and just rely on the UI control for resending a code.
  if (response.nextStep.signInStep === "CONFIRM_SIGN_UP") {
    await requestNewSignUpCode({ email });
  }

  return response;
}

export function resetPassword({
  email,
}: {
  email: string;
}): Promise<ResetPasswordOutput> {
  return AmplifyAuth.resetPassword({ username: email });
}

export function confirmResetPassword({
  email,
  code,
  newPassword,
}: {
  email: string;
  code: string;
  newPassword: string;
}): Promise<void> {
  return AmplifyAuth.confirmResetPassword({
    username: email,
    confirmationCode: code,
    newPassword,
  });
}

export async function signOut(): Promise<void> {
  const currentUser = await AmplifyAuth.getCurrentUser();
  const signedInDirectly = currentUser.signInDetails != null;

  await AmplifyAuth.signOut();

  if (signedInDirectly) {
    // Destroys all cached data
    window.location.reload();
  }
}

export function signInWithGoogle(): void {
  AmplifyAuth.signInWithRedirect({ provider: "Google" });
}

export function checkIsAuthError(
  error: unknown,
): error is AmplifyAuth.AuthError {
  return error instanceof AmplifyAuth.AuthError;
}

export function checkIsSignInDone(
  response: ConfirmSignUpOutput | SignInOutput,
): boolean {
  return (
    "signInStep" in response.nextStep && response.nextStep.signInStep === "DONE"
  );
}

export const subscribeToAuthEvent = Hub.listen.bind(Hub, "auth");
