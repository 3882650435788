import React, { useLayoutEffect, useRef, useState } from "react";
import { ExpandLess, ExpandMore, Notes } from "@mui/icons-material";
import type { ButtonBaseActions, TooltipProps } from "@mui/material";
import {
  alpha,
  Box,
  CardActionArea,
  Chip,
  IconButton,
  Link,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import type { PopupState } from "material-ui-popup-state/hooks";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import prettyMilliseconds from "pretty-ms";
import { Link as RouterLink } from "react-router-dom";
import { renderQuery } from "~/components/QueryRenderer";
import { Text } from "~/components/text";
import { LogThumbnail, useAllLabels } from "~/domain/logs";
import { useFormatToDay } from "~/format";
import { useIsMobile } from "~/layout";
import { nanosecondsToMilliseconds } from "~/lib/dates";
import { invariant } from "~/lib/invariant";
import { emplace, floor } from "~/lib/std";
import type { Label, LabelListResponse, Log } from "~/lqs";
import { useGroup, useTags } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { assertNever, combineQueries } from "~/utils";
import { getPaperDarkBackgroundImage } from "~/utils/get-paper-dark-background-image";

export interface ProcessedLabel {
  label: Label;
  selected: boolean;
}

export type LabelProcessor = (appliedLabels: ReadonlyArray<Label>) => {
  visible: ReadonlyArray<ProcessedLabel>;
  truncated: ReadonlyArray<ProcessedLabel>;
};

export type NoteFormRenderer = (props: {
  log: Log;
  popupState: PopupState;
}) => React.ReactNode;

// "Ultra-wide" breakpoint. If more features end up needing this breakpoint
// it should probably go into the theme.
const UW = 2000;

const GalleryRoot = styled("div")(({ theme }) => ({
  marginBlockEnd: theme.spacing(3),
  display: "grid",
  gridTemplateColumns: "repeat(var(--gallery-cols), minmax(0, 1fr))",
  gap: theme.spacing(3),
  [theme.breakpoints.up(UW)]: {
    "--gallery-cols": 4,
  },
  [theme.breakpoints.down(UW)]: {
    "--gallery-cols": 3,
  },
  [theme.breakpoints.down("lg")]: {
    "--gallery-cols": 2,
  },
  [theme.breakpoints.down("md")]: {
    "--gallery-cols": 1,
  },
}));

export function LogGallery({
  logs,
  renderNoteForm,
  processLabels,
}: {
  logs: ReadonlyArray<Log>;
  renderNoteForm?: NoteFormRenderer;
  processLabels: LabelProcessor;
}) {
  const formatToDay = useFormatToDay();

  const groupedLogs = groupLogsByDay(logs, formatToDay);

  return (
    <GalleryRoot>
      {groupedLogs.flatMap(({ day, logs }) =>
        logs.map((log, index) => (
          <Stack key={log.id}>
            <Typography variant="h5" component="p" gutterBottom>
              {index === 0 ? day : <>&nbsp;</>}
            </Typography>
            <LogGalleryItem
              log={log}
              renderNoteForm={renderNoteForm}
              processLabels={processLabels}
            />
          </Stack>
        )),
      )}
    </GalleryRoot>
  );
}

function groupLogsByDay(
  logs: ReadonlyArray<Log>,
  formatToDay: (value: Date | bigint) => string,
): ReadonlyArray<{ day: string; logs: ReadonlyArray<Log> }> {
  const groupedLogsMap = new Map<string, ReadonlyArray<Log>>();

  for (const log of logs) {
    emplace(groupedLogsMap, formatToDay(log.startTime!), {
      insert: () => [log],
      update: (value) => value.concat(log),
    });
  }

  const groupedLogs = new Array<{ day: string; logs: ReadonlyArray<Log> }>();
  for (const [day, dayGroup] of groupedLogsMap) {
    groupedLogs.push({ day, logs: dayGroup });
  }

  return groupedLogs;
}

const GalleryItemRoot = styled(CardActionArea)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "start",
  alignItems: "stretch",
  gap: theme.spacing(1),
})) as typeof CardActionArea;

const LogAttributes = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  left: theme.spacing(1),
  right: theme.spacing(1),
  height: "200px",
  display: "grid",
  gridTemplateAreas: `
    "group duration"
    "name  ."
  `,
  placeContent: "space-between",
  gap: theme.spacing(2),
}));

const LogGroup = styled(Typography)(({ theme }) => ({
  gridArea: "group",
  placeSelf: "start start",
  maxWidth: "100%",
  padding: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.background.paper, 0.75),
  backdropFilter: "blur(10px)",
}));

const LogDuration = styled(Typography)(({ theme }) => ({
  gridArea: "duration",
  placeSelf: "start end",
  maxWidth: "100%",
  fontWeight: "bold",
  padding: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.background.paper, 0.75),
  backdropFilter: "blur(10px)",
}));

const LogName = styled(Typography)(({ theme }) => ({
  gridArea: "name",
  placeSelf: "end start",
  maxWidth: "100%",
  fontWeight: "bold",
  padding: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.background.paper, 0.75),
  backdropFilter: "blur(10px)",
}));

export function LogGalleryItem({
  log,
  renderNoteForm,
  processLabels,
  actionRef,
}: {
  log: Log;
  renderNoteForm?: NoteFormRenderer;
  processLabels: LabelProcessor;
  actionRef?: React.Ref<ButtonBaseActions>;
}) {
  const [focused, setFocused] = useState(false);
  const focusTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Note form needs to be rendered as a sibling of the <GalleryItemRoot />
  // below rather than as a child of it since the <GalleryItemRoot /> will
  // render an <a>, consequently causing events in the form - such as clicks and
  // key presses -to bubble up to the <a>. It's not sufficient to prevent the
  // default event behavior in the form component because that also prevents
  // form submission via the submit button if it was activated.
  const formPopoverState = usePopupState({ variant: "popover" });
  invariant(
    !formPopoverState.isOpen || renderNoteForm != null,
    "Invalid state: 'renderNoteForm' should be defined",
  );

  function handleFocus(e: React.FocusEvent<HTMLElement>): void {
    // When the note form trigger button is focused - either through the user
    // focusing it or MUI's <Popover /> returning focus to the button when the
    // popover closes - the additional tags tooltip shouldn't appear.
    if (e.target.matches("[data-form-trigger]")) {
      return;
    }

    focusTimeoutRef.current = setTimeout(() => {
      setFocused(true);
    }, 250);
  }

  function handleBlur(): void {
    if (focusTimeoutRef.current !== null) {
      clearTimeout(focusTimeoutRef.current);
      focusTimeoutRef.current = null;
    }

    setFocused(false);
  }

  const groupNameQuery = useGroup(log.groupId, {
    select(response) {
      return response.data.name;
    },
  });

  const lqsNavigator = useLqsNavigator();

  return (
    <>
      <GalleryItemRoot
        action={actionRef}
        component={RouterLink}
        to={lqsNavigator.toPlayer({ logId: log.id })}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disableRipple
      >
        <LogThumbnail log={log} height={200} width="100%" />
        <Stack
          direction="row"
          spacing={2}
          sx={{ justifyContent: "space-between" }}
        >
          <TagList log={log} focused={focused} processLabels={processLabels} />
          <LogNote
            log={log}
            formPopoverState={
              renderNoteForm == null ? undefined : formPopoverState
            }
          />
        </Stack>
        <LogAttributes>
          <LogGroup
            variant="body2"
            noWrap
            style={
              groupNameQuery.status === "success"
                ? { fontWeight: "bold" }
                : { fontStyle: "italic" }
            }
          >
            {renderQuery(groupNameQuery, {
              loading: "Loading...",
              error: "Unknown",
              success: (name) => name,
            })}
          </LogGroup>
          <LogDuration variant="body2" noWrap>
            {prettyMilliseconds(
              floor(
                nanosecondsToMilliseconds(log.endTime! - log.startTime!),
                1,
              ),
              {
                colonNotation: true,
                keepDecimalsOnWholeSeconds: true,
              },
            )}
          </LogDuration>
          <LogName variant="body2" noWrap>
            {log.name}
          </LogName>
        </LogAttributes>
      </GalleryItemRoot>
      {renderNoteForm?.({ log, popupState: formPopoverState })}
    </>
  );
}

function LogNote({
  log,
  formPopoverState,
}: {
  log: Log;
  formPopoverState: PopupState | undefined;
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const lqsNavigator = useLqsNavigator();

  function preventDefaultClickBehavior(e: React.MouseEvent): void {
    e.preventDefault();
  }

  function handleTooltipOpen(): void {
    setTooltipOpen(true);
  }

  function handleTooltipClose(): void {
    setTooltipOpen(false);
  }

  let trigger;
  if (formPopoverState == null) {
    trigger = <Notes sx={{ height: 32 }} />;
  } else {
    const { onClick, ...triggerProps } = bindTrigger(formPopoverState);

    const handleClick = (e: React.MouseEvent): void => {
      onClick(e);

      preventDefaultClickBehavior(e);

      setTooltipOpen(false);
    };

    trigger = (
      <IconButton
        {...triggerProps}
        onClick={handleClick}
        data-form-trigger
        sx={{ alignSelf: "start" }}
      >
        <Notes />
      </IconButton>
    );
  }

  return (
    <Tooltip
      open={tooltipOpen && !formPopoverState?.isOpen}
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      placement="right"
      title={
        <Box sx={{ width: "50ch" }}>
          <Text bold>Log Note</Text>
          {log.note == null ? (
            <Text italic>No note</Text>
          ) : (
            <Text pre>{log.note}</Text>
          )}
          {lqsNavigator.toLogDetails != null && (
            <Link
              variant="body1"
              component={RouterLink}
              to={lqsNavigator.toLogDetails({ logId: log.id })}
            >
              See log details
            </Link>
          )}
        </Box>
      }
      slotProps={{
        popper: {
          onClick: preventDefaultClickBehavior,
        },
        tooltip: {
          sx: { maxWidth: "unset" },
        },
      }}
    >
      {trigger}
    </Tooltip>
  );
}

const TruncatedChipsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1),
    maxWidth: 400,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: alpha(theme.palette.background.paper, 0.75),
    backdropFilter: "blur(10px)",
    ...(theme.palette.mode === "dark" && {
      backgroundImage: getPaperDarkBackgroundImage(),
    }),
  },
}));

function TagList({
  log,
  processLabels,
  focused,
}: {
  log: Log;
  processLabels: LabelProcessor;
  focused: boolean;
}) {
  const [showTruncated, setShowTruncated] = useState(false);

  function handlePointerEnter(): void {
    setShowTruncated(true);
  }

  function handlePointerLeave(): void {
    setShowTruncated(false);
  }

  function handleMobileChipClick(e: React.MouseEvent<HTMLElement>): void {
    // The chip is a child of an <a> so prevent the default navigation behavior
    e.preventDefault();

    setShowTruncated((prev) => !prev);
  }

  const allLabelsQuery = useAllLabels({ select: selectLabelMap });

  const tagsQuery = useTags(log.id, { limit: 500 });

  const appliedLabelsQuery = combineQueries({
    queries: [allLabelsQuery, tagsQuery],
    transform([labelMap, { data: tags }]) {
      // For the gallery, want to show every label applied to this log whether
      // it has timestamps or not. Each label should only get listed once though.
      const appliedLabels = new Array<Label>();
      const uniqueLabelIds = new Set(tags.map((tag) => tag.labelId));
      for (const labelId of uniqueLabelIds) {
        const label = labelMap.get(labelId);
        if (label === undefined) {
          // Shouldn't happen
          continue;
        }

        appliedLabels.push(label);
      }

      return processLabels(appliedLabels);
    },
  });

  const isMobile = useIsMobile();

  const [prevIsMobile, setPrevIsMobile] = useState(isMobile);
  // Can't set state during render because there's a bug with React's
  // `useSyncExternalStore` (which MUI is using for the media query) and dispatching
  // state during render, so update in a layout effect instead for now.
  useLayoutEffect(
    function resetOnMobileChange() {
      if (isMobile !== prevIsMobile) {
        setPrevIsMobile(isMobile);
        setShowTruncated(false);
      }
    },
    [isMobile, prevIsMobile],
  );

  let content;
  switch (appliedLabelsQuery.status) {
    case "loading": {
      content = [1, 2, 3].map((key) => (
        <Skeleton
          key={key}
          variant="rectangular"
          width="8ch"
          height={32}
          sx={{ borderRadius: 4 }}
        />
      ));

      break;
    }
    case "error": {
      content = null;

      break;
    }
    case "success": {
      const {
        data: { visible, truncated },
      } = appliedLabelsQuery;

      const visibleChips = visible.map(({ label: { id, value }, selected }) => (
        <Chip key={id} label={value} color={selected ? "primary" : undefined} />
      ));
      const truncatedChips = truncated.map(
        ({ label: { id, value }, selected }) => (
          <Chip
            key={id}
            label={value}
            color={selected ? "primary" : undefined}
          />
        ),
      );

      content = (
        <>
          {visibleChips}
          {truncatedChips.length > 0 &&
            (isMobile ? (
              <>
                {showTruncated && truncatedChips}
                <Chip
                  variant="outlined"
                  icon={showTruncated ? <ExpandLess /> : <ExpandMore />}
                  label={showTruncated ? "Hide" : `+${truncatedChips.length}`}
                  onClick={handleMobileChipClick}
                />
              </>
            ) : (
              <TruncatedChipsTooltip
                open={focused || showTruncated}
                title={truncatedChips}
              >
                <Typography
                  onPointerEnter={handlePointerEnter}
                  onPointerLeave={handlePointerLeave}
                  sx={{ fontWeight: "bold" }}
                >
                  +{truncatedChips.length}
                </Typography>
              </TruncatedChipsTooltip>
            ))}
        </>
      );

      break;
    }
    default: {
      assertNever(appliedLabelsQuery);
    }
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        flexWrap: "wrap",
        alignItems: "center",
      }}
    >
      {content}
    </Stack>
  );
}

function selectLabelMap(response: LabelListResponse): Map<Label["id"], Label> {
  return new Map(response.data.map((label) => [label.id, label]));
}
