import type { StrictOmit } from "ts-essentials";
import type { HistoryCardProps } from "~/components/DetailsCards";
import { HistoryCard } from "~/components/DetailsCards";
import { renderForeignResource } from "./renderForeignResource";
import { useLqsVersion } from "./versioning";

type LqsHistoryCardProps = StrictOmit<
  HistoryCardProps,
  "apiVersion" | "deprecationPolicy" | "renderForeignResource"
>;

export function LqsHistoryCard(props: LqsHistoryCardProps) {
  const apiVersion = useLqsVersion();

  return (
    <HistoryCard
      {...props}
      apiVersion={apiVersion}
      deprecationPolicy="hide"
      renderForeignResource={renderForeignResource}
    />
  );
}
