import type { UseInfiniteQueryOptions } from "@tanstack/react-query";
import type { StrictOmit } from "ts-essentials";
import { invariant } from "~/lib/invariant";
import { useDataStoreClients } from "../context";
import type {
  CloudObject,
  ListObjectsRequest,
  ListObjectStoresRequest,
  ObjectDataResponse,
  ObjectListResponse,
  ObjectStore,
  ObjectStoreCreateRequest,
  ObjectStoreDataResponse,
  ObjectStoreListResponse,
  ObjectStoreUpdateRequest,
} from "../sdk";
import type { LqsQueryOptions } from "./utils";
import { createResourceCrudHooks } from "./utils";

export const {
  queryKeyFactory: objectStoreKeys,
  useList: useObjectStores,
  useFetch: useObjectStore,
  useCreate: useCreateObjectStore,
  useUpdate: useUpdateObjectStore,
  useDelete: useDeleteObjectStore,
} = createResourceCrudHooks({
  baseQueryKey: "object-stores",
  getIdentifier(objectStore: ObjectStore) {
    return objectStore.id;
  },
  listResource(
    { signal },
    { objectStoreApi },
    request: ListObjectStoresRequest,
  ) {
    return objectStoreApi.listObjectStores(request, { signal });
  },
  fetchResource(
    { signal },
    { objectStoreApi },
    objectStoreId: ObjectStore["id"],
  ) {
    return objectStoreApi.fetchObjectStore({ objectStoreId }, { signal });
  },
  createResource({ objectStoreApi }, request: ObjectStoreCreateRequest) {
    return objectStoreApi.createObjectStore({
      objectStoreCreateRequest: request,
    });
  },
  updateResource(
    { objectStoreApi },
    objectStoreId: ObjectStore["id"],
    updates: ObjectStoreUpdateRequest,
  ) {
    return objectStoreApi.updateObjectStore({
      objectStoreId,
      objectStoreUpdateRequest: updates,
    });
  },
  deleteResource({ objectStoreApi }, objectStoreId: ObjectStore["id"]) {
    return objectStoreApi.deleteObjectStore({ objectStoreId });
  },
});

export const objectStoreObjectKeys = {
  all: (objectStoreId: ObjectStore["id"]) =>
    [...objectStoreKeys.fetch(objectStoreId), "objects"] as const,
  lists: (objectStoreId: ObjectStore["id"]) =>
    [...objectStoreObjectKeys.all(objectStoreId), "list"] as const,
  list: (
    objectStoreId: ObjectStore["id"],
    request: StrictOmit<ListObjectsRequest, "objectStoreId">,
  ) => [...objectStoreObjectKeys.lists(objectStoreId), request] as const,
  fetches: (objectStoreId: ObjectStore["id"]) =>
    [...objectStoreObjectKeys.all(objectStoreId), "fetch"] as const,
  fetch: (objectStoreId: ObjectStore["id"], objectKey: CloudObject["key"]) =>
    [...objectStoreObjectKeys.fetches(objectStoreId), objectKey] as const,
};

export function useObjectStoresQueryOptionsFactory(): (
  request: ListObjectStoresRequest,
) => LqsQueryOptions<ObjectStoreListResponse> {
  const { objectStoreApi } = useDataStoreClients();

  return (request) => ({
    queryKey: objectStoreKeys.list(request),
    queryFn({ signal }) {
      return objectStoreApi.listObjectStores(request, { signal });
    },
  });
}

export function useObjectStoreQueryOptionsFactory(): (
  objectStoreId: ObjectStore["id"] | null,
) => LqsQueryOptions<ObjectStoreDataResponse, "enabled"> {
  const { objectStoreApi } = useDataStoreClients();

  return (objectStoreId) => {
    const enabled = objectStoreId !== null;

    return {
      queryKey: objectStoreKeys.fetch(objectStoreId),
      queryFn({ signal }) {
        invariant(enabled, "Object store ID not provided");

        return objectStoreApi.fetchObjectStore({ objectStoreId }, { signal });
      },
      enabled,
    };
  };
}

export function useObjectStoreObjectsQueryOptionsFactory(): (
  objectStoreId: ObjectStore["id"],
  request: StrictOmit<
    ListObjectsRequest,
    "objectStoreId" | "continuationToken"
  >,
) => UseInfiniteQueryOptions<ObjectListResponse> {
  const { objectStoreApi } = useDataStoreClients();

  return (objectStoreId, request) => ({
    queryKey: objectStoreObjectKeys.list(objectStoreId, request),
    queryFn({ signal, pageParam: continuationToken }) {
      return objectStoreApi.listObjects(
        { objectStoreId, ...request, continuationToken },
        { signal },
      );
    },
  });
}

export function useObjectStoreObjectQueryOptionsFactory(): (
  objectStoreId: ObjectStore["id"],
  objectKey: CloudObject["key"],
) => LqsQueryOptions<ObjectDataResponse> {
  const { objectStoreApi } = useDataStoreClients();

  return (objectStoreId, objectKey) => ({
    queryKey: objectStoreObjectKeys.fetch(objectStoreId, objectKey),
    queryFn({ signal }) {
      return objectStoreApi.fetchObject(
        { objectStoreId, objectKey },
        { signal },
      );
    },
  });
}
