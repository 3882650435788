import { NewLqsResourceForm, useCreateLogQuery } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { createLogQuerySchema } from "../schemas";

export function LogQueryCreateForm() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_QUERY_CREATE);

  const lqsNavigator = useLqsNavigator({ toLogQueryDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="query"
      registryKey="query"
      schema={createLogQuerySchema}
      descriptors={[
        { name: "name", type: "text" },
        { name: "statement", type: "text", multiline: true, monospace: true },
        { name: "parameters", type: "json" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateLogQuery(logId)}
      createDetailsLocation={(response) =>
        lqsNavigator.toLogQueryDetails({
          logId,
          queryId: response.data.id,
        })
      }
    />
  );
}
