import { EditLqsResourceForm, useApiKey, useUpdateApiKey } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editApiKeySchema } from "../schemas";

export function ApiKeyEditForm() {
  const { apiKeyId } = useTypedParams(lqsRoutePaths.API_KEY_EDIT);

  const lqsNavigator = useLqsNavigator({ toApiKeyDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="API key"
      registryKey="apiKey"
      query={useApiKey(apiKeyId, { select: selectData })}
      schema={editApiKeySchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        { name: "disabled", type: "boolean" },
      ]}
      mutation={useUpdateApiKey(apiKeyId)}
      detailsLocation={lqsNavigator.toApiKeyDetails({ apiKeyId })}
    />
  );
}
