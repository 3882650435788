import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar, processTypeOptions } from "~/lqs";
import { listWorkflowsSchema } from "../schemas";
import { useWorkflowSearchRequest } from "./WorkflowTable";

export function WorkflowFilters() {
  const [request, setRequest] = useWorkflowSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="workflow"
      request={request}
      setRequest={setRequest}
      schema={listWorkflowsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "processType", type: "select", options: processTypeOptions },
        { name: "_default", type: "boolean" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
