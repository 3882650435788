import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { Label, LqsColumn } from "~/lqs";
import { LqsResourceTable, useLabels } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { listLabelsSchema } from "../schemas";

function LabelValueCell({ label }: { label: Label }) {
  const lqsNavigator = useLqsNavigator({ toLabelDetails: true });

  return (
    <TableCell>
      {label.value}
      <Link
        component={RouterLink}
        to={lqsNavigator.toLabelDetails({ labelId: label.id })}
      >
        <Typography variant="body2">{label.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<"label">> = [
  {
    header: "Value",
    sortKey: "value",
    renderCell: (label) => <LabelValueCell label={label} />,
  },
  {
    accessor: "category",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useLabelSearchRequest, LabelSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listLabelsSchema,
  });

export function LabelTable() {
  const [request, setRequest] = useLabelSearchRequest();

  const lqsNavigator = useLqsNavigator({ toLabelCreate: true });

  const searchQuery = useLabels(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="label"
      registryKey="label"
      resourceCreateLocation={lqsNavigator.toLabelCreate()}
      getRowKey={(label) => label.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
