import { LoadingButton } from "@mui/lab";
import { Alert } from "@mui/material";
import type { UseMutationResult } from "@tanstack/react-query";
import { AuthErrorAlert } from "./auth-error-alert";

export function RequestNewCodeButton({
  mutation,
  email,
}: {
  mutation: UseMutationResult<unknown, unknown, { email: string }>;
  email: string;
}) {
  function handleRequestNewCode(): void {
    mutation.mutate({ email });
  }

  let alert;
  if (mutation.isSuccess) {
    alert = (
      <Alert variant="outlined" severity="success">
        A code was sent to your email
      </Alert>
    );
  } else if (mutation.isError) {
    alert = <AuthErrorAlert mutation={mutation} />;
  }

  return (
    <>
      <LoadingButton
        type="button"
        color="primary"
        variant="text"
        fullWidth
        loading={mutation.isLoading}
        onClick={handleRequestNewCode}
      >
        Send New Code
      </LoadingButton>
      {alert}
    </>
  );
}
