import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { LabelCreateForm } from "./components/LabelCreateForm";

export function NewLabelPage() {
  return (
    <LqsPage title="New Label">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LabelCreateForm />
      </Container>
    </LqsPage>
  );
}
