import { EditLqsResourceForm, useLabel, useUpdateLabel } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editLabelSchema } from "../schemas";

export function LabelEditForm() {
  const { labelId } = useTypedParams(lqsRoutePaths.LABEL_EDIT);

  const lqsNavigator = useLqsNavigator({ toLabelDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="label"
      registryKey="label"
      query={useLabel(labelId, { select: selectData })}
      schema={editLabelSchema}
      descriptors={[
        { name: "value", type: "text", required: true },
        { name: "category", type: "text" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateLabel(labelId)}
      detailsLocation={lqsNavigator.toLabelDetails({ labelId })}
    />
  );
}
