/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DigestionTopicCreateRequest {
  topicId: string;
  startTime: bigint | null;
  endTime: bigint | null;
  frequency?: number | null;
  queryDataFilter?: object | null;
  contextFilter?: object | null;
}

export function DigestionTopicCreateRequestFromJSON(
  json: any,
): DigestionTopicCreateRequest {
  return DigestionTopicCreateRequestFromJSONTyped(json);
}

export function DigestionTopicCreateRequestFromJSONTyped(
  json: any,
): DigestionTopicCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    topicId: json["topic_id"],
    startTime:
      json["start_time"] == null
        ? json["start_time"]
        : BigInt(json["start_time"]),
    endTime:
      json["end_time"] == null ? json["end_time"] : BigInt(json["end_time"]),
    frequency: json["frequency"],
    queryDataFilter: json["query_data_filter"],
    contextFilter: json["context_filter"],
  };
}

export function DigestionTopicCreateRequestToJSON(
  value: DigestionTopicCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    topic_id: value.topicId,
    start_time: value.startTime === null ? null : value.startTime.toString(),
    end_time: value.endTime === null ? null : value.endTime.toString(),
    frequency: value.frequency,
    query_data_filter: value.queryDataFilter,
    context_filter: value.contextFilter,
  };
}
