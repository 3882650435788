import { TablePage } from "~/components/Table";
import { DigestionFilters } from "./components/DigestionFilters";
import {
  DigestionSearchRequestProvider,
  DigestionTable,
} from "./components/DigestionTable";

export function DigestionsPage() {
  return (
    <TablePage
      title="Search Digestions"
      RequestProvider={DigestionSearchRequestProvider}
      table={<DigestionTable />}
      filters={<DigestionFilters />}
    />
  );
}
