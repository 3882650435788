import React from "react";
import { Stack } from "@mui/material";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { StrictOmit } from "ts-essentials";
import { DocumentTitle } from "~/components/document-title";
import * as Layout from "~/layout";
import { SidebarTrigger } from "~/layout";
import { identity } from "~/lib/std";
import type {
  PanelInitializationStateOverrider,
  PlayerConfigProps,
  VisualizationStoreParams,
} from "~/player";
import {
  LogPlaybackSourceProvider,
  PanelLayoutProvider,
  PlaybackSettingsProvider,
  PlaybackTimerProvider,
  Player,
  PlayerConfigProvider,
  PlayerLayoutStateProvider,
  RecordStoreProvider,
  TagStoreProvider,
  ThreeDControlsProvider,
  usePlayerParams,
  VisualizationStoreParamsProvider,
} from "~/player";
import { useDefaultLayoutProfile } from "./hooks";
import { useAutoSkipSetting } from "./sidebars";

interface PlayerPageProps extends StrictOmit<PlayerConfigProps, "autoSkip"> {
  headerTitle: React.JSX.Element;
  renderSidebarWrapper?: (children: React.ReactNode) => React.ReactNode;
  InferenceProvider?: React.ComponentType<{ children: React.ReactNode }>;
  visualizationStoreParams: VisualizationStoreParams;
  sidebars: ReadonlyArray<{
    sidebarId: string;
    trigger?: {
      title: string;
      icon: React.JSX.Element;
    };
    element: React.JSX.Element;
  }>;
  disableSettingDefaultLayout?: boolean;
  overridePanelInitializationState?: PanelInitializationStateOverrider;
}

export function PlayerPage({
  headerTitle,
  renderSidebarWrapper = identity,
  InferenceProvider = React.Fragment,
  visualizationStoreParams,
  sidebars,
  disableSettingDefaultLayout = false,
  overridePanelInitializationState,
  ...config
}: PlayerPageProps) {
  const { logId } = usePlayerParams();

  const initialLayoutProfile = useDefaultLayoutProfile();

  const autoSkipSetting = useAutoSkipSetting();

  const hasLogId = logId != null;

  const sidebarTriggers = sidebars.flatMap((sidebarOptions) => {
    if (sidebarOptions.trigger == null) {
      return [];
    }

    return (
      <SidebarTrigger
        key={sidebarOptions.sidebarId}
        title={sidebarOptions.trigger.title}
        sidebarId={sidebarOptions.sidebarId}
        icon={sidebarOptions.trigger.icon}
      />
    );
  });
  const sidebarSwitchOptions = sidebars.map(({ sidebarId, element }) => ({
    sidebarId,
    element,
  }));

  return (
    <>
      <DocumentTitle>Player</DocumentTitle>
      <PlayerLayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation />
          <Layout.Header
            title={headerTitle}
            actions={
              <Stack direction="row" spacing={1}>
                {sidebarTriggers}
              </Stack>
            }
          />
          <PlayerConfigProvider {...config} autoSkip={autoSkipSetting}>
            <PlaybackSettingsProvider>
              <PanelLayoutProvider
                // This will reset all panel layout state when switching between
                // there being some log selected and no log selected. It's
                // important for the key not to change when switching between
                // logs since keeping the same panels open is a supported use
                // case. This value should only change in a few situations:
                // 1. Selecting a log for the first time on the player page
                //    (`false` -> `true`)
                // 2. Clicking the browser's back button after situation (1) or
                //    otherwise traversing session history to an entry where the
                //    URL doesn't have a log ID (`true` -> `false`)
                // 3. Clicking the "Player" link in the global navigation when
                //    a log is currently selected (`true` -> `false`)
                key={String(hasLogId)}
                initialLayout={initialLayoutProfile?.layout}
                overrideInitializationState={overridePanelInitializationState}
              >
                <LogPlaybackSourceProvider>
                  <VisualizationStoreParamsProvider
                    params={visualizationStoreParams}
                  >
                    <RecordStoreProvider>
                      <TagStoreProvider>
                        <PlaybackTimerProvider>
                          <ThreeDControlsProvider>
                            <InferenceProvider>
                              <Layout.Main>
                                <Player
                                  noLogMessage="To get started, search for a log in the side panel to the right"
                                  disableSettingDefaultLayout={
                                    disableSettingDefaultLayout
                                  }
                                />
                              </Layout.Main>
                              {renderSidebarWrapper(
                                <Layout.Sidebar>
                                  <Layout.SidebarSwitch
                                    options={sidebarSwitchOptions}
                                  />
                                </Layout.Sidebar>,
                              )}
                            </InferenceProvider>
                          </ThreeDControlsProvider>
                        </PlaybackTimerProvider>
                      </TagStoreProvider>
                    </RecordStoreProvider>
                  </VisualizationStoreParamsProvider>
                </LogPlaybackSourceProvider>
              </PanelLayoutProvider>
            </PlaybackSettingsProvider>
          </PlayerConfigProvider>
        </Layout.Root>
      </PlayerLayoutStateProvider>
      <ReactQueryDevtools position="bottom-left" />
    </>
  );
}
