import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";

export function GlobalLoadingFallback() {
  return (
    <Backdrop open>
      <span data-testid="global-fallback" />
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
