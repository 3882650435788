import type React from "react";
import { createSafeContext } from "~/contexts";
import {
  usePanelLayoutContext,
  usePlaybackSource,
  usePlayerTopics,
} from "~/player";
import { useDigestionFinalizer } from "./digestion-sidebar";
import type { DraftDigestion } from "./use-draft-digestion";
import { useDraftDigestion } from "./use-draft-digestion";

const [useDigestionStateContext, DigestionStateContext] = createSafeContext<
  {
    draftDigestion: DraftDigestion;
    naming: "digestion" | "extraction";
  } & ReturnType<typeof useDigestionFinalizer>
>("DigestionState");

export { useDigestionStateContext };

export function DigestionStateProvider({
  naming,
  children,
}: {
  naming: "digestion" | "extraction";
  children: React.ReactNode;
}) {
  const topicsQuery = usePlayerTopics();

  const playbackSource = usePlaybackSource();

  const { layout } = usePanelLayoutContext();

  const draftDigestion = useDraftDigestion({
    playerTopics: topicsQuery.data,
    playerRange: playbackSource.range,
    layout,
  });

  const digestionFinalizer = useDigestionFinalizer(
    draftDigestion.topics,
    naming,
  );

  return (
    <DigestionStateContext.Provider
      value={{
        draftDigestion,
        naming,
        ...digestionFinalizer,
      }}
    >
      {children}
    </DigestionStateContext.Provider>
  );
}
