import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { LogQueryEditForm } from "./components/LogQueryEditForm";

export function EditLogQueryPage() {
  return (
    <LqsPage title="Edit Query">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LogQueryEditForm />
      </Container>
    </LqsPage>
  );
}
