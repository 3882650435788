import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar, processStateOptions } from "~/lqs";
import { listIngestionsSchema } from "../schemas";
import { useIngestionSearchRequest } from "./IngestionTable";

export function IngestionFilters() {
  const [request, setRequest] = useIngestionSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="ingestion"
      request={request}
      setRequest={setRequest}
      schema={listIngestionsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "logId", type: "foreign-key", resourceType: "log" },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowIdNull", type: "boolean" },
        {
          name: "objectStoreId",
          type: "foreign-key",
          resourceType: "object-store",
        },
        { name: "objectKey", type: "fuzzy", fuzzyName: "objectKeyLike" },
        { name: "state", type: "select", options: processStateOptions },
        { name: "noteLike", type: "text", label: "Note" },
        { name: "progressGte", type: "number" },
        { name: "progressLte", type: "number" },
        { name: "progressNull", type: "boolean" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
