import type { FieldValues, Path } from "react-hook-form";
import type { ResourceSelectFieldProps } from "~/components/Form";
import { ResourceSelectField } from "~/components/Form";
import {
  useWorkflowQueryOptionsFactory,
  useWorkflowsQueryOptionsFactory,
} from "../api";
import type { ProcessType } from "../sdk";

interface WorkflowSelectProps<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
> extends ResourceSelectFieldProps<TFieldValues, TName> {
  processType?: ProcessType;
}

export function WorkflowSelect<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>({ processType, ...rest }: WorkflowSelectProps<TFieldValues, TName>) {
  const createWorkflowsQueryOptions = useWorkflowsQueryOptionsFactory();
  const createWorkflowQueryOptions = useWorkflowQueryOptionsFactory();

  return (
    <ResourceSelectField
      {...rest}
      createListQueryOptions={(text) =>
        createWorkflowsQueryOptions({
          processType,
          nameLike: text,
          limit: 5,
          sort: "asc",
          order: "name",
        })
      }
      createFetchQueryOptions={createWorkflowQueryOptions}
      selectOption={(workflow) => ({
        value: workflow.id,
        label: workflow.name,
      })}
    />
  );
}
