import type { Control } from "react-hook-form";
import type { FormFieldDescriptor } from "~/components/Form";
import { BooleanField, DateField } from "~/components/Form";
import { filterBoolean, filterDate, filterUuid } from "./schemas";
import type { CommonResourceFilters } from "./types";
import { UserSelect } from "./user-select";

export const commonResourceFilterSchemas = {
  createdBy: filterUuid,
  createdAtGte: filterDate,
  createdAtLte: filterDate,
  updatedBy: filterUuid,
  updatedByNull: filterBoolean,
  updatedAtGte: filterDate,
  updatedAtLte: filterDate,
  updatedAtNull: filterBoolean,
  deletedBy: filterUuid,
  deletedByNull: filterBoolean,
  deletedAtGte: filterDate,
  deletedAtLte: filterDate,
  deletedAtNull: filterBoolean,
} as const;

export const commonResourceFormFieldDescriptors = [
  { name: "createdBy", type: "foreign-key", resourceType: "user" },
  { name: "createdAtGte", type: "datetime" },
  { name: "createdAtLte", type: "datetime" },
  { name: "updatedBy", type: "foreign-key", resourceType: "user" },
  { name: "updatedByNull", type: "boolean" },
  { name: "updatedAtGte", type: "datetime" },
  { name: "updatedAtLte", type: "datetime" },
  { name: "updatedAtNull", type: "boolean" },
  { name: "deletedBy", type: "foreign-key", resourceType: "user" },
  { name: "deletedByNull", type: "boolean" },
  { name: "deletedAtGte", type: "datetime" },
  { name: "deletedAtLte", type: "datetime" },
  { name: "deletedAtNull", type: "boolean" },
] satisfies ReadonlyArray<FormFieldDescriptor<CommonResourceFilters, "user">>;

export function CommonFilters<TFieldValues extends CommonResourceFilters>({
  control,
}: {
  control: Control<TFieldValues>;
}) {
  // It appears necessary to cast `control` as `any` for all the fields below
  // since otherwise TS raises an error about the `name` props not being
  // assignable. My guess is there's an issue when the field components check
  // the `name` prop since the `control` prop here is already generic.

  return (
    <>
      <UserSelect control={control as any} name="createdBy" />
      <DateField control={control as any} name="createdAtGte" />
      <DateField control={control as any} name="createdAtLte" />
      <UserSelect control={control as any} name="updatedBy" />
      <BooleanField control={control as any} name="updatedByNull" />
      <DateField control={control as any} name="updatedAtGte" />
      <DateField control={control as any} name="updatedAtLte" />
      <BooleanField control={control as any} name="updatedAtNull" />
      <UserSelect control={control as any} name="deletedBy" />
      <BooleanField control={control as any} name="deletedByNull" />
      <DateField control={control as any} name="deletedAtGte" />
      <DateField control={control as any} name="deletedAtLte" />
      <BooleanField control={control as any} name="deletedAtNull" />
    </>
  );
}
