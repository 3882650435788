import type React from "react";
import type { UseQueryResult } from "@tanstack/react-query";

interface QueryRendererProps<TData, TError> {
  query: UseQueryResult<TData, TError>;
  loading?: React.ReactNode;
  error?: React.ReactNode | ((error: TError) => React.ReactNode);
  success: (data: TData) => React.ReactNode;
}

export function QueryRenderer<TData, TError>({
  query,
  loading = null,
  error = null,
  success,
}: QueryRendererProps<TData, TError>) {
  return renderQuery(query, { loading, error, success });
}

export function renderQuery<TData, TError>(
  query: UseQueryResult<TData, TError>,
  {
    error,
    loading,
    success,
  }: Pick<QueryRendererProps<TData, TError>, "loading" | "error" | "success">,
) {
  switch (query.status) {
    case "loading": {
      return loading;
    }
    case "error": {
      return typeof error === "function" ? error(query.error) : error;
    }
    case "success": {
      return success(query.data);
    }
  }
}
