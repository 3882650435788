import type { Option } from "~/components/Form";
import { filterEnum, optionalEnum, requiredEnum } from "~/domain/common";
import { ProcessType } from "../sdk";

export const processTypeOptions = Object.values(ProcessType).map(
  (value): Option => ({
    label: value,
    value,
  }),
);

export const requiredProcessType = requiredEnum(ProcessType);

export const optionalProcessType = optionalEnum(ProcessType);

export const filterProcessType = filterEnum(ProcessType);
