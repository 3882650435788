import type React from "react";
import {
  InferenceFrameControls,
  InferenceFrameControlsProvider,
  OnDemandInferenceControls,
  OnDemandInferenceControlsProvider,
  OnDemandInferenceProvider,
} from "~/player";

export function StudioPlayerInferenceProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <OnDemandInferenceProvider>
      <OnDemandInferenceControlsProvider
        element={<OnDemandInferenceControls />}
      >
        <InferenceFrameControlsProvider element={<InferenceFrameControls />}>
          {children}
        </InferenceFrameControlsProvider>
      </OnDemandInferenceControlsProvider>
    </OnDemandInferenceProvider>
  );
}
