import type { ElementOf } from "ts-essentials";
import { emplace } from "~/lib/std";

export interface SearchParamsParser<TSearchParams extends Record<string, any>> {
  parse: (
    rawSearchParams: Record<string, string | Array<string>>,
  ) => TSearchParams;
}

export type SearchParamAliases<TParams extends Record<string, any>> = Partial<
  Record<keyof TParams & string, string>
>;

export function createPrefixedAliases<
  const TNames extends ReadonlyArray<string>,
>(prefix: string, names: TNames): Record<ElementOf<TNames>, string> {
  const aliases: Partial<Record<ElementOf<TNames>, string>> = {};
  for (const name of names) {
    aliases[name as ElementOf<TNames>] = `${prefix}.${name}`;
  }

  return aliases as any;
}

/**
 * Transforms URL search params to an object suitable for parsing by zod which
 * preserves multiple values if encountered. Makes no assumptions about which
 * fields should or should not be arrays. Instead, if a param is only
 * encountered once, the resulting object field will contain that single string
 * value; if it's encountered multiple times, the resulting object field will
 * contain an array of the string values in the order encountered.
 *
 * Can optionally use aliases to map a search param's name to a different
 * property name on the resulting object. Aliases should be an object whose keys
 * are the object property names and values are the corresponding search param
 * names.
 */
export function searchParamsToObject(
  searchParams: URLSearchParams,
  aliases: Partial<Record<string, string>> = {},
): Record<string, string | Array<string>> {
  const entries = new Map<string, string | Array<string>>();

  const invertedAliases = Object.fromEntries(
    Object.entries(aliases).map(([jsName, paramName]) => [paramName, jsName]),
  );

  for (const [key, value] of searchParams.entries()) {
    const effectiveKey = invertedAliases[key] ?? key;

    emplace(entries, effectiveKey, {
      insert() {
        return value;
      },
      update(prevValue) {
        if (Array.isArray(prevValue)) {
          return prevValue.concat(value);
        } else {
          return [prevValue, value];
        }
      },
    });
  }

  return Object.fromEntries(entries);
}

/**
 * Given a record representing search params, an optional source search
 * params, and an optional set of param name aliases, serialize the record's
 * values. Object values are not supported.
 *
 * Serialized values lose information about their original type, e.g. a boolean
 * `true` is serialized as the string "1" which is indistinguishable from a
 * param whose value was "1".
 *
 * Nullish values are omitted from the final search params.
 *
 * When passed source search params, the record effectively acts as a rebase
 * on top of those source params, updating params from the source with keys
 * in the record, removing params from the source with nullish values in the
 * record, adding new params with keys in the record but not in the source,
 * and not modifying source params with no keys in the record.
 *
 * When passed aliases, any keys must correspond with keys in the params record.
 * The alias value will be used as the name of the serialized search param for
 * the corresponding record key. This is useful for params whose programmatic
 * names aren't suitable for a URL, perhaps because the name is too long or it
 * simply looks ugly.
 */
export function serializeSearchParams<TParams extends Record<string, unknown>>({
  params,
  source,
  aliases = {},
}: {
  params: TParams;
  source?: URLSearchParams;
  aliases?: NoInfer<SearchParamAliases<TParams>>;
}): URLSearchParams {
  const clone = new URLSearchParams(source);

  Object.entries(params).forEach(([key, value]) => {
    const effectiveKey = aliases[key] ?? key;

    if (value == null) {
      clone.delete(effectiveKey);
    } else if (Array.isArray(value)) {
      // Remove any existing instances of this param
      clone.delete(effectiveKey);

      value.forEach((element) => {
        clone.append(effectiveKey, serializeValue(element));
      });
    } else {
      clone.set(effectiveKey, serializeValue(value));
    }
  });

  return clone;
}

export function createSearchParamHandlers<TParams extends Record<any, any>>(
  parser: SearchParamsParser<TParams>,
  aliases?: NoInfer<SearchParamAliases<TParams>>,
) {
  return {
    deserialize(rawParams: URLSearchParams): TParams {
      return parser.parse(searchParamsToObject(rawParams, aliases));
    },
    serialize(
      params: Partial<TParams>,
      source?: URLSearchParams,
    ): URLSearchParams {
      return serializeSearchParams({
        params,
        source,
        aliases,
      });
    },
  };
}

function serializeValue(value: NonNullable<unknown>): string {
  if (value instanceof Date) {
    return value.toISOString();
  } else if (typeof value === "boolean") {
    return value ? "1" : "0";
  } else if (typeof value !== "object") {
    return String(value);
  } else {
    throw new Error("Object serialization not supported");
  }
}
