import type { StrictOmit } from "ts-essentials";
import * as z from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import {
  commonResourceFilterSchemas,
  filterText,
  filterUuid,
  optionalText,
  requiredText,
} from "~/domain/common";
import type {
  LabelCreateRequest,
  LabelUpdateRequest,
  ListLabelsRequest,
} from "~/lqs";

export const listLabelsSchema = z.object({
  id: filterUuid,
  value: filterText,
  valueLike: filterText,
  noteLike: filterText,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<StrictOmit<ListLabelsRequest, "includeCount">>);

export const createLabelSchema = z.object({
  value: requiredText,
  category: optionalText,
  note: optionalText,
} satisfies SchemaShape<LabelCreateRequest>);

export const editLabelSchema = z.object({
  value: requiredText,
  category: optionalText,
  note: optionalText,
} satisfies SchemaShape<LabelUpdateRequest>);
