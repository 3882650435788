import React from "react";
import type { Control } from "react-hook-form";
import type { ForeignKeyFormFieldDescriptor } from "~/components/Form";
import { RoleSelect, UserSelect } from "~/domain/common";
import { assertNever } from "~/utils/assertNever";
import { IngestionSelect } from "./IngestionSelect";
import { LogSelect } from "./LogSelect";
import { ObjectStoreSelect } from "./ObjectStoreSelect";
import { TopicSelect } from "./TopicSelect";
import { WorkflowSelect } from "./WorkflowSelect";
import { GroupSelectField } from "./group-select-field";
import { LabelMultiSelect, LabelSelectField } from "./label-select-field";
import type { LqsForeignKeyFormFieldType } from "./types";

export function renderLqsForeignKeyFormField(
  control: Control<any>,
  descriptor: ForeignKeyFormFieldDescriptor<any, LqsForeignKeyFormFieldType>,
  deprecated: boolean,
): React.JSX.Element {
  const { name, label, required, additionalProps } = descriptor;
  switch (descriptor.resourceType) {
    case "group": {
      return (
        <GroupSelectField
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "ingestion": {
      return (
        <IngestionSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "label": {
      const { multiple } = descriptor;

      if (multiple) {
        return (
          <LabelMultiSelect
            control={control}
            name={name}
            deprecated={deprecated}
            label={label}
            required={required}
          />
        );
      } else {
        return (
          <LabelSelectField
            control={control}
            name={name}
            deprecated={deprecated}
            label={label}
            required={required}
          />
        );
      }
    }
    case "log": {
      return (
        <LogSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "object-store": {
      return (
        <ObjectStoreSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "role": {
      return (
        <RoleSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "topic": {
      return (
        <TopicSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
          {...additionalProps}
        />
      );
    }
    case "user": {
      return (
        <UserSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    case "workflow": {
      return (
        <WorkflowSelect
          control={control}
          name={name}
          deprecated={deprecated}
          label={label}
          required={required}
        />
      );
    }
    default: {
      assertNever(descriptor);
    }
  }
}
