import * as z from "zod";
import type { SchemaShape } from "~/components/Form";
import {
  createErrorMap,
  isValueMissing,
  optionalObject,
  requiredArray,
  requiredEnum,
  requiredText,
} from "~/domain/common";
import type { PolicyInput } from "../sdk";
import { StatementAction, StatementEffect } from "../sdk";

// Zod doesn't seem to have a way to promote an object's field errors to a
// top-level form/schema error, so this will need to be used inside another
// schema's transform or refinement where custom error messages can be set
// if any part of this base schema fails to parse.
const basePolicyInput = z.object(
  {
    statement: requiredArray(
      z.object({
        effect: requiredEnum(StatementEffect),
        action: requiredArray(requiredEnum(StatementAction)),
        resource: requiredArray(requiredText),
        filter: optionalObject,
      }),
    ),
  } satisfies SchemaShape<PolicyInput>,
  {
    errorMap: createErrorMap({
      invalid_type(issue) {
        if (isValueMissing(issue)) {
          return "Field is required";
        }
      },
    }),
  },
);

export const requiredPolicyInput = z.unknown().transform((arg, ctx) => {
  if (arg == null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Field is required",
      fatal: true,
    });
    return z.NEVER;
  }

  const parseResult = basePolicyInput.safeParse(arg);

  if (parseResult.success) {
    return parseResult.data;
  } else {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        "Incorrect policy input. Refer to API documentation for policy input shape",
      fatal: true,
    });
    return z.NEVER;
  }
});
