import React from "react";
import { useSnackbar } from "notistack";
import type { Control } from "react-hook-form";
import type { Path } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import type { z } from "zod";
import type {
  ApiVersion,
  DeprecationPolicy,
  VersionHistories,
} from "~/domain/versioning";
import { Card } from "../Card";
import { Form } from "./Form";
import { useStudioForm } from "./hooks";
import { renderFormField } from "./render-form-field";
import type {
  ForeignKeyFormFieldDescriptor,
  ForeignKeyResourceType,
  FormFieldDescriptor,
  SimplifiedMutation,
} from "./types";
import { getAvailableFieldNames } from "./utils";

export interface NewResourceFormProps<
  TRequest extends object,
  TResponse extends object,
  TForeignResource extends ForeignKeyResourceType,
> {
  resourceName: string;
  schema: z.ZodObject<
    z.ZodRawShape,
    z.UnknownKeysParam,
    z.ZodTypeAny,
    TRequest
  >;
  descriptors: ReadonlyArray<
    FormFieldDescriptor<NoInfer<TRequest>, TForeignResource>
  >;
  versionHistories?: VersionHistories<any>;
  apiVersion: ApiVersion;
  deprecationPolicy: DeprecationPolicy;
  renderForeignKeyFormField: (
    control: Control<NoInfer<TRequest>>,
    descriptor: ForeignKeyFormFieldDescriptor<
      NoInfer<TRequest>,
      TForeignResource
    >,
    deprecated: boolean,
  ) => React.JSX.Element;
  mutation: SimplifiedMutation<TResponse, NoInfer<TRequest>>;
  createDetailsLocation: (response: TResponse) => Partial<Path>;
}

export function NewResourceForm<
  TRequest extends object,
  TResponse extends object,
  TForeignResource extends ForeignKeyResourceType,
>({
  resourceName,
  schema,
  descriptors,
  versionHistories,
  apiVersion,
  deprecationPolicy,
  renderForeignKeyFormField,
  mutation,
  createDetailsLocation,
}: NewResourceFormProps<TRequest, TResponse, TForeignResource>) {
  const availableFields = descriptors.flatMap((descriptor) => {
    const history = versionHistories?.[descriptor.name];

    if (history == null) {
      return [{ descriptor, deprecated: false }];
    } else {
      const status = apiVersion.checkStatus(history);

      if (status === "unavailable") {
        return [];
      } else if (deprecationPolicy === "hide" && status === "deprecated") {
        return [];
      } else {
        return [{ descriptor, deprecated: status === "deprecated" }];
      }
    }
  });

  const availableFieldNames = getAvailableFieldNames(availableFields);

  const defaultValues: any = Object.fromEntries(
    availableFields.map(({ descriptor }) => {
      return [
        descriptor.name,
        "defaultValue" in descriptor
          ? descriptor.defaultValue
          : descriptor.type === "boolean"
            ? false
            : null,
      ];
    }),
  );

  const pickedSchema = schema.pick(
    Object.fromEntries(availableFieldNames.map((name) => [name, true])),
  );

  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const { control, handleSubmit } = useStudioForm({
    schema: pickedSchema,
    defaultValues,
    onSubmit: function onSubmit(values: any) {
      mutation.mutate(values, {
        onSuccess(response) {
          navigate(createDetailsLocation(response));
        },
        onError() {
          enqueueSnackbar(`Unable to create ${resourceName}`, {
            variant: "error",
          });
        },
      });
    } as any,
  });

  return (
    <Card>
      <Form
        onSubmit={handleSubmit}
        loading={mutation.isLoading}
        submitText="Create"
      >
        {availableFields.map((field) => (
          <React.Fragment key={field.descriptor.name}>
            {renderFormField(
              control,
              field.descriptor,
              field.deprecated,
              renderForeignKeyFormField,
            )}
          </React.Fragment>
        ))}
      </Form>
    </Card>
  );
}
