import type { StrictOmit } from "ts-essentials";
import type { ResourceFieldsProps } from "~/components/DetailsCards";
import { ResourceFields } from "~/components/DetailsCards";
import { renderForeignResource } from "./renderForeignResource";
import type { DsmForeignResourceType } from "./types";
import { useDsmVersion } from "./versioning";

type DsmResourceFieldsProps<TResource extends object> = StrictOmit<
  ResourceFieldsProps<TResource, DsmForeignResourceType>,
  "apiVersion" | "deprecationPolicy" | "renderForeignResource"
>;

export function DsmResourceFields<TResource extends object>(
  props: DsmResourceFieldsProps<TResource>,
) {
  const apiVersion = useDsmVersion();

  return (
    <ResourceFields
      {...props}
      apiVersion={apiVersion}
      deprecationPolicy="hide"
      renderForeignResource={renderForeignResource}
    />
  );
}
