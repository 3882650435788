import React from "react";
import { Settings } from "@mui/icons-material";
import { ErrorBoundary } from "react-error-boundary";
import { DocumentTitle } from "~/components/document-title";
import { ErrorMessage } from "~/components/error-message";
import { SettingsSidebar } from "~/settings";
import * as Layout from "./layout";
import { ScrollableContainer } from "./scrollable-container";

export function Page({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <DocumentTitle>{title}</DocumentTitle>
      <Layout.LayoutStateProvider>
        <Layout.Root>
          <Layout.Navigation />
          <Layout.Header
            title={title}
            actions={
              <Layout.SidebarTrigger
                title="Settings"
                sidebarId="settings"
                icon={<Settings />}
              />
            }
          />
          <Layout.Main>
            <ScrollableContainer>
              <ErrorBoundary fallback={<PageError />}>{children}</ErrorBoundary>
            </ScrollableContainer>
          </Layout.Main>
          <Layout.Sidebar>
            <Layout.SidebarSwitch
              options={[
                {
                  sidebarId: "settings",
                  element: <SettingsSidebar />,
                },
              ]}
            />
          </Layout.Sidebar>
        </Layout.Root>
      </Layout.LayoutStateProvider>
    </>
  );
}

function PageError() {
  return (
    <ErrorMessage disableTypography>
      <ErrorMessage.Paragraph>
        An unexpected error occurred.
      </ErrorMessage.Paragraph>
      <ErrorMessage.Paragraph>Try refreshing the page.</ErrorMessage.Paragraph>
    </ErrorMessage>
  );
}
