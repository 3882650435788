import React, { useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  LinearProgress,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Link as RouterLink } from "react-router-dom";
import * as z from "zod";
import { Card } from "~/components/Card";
import { file, FileField, useStudioForm } from "~/components/Form";
import { MultipartUploader } from "~/domain/network";
import { formatPercent } from "~/format";
import {
  createObjectPartPresignedUrlFactory,
  useDataStoreClients,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";

const schema = z.object({
  file: file(),
});

export function LogObjectUploadForm() {
  const lqsNavigator = useLqsNavigator({ toLogObjectDetails: true });

  const { uploadObject, uploadProgress } = useObjectUpload();

  const { control, handleSubmit, trigger } = useStudioForm({
    schema,
    defaultValues: {
      file: null,
    },
    onSubmit(values) {
      uploadObject.mutate(values);
    },
  });

  return (
    <Card>
      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>
        <FileField
          control={control}
          name="file"
          maxSize={MultipartUploader.MAX_FILE_SIZE_BYTES}
          trigger={trigger}
        />
        <Button
          type="submit"
          variant="contained"
          disabled={uploadObject.isLoading}
          disableElevation
          sx={{ mt: 2 }}
        >
          Upload
        </Button>
        {uploadObject.isLoading && (
          <Stack sx={{ width: 1, pt: 2 }}>
            <Typography paragraph id="progress-label">
              {uploadProgress === null
                ? "Preparing to upload..."
                : uploadProgress === 1
                  ? "Finishing up..."
                  : `Upload progress: ${formatPercent(uploadProgress)}`}
            </Typography>
            <LinearProgress
              aria-labelledby="progress-label"
              variant="determinate"
              value={(uploadProgress ?? 0) * 100}
            />
          </Stack>
        )}
      </Box>
      {uploadObject.isError && (
        <Alert severity="error" variant="filled" sx={{ width: 1 }}>
          <AlertTitle>Upload Failed</AlertTitle>
          An error occurred trying to upload the object.
        </Alert>
      )}
      {uploadObject.isSuccess && (
        <Alert
          severity="success"
          variant="filled"
          sx={{ width: 1, color: "text.primary" }}
        >
          <AlertTitle>Upload Complete</AlertTitle>
          Object uploaded.{" "}
          <Link
            component={RouterLink}
            to={lqsNavigator.toLogObjectDetails(uploadObject.data)}
            color="inherit"
          >
            View its details
          </Link>
          .
        </Alert>
      )}
    </Card>
  );
}

function useObjectUpload() {
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);

  const { logId } = useTypedParams(lqsRoutePaths.LOG_OBJECT_UPLOAD);

  const { logApi } = useDataStoreClients();

  const uploadObject = useMutation({
    async mutationFn({ file }: { file: File }) {
      const {
        data: { key: objectKey },
      } = await logApi.createLogObject({
        logId,
        objectCreateRequest: {
          key: file.name,
        },
      });

      const multipartUploader = new MultipartUploader({
        blob: file,
        createPartPresignedUrl: createObjectPartPresignedUrlFactory(
          logApi,
          logId,
          objectKey,
        ),
        onUpdate: setUploadProgress,
      });

      setUploadProgress(0);

      await multipartUploader.start();

      setUploadProgress(1);

      await logApi.updateLogObject({
        logId,
        objectKey,
        objectUpdateRequest: {
          uploadState: "complete",
        },
      });

      return { logId, key: objectKey };
    },
  });

  return {
    uploadObject,
    uploadProgress,
  };
}
