import { Error as ErrorIcon } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import type { FallbackProps } from "react-error-boundary";

export function DefaultErrorFallback({ resetErrorBoundary }: FallbackProps) {
  return (
    <Stack height={1} width={1} justifyContent="center" alignItems="center">
      <ErrorIcon color="error" fontSize="large" />
      <Typography variant="h4" component="h1" gutterBottom>
        An error occurred!
      </Typography>
      <Typography paragraph>
        An unexpected error caused the DataStore to crash. Select <b>Reload</b>{" "}
        below to reload the page you were on.
      </Typography>
      <Button variant="contained" onClick={resetErrorBoundary}>
        Reload
      </Button>
    </Stack>
  );
}
