import { nanosecondsToDate } from "~/lib/dates";
import type { DateDisplay } from "~/settings";
import { assertNever } from "~/utils";

const commonOptions: Readonly<Intl.DateTimeFormatOptions> = {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

const utcFormatter = new Intl.DateTimeFormat(undefined, {
  ...commonOptions,
  timeZone: "UTC",
});

const localFormatter = new Intl.DateTimeFormat(undefined, {
  ...commonOptions,
});

/**
 * @internal
 */
export function formatDateOrTimestamp(
  value: Date | bigint,
  dateDisplay: DateDisplay,
): string {
  const date = typeof value === "bigint" ? nanosecondsToDate(value) : value;

  switch (dateDisplay) {
    case "utc": {
      return utcFormatter.format(date);
    }
    case "local": {
      return localFormatter.format(date);
    }
    default: {
      assertNever(dateDisplay);
    }
  }
}

const commonDayFormatterOptions: Readonly<Intl.DateTimeFormatOptions> = {
  weekday: "short",
  month: "short",
  day: "numeric",
};

const utcDayFormatter = new Intl.DateTimeFormat(undefined, {
  ...commonDayFormatterOptions,
  timeZone: "UTC",
});

const localDayFormatter = new Intl.DateTimeFormat(undefined, {
  ...commonDayFormatterOptions,
});

export function formatToDay(
  dateDisplay: DateDisplay,
  value: Date | bigint,
): string {
  const date = typeof value === "bigint" ? nanosecondsToDate(value) : value;

  switch (dateDisplay) {
    case "utc": {
      return utcDayFormatter.format(date);
    }
    case "local": {
      return localDayFormatter.format(date);
    }
    default: {
      assertNever(dateDisplay);
    }
  }
}
