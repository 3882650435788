import React, { useState } from "react";
import { createSafeContext } from "~/contexts";
import { useDataStoreClients } from "~/lqs";
import { RecordStore } from "./store";

export const [useRecordStoreContext, RecordStoreContext] =
  createSafeContext<RecordStore>("RecordStore");

interface RecordStoreProviderProps {
  children: React.ReactNode;
}

export function RecordStoreProvider({ children }: RecordStoreProviderProps) {
  const { topicApi } = useDataStoreClients();

  const [store] = useState(
    () => new RecordStore(topicApi.listRecords.bind(topicApi)),
  );

  return (
    <RecordStoreContext.Provider value={store}>
      {children}
    </RecordStoreContext.Provider>
  );
}
