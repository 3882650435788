import type { Record } from "~/lqs";
import type { RecordTypeDataMap } from "../../record-store";
import type { BaseRecordTypeHandler } from "../../record-store/record-types";
import { imageSegmentationsSchema } from "./schemas";

export class SegmentationsRecordHandler
  implements BaseRecordTypeHandler<"segmentations">
{
  getRequestParams() {
    return { includeRawData: true };
  }

  async transform(record: Record): Promise<RecordTypeDataMap["segmentations"]> {
    return imageSegmentationsSchema.parseAsync(record.rawData);
  }
}
