import type { DataStore } from "~/dsm";

export function buildDsmEndpoint(apiEndpoint: string): string {
  return `${apiEndpoint}/apps/dsm`;
}

export function buildPjmEndpoint(
  apiEndpoint: string,
  dataStoreId: DataStore["id"],
): string {
  return `${apiEndpoint}/apps/pjm/${dataStoreId}`;
}

function buildLqsEndpoint(apiEndpoint: string): string {
  return `${apiEndpoint}/apps/lqs`;
}

export function buildLqsRestApiDocsEndpoint(apiEndpoint: string): string {
  return `${buildLqsEndpoint(apiEndpoint)}/redoc`;
}

export function buildDataStoreEndpoint(
  apiEndpoint: string,
  dataStoreId: DataStore["id"],
): string {
  return `${buildLqsEndpoint(apiEndpoint)}/${dataStoreId}`;
}
