/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface DataStoreCreateRequest {
  name: string;
  note?: string | null;
  context?: object | null;
  ownerId?: string | null;
  config?: object | null;
  version?: string | null;
  region?: string | null;
  endpoint?: string | null;
  disabled?: boolean;
}

export function DataStoreCreateRequestFromJSON(
  json: any,
): DataStoreCreateRequest {
  return DataStoreCreateRequestFromJSONTyped(json);
}

export function DataStoreCreateRequestFromJSONTyped(
  json: any,
): DataStoreCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    name: json["name"],
    note: json["note"],
    context: json["context"],
    ownerId: json["owner_id"],
    config: json["config"],
    version: json["version"],
    region: json["region"],
    endpoint: json["endpoint"],
    disabled: json["disabled"],
  };
}

export function DataStoreCreateRequestToJSON(
  value: DataStoreCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value.name,
    note: value.note,
    context: value.context,
    owner_id: value.ownerId,
    config: value.config,
    version: value.version,
    region: value.region,
    endpoint: value.endpoint,
    disabled: value.disabled,
  };
}
