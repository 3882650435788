import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { LogObjectDetails } from "./components/LogObjectDetails";

export function LogObjectPage() {
  return (
    <LqsPage title="Object Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LogObjectDetails />
      </Container>
    </LqsPage>
  );
}
