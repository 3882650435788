import { convertBase64ImageToBlob } from "~/domain/network";
import { StudioError } from "~/errors";
import { loadDracoModel } from "~/lib/draco";
import { invariant } from "~/lib/invariant";
import type { ListRecordsRequest, Record as LqsRecord, Topic } from "~/lqs";
import type { Maybe } from "~/types";
import { assertNever } from "~/utils";
import {
  determineDetectionResultsDataField,
  getDetectionResultsRequest,
  getRecordDetectionResults,
  SegmentationsRecordHandler,
} from "../inference";
import type { DetectionResultsDataField } from "../inference";
import type {
  ListRecordsFn,
  PlayerRecord,
  RecordType,
  RecordTypeDataMap,
} from "./types";

export interface BaseRecordTypeHandler<TRecordType extends RecordType> {
  getRequestParams?: () =>
    | Pick<ListRecordsRequest, "includeAuxiliaryData" | "includeRawData">
    | undefined;
  transform: (
    record: LqsRecord,
  ) => RecordTypeDataMap[TRecordType] | Promise<RecordTypeDataMap[TRecordType]>;
  dispose?: (record: PlayerRecord<TRecordType>) => void;
}

interface InitializationParams {
  topicId: Topic["id"];
  listRecords: ListRecordsFn;
}

export interface InitializableRecordTypeHandler<TRecordType extends RecordType>
  extends BaseRecordTypeHandler<TRecordType> {
  getInitializationStatus: () => null | "pending" | "fulfilled";
  initialize: (params: InitializationParams) => Promise<void>;
  resetInitialization: () => void;
}

export type RecordTypeHandler<TRecordType extends RecordType> =
  | BaseRecordTypeHandler<TRecordType>
  | InitializableRecordTypeHandler<TRecordType>;

export class DefaultRecordHandler implements BaseRecordTypeHandler<"default"> {
  transform(record: LqsRecord): RecordTypeDataMap["default"] {
    return record.queryData;
  }
}

export class ImageRecordHandler implements BaseRecordTypeHandler<"image"> {
  getRequestParams() {
    return { includeAuxiliaryData: true };
  }

  async transform(record: LqsRecord): Promise<RecordTypeDataMap["image"]> {
    const base64EncodedImage: unknown = (record.auxiliaryData as any)?.image;

    invariant(
      typeof base64EncodedImage === "string",
      "Record has no auxiliary image data",
    );

    const image = await convertBase64ImageToBlob(base64EncodedImage);

    invariant(image !== null, "Auxiliary image data not usable");

    return image;
  }
}

export class DetectionsRecordHandler
  implements InitializableRecordTypeHandler<"detections">
{
  #initializationStatus: null | "pending" | "fulfilled" = null;
  #dataField: DetectionResultsDataField | null = null;

  getInitializationStatus(): null | "pending" | "fulfilled" {
    return this.#initializationStatus;
  }

  async initialize({
    topicId,
    listRecords,
  }: InitializationParams): Promise<void> {
    invariant(this.#initializationStatus === null, "Cannot initialize again");

    this.#initializationStatus = "pending";

    const { data } = await listRecords({
      topicId,
      limit: 1,
      includeAuxiliaryData: true,
      includeRawData: true,
    });

    invariant(data.length === 1, "Unable to determine detections data field");

    const [record] = data;

    this.#dataField = determineDetectionResultsDataField(record);

    this.#initializationStatus = "fulfilled";
  }

  resetInitialization(): void {
    this.#initializationStatus = null;
  }

  getRequestParams() {
    invariant(this.#dataField !== null, "Data field has not been determined");

    return getDetectionResultsRequest(this.#dataField);
  }

  transform(record: LqsRecord): RecordTypeDataMap["detections"] {
    invariant(this.#dataField !== null, "Data field has not been determined");

    return getRecordDetectionResults(record, this.#dataField);
  }
}

export class ThreeDError extends StudioError {}

export class ThreeDRecordHandler implements BaseRecordTypeHandler<"threeD"> {
  getRequestParams() {
    return { includeAuxiliaryData: true };
  }

  async transform(record: LqsRecord): Promise<RecordTypeDataMap["threeD"]> {
    const base64EncodedDraco: Maybe<string> = (record.auxiliaryData as any)
      ?.draco_binary;

    if (typeof base64EncodedDraco !== "string") {
      throw new ThreeDError({
        message: `Record with timestamp ${record.timestamp} did not contain a Draco model`,
      });
    }

    try {
      const geometry = await loadDracoModel(base64EncodedDraco);
      return geometry;
    } catch (reason) {
      throw new ThreeDError({ cause: reason });
    }
  }

  dispose(record: PlayerRecord<"threeD">): void {
    record.data?.dispose();
  }
}

export function createRecordHandler<TRecordType extends RecordType>(
  recordType: TRecordType,
): RecordTypeHandler<TRecordType> {
  switch (recordType) {
    case "default": {
      return new DefaultRecordHandler() as any;
    }
    case "image": {
      return new ImageRecordHandler() as any;
    }
    case "detections": {
      return new DetectionsRecordHandler() as any;
    }
    case "segmentations": {
      return new SegmentationsRecordHandler() as any;
    }
    case "threeD": {
      return new ThreeDRecordHandler() as any;
    }
    default: {
      assertNever(recordType);
    }
  }
}

export function isInitializableHandler<TRecordType extends RecordType>(
  handler: RecordTypeHandler<TRecordType>,
): handler is InitializableRecordTypeHandler<TRecordType> {
  return "getInitializationStatus" in handler;
}
