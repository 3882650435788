import { Close } from "@mui/icons-material";
import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useLayoutStateContext } from "./layout";

export function SidebarHeader({ title }: { title: string }) {
  const { setCurrentSidebarId } = useLayoutStateContext();

  function handleCloseSidebar() {
    setCurrentSidebarId(null);
  }

  return (
    <>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Typography variant="h5" component="p">
          {title}
        </Typography>
        <Tooltip title="Close">
          <IconButton onClick={handleCloseSidebar}>
            <Close />
          </IconButton>
        </Tooltip>
      </Stack>
      <Divider sx={{ my: 2 }} />
    </>
  );
}
