import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { ObjectStoreObjectDetails } from "./components/ObjectStoreObjectDetails";

export function ObjectStoreObjectPage() {
  return (
    <LqsPage title="Object Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <ObjectStoreObjectDetails />
      </Container>
    </LqsPage>
  );
}
