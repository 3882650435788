import type { FieldValues, Path } from "react-hook-form";
import type {
  ResourceMultiSelectFieldProps,
  ResourceMultiSelectInputProps,
  ResourceSelectFieldProps,
} from "~/components/Form";
import {
  ResourceMultiSelectField,
  ResourceMultiSelectInput,
  ResourceSelectField,
} from "~/components/Form";
import {
  useLabelQueryOptionsFactory,
  useLabelsQueryOptionsFactory,
} from "../api";

export function LabelSelectField<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(props: ResourceSelectFieldProps<TFieldValues, TName>) {
  const createLabelsQueryOptions = useLabelsQueryOptionsFactory();
  const createLabelQueryOptions = useLabelQueryOptionsFactory();

  return (
    <ResourceSelectField
      {...props}
      createListQueryOptions={(text) =>
        createLabelsQueryOptions({
          valueLike: text,
          limit: 5,
          sort: "asc",
          order: "value",
        })
      }
      createFetchQueryOptions={createLabelQueryOptions}
      selectOption={(label) => ({
        value: label.id,
        label: label.value,
      })}
    />
  );
}

export function LabelMultiSelectInput(props: ResourceMultiSelectInputProps) {
  const createLabelsQueryOptions = useLabelsQueryOptionsFactory();
  const createLabelQueryOptions = useLabelQueryOptionsFactory();

  return (
    <ResourceMultiSelectInput
      {...props}
      createListQueryOptions={(text) =>
        createLabelsQueryOptions({
          valueLike: text,
          limit: 5,
          sort: "asc",
          order: "value",
        })
      }
      createFetchQueryOptions={createLabelQueryOptions}
      selectOption={(label) => ({
        value: label.id,
        label: label.value,
      })}
    />
  );
}

export function LabelMultiSelect<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(props: ResourceMultiSelectFieldProps<TFieldValues, TName>) {
  const createLabelsQueryOptions = useLabelsQueryOptionsFactory();
  const createLabelQueryOptions = useLabelQueryOptionsFactory();

  return (
    <ResourceMultiSelectField
      {...props}
      createListQueryOptions={(text) =>
        createLabelsQueryOptions({
          valueLike: text,
          limit: 5,
          sort: "asc",
          order: "value",
        })
      }
      createFetchQueryOptions={createLabelQueryOptions}
      selectOption={(label) => ({
        value: label.id,
        label: label.value,
      })}
    />
  );
}
