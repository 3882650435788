import React from "react";
import { Box, CircularProgress, LinearProgress, Skeleton } from "@mui/material";
import { Center } from "./Center";

interface LoadingProps {
  type: "skeleton" | "circular" | "linear";
  children?: React.ReactNode;
}

export function Loading({ type = "skeleton", children }: LoadingProps) {
  if (type === "skeleton") {
    return <Skeleton variant="rectangular" height="100%" width="100%" />;
  } else if (type === "circular") {
    return (
      <Center>
        <CircularProgress />
        {children != null && children}
      </Center>
    );
  } else {
    return (
      <Box height={1} width={1}>
        <LinearProgress />
        {children != null && <Center>{children}</Center>}
      </Box>
    );
  }
}
