import type { StrictOmit } from "ts-essentials";
import * as z from "zod";
import type { SchemaShape, TableSchemaShape } from "~/components/Form";
import { commonResourceFilterSchemas } from "../../filters";
import {
  boolean,
  filterBoolean,
  filterText,
  filterUuid,
  optionalText,
  optionalUuid,
  requiredText,
} from "../../schemas";
import type {
  CommonListUsersRequest,
  CommonUserCreateRequest,
  CommonUserUpdateRequest,
} from "../../types";

export const listUsersSchema = z.object({
  username: filterText,
  usernameLike: filterText,
  roleId: filterUuid,
  admin: filterBoolean,
  managed: filterBoolean,
  disabled: filterBoolean,
  ...commonResourceFilterSchemas,
} satisfies TableSchemaShape<CommonListUsersRequest>);

export const createUserSchema = z.object({
  username: requiredText,
  roleId: optionalUuid,
  admin: boolean,
  managed: boolean,
  disabled: boolean,
  externalId: optionalText,
} satisfies SchemaShape<StrictOmit<CommonUserCreateRequest, "password">>);

export const editUserSchema = z.object({
  username: requiredText,
  roleId: optionalUuid,
  admin: boolean,
  disabled: boolean,
  externalId: optionalText,
} satisfies SchemaShape<StrictOmit<CommonUserUpdateRequest, "password">>);
