import {
  NewLqsResourceForm,
  ProcessState,
  processStateOptions,
  useCreateIngestionPart,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { createIngestionPartSchema } from "../schemas";

export function IngestionPartCreateForm() {
  const { ingestionId } = useTypedParams(lqsRoutePaths.INGESTION_PART_CREATE);

  const lqsNavigator = useLqsNavigator({ toIngestionPartDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="ingestion part"
      registryKey="ingestionPart"
      schema={createIngestionPartSchema}
      descriptors={[
        { name: "sequence", type: "number", required: true },
        { name: "source", type: "text" },
        {
          name: "state",
          type: "select",
          options: processStateOptions,
          defaultValue: ProcessState.Ready,
          required: true,
        },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowContext", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateIngestionPart(ingestionId)}
      createDetailsLocation={(response) =>
        lqsNavigator.toIngestionPartDetails({
          ingestionId,
          ingestionPartId: response.data.id,
        })
      }
    />
  );
}
