import {
  EditLqsResourceForm,
  processStateOptions,
  useDigestionPart,
  useUpdateDigestionPart,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editDigestionPartSchema } from "../schemas";

export function DigestionPartEditForm() {
  const { digestionId, digestionPartId } = useTypedParams(
    lqsRoutePaths.DIGESTION_PART_EDIT,
  );

  const lqsNavigator = useLqsNavigator({ toDigestionPartDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="digestion part"
      registryKey="digestionPart"
      query={useDigestionPart(digestionId, digestionPartId, {
        select: selectData,
      })}
      schema={editDigestionPartSchema}
      descriptors={[
        { name: "sequence", type: "number", required: true },
        {
          name: "state",
          type: "select",
          options: processStateOptions,
          required: true,
        },
        { name: "progress", type: "number" },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowContext", type: "json" },
        { name: "error", type: "json" },
        { name: "errorName", type: "text" },
        { name: "errorMessage", type: "text" },
        { name: "errorPayload", type: "json" },
        { name: "locked", type: "boolean" },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateDigestionPart(digestionId, digestionPartId)}
      detailsLocation={lqsNavigator.toDigestionPartDetails({
        digestionId,
        digestionPartId,
      })}
    />
  );
}
