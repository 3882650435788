/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProcessState } from "./ProcessState";
import {
  ProcessStateFromJSON,
  ProcessStateFromJSONTyped,
  ProcessStateToJSON,
} from "./ProcessState";
import type { ProcessType } from "./ProcessType";
import {
  ProcessTypeFromJSON,
  ProcessTypeFromJSONTyped,
  ProcessTypeToJSON,
} from "./ProcessType";

export interface Hook {
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  workflowId: string;
  triggerProcess: ProcessType;
  triggerState: ProcessState;
  name: string | null;
  note: string | null;
  context: object | null;
  managed: boolean;
  disabled: boolean;
  uri: string | null;
}

export function HookFromJSON(json: any): Hook {
  return HookFromJSONTyped(json);
}

export function HookFromJSONTyped(json: any): Hook {
  if (json == null) {
    return json;
  }

  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null
        ? json["updated_at"]
        : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] == null
        ? json["deleted_at"]
        : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    workflowId: json["workflow_id"],
    triggerProcess: ProcessTypeFromJSON(json["trigger_process"]),
    triggerState: ProcessStateFromJSON(json["trigger_state"]),
    name: json["name"],
    note: json["note"],
    context: json["context"],
    managed: json["managed"],
    disabled: json["disabled"],
    uri: json["uri"],
  };
}

export function HookToJSON(value: Hook | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    workflow_id: value.workflowId,
    trigger_process: ProcessTypeToJSON(value.triggerProcess),
    trigger_state: ProcessStateToJSON(value.triggerState),
    name: value.name,
    note: value.note,
    context: value.context,
    managed: value.managed,
    disabled: value.disabled,
    uri: value.uri,
  };
}
