import React from "react";
import { Divider } from "@mui/material";
import type { Topic } from "~/lqs";
import { assertNever } from "~/utils/assertNever";
import { usePlayerActions } from "../../actions";
import { TagsControl, TopicSelect } from "../../components";
import { useLoadedPlayerTopics } from "../../hooks";
import type { InitializedPanelNode } from "../../panels";
import { getMapSubVisualization } from "../../panels";
import {
  filterSupplementaryTopics,
  findSelectedSupplementaryTopic,
} from "./utils";

export function MapVisualizationControls({
  panel,
  topic,
}: {
  panel: InitializedPanelNode;
  topic: Topic;
}) {
  const subVisualization = getMapSubVisualization(topic.typeName);
  switch (subVisualization) {
    case "gps": {
      return <GpsVisualizationControls panel={panel} topic={topic} />;
    }
    case "cartesian": {
      return <TagsControl topic={topic} />;
    }
    default: {
      assertNever(subVisualization);
    }
  }
}

function GpsVisualizationControls({
  panel,
  topic,
}: {
  panel: InitializedPanelNode;
  topic: Topic;
}) {
  const playerActions = usePlayerActions();

  const topics = useLoadedPlayerTopics();

  const supplementaryTopics = filterSupplementaryTopics(topic, topics);

  const selectedSupplementaryTopic = findSelectedSupplementaryTopic(
    supplementaryTopics,
    panel,
  );

  function handleSupplementaryTopicChange(newValue: Topic | null): void {
    if (newValue === null) {
      playerActions.removeSupplementaryMapTopic(panel);
    } else {
      playerActions.addSupplementaryMapTopic(panel, newValue);
    }
  }

  return (
    <>
      <TagsControl topic={topic} />
      <Divider />
      <TopicSelect
        inputLabel="Comparison topic"
        value={selectedSupplementaryTopic}
        topics={supplementaryTopics}
        onChange={handleSupplementaryTopicChange}
      />
    </>
  );
}
