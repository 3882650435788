import type { DataStore } from "~/dsm";
import { Configuration as DsmConfiguration } from "~/dsm";
import { getAuthToken } from "~/lib/auth";
import { Configuration as LqsConfiguration } from "~/lqs";
import {
  buildDataStoreEndpoint,
  buildDsmEndpoint,
  buildPjmEndpoint,
} from "~/utils/build-api-endpoints";
import { getAppConfig } from "./config";

export function createDataStoreManagementApiConfiguration(): DsmConfiguration {
  return new DsmConfiguration({
    basePath: buildDsmEndpoint(getAppConfig().apiEndpoint),
    accessToken: getAuthToken,
  });
}

export function createPjmApiConfiguration(dataStoreId: DataStore["id"]): {
  basePath: string;
  accessToken: () => Promise<string>;
} {
  return {
    basePath: buildPjmEndpoint(getAppConfig().apiEndpoint, dataStoreId),
    accessToken: getAuthToken,
  };
}

export function createDataStoreApiConfiguration(
  dataStore: DataStore,
): LqsConfiguration {
  return new LqsConfiguration({
    basePath: buildDataStoreEndpoint(getAppConfig().apiEndpoint, dataStore.id),
    accessToken: getAuthToken,
  });
}
