import React from "react";
import { Error as ErrorIcon } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Center } from "./Center";

export function ErrorMessage({
  icon,
  disableTypography = false,
  children,
}: {
  icon?: React.ReactNode;
  disableTypography?: boolean;
  children: React.ReactNode;
}) {
  let content;
  if (disableTypography) {
    content = children;
  } else {
    content = <ErrorMessage.Paragraph>{children}</ErrorMessage.Paragraph>;
  }

  return (
    <Center spacing={1}>
      {icon == null ? <ErrorIcon fontSize="large" color="error" /> : icon}
      {content}
    </Center>
  );
}

ErrorMessage.Paragraph = function Paragraph({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Typography variant="h5" component="p">
      {children}
    </Typography>
  );
};
