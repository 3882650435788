/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface User {
  id: string;
  createdAt: Date;
  updatedAt: Date | null;
  deletedAt: Date | null;
  createdBy: string | null;
  updatedBy: string | null;
  deletedBy: string | null;
  username: string;
  roleId: string | null;
  admin: boolean;
  disabled: boolean;
  managed: boolean;
  externalId: string | null;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json);
}

export function UserFromJSONTyped(json: any): User {
  if (json == null) {
    return json;
  }

  return {
    id: json["id"],
    createdAt: new Date(json["created_at"]),
    updatedAt:
      json["updated_at"] == null
        ? json["updated_at"]
        : new Date(json["updated_at"]),
    deletedAt:
      json["deleted_at"] == null
        ? json["deleted_at"]
        : new Date(json["deleted_at"]),
    createdBy: json["created_by"],
    updatedBy: json["updated_by"],
    deletedBy: json["deleted_by"],
    username: json["username"],
    roleId: json["role_id"],
    admin: json["admin"],
    disabled: json["disabled"],
    managed: json["managed"],
    externalId: json["external_id"],
  };
}

export function UserToJSON(value: User | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt === null ? null : value.updatedAt.toISOString(),
    deleted_at: value.deletedAt === null ? null : value.deletedAt.toISOString(),
    created_by: value.createdBy,
    updated_by: value.updatedBy,
    deleted_by: value.deletedBy,
    username: value.username,
    role_id: value.roleId,
    admin: value.admin,
    disabled: value.disabled,
    managed: value.managed,
    external_id: value.externalId,
  };
}
