import { invariant } from "~/lib/invariant";

export { default as capitalize } from "lodash/capitalize";

export { default as chunk } from "lodash/chunk";

export { default as clamp } from "lodash/clamp";

export { default as compact } from "lodash/compact";

export { default as filter } from "lodash/filter";

export { default as find } from "lodash/find";

export { default as findIndex } from "lodash/findIndex";

export { default as floor } from "lodash/floor";

export { default as get } from "lodash/get";

export { default as isEmpty } from "lodash/isEmpty";

export { default as isEqual } from "lodash/isEqual";

export { default as isMatch } from "lodash/isMatch";

export { default as isPlainObject } from "lodash/isPlainObject";

export { default as last } from "lodash/last";

export { default as map } from "lodash/map";

export { default as maxBy } from "lodash/maxBy";

export { default as merge } from "lodash/merge";

export { default as minBy } from "lodash/minBy";

export { default as omit } from "lodash/omit";

export { default as pick } from "lodash/pick";

export { pipe } from "remeda";

export { default as pull } from "lodash/pull";

export { default as pullAllWith } from "lodash/pullAllWith";

export { default as pullAt } from "lodash/pullAt";

export { default as range } from "lodash/range";

export { default as reject } from "lodash/reject";

export { default as round } from "lodash/round";

export { default as snakeCase } from "lodash/snakeCase";

export { default as some } from "lodash/some";

export { default as sortBy } from "lodash/sortBy";

export { default as sortedIndexBy } from "lodash/sortedIndexBy";

export { default as sortedLastIndex } from "lodash/sortedLastIndex";

export { default as sortedLastIndexBy } from "lodash/sortedLastIndexBy";

export { default as startCase } from "lodash/startCase";

export { default as xor } from "lodash/xor";

export function identity<T>(value: T): T {
  return value;
}

export function modulo(dividend: number, divisor: number): number {
  return ((dividend % divisor) + divisor) % divisor;
}

interface EmplaceHandlers<TValue> {
  insert?: () => TValue;
  update?: (oldValue: TValue) => TValue;
}

// Based off proposal for `Map.prototype.emplace`: https://github.com/tc39/proposal-upsert
export function emplace<TKey, TValue extends Exclude<unknown, undefined>>(
  map: Map<TKey, TValue>,
  key: TKey,
  handlers: EmplaceHandlers<TValue>,
): TValue {
  let value = map.get(key);

  if (value === undefined) {
    invariant(
      handlers.insert !== undefined,
      "Value not in map and no insert handler was given",
    );

    value = handlers.insert();
  } else if (handlers.update !== undefined) {
    value = handlers.update(value);
  }

  map.set(key, value);

  return value;
}

export interface PromiseWithResolvers<TValue> {
  promise: Promise<TValue>;
  resolve: (value: TValue) => void;
  reject: (reason?: unknown) => void;
}

// Based off proposal for `Promise.withResolvers`: https://github.com/tc39/proposal-promise-with-resolvers
export function promiseWithResolvers<TValue>(): PromiseWithResolvers<TValue> {
  let resolve!: (value: TValue) => void;
  let reject!: (reason?: unknown) => void;
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });

  return {
    promise,
    resolve,
    reject,
  } as PromiseWithResolvers<TValue>;
}
