import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { IngestionPartEditForm } from "./components/IngestionPartEditForm";

export function EditIngestionPartPage() {
  return (
    <LqsPage title="Edit Ingestion Part">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <IngestionPartEditForm />
      </Container>
    </LqsPage>
  );
}
