import React from "react";
import {
  Backdrop,
  CircularProgress,
  Fade,
  Stack,
  Typography,
} from "@mui/material";

export function LoadingFeedback({
  description,
  BackdropComponent,
}: {
  description: string;
  BackdropComponent?: React.ComponentType<{ children: React.ReactNode }>;
}) {
  const children = (
    <Stack sx={{ alignItems: "center" }}>
      <CircularProgress />
      <Typography variant="h5" component="p">
        Fetching {description} for playback
      </Typography>
      <Fade in style={{ transitionDelay: "10s" }}>
        <Typography variant="h5" component="p">
          Taking longer than expected...
        </Typography>
      </Fade>
    </Stack>
  );

  if (BackdropComponent != null) {
    return <BackdropComponent>{children}</BackdropComponent>;
  } else {
    return (
      <Backdrop open sx={{ position: "absolute" }}>
        {children}
      </Backdrop>
    );
  }
}
