import type { FieldValues, Path } from "react-hook-form";
import type { ResourceSelectFieldProps } from "~/components/Form";
import { ResourceSelectField } from "~/components/Form";
import { useCommonResourcesContextValue } from "./context";

export function UserSelect<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(props: ResourceSelectFieldProps<TFieldValues, TName>) {
  const { createUserListQueryOptions, createUserFetchQueryOptions } =
    useCommonResourcesContextValue();

  return (
    <ResourceSelectField
      {...props}
      createListQueryOptions={(text) =>
        createUserListQueryOptions({
          usernameLike: text,
          limit: 5,
          sort: "asc",
          order: "username",
        })
      }
      createFetchQueryOptions={createUserFetchQueryOptions}
      selectOption={(user) => ({
        value: user.id,
        label: user.username,
      })}
    />
  );
}
