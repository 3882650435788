import { Link, TableCell, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  createSearchRequestProvider,
  getActiveFiltersCount,
  preprocessSearchRequest,
  withoutBaseTableModel,
} from "~/components/Table";
import {
  commonResourceColumns,
  selectCountableListResponse,
} from "~/domain/common";
import type { LqsColumn, ObjectStore } from "~/lqs";
import { LqsResourceTable, useObjectStores } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { listObjectStoresSchema } from "../schemas";

function ObjectStoreBucketNameCell({
  objectStore,
}: {
  objectStore: ObjectStore;
}) {
  const lqsNavigator = useLqsNavigator({ toObjectStoreDetails: true });

  return (
    <TableCell>
      {objectStore.bucketName}
      <Link
        component={RouterLink}
        to={lqsNavigator.toObjectStoreDetails({
          objectStoreId: objectStore.id,
        })}
      >
        <Typography variant="body2">{objectStore.id}</Typography>
      </Link>
    </TableCell>
  );
}

const columns: ReadonlyArray<LqsColumn<"objectStore">> = [
  {
    header: "Bucket Name",
    sortKey: "bucketName",
    renderCell: (objectStore) => (
      <ObjectStoreBucketNameCell objectStore={objectStore} />
    ),
  },
  {
    accessor: "keyPrefix",
    dataType: "text",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "accessKeyId",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "regionName",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "endpointUrl",
    dataType: "text",
    isSortable: true,
  },
  {
    accessor: "_default",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "readOnly",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "managed",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "disabled",
    dataType: "boolean",
    isSortable: true,
    defaultHidden: true,
  },
  {
    accessor: "note",
    dataType: "text",
    isSortable: true,
  },
  ...commonResourceColumns,
];

export const [useObjectStoreSearchRequest, ObjectStoreSearchRequestProvider] =
  createSearchRequestProvider({
    columns,
    filterSchema: listObjectStoresSchema,
  });

export function ObjectStoreTable() {
  const [request, setRequest] = useObjectStoreSearchRequest();

  const lqsNavigator = useLqsNavigator({ toObjectStoreCreate: true });

  const searchQuery = useObjectStores(preprocessSearchRequest(request), {
    keepPreviousData: true,
    cacheTime: 0,
    select: selectCountableListResponse,
  });

  const filterValues = withoutBaseTableModel(request);

  return (
    <LqsResourceTable
      resourceName="object store"
      registryKey="objectStore"
      resourceCreateLocation={lqsNavigator.toObjectStoreCreate()}
      getRowKey={(objectStore) => objectStore.id}
      columns={columns}
      searchQuery={searchQuery}
      tableModel={request}
      onTableModelChange={setRequest}
      activeFilterCount={getActiveFiltersCount(filterValues)}
    />
  );
}
