import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { createSafeContext } from "~/contexts";

const [useDocumentTitleSuffixContext, DocumentTitleSuffixContext] =
  createSafeContext<string>("DocumentTitleSuffix");

export function DocumentTitleSuffixProvider({
  suffix,
  children,
}: {
  suffix: string;
  children: React.ReactNode;
}) {
  return (
    <HelmetProvider>
      <DocumentTitleSuffixContext.Provider value={suffix}>
        {children}
      </DocumentTitleSuffixContext.Provider>
    </HelmetProvider>
  );
}

export function DocumentTitle({ children }: { children: string }) {
  const suffix = useDocumentTitleSuffixContext();

  return (
    <Helmet titleTemplate={`%s - ${suffix}`}>
      <title>{children}</title>
    </Helmet>
  );
}
