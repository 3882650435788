import type { FieldNamesMarkedBoolean } from "react-hook-form";
import type * as z from "zod";
import type { ResourceTableModel } from "../Table";
import type { FormFieldDescriptor } from "./types";

export function getChangedFields<
  TFieldValues extends Record<
    string,
    | number
    | bigint
    | string
    | Date
    | boolean
    | null
    | undefined
    // This just includes fields whose values are objects. Nested form fields
    // aren't supported
    | object
  >,
>(
  values: TFieldValues,
  dirtyFields: Partial<FieldNamesMarkedBoolean<TFieldValues>>,
): Partial<TFieldValues> {
  const changedFields: Partial<TFieldValues> = {};

  for (const field in values) {
    if (dirtyFields[field as keyof typeof dirtyFields]) {
      changedFields[field] = values[field];
    }
  }

  return changedFields;
}

export type SchemaShape<TType extends object> = {
  [Key in keyof TType]-?: z.ZodType<TType[Key], z.ZodTypeDef, any>;
};

export type TableSchemaShape<TType extends object> = Omit<
  SchemaShape<TType>,
  keyof ResourceTableModel
>;

export function getAvailableFieldNames<TRequest extends object>(
  availableFields: ReadonlyArray<{
    descriptor: FormFieldDescriptor<TRequest, any>;
  }>,
): ReadonlyArray<keyof TRequest> {
  return availableFields.flatMap(({ descriptor }) => {
    if (descriptor.type === "fuzzy") {
      return [descriptor.name, descriptor.fuzzyName];
    } else {
      return descriptor.name;
    }
  });
}
