import type { Join } from "type-fest";

type CollapseSlashes<TInput extends string> =
  TInput extends `${infer THead}//${infer TTail}`
    ? CollapseSlashes<`${THead}/${TTail}`>
    : TInput;

type StripLastSlash<TInput extends string> = TInput extends `${infer THead}/`
  ? THead
  : TInput;

export type JoinPaths<TPaths extends ReadonlyArray<string>> = StripLastSlash<
  CollapseSlashes<Join<TPaths, "/">>
>;

export function joinPaths<const TPaths extends ReadonlyArray<string>>(
  paths: TPaths,
): JoinPaths<TPaths> {
  const joined = paths.join("/");

  return joined.replace(/\/{2,}/g, "/").replace(/\/$/, "") as any;
}
