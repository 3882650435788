import { OBJECT_KEY_DELIMITER } from "~/components/ObjectExplorer";
import type { Log } from "~/lqs";

/**
 * Keys for objects belonging to a log start with
 * `"lqs/<datastore-id>/logs/<log-id>/"`; however, the back-end will prepend
 * that prefix to any keys it receives which don't already start with it. When
 * using the `<ObjectExplorer />` for a log object, that prefix should be
 * stripped from any keys or common prefixes received from the back-end,
 * if present.
 */
export function stripLogKeyPrefix(logId: Log["id"], key: string): string {
  const searchString = `${logId}${OBJECT_KEY_DELIMITER}`;
  const searchStringIndex = key.indexOf(searchString);

  if (searchStringIndex === -1) {
    return key;
  } else {
    return key.slice(searchStringIndex + searchString.length);
  }
}
