import { useMemo } from "react";
import { get } from "~/lib/std";
import { useCurrentDataStore } from "~/lqs";
import type { RuntimeLayoutProfilesList } from "../panels/api/models";
import { RuntimeProfileArraySchema } from "../panels/api/models";

export function useDataStoreLayoutProfiles(): RuntimeLayoutProfilesList {
  const currentDataStore = useCurrentDataStore({ strict: false });

  return useMemo(() => {
    if (currentDataStore == null) {
      return [];
    }

    // Default to an empty list if the layouts are missing or malformed
    return RuntimeProfileArraySchema.catch([]).parse(
      get(currentDataStore.context, "studio.layout_profiles"),
    );
  }, [currentDataStore]);
}
