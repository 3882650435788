import { useState } from "react";
import { Delete } from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import type { UseQueryResult } from "@tanstack/react-query";
import { Center } from "~/components/Center";
import { DeleteDialog } from "~/components/DeleteDialog";
import { Loading } from "~/components/Loading";
import { QueryRenderer } from "~/components/QueryRenderer";
import { ErrorMessage } from "~/components/error-message";
import { useDeleteApiKey } from "~/lqs";
import type { APIKey, APIKeyListResponse } from "~/lqs";
import CreateDialog from "../components/CreateDialog";

export interface APIKeyViewProps {
  apiKey: APIKey;
  refetch: () => void;
}

function ApiKeyView({ apiKey, refetch }: APIKeyViewProps) {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const deleteApiKey = useDeleteApiKey(apiKey.id);

  function closeModal() {
    setShowDeleteDialog(false);
  }

  function openModal() {
    setShowDeleteDialog(true);
  }

  function handleDelete() {
    refetch();
  }

  return (
    <>
      <ListItem
        secondaryAction={
          <Tooltip title="Delete">
            <IconButton color="error" onClick={openModal}>
              <Delete />
            </IconButton>
          </Tooltip>
        }
      >
        <ListItemText primary={apiKey.name} secondary={apiKey.id} />
      </ListItem>
      <DeleteDialog
        open={showDeleteDialog}
        onClose={closeModal}
        resourceName="API Key"
        mutation={deleteApiKey}
        onDeleted={handleDelete}
      >
        {apiKey.name}
      </DeleteDialog>
    </>
  );
}

export interface APIKeysViewProps {
  apiKeysQuery: UseQueryResult<APIKeyListResponse>;
  userId: string | undefined;
}

export default function APIKeysView({
  apiKeysQuery,
  userId,
}: APIKeysViewProps) {
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  function openDialog() {
    setShowCreateDialog(true);
  }

  function closeDialog() {
    setShowCreateDialog(false);
  }

  return (
    <Card>
      <CardHeader
        title="API Keys"
        titleTypographyProps={{ component: "h2" }}
        action={
          userId !== undefined && (
            <Button variant="contained" onClick={openDialog}>
              Create API Key
            </Button>
          )
        }
      />
      <CardContent>
        <QueryRenderer
          query={apiKeysQuery}
          loading={<Loading type="circular" />}
          error={<ErrorMessage>Error fetching API keys</ErrorMessage>}
          success={(data) =>
            data.count === 0 ? (
              <Center>
                <Typography
                  variant="h6"
                  component="p"
                  sx={{ fontStyle: "italic", my: 4 }}
                >
                  You don't have any API keys
                </Typography>
              </Center>
            ) : (
              <List>
                {data.data.map((apiKey) => (
                  <ApiKeyView
                    key={apiKey.id}
                    apiKey={apiKey}
                    refetch={apiKeysQuery.refetch}
                  />
                ))}
              </List>
            )
          }
        />
        {userId !== undefined && (
          <CreateDialog
            open={showCreateDialog}
            onClose={closeDialog}
            userId={userId}
          />
        )}
      </CardContent>
    </Card>
  );
}
