import { SortDirection } from "~/components/Table";
import type { Tag } from "~/lqs";
import { useTags } from "~/lqs";
import { usePlayerParams } from "../hooks";
import { usePlaybackTagsFilters } from "./store";

export interface PlaybackTag extends Tag {
  startTime: bigint;
  // This is the same type as a typical `Tag` but it expresses the intent that
  // the `endTime` need not be defined.
  endTime: bigint | null;
}

export function usePlaybackTags() {
  const { logId } = usePlayerParams();

  const filters = usePlaybackTagsFilters();

  const enabled = filters !== null;

  return useTags(
    logId,
    {
      ...filters,
      limit: 500,
      sort: SortDirection.Asc,
      order: "start_time",
    },
    {
      enabled,
      select({ data }): ReadonlyArray<PlaybackTag> {
        return data as ReadonlyArray<PlaybackTag>;
      },
    },
  );
}
