import { NewLqsResourceForm, useCreateGroup } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createGroupSchema } from "../schemas";

export function GroupCreateForm() {
  const lqsNavigator = useLqsNavigator({ toGroupDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="group"
      registryKey="group"
      schema={createGroupSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        {
          name: "defaultWorkflowId",
          type: "foreign-key",
          resourceType: "workflow",
        },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateGroup()}
      createDetailsLocation={(response) =>
        lqsNavigator.toGroupDetails({ groupId: response.data.id })
      }
    />
  );
}
