import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { listLogsSchema } from "../schemas";
import { useLogSearchRequest } from "./LogTable";

export function LogFilters() {
  const [request, setRequest] = useLogSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="log"
      request={request}
      setRequest={setRequest}
      schema={listLogsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "groupId", type: "foreign-key", resourceType: "group" },
        {
          name: "tagLabelIdsIncludes",
          type: "foreign-key",
          resourceType: "label",
          label: "Tags",
          multiple: true,
        },
        { name: "locked", type: "boolean" },
        { name: "noteLike", type: "text", label: "Note" },
        { name: "startTimeGte", type: "timestamp" },
        { name: "startTimeLte", type: "timestamp" },
        { name: "startTimeNull", type: "boolean" },
        { name: "endTimeGte", type: "timestamp" },
        { name: "endTimeLte", type: "timestamp" },
        { name: "endTimeNull", type: "boolean" },
        { name: "recordSizeGte", type: "number" },
        { name: "recordSizeLte", type: "number" },
        { name: "recordCountGte", type: "number" },
        { name: "recordCountLte", type: "number" },
        { name: "objectSizeGte", type: "number" },
        { name: "objectSizeLte", type: "number" },
        { name: "objectCountGte", type: "number" },
        { name: "objectCountLte", type: "number" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
