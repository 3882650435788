import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import {
  ObjectStoreObjectFilters,
  ObjectStoreObjectSearchRequestProvider,
  ObjectStoreObjectTable,
} from "./components/ObjectStoreObjectTable";

export function ObjectStoreObjectsPage() {
  return (
    <TablePage
      title="Search Objects"
      RequestProvider={ObjectStoreObjectSearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <ObjectStoreObjectTable />
        </>
      }
      filters={<ObjectStoreObjectFilters />}
    />
  );
}
