import React, { useState } from "react";
import { Stack, styled, Tooltip, Typography } from "@mui/material";
import { renderQuery } from "~/components/QueryRenderer";
import { useLabelColors } from "~/domain/logs";
import { useInsecureHash } from "~/hooks/use-insecure-hash";
import { selectLabelColor, useLabel } from "~/lqs";
import { selectData } from "~/utils/queries";
import { usePlayerActions } from "../actions";
import {
  useFormatPlaybackTimestamp,
  useLoadedPlaybackSource,
} from "../playback";
import type { PlaybackTag } from "./use-playback-tags";

const classNames = {
  markerContainer: "marker-container",
  instantMarker: "instant-marker",
  rangeStartMarker: "range-start-marker",
  rangeEndMarker: "range-end-marker",
  rangeBackground: "range-background",
} as const;

const Root = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "20px",
  translate: "0 calc(-50% - 8px)",
  [`& .${classNames.markerContainer}`]: {
    // Default color if label's value hash query hasn't completed yet
    color: theme.palette.grey["800"],
    // There could be many markers and each marker is composed of several
    // sub-elements. To ensure pointer events, hover states, etc. are firing
    // on the marker the user would expect it to, turn off pointer events for
    // the marker container and re-enable on child elements as needed
    pointerEvents: "none",
    // For any element with pointer events enabled, show a pointer-style cursor
    cursor: "pointer",
    position: "absolute",
    height: "100%",
    transition: theme.transitions.create(["opacity"]),
    // A container is dimmed when *some* marker is being hovered but not
    // this one
    '&[data-dim="true"]': {
      opacity: 0.5,
    },
    "&:hover": {
      // A hovered marker should appear above others
      zIndex: 1,
    },
    [`& :is(.${classNames.instantMarker}, .${classNames.rangeStartMarker}, .${classNames.rangeEndMarker})`]:
      {
        // Markers have their positions set dynamically through styles since
        // they're calculated during render
        position: "absolute",
        height: "100%",
        "& svg": {
          // Use "fill" for pointer events in the <svg> rather than "auto"
          // because range-type markers' <polygon>s only take up half the
          // <svg>s `viewBox` and it's important pointer events aren't being
          // captured by parts of the <svg> with nothing actually painted
          pointerEvents: "fill",
          // Individual marker type classes will position their child <svg>s
          position: "absolute",
          height: "100%",
          display: "block",
          // Distinct color for the source label is set dynamically through
          // styles on the container
          fill: "currentcolor",
          transition: theme.transitions.create(["scale"]),
        },
      },
    [`& .${classNames.instantMarker}`]: {
      "& svg": {
        // An instant-type marker's container will have its `left` property set
        // to where the marker should point, so translate the shape back 50% to
        // line its point up with the timeline
        translate: "-50%",
      },
    },
    [`& :is(.${classNames.rangeStartMarker}, .${classNames.rangeEndMarker})`]: {
      // The range backgrounds will connect the two halves of the marker
      [`& .${classNames.rangeBackground}`]: {
        position: "absolute",
        top: 0,
        width: "100%",
        height: "75%",
        // Distinct color for the source label is set dynamically through
        // styles on the container
        backgroundColor: "currentcolor",
        opacity: 0,
        transition: theme.transitions.create(["opacity"]),
        '&[data-show="true"]': {
          opacity: 1,
          // Re-enable pointer events once a hover interaction has started;
          // otherwise, if the user tries to move their pointer along the
          // background the interaction will stop
          pointerEvents: "auto",
        },
      },
    },
    // Range-start marker is a container for the start-half marker and background
    [`& .${classNames.rangeStartMarker}`]: {
      // Fill left half of marker container
      left: 0,
      right: "50%",
      "& svg": {
        // Place on left edge of marker container and translate back so the
        // point is over top of the correct spot on the timeline
        left: 0,
        translate: "-50%",
      },
    },
    // Range-end marker is a container for the end-half marker and background
    [`& .${classNames.rangeEndMarker}`]: {
      // Fill right half of marker container
      left: "50%",
      right: 0,
      "& svg": {
        // Place on right edge of marker container and translate forward so the
        // point is over top of the correct spot on the timeline
        right: 0,
        translate: "50%",
      },
    },
  },
}));

export function PlaybackTags({ tags }: { tags: ReadonlyArray<PlaybackTag> }) {
  const [hoveredTagId, setHoveredTagId] = useState<PlaybackTag["id"] | null>(
    null,
  );

  const playbackSource = useLoadedPlaybackSource();
  const playerActions = usePlayerActions();

  const duration =
    playbackSource.bounds.endTime - playbackSource.bounds.startTime;

  function handleSeek(to: bigint): void {
    playerActions.seek(to);
  }

  function createHoverHandlers(tag: PlaybackTag) {
    return {
      onHoverStart(): void {
        setHoveredTagId(tag.id);
      },
      onHoverEnd(): void {
        setHoveredTagId(null);
      },
    };
  }

  return (
    <Root>
      {tags.map((tag) => (
        <Marker
          key={tag.id}
          tag={tag}
          playbackStartTime={playbackSource.bounds.startTime}
          playbackDuration={duration}
          onSeek={handleSeek}
          {...createHoverHandlers(tag)}
          dim={hoveredTagId !== null && hoveredTagId !== tag.id}
        />
      ))}
    </Root>
  );
}

function Marker({
  tag,
  playbackStartTime,
  playbackDuration,
  onSeek,
  onHoverStart,
  onHoverEnd,
  dim,
}: {
  tag: PlaybackTag;
  playbackStartTime: bigint;
  playbackDuration: bigint;
  onSeek: (to: bigint) => void;
  onHoverStart: () => void;
  onHoverEnd: () => void;
  dim: boolean;
}) {
  const [hovered, setHovered] = useState<"start" | "end" | null>(null);

  const labelQuery = useLabel(tag.labelId, {
    select: selectData,
  });
  const labelColorQuery = useInsecureHash(labelQuery.data?.value, {
    select: selectLabelColor,
  });

  const labelColors = useLabelColors(labelQuery.data, labelColorQuery.data);

  const formatPlaybackTimestamp = useFormatPlaybackTimestamp();

  const title = renderQuery(labelQuery, {
    loading: "Loading...",
    error: "Unknown",
    success(label) {
      return renderStackedLabel(
        label.value,
        tag.endTime === null ? (
          formatPlaybackTimestamp(tag.startTime)
        ) : (
          <>
            <span
              style={{ ...(hovered === "start" && { fontWeight: "bold" }) }}
            >
              {formatPlaybackTimestamp(tag.startTime)}
            </span>
            {" - "}
            <span style={{ ...(hovered === "end" && { fontWeight: "bold" }) }}>
              {formatPlaybackTimestamp(tag.endTime)}
            </span>
          </>
        ),
      );
    },
  });

  const startOffsetPercentage =
    (Number(tag.startTime - playbackStartTime) / Number(playbackDuration)) *
    100;

  const endOffsetPercentage =
    tag.endTime !== null
      ? 100 -
        (Number(tag.endTime - playbackStartTime) / Number(playbackDuration)) *
          100
      : null;

  function createPointerHandlers(type: "start" | "end") {
    return {
      onPointerEnter(): void {
        setHovered(type);
        onHoverStart();
      },
      onPointerLeave(): void {
        setHovered(null);
        onHoverEnd();
      },
    };
  }

  function createClickHandler(to: bigint) {
    return function handleClick(): void {
      onSeek(to);
    };
  }

  return (
    <div
      className={classNames.markerContainer}
      style={{
        left: `${startOffsetPercentage}%`,
        ...(endOffsetPercentage !== null && {
          right: `${endOffsetPercentage}%`,
        }),
        color: labelColors?.background,
      }}
      data-dim={dim}
    >
      {endOffsetPercentage === null ? (
        <div
          className={classNames.instantMarker}
          onClick={createClickHandler(tag.startTime)}
          {...createPointerHandlers("start")}
        >
          <Tooltip
            title={title}
            placement="top"
            arrow
            open={hovered === "start"}
          >
            <svg
              viewBox="0 0 1 2"
              style={{
                scale:
                  hovered === "start" ? "1.2" : hovered !== null ? "1.1" : "1",
              }}
            >
              <polygon points="0,0 1,0 1,1.5 0.5,2 0,1.5" />
            </svg>
          </Tooltip>
        </div>
      ) : (
        <div
          className={classNames.rangeStartMarker}
          onClick={createClickHandler(tag.startTime)}
          {...createPointerHandlers("start")}
        >
          <Tooltip
            title={title}
            placement="top"
            arrow
            open={hovered === "start"}
          >
            <svg
              viewBox="0 0 1 2"
              style={{
                scale:
                  hovered === "start" ? "1.2" : hovered !== null ? "1.1" : "1",
              }}
            >
              <polygon points="0,0 0.5,0 0.5,2 0,1.5" />
            </svg>
          </Tooltip>
          <div
            className={classNames.rangeBackground}
            data-show={hovered !== null}
          />
        </div>
      )}
      {endOffsetPercentage !== null && (
        <div
          className={classNames.rangeEndMarker}
          onClick={createClickHandler(tag.endTime!)}
          {...createPointerHandlers("end")}
        >
          <div
            className={classNames.rangeBackground}
            data-show={hovered !== null}
          />
          <Tooltip title={title} placement="top" arrow open={hovered === "end"}>
            <svg
              viewBox="0 0 1 2"
              style={{
                scale:
                  hovered === "end" ? "1.2" : hovered !== null ? "1.1" : "1",
              }}
            >
              <polygon points="0.5,0 1,0 1,1.5 0.5,2" />
            </svg>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

// TODO: Don't copy this from <LogTagChip /> component's file
function renderStackedLabel(first: React.ReactNode, second: React.ReactNode) {
  return (
    <Stack component="span" sx={{ alignItems: "center", padding: 0.25 }}>
      <Typography component="span" sx={{ fontSize: "1.1em" }}>
        {first}
      </Typography>
      <Typography component="span" sx={{ fontSize: "1em" }}>
        {second}
      </Typography>
    </Stack>
  );
}
