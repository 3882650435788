import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { LabelEditForm } from "./components/LabelEditForm";

export function EditLabelPage() {
  return (
    <LqsPage title="Edit Label">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LabelEditForm />
      </Container>
    </LqsPage>
  );
}
