import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { LabelDetails } from "./components/LabelDetails";

export function LabelPage() {
  return (
    <LqsPage title="Label Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LabelDetails />
      </Container>
    </LqsPage>
  );
}
