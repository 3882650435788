import type React from "react";
import { createSafeContext } from "~/contexts";
import type { PlaybackSpeedValue, TimestepValue } from "./types";
import { Timestep, PlaybackSpeed } from "./types";

interface PlayerConfig {
  /**
   * How elements should be sized and positioned. When `true`, the Player
   * will be adapted for a more compact presentation.
   */
  dense: boolean;
  defaultPlaybackSpeed: PlaybackSpeedValue;
  defaultPlaybackTimestep: TimestepValue;
  autoSkip: boolean;
  threeDInteractivityIndicator?: React.JSX.Element;
}

export type PlayerConfigProps = Partial<PlayerConfig>;

const [usePlayerConfig, PlayerConfigContext] =
  createSafeContext<PlayerConfig>("PlayerConfig");

export { usePlayerConfig };

export function PlayerConfigProvider({
  children,
  ...config
}: {
  children: React.ReactNode;
} & PlayerConfigProps) {
  const {
    dense = false,
    defaultPlaybackSpeed = PlaybackSpeed.TimesTen,
    defaultPlaybackTimestep = Timestep.Second,
    autoSkip = true,
    threeDInteractivityIndicator,
  } = config;

  const value: PlayerConfig = {
    dense,
    defaultPlaybackSpeed,
    defaultPlaybackTimestep,
    autoSkip,
    threeDInteractivityIndicator,
  };

  return (
    <PlayerConfigContext.Provider value={value}>
      {children}
    </PlayerConfigContext.Provider>
  );
}
