import type { UseQueryResult } from "@tanstack/react-query";
import { Card } from "~/components/Card";
import { DetailsLayout } from "~/components/DetailsCards";
import type { Hook } from "~/lqs";
import {
  EditLqsResourceForm,
  LqsHistoryCard,
  LqsManageCard,
  LqsResourceFields,
  useDeleteWorkflowHook,
  useUpdateWorkflowHook,
  useWorkflowHook,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editWorkflowHookSchema } from "../schemas";

export function WorkflowHookDetails() {
  const { workflowId, hookId } = useTypedParams(
    lqsRoutePaths.WORKFLOW_HOOK_DETAILS,
  );

  const lqsNavigator = useLqsNavigator({
    toWorkflowHookEdit: true,
    toWorkflowHookTable: true,
  });

  const query = useWorkflowHook(workflowId, hookId, { select: selectData });

  const generalSection = <GeneralSection query={query} />;
  const infoSection = <InfoSection query={query} />;
  const historySection = <LqsHistoryCard query={query} />;
  const manageSection = (
    <LqsManageCard
      resourceName="hook"
      query={query}
      editLocation={lqsNavigator.toWorkflowHookEdit({ workflowId, hookId })}
      deleteMutation={useDeleteWorkflowHook(workflowId, hookId)}
      getReadableName={(workflowHook) => workflowHook.name ?? workflowHook.id}
      listLocation={lqsNavigator.toWorkflowHookTable({ workflowId })}
    />
  );

  return (
    <DetailsLayout
      primaryGridColumn={generalSection}
      secondaryGridColumn={
        <>
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
      stack={
        <>
          {generalSection}
          {infoSection}
          {historySection}
          {manageSection}
        </>
      }
    />
  );
}

function GeneralSection({ query }: { query: UseQueryResult<Hook> }) {
  return (
    <Card title="General">
      <LqsResourceFields
        registryKey="hook"
        query={query}
        fields={[
          { dataType: "id", accessor: "id" },
          {
            dataType: "foreign-key",
            resourceType: "workflow",
            accessor: "workflowId",
          },
          { dataType: "text", accessor: "name" },
          { dataType: "text", accessor: "triggerProcess" },
          { dataType: "text", accessor: "triggerState" },
          { dataType: "text", accessor: "uri" },
          { dataType: "boolean", accessor: "managed" },
          { dataType: "json", accessor: "context" },
        ]}
      />
    </Card>
  );
}

function InfoSection({ query }: { query: UseQueryResult<Hook> }) {
  const { workflowId, hookId } = useTypedParams(
    lqsRoutePaths.WORKFLOW_HOOK_DETAILS,
  );

  return (
    <EditLqsResourceForm
      resourceName="hook"
      registryKey="hook"
      query={query}
      schema={editWorkflowHookSchema}
      descriptors={[
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useUpdateWorkflowHook(workflowId, hookId)}
    />
  );
}
