import React, { useState } from "react";
import { createSafeContext } from "~/contexts";
import { PlaybackTimer } from "./playbackTimer";

export const [usePlaybackTimer, PlaybackTimerContext] =
  createSafeContext<PlaybackTimer>("PlaybackTimer");

export interface PlaybackTimerProviderProps {
  children: React.ReactNode;
}

export function PlaybackTimerProvider({
  children,
}: PlaybackTimerProviderProps) {
  const [playbackTimer] = useState(() => new PlaybackTimer());

  return (
    <PlaybackTimerContext.Provider value={playbackTimer}>
      {children}
    </PlaybackTimerContext.Provider>
  );
}
