import { EditLqsResourceForm, useLog, useUpdateLog } from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editLogSchema } from "../schemas";

export function LogEditForm() {
  const { logId } = useTypedParams(lqsRoutePaths.LOG_EDIT);

  const lqsNavigator = useLqsNavigator({ toLogDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="log"
      registryKey="log"
      query={useLog(logId, { select: selectData })}
      schema={editLogSchema}
      descriptors={[
        { name: "name", type: "text", required: true },
        {
          name: "groupId",
          type: "foreign-key",
          resourceType: "group",
          required: true,
        },
        {
          name: "defaultWorkflowId",
          type: "foreign-key",
          resourceType: "workflow",
        },
        { name: "timeAdjustment", type: "timestamp" },
        { name: "locked", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateLog(logId)}
      detailsLocation={lqsNavigator.toLogDetails({ logId })}
    />
  );
}
