/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { APIKey } from "./APIKey";
import { APIKeyFromJSON, APIKeyFromJSONTyped, APIKeyToJSON } from "./APIKey";

export interface APIKeyDataResponse {
  data: APIKey;
}

export function APIKeyDataResponseFromJSON(json: any): APIKeyDataResponse {
  return APIKeyDataResponseFromJSONTyped(json);
}

export function APIKeyDataResponseFromJSONTyped(json: any): APIKeyDataResponse {
  if (json == null) {
    return json;
  }

  return {
    data: APIKeyFromJSON(json["data"]),
  };
}

export function APIKeyDataResponseToJSON(
  value: APIKeyDataResponse | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    data: APIKeyToJSON(value.data),
  };
}
