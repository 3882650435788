import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { RoleCreateForm } from "./components/RoleCreateForm";

export function NewRolePage() {
  return (
    <LqsPage title="New Role">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <RoleCreateForm />
      </Container>
    </LqsPage>
  );
}
