import type { CSSProperties } from "react";
import type { StrictOmit } from "ts-essentials";
import { calculateRotationQuadrant, FlipDirection } from "../../panels";

export function isRotatedSideways(rotationDeg: number): boolean {
  return [1, 3].includes(calculateRotationQuadrant(rotationDeg));
}

const flipDirectionToCssScale = new Map([
  [null, "1, 1"],
  [FlipDirection.X, "-1, 1"],
  [FlipDirection.Y, "1, -1"],
]);

export function calculateTransforms(
  rotationDeg: number,
  flipDirection: FlipDirection | null,
): string {
  return [
    "translate(-50%, -50%)",
    `rotate(${rotationDeg}deg)`,
    `scale(${flipDirectionToCssScale.get(flipDirection)})`,
  ].join(" ");
}

export function getTransformProps({
  rotationDeg,
  flipDirection,
  filter,
  style,
}: {
  rotationDeg: number;
  flipDirection: FlipDirection | null;
  filter?: false | string;
  style?: StrictOmit<CSSProperties, "transform" | "filter">;
}) {
  const sideways = isRotatedSideways(rotationDeg);

  return {
    "data-content": "",
    "data-sideways": sideways,
    style: {
      ...style,
      transform: calculateTransforms(rotationDeg, flipDirection),
      filter: filter || undefined,
    } satisfies CSSProperties,
  };
}
