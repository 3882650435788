import { useId, useLayoutEffect, useState } from "react";
import type { Maybe } from "~/types";
import { usePlayerActions } from "../../actions";
import type { PointerContainerProps } from "../../hooks";
import { usePointerLocation } from "../../hooks";
import { supportsClipInset, usePanelOnDemandInference } from "../../inference";
import type { InitializedPanelNode } from "../../panels";
import type { ImageFrame } from "./types";

export function useImageSliderController({
  panel,
  imageFrame,
}: {
  panel: InitializedPanelNode;
  imageFrame: Maybe<ImageFrame>;
}): {
  enabled: boolean;
  baseImageId: string;
  pointerContainerProps: PointerContainerProps;
} {
  const onDemandInference = usePanelOnDemandInference(panel);

  const inferenceType =
    onDemandInference?.status === "fulfilled"
      ? onDemandInference.value.task
      : imageFrame?.inferenceFrames?.type;

  const enabled = inferenceType != null && supportsClipInset(inferenceType);

  const baseImageId = useId();

  const [baseImageDimensions, setBaseImageDimensions] = useState<{
    naturalWidth: number;
    naturalHeight: number;
  } | null>(null);

  useLayoutEffect(
    function storeImageDimensions() {
      if (baseImageDimensions !== null) {
        // Only going to measure once and assume all other images from this
        // topic will have at least the same aspect ratio
        return;
      }

      if (!enabled) {
        // No need to queue an extra re-render if we're not going to use the
        // information yet
        return;
      }

      const inferenceImageElement = document.getElementById(baseImageId);
      if (inferenceImageElement === null) {
        // The <img> element should be in the DOM but just bail out in
        // case it isn't
        return;
      }

      const { naturalWidth, naturalHeight } =
        inferenceImageElement as HTMLImageElement;

      setBaseImageDimensions({ naturalWidth, naturalHeight });
    },
    [baseImageDimensions, enabled, baseImageId],
  );

  const playerActions = usePlayerActions();

  const pointerContainerProps = usePointerLocation({
    enabled,
    onChange(pointerLocation) {
      if (baseImageDimensions === null) {
        // This shouldn't happen because, if this callback is enabled, then the
        // layout effect above should've already run and caused the dimensions
        // to be stored
        return;
      }

      playerActions.clipInferenceImage(
        panel,
        pointerLocation,
        baseImageDimensions,
      );
    },
  });

  return {
    enabled,
    baseImageId,
    pointerContainerProps,
  };
}
