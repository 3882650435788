import React from "react";
import { Delete, Edit } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import type { Path } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Card } from "../Card";
import { DeleteDialog, useDeleteResourceDialog } from "../DeleteDialog";

export interface ManageCardProps<TResource> {
  resourceName: string;
  query: UseQueryResult<TResource>;
  editLocation?: Partial<Path>;
  deleteMutation: UseMutationResult<void, unknown, void>;
  getReadableName: (resource: TResource) => string;
  listLocation: Partial<Path>;
}

export function ManageCard<TResource>({
  resourceName,
  query,
  editLocation,
  deleteMutation,
  getReadableName,
  listLocation,
}: ManageCardProps<TResource>) {
  const deleteDialog = useDeleteResourceDialog({ query, listLocation });

  return (
    <Card title="Manage">
      <Stack spacing={2}>
        {editLocation !== undefined && (
          <Button
            component={RouterLink}
            to={editLocation}
            variant="outlined"
            color="secondary"
            fullWidth
            disabled={!query.isSuccess}
            startIcon={<Edit />}
          >
            Edit
          </Button>
        )}
        <Button
          variant="outlined"
          fullWidth
          disabled={!query.isSuccess}
          onClick={deleteDialog.handleOpen}
          startIcon={<Delete />}
        >
          Delete...
        </Button>
      </Stack>
      <DeleteDialog
        open={deleteDialog.open}
        onClose={deleteDialog.handleClose}
        resourceName={resourceName}
        mutation={deleteMutation}
        onDeleted={deleteDialog.handleDeleted}
      >
        {query.isSuccess ? getReadableName(query.data) : null}
      </DeleteDialog>
    </Card>
  );
}
