import { useSettings } from "~/settings";
import type { Maybe } from "~/types";
import { formatDateOrTimestamp } from "./utils";

export function Timestamp({ value }: { value: Maybe<bigint> }) {
  const { dateDisplay } = useSettings();

  return value == null ? (
    "-"
  ) : (
    <span title={String(value)}>
      {formatDateOrTimestamp(value, dateDisplay)}
    </span>
  );
}
