import type { Record } from "~/lqs";
import { assertNever } from "~/utils";
import { InferenceError } from "../errors";
import type { DetectionInferenceResults } from "./schemas";
import { detectionResultsSchema } from "./schemas";

// Not sure if I'm set on this file name but the idea is these utilities are
// used by the record store's "detection" record type handler. It might make
// sense to just colocate that handler here

export type DetectionResultsDataField = keyof Pick<
  Record,
  "queryData" | "auxiliaryData" | "rawData"
>;

export function determineDetectionResultsDataField(
  record: Record,
): DetectionResultsDataField {
  if (detectionResultsSchema.safeParse(record.queryData).success) {
    return "queryData";
  }

  if (detectionResultsSchema.safeParse(record.auxiliaryData).success) {
    return "auxiliaryData";
  }

  let parsedRawData: unknown;
  try {
    // If `rawData === null` then it'll be coerced to the string "null" by
    // `JSON.parse` which will fail to be parsed by the zod schema below
    parsedRawData = JSON.parse(record.rawData as any);
  } catch (e) {
    throw new InferenceError({ cause: e });
  }

  if (detectionResultsSchema.safeParse(parsedRawData).success) {
    return "rawData";
  }

  throw new InferenceError();
}

export function getDetectionResultsRequest(
  dataField: DetectionResultsDataField,
) {
  switch (dataField) {
    case "queryData": {
      return undefined;
    }
    case "auxiliaryData": {
      return { includeAuxiliaryData: true };
    }
    case "rawData": {
      return { includeRawData: true };
    }
    default: {
      assertNever(dataField);
    }
  }
}

export function getRecordDetectionResults(
  record: Record,
  dataField: DetectionResultsDataField,
): DetectionInferenceResults {
  try {
    switch (dataField) {
      case "queryData": {
        return detectionResultsSchema.parse(record.queryData);
      }
      case "auxiliaryData": {
        return detectionResultsSchema.parse(record.auxiliaryData);
      }
      case "rawData": {
        return detectionResultsSchema.parse(JSON.parse(record.rawData as any));
      }
      default: {
        assertNever(dataField);
      }
    }
  } catch (e) {
    const wrapped = new InferenceError({ cause: e });

    console.error(wrapped);

    throw wrapped;
  }
}
