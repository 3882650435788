import type React from "react";
import { Typography } from "@mui/material";

export function Text({
  inherit = false,
  inline = false,
  bold = false,
  italic = false,
  pre = false,
  align = "left",
  children,
}: {
  inherit?: boolean;
  inline?: boolean;
  bold?: boolean;
  italic?: boolean;
  pre?: boolean;
  align?: "left" | "center" | "right";
  children: React.ReactNode;
}) {
  return (
    <Typography
      variant={inherit ? "inherit" : "body1"}
      component={inline ? "span" : "p"}
      gutterBottom={!inline}
      sx={{
        fontWeight: bold ? "bold" : undefined,
        fontStyle: italic ? "italic" : undefined,
        whiteSpace: pre ? "pre-wrap" : undefined,
        textAlign: align,
      }}
    >
      {children}
    </Typography>
  );
}
