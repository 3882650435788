/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { UploadState } from "./UploadState";
import {
  UploadStateFromJSON,
  UploadStateFromJSONTyped,
  UploadStateToJSON,
} from "./UploadState";

export interface ObjectUpdateRequest {
  uploadState: UploadState;
}

export function ObjectUpdateRequestFromJSON(json: any): ObjectUpdateRequest {
  return ObjectUpdateRequestFromJSONTyped(json);
}

export function ObjectUpdateRequestFromJSONTyped(
  json: any,
): ObjectUpdateRequest {
  if (json == null) {
    return json;
  }

  return {
    uploadState: UploadStateFromJSON(json["upload_state"]),
  };
}

export function ObjectUpdateRequestToJSON(
  value: ObjectUpdateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    upload_state: UploadStateToJSON(value.uploadState),
  };
}
