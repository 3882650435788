import type React from "react";
import type { StrictOmit } from "ts-essentials";
import { createSafeContext } from "~/contexts";
import type { ApiVersion, VersionGuardProps } from "~/domain/versioning";
import { VersionGuard, v } from "~/domain/versioning";

const [useDsmVersion, DsmVersionContext] =
  createSafeContext<ApiVersion>("DsmVersion");

export { useDsmVersion };

const DEFAULT_DSM_VERSION = v(1, 0, 0);

export function DsmVersionProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <DsmVersionContext.Provider value={DEFAULT_DSM_VERSION}>
      {children}
    </DsmVersionContext.Provider>
  );
}

export function DsmVersionGuard(
  props: StrictOmit<VersionGuardProps, "apiVersion">,
) {
  const dsmVersion = useDsmVersion();

  return <VersionGuard {...props} apiVersion={dsmVersion} />;
}
