import React from "react";
import { People } from "@mui/icons-material";
import { Database } from "mdi-material-ui";
import { Link as RouterLink } from "react-router-dom";
import {
  DocsLink,
  GlobalNavigation,
  NavigationLink,
  SignOutButton,
  useIsActivePath,
  useShouldUseMiniDrawerStyles,
} from "~/components/GlobalNavigation";
import { LqsLogo } from "~/components/lqs-logo";
import { AdminGuard } from "~/domain/common";
import { makeStudioHomepageLocation, STUDIO_HOMEPAGE } from "~/paths/paths";
import * as dsmPaths from "./paths";
import { SupportTicketButton } from "./support-ticket";

export function DsmNavigation() {
  const isActivePath = useIsActivePath();
  const shouldUseMiniDrawerStyles = useShouldUseMiniDrawerStyles();

  return (
    <GlobalNavigation
      homepageLink={
        isActivePath(STUDIO_HOMEPAGE, true) ? (
          <LqsLogo alt="LogQS logo" icon={shouldUseMiniDrawerStyles} />
        ) : (
          <RouterLink
            className="homepage-link"
            to={makeStudioHomepageLocation()}
          >
            <LqsLogo alt="Homepage" icon={shouldUseMiniDrawerStyles} />
          </RouterLink>
        )
      }
      footerActions={
        <>
          <SupportTicketButton />
          <DocsLink small />
          <SignOutButton />
        </>
      }
    >
      <AdminGuard>
        <NavigationLink
          to={dsmPaths.createDsmPath(dsmPaths.DATASTORE_TABLE)}
          Icon={Database}
          text="DataStores"
          path={dsmPaths.DATASTORE_TABLE}
        />
        <NavigationLink
          to={dsmPaths.createDsmPath(dsmPaths.USER_TABLE)}
          Icon={People}
          text="Users"
          path={dsmPaths.USER_TABLE}
        />
      </AdminGuard>
    </GlobalNavigation>
  );
}
