import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { listGroupsSchema } from "../schemas";
import { useGroupSearchRequest } from "./GroupTable";

export function GroupFilters() {
  const [request, setRequest] = useGroupSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="group"
      request={request}
      setRequest={setRequest}
      schema={listGroupsSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
