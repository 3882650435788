/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { TicketType } from "./TicketType";
import {
  TicketTypeFromJSON,
  TicketTypeFromJSONTyped,
  TicketTypeToJSON,
} from "./TicketType";

export interface Ticket {
  subject: string;
  type: TicketType;
  description: string;
  email: string;
  priority: number;
  status: number;
}

export function TicketFromJSON(json: any): Ticket {
  return TicketFromJSONTyped(json);
}

export function TicketFromJSONTyped(json: any): Ticket {
  if (json == null) {
    return json;
  }

  return {
    subject: json["subject"],
    type: TicketTypeFromJSON(json["type"]),
    description: json["description"],
    email: json["email"],
    priority: json["priority"],
    status: json["status"],
  };
}

export function TicketToJSON(value: Ticket | null | undefined): any {
  if (value == null) {
    return value;
  }

  return {
    subject: value.subject,
    type: TicketTypeToJSON(value.type),
    description: value.description,
    email: value.email,
    priority: value.priority,
    status: value.status,
  };
}
