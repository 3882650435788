import { FiltersSidebar } from "~/components/Table";
import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { renderDsmForeignKeyFormField } from "../../../forms";
import { useDsmVersion } from "../../../versioning";
import {
  listDataStoresSchema,
  useDataStoreSearchRequest,
} from "./DataStoreTable";

export function DataStoreFilters() {
  const [request, setRequest] = useDataStoreSearchRequest();

  const dsmVersion = useDsmVersion();

  return (
    <FiltersSidebar
      request={request}
      setRequest={setRequest}
      schema={listDataStoresSchema}
      descriptors={[
        { name: "id", type: "text" },
        { name: "name", type: "fuzzy", fuzzyName: "nameLike" },
        { name: "noteLike", type: "text", label: "Note" },
        { name: "ownerId", type: "foreign-key", resourceType: "user" },
        ...commonResourceFormFieldDescriptors,
      ]}
      apiVersion={dsmVersion}
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderDsmForeignKeyFormField}
    />
  );
}
