import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { RecordDetails } from "./components/RecordDetails";

export function RecordPage() {
  return (
    <LqsPage title="Record Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <RecordDetails />
      </Container>
    </LqsPage>
  );
}
