import {
  EditLqsResourceForm,
  processStateOptions,
  processTypeOptions,
  useUpdateWorkflowHook,
  useWorkflowHook,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { selectData } from "~/utils";
import { editWorkflowHookSchema } from "../schemas";

export function WorkflowHookEditForm() {
  const { workflowId, hookId } = useTypedParams(
    lqsRoutePaths.WORKFLOW_HOOK_EDIT,
  );

  const lqsNavigator = useLqsNavigator({ toWorkflowHookDetails: true });

  return (
    <EditLqsResourceForm
      resourceName="hook"
      registryKey="hook"
      query={useWorkflowHook(workflowId, hookId, { select: selectData })}
      schema={editWorkflowHookSchema}
      descriptors={[
        { name: "name", type: "text" },
        {
          name: "triggerProcess",
          type: "select",
          options: processTypeOptions,
          required: true,
        },
        {
          name: "triggerState",
          type: "select",
          options: processStateOptions,
          required: true,
        },
        { name: "uri", type: "text" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useUpdateWorkflowHook(workflowId, hookId)}
      detailsLocation={lqsNavigator.toWorkflowHookDetails({
        workflowId,
        hookId,
      })}
    />
  );
}
