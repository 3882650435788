import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  DataStoreAssociation,
  DataStoreAssociationCreateRequest,
  DataStoreAssociationDataResponse,
  DataStoreAssociationListResponse,
  ListDatastoreAssociationsRequest,
} from "~/dsm";
import { invariant } from "~/lib/invariant";
import type { Maybe } from "../../types";
import { useDsmContext } from "../provider";
import { useDsmMutation, useDsmQuery, useDsmQueryClient } from "./utils";

const dataStoreAssociationKeys = {
  all: ["datastore-associations"] as const,
  lists: () => [...dataStoreAssociationKeys.all, "list"] as const,
  list: (request: ListDatastoreAssociationsRequest) =>
    [...dataStoreAssociationKeys.lists(), request] as const,
  fetches: () => [...dataStoreAssociationKeys.all, "fetch"] as const,
  fetch: (dataStoreAssociationId: DataStoreAssociation["id"]) =>
    [...dataStoreAssociationKeys.fetches(), dataStoreAssociationId] as const,
};

export function useDataStoreAssociations(
  request: ListDatastoreAssociationsRequest,
  options?: Pick<
    UseQueryOptions<DataStoreAssociationListResponse>,
    "keepPreviousData" | "cacheTime"
  >,
): UseQueryResult<DataStoreAssociationListResponse> {
  const { dataStoreAssociationApi } = useDsmContext();

  return useDsmQuery({
    queryKey: dataStoreAssociationKeys.list(request),
    queryFn({ signal }) {
      return dataStoreAssociationApi.listDatastoreAssociations(request, {
        signal,
      });
    },
    ...options,
  });
}

export function useCreateDataStoreAssociation(): UseMutationResult<
  DataStoreAssociationDataResponse,
  unknown,
  DataStoreAssociationCreateRequest
> {
  const queryClient = useDsmQueryClient();

  const { dataStoreAssociationApi } = useDsmContext();

  return useDsmMutation({
    mutationFn(request) {
      return dataStoreAssociationApi.createDatastoreAssociation({
        dataStoreAssociationCreateRequest: request,
      });
    },
    onSuccess(response) {
      queryClient.setQueryData<DataStoreAssociationDataResponse>(
        dataStoreAssociationKeys.fetch(response.data.id),
        response,
      );

      return queryClient.invalidateQueries({
        queryKey: dataStoreAssociationKeys.lists(),
      });
    },
  });
}

export function useDeleteDataStoreAssociation(
  dataStoreAssociationId: Maybe<DataStoreAssociation["id"]>,
): UseMutationResult<void, unknown, void> {
  const queryClient = useDsmQueryClient();

  const { dataStoreAssociationApi } = useDsmContext();

  return useDsmMutation({
    mutationFn() {
      invariant(
        dataStoreAssociationId != null,
        "DataStore association ID not provided",
      );

      return dataStoreAssociationApi.deleteDatastoreAssociation({
        datastoreAssociationId: dataStoreAssociationId,
      });
    },
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: dataStoreAssociationKeys.lists(),
      });
    },
  });
}
