import { commonResourceFormFieldDescriptors } from "~/domain/common";
import { LqsFiltersSidebar } from "~/lqs";
import { listRecordsSchema } from "../schemas";
import { useRecordSearchRequest } from "./RecordTable";

export function RecordFilters() {
  const [request, setRequest] = useRecordSearchRequest();

  return (
    <LqsFiltersSidebar
      registryKey="record"
      request={request}
      setRequest={setRequest}
      schema={listRecordsSchema}
      descriptors={[
        { name: "timestamp", type: "timestamp" },
        { name: "timestampGte", type: "timestamp" },
        { name: "timestampLte", type: "timestamp" },
        { name: "ingestionId", type: "foreign-key", resourceType: "ingestion" },
        { name: "workflowId", type: "foreign-key", resourceType: "workflow" },
        { name: "workflowIdNull", type: "boolean" },
        { name: "source", type: "text" },
        { name: "altered", type: "boolean" },
        { name: "dataLengthGte", type: "number" },
        { name: "dataLengthLte", type: "number" },
        { name: "dataOffsetGte", type: "number" },
        { name: "dataOffsetLte", type: "number" },
        ...commonResourceFormFieldDescriptors,
      ]}
    />
  );
}
