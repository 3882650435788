import type React from "react";
import type { MarkRequired } from "ts-essentials";
import { createSafeContext } from "~/contexts";
import { invariant } from "~/lib/invariant";
import type { LqsNavigator } from "./paths";

const [useLqsNavigatorContext, LqsNavigatorContext] =
  createSafeContext<LqsNavigator>("LqsNavigator");

function useLqsNavigator<
  TRequired extends Partial<Record<keyof LqsNavigator, true>> = {},
>(
  required?: TRequired,
): MarkRequired<LqsNavigator, keyof TRequired & keyof LqsNavigator> {
  const lqsNavigator = useLqsNavigatorContext();

  for (const name of Object.keys(required ?? {})) {
    invariant(
      lqsNavigator[name as keyof LqsNavigator] != null,
      `Expected "${name}" to be present on LQS navigator`,
    );
  }

  return lqsNavigator as any;
}

export { useLqsNavigator };

export function LqsNavigatorProvider({
  navigator,
  children,
}: {
  navigator: LqsNavigator;
  children: React.ReactNode;
}) {
  return (
    <LqsNavigatorContext.Provider value={navigator}>
      {children}
    </LqsNavigatorContext.Provider>
  );
}
