import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { DigestionTopicDetails } from "./components/DigestionTopicDetails";

export function DigestionTopicPage() {
  return (
    <LqsPage title="Digestion Topic Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <DigestionTopicDetails />
      </Container>
    </LqsPage>
  );
}
