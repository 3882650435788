/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import type {
  DataStoreAssociationCreateRequest,
  DataStoreAssociationDataResponse,
  DataStoreAssociationListResponse,
  DataStoreAssociationUpdateRequest,
  HTTPValidationError,
} from "../models";
import {
  DataStoreAssociationCreateRequestFromJSON,
  DataStoreAssociationCreateRequestToJSON,
  DataStoreAssociationDataResponseFromJSON,
  DataStoreAssociationDataResponseToJSON,
  DataStoreAssociationListResponseFromJSON,
  DataStoreAssociationListResponseToJSON,
  DataStoreAssociationUpdateRequestFromJSON,
  DataStoreAssociationUpdateRequestToJSON,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from "../models";

export interface CreateDatastoreAssociationRequest {
  dataStoreAssociationCreateRequest: DataStoreAssociationCreateRequest;
}

export interface DeleteDatastoreAssociationRequest {
  datastoreAssociationId: string;
}

export interface FetchDatastoreAssociationRequest {
  datastoreAssociationId: string;
}

export interface ListDatastoreAssociationsRequest {
  id?: string | null;
  includeCount?: boolean | null;
  offset?: number | null;
  limit?: number | null;
  order?: string | null;
  sort?: string | null;
  userId?: string | null;
  datastoreId?: string | null;
  datastoreUserId?: string | null;
  manager?: boolean | null;
  disabled?: boolean | null;
  createdBy?: string | null;
  updatedBy?: string | null;
  deletedBy?: string | null;
  updatedByNull?: boolean | null;
  deletedByNull?: boolean | null;
  updatedAtNull?: boolean | null;
  deletedAtNull?: boolean | null;
  createdAtLte?: Date | null;
  updatedAtLte?: Date | null;
  deletedAtLte?: Date | null;
  createdAtGte?: Date | null;
  updatedAtGte?: Date | null;
  deletedAtGte?: Date | null;
}

export interface UpdateDatastoreAssociationRequest {
  datastoreAssociationId: string;
  dataStoreAssociationUpdateRequest: DataStoreAssociationUpdateRequest;
}

export class DataStoreAssociationApi extends runtime.BaseAPI {
  async createDatastoreAssociation(
    this: DataStoreAssociationApi,
    requestParameters: CreateDatastoreAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DataStoreAssociationDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.dataStoreAssociationCreateRequest,
      "dataStoreAssociationCreateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/dataStoreAssociations`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: DataStoreAssociationCreateRequestToJSON(
          requestParameters.dataStoreAssociationCreateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DataStoreAssociationDataResponseFromJSON(jsonValue);
  }

  async deleteDatastoreAssociation(
    this: DataStoreAssociationApi,
    requestParameters: DeleteDatastoreAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    runtime.assertParamIsPresent(
      requestParameters.datastoreAssociationId,
      "datastoreAssociationId",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    await this.request(
      {
        path: `/api/dataStoreAssociations/{datastore_association_id}`.replace(
          `{${"datastore_association_id"}}`,
          encodeURIComponent(String(requestParameters.datastoreAssociationId)),
        ),
        method: "DELETE",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );
  }

  async fetchDatastoreAssociation(
    this: DataStoreAssociationApi,
    requestParameters: FetchDatastoreAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DataStoreAssociationDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.datastoreAssociationId,
      "datastoreAssociationId",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/dataStoreAssociations/{datastore_association_id}`.replace(
          `{${"datastore_association_id"}}`,
          encodeURIComponent(String(requestParameters.datastoreAssociationId)),
        ),
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DataStoreAssociationDataResponseFromJSON(jsonValue);
  }

  async listDatastoreAssociations(
    this: DataStoreAssociationApi,
    requestParameters: ListDatastoreAssociationsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DataStoreAssociationListResponse> {
    const queryParameters: any = {};

    if (requestParameters.id != null) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.includeCount != null) {
      queryParameters["include_count"] = requestParameters.includeCount;
    }

    if (requestParameters.offset != null) {
      queryParameters["offset"] = requestParameters.offset;
    }

    if (requestParameters.limit != null) {
      queryParameters["limit"] = requestParameters.limit;
    }

    if (requestParameters.order != null) {
      queryParameters["order"] = requestParameters.order;
    }

    if (requestParameters.sort != null) {
      queryParameters["sort"] = requestParameters.sort;
    }

    if (requestParameters.userId != null) {
      queryParameters["user_id"] = requestParameters.userId;
    }

    if (requestParameters.datastoreId != null) {
      queryParameters["datastore_id"] = requestParameters.datastoreId;
    }

    if (requestParameters.datastoreUserId != null) {
      queryParameters["datastore_user_id"] = requestParameters.datastoreUserId;
    }

    if (requestParameters.manager != null) {
      queryParameters["manager"] = requestParameters.manager;
    }

    if (requestParameters.disabled != null) {
      queryParameters["disabled"] = requestParameters.disabled;
    }

    if (requestParameters.createdBy != null) {
      queryParameters["created_by"] = requestParameters.createdBy;
    }

    if (requestParameters.updatedBy != null) {
      queryParameters["updated_by"] = requestParameters.updatedBy;
    }

    if (requestParameters.deletedBy != null) {
      queryParameters["deleted_by"] = requestParameters.deletedBy;
    }

    if (requestParameters.updatedByNull != null) {
      queryParameters["updated_by_null"] = requestParameters.updatedByNull;
    }

    if (requestParameters.deletedByNull != null) {
      queryParameters["deleted_by_null"] = requestParameters.deletedByNull;
    }

    if (requestParameters.updatedAtNull != null) {
      queryParameters["updated_at_null"] = requestParameters.updatedAtNull;
    }

    if (requestParameters.deletedAtNull != null) {
      queryParameters["deleted_at_null"] = requestParameters.deletedAtNull;
    }

    if (requestParameters.createdAtLte != null) {
      queryParameters["created_at_lte"] =
        requestParameters.createdAtLte.toISOString();
    }

    if (requestParameters.updatedAtLte != null) {
      queryParameters["updated_at_lte"] =
        requestParameters.updatedAtLte.toISOString();
    }

    if (requestParameters.deletedAtLte != null) {
      queryParameters["deleted_at_lte"] =
        requestParameters.deletedAtLte.toISOString();
    }

    if (requestParameters.createdAtGte != null) {
      queryParameters["created_at_gte"] =
        requestParameters.createdAtGte.toISOString();
    }

    if (requestParameters.updatedAtGte != null) {
      queryParameters["updated_at_gte"] =
        requestParameters.updatedAtGte.toISOString();
    }

    if (requestParameters.deletedAtGte != null) {
      queryParameters["deleted_at_gte"] =
        requestParameters.deletedAtGte.toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request(
      {
        path: `/api/dataStoreAssociations`,
        method: "GET",
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DataStoreAssociationListResponseFromJSON(jsonValue);
  }

  async updateDatastoreAssociation(
    this: DataStoreAssociationApi,
    requestParameters: UpdateDatastoreAssociationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<DataStoreAssociationDataResponse> {
    runtime.assertParamIsPresent(
      requestParameters.datastoreAssociationId,
      "datastoreAssociationId",
    );

    runtime.assertParamIsPresent(
      requestParameters.dataStoreAssociationUpdateRequest,
      "dataStoreAssociationUpdateRequest",
    );

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request(
      {
        path: `/api/dataStoreAssociations/{datastore_association_id}`.replace(
          `{${"datastore_association_id"}}`,
          encodeURIComponent(String(requestParameters.datastoreAssociationId)),
        ),
        method: "PATCH",
        headers: headerParameters,
        query: queryParameters,
        body: DataStoreAssociationUpdateRequestToJSON(
          requestParameters.dataStoreAssociationUpdateRequest,
        ),
      },
      initOverrides,
    );

    const jsonValue = await response.json();

    return DataStoreAssociationDataResponseFromJSON(jsonValue);
  }
}
