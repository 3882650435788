import { Box } from "@mui/material";
import { BreakableText } from "~/components/BreakableText";

export function TopicName({
  monospace = false,
  children,
}: {
  monospace?: boolean;
  children: string;
}) {
  return (
    <Box
      component="span"
      sx={monospace ? { fontFamily: "monospace" } : undefined}
    >
      <BreakableText separator={/(\/)/} insertBreak="after">
        {children}
      </BreakableText>
    </Box>
  );
}
