import {
  NewLqsResourceForm,
  processStateOptions,
  processTypeOptions,
  useCreateWorkflowHook,
} from "~/lqs";
import { lqsRoutePaths, useLqsNavigator, useTypedParams } from "~/paths";
import { createWorkflowHookSchema } from "../schemas";

export function WorkflowHookCreateForm() {
  const { workflowId } = useTypedParams(lqsRoutePaths.WORKFLOW_HOOK_CREATE);

  const lqsNavigator = useLqsNavigator({ toWorkflowHookDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="hook"
      registryKey="hook"
      schema={createWorkflowHookSchema}
      descriptors={[
        { name: "name", type: "text" },
        {
          name: "triggerProcess",
          type: "select",
          options: processTypeOptions,
          required: true,
        },
        {
          name: "triggerState",
          type: "select",
          options: processStateOptions,
          required: true,
        },
        { name: "uri", type: "text" },
        { name: "secret", type: "text" },
        { name: "managed", type: "boolean" },
        { name: "disabled", type: "boolean" },
        { name: "note", type: "text", multiline: true },
        { name: "context", type: "json" },
      ]}
      mutation={useCreateWorkflowHook(workflowId)}
      createDetailsLocation={(response) =>
        lqsNavigator.toWorkflowHookDetails({
          workflowId,
          hookId: response.data.id,
        })
      }
    />
  );
}
