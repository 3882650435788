/* tslint:disable */
/* eslint-disable */
/**
 * LogQS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { ProcessType } from "./ProcessType";
import {
  ProcessTypeFromJSON,
  ProcessTypeFromJSONTyped,
  ProcessTypeToJSON,
} from "./ProcessType";

export interface WorkflowCreateRequest {
  name: string;
  processType?: ProcessType;
  note?: string | null;
  context?: object | null;
  _default?: boolean;
  disabled?: boolean;
  managed?: boolean;
  contextSchema?: object | null;
}

export function WorkflowCreateRequestFromJSON(
  json: any,
): WorkflowCreateRequest {
  return WorkflowCreateRequestFromJSONTyped(json);
}

export function WorkflowCreateRequestFromJSONTyped(
  json: any,
): WorkflowCreateRequest {
  if (json == null) {
    return json;
  }

  return {
    name: json["name"],
    processType:
      json["process_type"] === undefined
        ? undefined
        : ProcessTypeFromJSON(json["process_type"]),
    note: json["note"],
    context: json["context"],
    _default: json["default"],
    disabled: json["disabled"],
    managed: json["managed"],
    contextSchema: json["context_schema"],
  };
}

export function WorkflowCreateRequestToJSON(
  value: WorkflowCreateRequest | null | undefined,
): any {
  if (value == null) {
    return value;
  }

  return {
    name: value.name,
    process_type: ProcessTypeToJSON(value.processType),
    note: value.note,
    context: value.context,
    default: value._default,
    disabled: value.disabled,
    managed: value.managed,
    context_schema: value.contextSchema,
  };
}
