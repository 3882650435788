import React from "react";
import { KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Card,
  CardHeader,
  Link,
  Stack,
  svgIconClasses,
  useTheme,
} from "@mui/material";
import type { Path } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

export function RelatedResource({
  text,
  to,
  table,
}: {
  text: string;
  to: Partial<Path>;
  table: React.ReactNode;
}) {
  const theme = useTheme();

  return (
    <Card>
      <CardHeader
        disableTypography
        title={
          <Link
            variant="h4"
            component={RouterLink}
            to={to}
            underline="hover"
            sx={{
              [`.${svgIconClasses.root}`]: {
                transition: theme.transitions.create(["scale", "translate"]),
              },
              [`&:hover .${svgIconClasses.root}`]: {
                scale: "1.1",
                translate: `${theme.spacing(0.5)} 0`,
              },
            }}
          >
            <Stack
              direction="row"
              spacing={0.5}
              sx={{ display: "inline-flex", alignItems: "center" }}
            >
              {text}
              <KeyboardArrowRight sx={{ color: "text.primary" }} />
            </Stack>
          </Link>
        }
      />
      <Box sx={{ px: 2 }}>{table}</Box>
    </Card>
  );
}
