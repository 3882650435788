import { NewLqsResourceForm, useCreateLabel } from "~/lqs";
import { useLqsNavigator } from "~/paths";
import { createLabelSchema } from "../schemas";

export function LabelCreateForm() {
  const lqsNavigator = useLqsNavigator({ toLabelDetails: true });

  return (
    <NewLqsResourceForm
      resourceName="label"
      registryKey="label"
      schema={createLabelSchema}
      descriptors={[
        { name: "value", type: "text", required: true },
        { name: "category", type: "text" },
        { name: "note", type: "text", multiline: true },
      ]}
      mutation={useCreateLabel()}
      createDetailsLocation={(response) =>
        lqsNavigator.toLabelDetails({ labelId: response.data.id })
      }
    />
  );
}
