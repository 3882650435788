import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { LogEditForm } from "./components/LogEditForm";

export function EditLogPage() {
  return (
    <LqsPage title="Edit Log">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <LogEditForm />
      </Container>
    </LqsPage>
  );
}
