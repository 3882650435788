import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { DigestionPartEditForm } from "./components/DigestionPartEditForm";

export function EditDigestionPartPage() {
  return (
    <LqsPage title="Edit Digestion Part">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <DigestionPartEditForm />
      </Container>
    </LqsPage>
  );
}
