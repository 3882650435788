import * as z from "zod";
import { assertNever } from "~/utils/assertNever";
import { MAX_IMAGE_BRIGHTNESS_PCT, MAX_IMAGE_CONTRAST_PCT } from "../constants";
import {
  chartFieldsSchema,
  imageFlipDirectionSchema,
  imageRotationSchema,
  topicNameSchema,
  topicTypeNameSchema,
  visualizationTypeSchema,
} from "./common";
import type { PersistentPanelState } from "./models";
import { persistentPanelStateV5Schema } from "./models";

const persistentPanelStateV1Schema = z.object({
  // Existing v1 objects in storage may not have the version field set so it
  // needs to have a default
  version: z.literal("1").default("1"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: chartFieldsSchema,
  tab: visualizationTypeSchema,
});

const persistentPanelStateV2Schema = z.object({
  version: z.literal("2"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: chartFieldsSchema,
  tab: visualizationTypeSchema,
  image: z.object({
    rotateDeg: imageRotationSchema,
  }), // Added
});

const persistentPanelStateV3Schema = z.object({
  version: z.literal("3"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: chartFieldsSchema,
  tab: visualizationTypeSchema,
  colorizeImage: z.boolean(), // Added
  imageRotationDeg: imageRotationSchema, // `image.rotateDeg` from v2
  imageFlipDirection: imageFlipDirectionSchema.nullable(), // Added
  pointCloudPointSize: z.number().min(0).max(0.1), // Added
});

const persistentPanelStateV4Schema = z.object({
  version: z.literal("4"),
  name: topicNameSchema,
  messageTypeName: topicTypeNameSchema,
  fields: chartFieldsSchema,
  tab: visualizationTypeSchema,
  colorizeImage: z.boolean(),
  imageRotationDeg: imageRotationSchema,
  imageFlipDirection: imageFlipDirectionSchema.nullable(),
  pointCloudPointSize: z.number().min(0).max(0.1),
  imageBrightnessPct: z.number().min(0).max(MAX_IMAGE_BRIGHTNESS_PCT), // Added
  imageContrastPct: z.number().min(0).max(MAX_IMAGE_CONTRAST_PCT), // Added
});

export const previousPersistentPanelStateVersionSchema = z
  .union([
    persistentPanelStateV4Schema,
    persistentPanelStateV3Schema,
    persistentPanelStateV2Schema,
    persistentPanelStateV1Schema,
  ])
  .transform((result): PersistentPanelState => {
    /* eslint-disable no-fallthrough */
    switch (result.version) {
      // @ts-expect-error intentional fallthrough
      case "1": {
        const v2Result: z.infer<typeof persistentPanelStateV2Schema> = {
          ...result,
          version: "2",
          image: {
            rotateDeg: 0,
          },
        };

        result = persistentPanelStateV2Schema.parse(v2Result);
      }
      // @ts-expect-error intentional fallthrough
      case "2": {
        const v3Result: z.infer<typeof persistentPanelStateV3Schema> = {
          ...result,
          version: "3",
          colorizeImage: false,
          imageRotationDeg: result.image.rotateDeg,
          imageFlipDirection: null,
          pointCloudPointSize: 0.05,
        };

        result = persistentPanelStateV3Schema.parse(v3Result);
      }
      // @ts-expect-error intentional fallthrough
      case "3": {
        const v4Result: z.infer<typeof persistentPanelStateV4Schema> = {
          ...result,
          version: "4",
          imageBrightnessPct: 1,
          imageContrastPct: 1,
        };

        result = persistentPanelStateV4Schema.parse(v4Result);
      }
      case "4": {
        const v5Result: z.infer<typeof persistentPanelStateV5Schema> = {
          ...result,
          version: "5",
          supplementaryMapTopics: [],
        };

        return persistentPanelStateV5Schema.parse(v5Result);
      }
      default: {
        assertNever(result);
      }
    }
    /* eslint-enable no-fallthrough */
  });
