import { useState } from "react";
import { invariant } from "~/lib/invariant";
import { useControlledPanelContext } from "../../layout";
import type { InitializedPanelNode, LayoutNode, PanelNode } from "../../panels";
import {
  flattenPanels,
  usePanelLayoutContext,
  VisualizationType,
} from "../../panels";

export interface PanelImageOperation {
  type: "panel-image";
  panel: InitializedPanelNode;
}

export type OnDemandInferenceOperation = PanelImageOperation;

interface OperationController {
  operation: OnDemandInferenceOperation | null;
  lockPanelImageOperationSource: () => void;
  clearPanelImageOperationSource: () => void;
}

export function useOperationController(): OperationController {
  const controlledPanelContext = useControlledPanelContext();

  const { layout } = usePanelLayoutContext();

  const controlledPanel = flattenPanels(layout).find(
    (panel): panel is InitializedPanelNode =>
      panel.isInitialized && panel.id === controlledPanelContext.panelId,
  );

  const [panelImageId, setPanelImageId] = useState<
    InitializedPanelNode["id"] | null
  >(null);

  let operation: OnDemandInferenceOperation | null = null;
  if (panelImageId !== null) {
    operation = {
      type: "panel-image",
      panel: getSourcePanel(layout, panelImageId),
    };
  } else if (
    controlledPanel != null &&
    controlledPanel.visualization === VisualizationType.Image
  ) {
    operation = {
      type: "panel-image",
      panel: controlledPanel,
    };
  }

  return {
    operation,
    lockPanelImageOperationSource() {
      invariant(
        operation != null && operation.type === "panel-image",
        "Cannot lock operation source",
      );

      setPanelImageId(operation.panel.id);
    },
    clearPanelImageOperationSource() {
      setPanelImageId(null);
    },
  };
}

export function isPanelImageOperation(
  operation: OnDemandInferenceOperation | null,
): operation is PanelImageOperation {
  return operation !== null && operation.type === "panel-image";
}

export function isPanelImageOperationSource(
  operation: OnDemandInferenceOperation | null,
  panel: PanelNode,
): boolean {
  return isPanelImageOperation(operation) && operation.panel.id === panel.id;
}

function getSourcePanel(
  root: LayoutNode,
  panelId: PanelNode["id"],
): InitializedPanelNode {
  const foundPanel = flattenPanels(root).find((panel) => panel.id === panelId);

  invariant(foundPanel != null, `Expected to find a panel with ID ${panelId}`);
  invariant(
    foundPanel.isInitialized &&
      foundPanel.visualization === VisualizationType.Image,
    "Expected panel with image visualization",
  );

  return foundPanel;
}
