import {
  Autocomplete,
  autocompleteClasses,
  createFilterOptions,
  TextField,
} from "@mui/material";
import type { StudioFormValues } from "~/components/Form";
import { useField } from "~/components/Form";
import type { BaseFieldProps, Option } from "~/components/Form/types";
import { getFieldLabel } from "~/domain/common";
import { capitalize } from "~/lib/std";
import type { InferencePipelineTask } from "../types";
import type { _OnDemandInferenceFormValues } from "./on-demand-inference";
import { useRecentModels } from "./recent-models";

interface ModelOption extends Option {
  type?: "recent" | "suggested";
}

const filterModels = createFilterOptions<ModelOption>();

export interface PipelineModelFieldProps
  extends BaseFieldProps<
    StudioFormValues<_OnDemandInferenceFormValues>,
    "model"
  > {
  task: InferencePipelineTask | null;
  disabled: boolean;
  onChange: () => void;
  suggestedModels: ReadonlyArray<string>;
}

export function PipelineModelField({
  control,
  name,
  label = getFieldLabel(name),
  task,
  required,
  disabled,
  onChange: onChangeProp,
  suggestedModels,
}: PipelineModelFieldProps) {
  const recentModels = useRecentModels(task);

  const { value, onChange, errorMessage } = useField({ control, name });

  function handleChange(
    _: unknown,
    nextOption: ModelOption | string | null,
  ): void {
    onChange(
      nextOption === null || typeof nextOption === "string"
        ? nextOption
        : nextOption.value,
    );
    onChangeProp();
  }

  const options = new Array<ModelOption>();
  if (recentModels !== null) {
    options.push(
      ...recentModels.map(
        (model): ModelOption => ({
          type: "recent",
          label: model,
          value: model,
        }),
      ),
    );
  }
  options.push(
    ...suggestedModels.map(
      (model): ModelOption => ({
        type: "suggested",
        label: model,
        value: model,
      }),
    ),
  );

  let selectedOption;
  if (value === null) {
    selectedOption = null;
  } else {
    // If no option corresponds to the field value, then the user must've typed
    // the value and selected it, so it's a "custom" option
    selectedOption = options.find((option) => option.value === value) ?? {
      label: `Use "${value}"`,
      value,
    };
  }

  return (
    <Autocomplete
      fullWidth
      freeSolo
      selectOnFocus
      clearOnBlur
      autoHighlight
      disabled={disabled}
      value={selectedOption}
      onChange={handleChange}
      options={options}
      // Display the option's value in the text field when it's selected, not
      // its label. A custom option's label is always 'Use "..."' which isn't
      // what should show up in the text field
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.value
      }
      filterOptions={(options, params) => {
        const filtered = filterModels(options, params);

        if (
          params.inputValue !== "" &&
          !options.some((option) => option.value === params.inputValue)
        ) {
          // The user has typed something that's not a known model, so provide
          // them an "option" to use the input value as the model. This "option"
          // should be at the top of the list
          filtered.unshift({
            label: `Use "${params.inputValue}"`,
            value: params.inputValue,
          });
        } else if (
          selectedOption !== null &&
          !options.some((option) => option.value === selectedOption.value)
        ) {
          // The selected option is some unknown model the user previously
          // typed and selected, so it's not already present in the list of
          // filtered options
          filtered.unshift(selectedOption);
        }

        return filtered;
      }}
      groupBy={(option) => capitalize(option.type ?? "")}
      renderInput={(props) => (
        <TextField
          {...props}
          label={label}
          required={required}
          error={errorMessage !== undefined}
          helperText={errorMessage ?? " "}
        />
      )}
      // Show an option's label in the dropdown so a custom option will show
      // as 'Use "..."'
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      slotProps={{
        paper: {
          sx: {
            [`& .${autocompleteClasses.groupLabel}`]: {
              lineHeight: 2,
            },
          },
        },
      }}
    />
  );
}
