import type { FieldValues, Path } from "react-hook-form";
import type { ResourceSelectFieldProps } from "~/components/Form";
import { ResourceSelectField } from "~/components/Form";
import { useLogQueryOptionsFactory, useLogsQueryOptionsFactory } from "../api";

export function LogSelect<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
>(props: ResourceSelectFieldProps<TFieldValues, TName>) {
  const createLogsQueryOptions = useLogsQueryOptionsFactory();
  const createLogQueryOptions = useLogQueryOptionsFactory();

  return (
    <ResourceSelectField
      {...props}
      createListQueryOptions={(text) =>
        createLogsQueryOptions({
          nameLike: text,
          limit: 5,
          sort: "asc",
          order: "name",
        })
      }
      createFetchQueryOptions={createLogQueryOptions}
      selectOption={(log) => ({
        value: log.id,
        label: log.name,
      })}
    />
  );
}
