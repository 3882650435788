import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { TablePage } from "~/components/Table";
import { TagFilters } from "./components/TagFilters";
import { TagSearchRequestProvider, TagTable } from "./components/TagTable";

export function TagsPage() {
  return (
    <TablePage
      title="Search Tags"
      RequestProvider={TagSearchRequestProvider}
      table={
        <>
          <DataStoreBreadcrumbs />
          <TagTable />
        </>
      }
      filters={<TagFilters />}
    />
  );
}
