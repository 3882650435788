import type { StudioErrorOptions } from "./studio-error";
import { StudioError } from "./studio-error";

export type ValidationErrorSource = "search";

export interface ValidationErrorOptions extends StudioErrorOptions {
  source: ValidationErrorSource;
}

/**
 * Class representing a failure when trying to parse or validate some data.
 */
export class ValidationError extends StudioError {
  /**
   * Describes the source of the invalid data. Useful for error handlers to
   * know how to respond. For example, bad data from URL search params may
   * indicate the search params need cleared for the app to function again.
   */
  source: ValidationErrorSource;

  constructor(options: ValidationErrorOptions) {
    super(options);

    this.source = options.source;
  }
}
