import React, { useState } from "react";
import type { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { createQueryClient } from "~/create-query-client";

export const CommonQueryClientContext = React.createContext<
  QueryClient | undefined
>(undefined);

interface CommonQueryClientProviderProps {
  children: React.ReactNode;
}

/**
 * Provides a `QueryClient` instance for common queries and mutations which can
 * be used in both DSM and LQS areas of Studio. Any common queries or mutations
 * should receive the `CommonQueryClientContext` as their `context` option.
 */
export function CommonQueryClientProvider({
  children,
}: CommonQueryClientProviderProps) {
  const [queryClient] = useState(createQueryClient);

  return (
    <QueryClientProvider
      client={queryClient}
      context={CommonQueryClientContext}
    >
      {children}
    </QueryClientProvider>
  );
}
