import type { StrictOmit } from "ts-essentials";
import type { NewResourceFormProps } from "~/components/Form";
import { NewResourceForm } from "~/components/Form";
import type {
  GetLqsResourceRequest,
  GetLqsResourceResponse,
  LqsResource,
} from "../types";
import { lqsResourceVersionRegistry, useLqsVersion } from "../versioning";
import { renderLqsForeignKeyFormField } from "./render-lqs-foreign-key-form-field";
import type {
  LqsForeignKeyFormFieldType,
  LqsFormFieldDescriptors,
} from "./types";

interface NewLqsResourceFormProps<TResourceKey extends LqsResource>
  extends StrictOmit<
    NewResourceFormProps<
      GetLqsResourceRequest<TResourceKey, "create">,
      GetLqsResourceResponse<TResourceKey, "data">,
      LqsForeignKeyFormFieldType
    >,
    | "descriptors"
    | "apiVersion"
    | "versionHistories"
    | "deprecationPolicy"
    | "renderForeignKeyFormField"
  > {
  registryKey: TResourceKey;
  descriptors: LqsFormFieldDescriptors<
    GetLqsResourceRequest<TResourceKey, "create">
  >;
}

export function NewLqsResourceForm<TResourceKey extends LqsResource>({
  registryKey,
  ...rest
}: NewLqsResourceFormProps<TResourceKey>) {
  const lqsVersion = useLqsVersion();

  return (
    <NewResourceForm
      {...rest}
      apiVersion={lqsVersion}
      versionHistories={
        lqsResourceVersionRegistry[registryKey]?.operations?.create
      }
      deprecationPolicy="hide"
      renderForeignKeyFormField={renderLqsForeignKeyFormField}
    />
  );
}
