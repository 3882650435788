import type { StrictOmit } from "ts-essentials";
import type { ResourceFieldsProps } from "~/components/DetailsCards";
import { ResourceFields } from "~/components/DetailsCards";
import { renderForeignResource } from "./renderForeignResource";
import type {
  GetLqsResourceModel,
  LqsForeignResourceType,
  LqsResource,
} from "./types";
import { lqsResourceVersionRegistry, useLqsVersion } from "./versioning";

interface LqsResourceFieldsProps<TResourceKey extends LqsResource>
  extends StrictOmit<
    ResourceFieldsProps<
      GetLqsResourceModel<TResourceKey>,
      LqsForeignResourceType
    >,
    | "apiVersion"
    | "versionHistories"
    | "deprecationPolicy"
    | "renderForeignResource"
  > {
  registryKey: TResourceKey;
}

export function LqsResourceFields<TResourceKey extends LqsResource>({
  registryKey,
  ...rest
}: LqsResourceFieldsProps<TResourceKey>) {
  const apiVersion = useLqsVersion();

  return (
    <ResourceFields
      {...rest}
      apiVersion={apiVersion}
      versionHistories={lqsResourceVersionRegistry[registryKey]?.model}
      deprecationPolicy="hide"
      renderForeignResource={renderForeignResource}
    />
  );
}
