import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { DigestionPartDetails } from "./components/DigestionPartDetails";

export function DigestionPartPage() {
  return (
    <LqsPage title="Digestion Part Details">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <DigestionPartDetails />
      </Container>
    </LqsPage>
  );
}
