import type { Topic } from "~/lqs";
import { VisualizationType } from "../../panels";
import type { BasicRecordsSnapshot } from "../../record-store";
import { useRecords } from "../../record-store";
import { useVisualizationStoreParams } from "../context";

export function useThreeDRecords(topic: Topic): {
  snapshot: BasicRecordsSnapshot<"threeD">;
  isPlaceholder: boolean;
} {
  const storeParams = useVisualizationStoreParams(VisualizationType.ThreeD);
  return useRecords({
    recordType: "threeD",
    topic,
    ...storeParams,
  });
}
