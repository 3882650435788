import { Container } from "@mui/material";
import { DataStoreBreadcrumbs } from "~/components/DataStoreBreadcrumbs";
import { LqsPage } from "~/lqs/layout";
import { IngestionPartCreateForm } from "./components/IngestionPartCreateForm";

export function NewIngestionPartPage() {
  return (
    <LqsPage title="New Ingestion Part">
      <Container fixed>
        <DataStoreBreadcrumbs />
        <IngestionPartCreateForm />
      </Container>
    </LqsPage>
  );
}
